import React from 'react';

import { AtomicSwapStatus, useAcceptBlockchainSwapMutation } from 'services/apollo/graphql';

import { BsSwal, Button, FontAweIcon } from 'atoms';
import { useTranslation } from 'react-i18next';
import { Address, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import atomicSwapContractAbi from 'abis/atomicSwapContractAbi.json';
import Web3 from 'web3';
import BlockchainNetworkSwitchButton from 'components/BlockchainNetworkSwitchButton';

interface AtomicSwapCloseButtonProps {
  chainID: number;
  blockchainExplorer: string;
  swapContractAddress: string;
  setAtomicSwapCurrentStatus: (atomicSwapStatus: AtomicSwapStatus) => void;
  offerID: number;
  isSell: boolean;
  swapSecret: string;
  originatorWalletAddress: string;
  atomicSwapStatus: AtomicSwapStatus;
}

const AtomicSwapCloseButton: React.FC<AtomicSwapCloseButtonProps> = ({
  chainID,
  blockchainExplorer,
  swapContractAddress,
  setAtomicSwapCurrentStatus,
  offerID,
  isSell,
  originatorWalletAddress,
  swapSecret,
  atomicSwapStatus,
}) => {
  const { t } = useTranslation();
  const [acceptBlockchainSwap] = useAcceptBlockchainSwapMutation();
  const { chain } = useNetwork();
  const isBuyerSellerCommitted = [AtomicSwapStatus.BuyerCommitted, AtomicSwapStatus.SellerCommitted].includes(
    atomicSwapStatus,
  );

  const _originator = originatorWalletAddress;
  const _swapNumber = Web3.utils.toHex(swapSecret);

  const { config } = usePrepareContractWrite({
    address: swapContractAddress as Address,
    abi: atomicSwapContractAbi,
    functionName: 'close',
    args: [_originator, _swapNumber],
    enabled: isBuyerSellerCommitted,
  });

  const {
    write,
    isLoading,
    data: writeContractData,
  } = useContractWrite({
    ...config,
    chainId: chainID,
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  const { isLoading: transactionLoading } = useWaitForTransaction({
    hash: writeContractData?.hash,
    enabled: isBuyerSellerCommitted,
    onSuccess(data) {
      acceptBlockchainSwap({
        variables: { offerID, walletAddress: data.from.toString() },
      })
        .then(() => {
          if (isSell) {
            setAtomicSwapCurrentStatus(AtomicSwapStatus.BuyerCompleted);
          }
          if (!isSell) {
            setAtomicSwapCurrentStatus(AtomicSwapStatus.SellerSent);
          }
          BsSwal.fire({
            title: t('useAtomicSwap-closeSwap-PopUp-success-title'),
            icon: 'success',
            footer: `<a target="_blank" href="${blockchainExplorer}tx/${data.transactionHash}">
              ${t('useAtomicSwap-closeSwap-PopUp-success-footer')}</a>`,
          });
        })
        .catch((error) => {
          BsSwal.fire({
            title: t('Error'),
            icon: 'error',
            text: error.message,
          });
        });
    },
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  return (
    <>
      {chain?.id !== chainID && isBuyerSellerCommitted ? (
        <BlockchainNetworkSwitchButton chainID={chainID} />
      ) : (
        <>
          <Button size="lg" disabled={transactionLoading || isLoading} onClick={() => write?.()}>
            {t('AtomicSwapComponent-button-finalizeSwap')}
          </Button>
          {(transactionLoading || isLoading) && (
            <p>
              <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
              {t('AtomicSwapComponent-label-step3Loading')}
            </p>
          )}
        </>
      )}
    </>
  );
};

export default AtomicSwapCloseButton;
