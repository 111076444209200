import React, { useState } from 'react';
import { FormFeedback, CustomInput } from 'reactstrap';

import Auth from 'services/core/auth';
import { useSignUpMutation, useInvestorAppDataQuery } from 'services/apollo';

import { Button, Form, FormGroup, Input, Loading } from 'atoms';
import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import RegistrationInstructions from 'pages/auth/RegistrationInstructions';
import RegistrationSuccess from 'pages/auth/RegistrationSuccess';
import { getTranslationKeyOfApiError } from 'services/core/helpers';
import SecretInput from './SecretInput';

const checkPass = (value) => {
  let passChecks = 0;
  passChecks += /[a-z]/.test(value) ? 1 : 0;
  passChecks += /[A-Z]/.test(value) ? 1 : 0;
  passChecks += /\d/.test(value) ? 1 : 0;
  passChecks += /[^\w\d\s]/.test(value) ? 1 : 0;
  return passChecks > 2;
};



const RegistrationForm = ({ registrationText, title, brokerID }) => {
  const { t } = useTranslation();
  const [stateForm, setStateForm] = useState({});
  const [rePassword, setRePassword] = useState('');
  const [message, setMessage] = useState('');
  const [success, setSuccess] = useState(false);
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);
  const [agree, setAgree] = useState(false);
  const [agreeTeam, setAgreeTeam] = useState(false);
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (appDataLoading || !appData) {
    return <Loading />;
  }

  const termsAndConditionsLink = appData?.investorAppParameters?.termsAndConditionsConfig?.link;
  const termsAndConditionstext = appData?.investorAppParameters?.termsAndConditionsConfig?.text;

  const onChange = (e) => {
    setMessage('');
    setErrors({});
    const newState = { [e.currentTarget.name]: e.currentTarget.value };
    setStateForm({ ...stateForm, ...newState });
  };

  const validation = (form) => {
    if (!form.firstName) {
      setErrors({ firstName: t('RegistrationForm-error-firstName') });
      setMessage(t('RegistrationForm-field-error-firstName'));
      return false;
    }

    if (!form.lastName) {
      setErrors({ lastName: t('RegistrationForm-error-lastName') });
      setMessage(t('RegistrationForm-field-error-lastName'));
      return false;
    }

    if (!form?.email?.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)) {
      setMessage(t('RegistrationForm-field-error-email'));
      return false;
    }

    if (rePassword !== stateForm.password) {
      setMessage(t('RegistrationForm-field-error-distinctPasswords'));
      return false;
    }

    if (!checkPass(stateForm.password)) {
      setMessage(t('RegistrationForm-field-error-weakPassword'));
      return false;
    }

    if (form.password.length < 8) {
      setMessage(t('RegistrationForm-field-error-smallPassword'));
      return false;
    }

    if (form.password.length > 100) {
      setMessage(t('RegistrationForm-field-error-bigPassword'));
      return false;
    }

    if (!agree) {
      setMessage(t('RegistrationForm-field-error-agree'));
      return false;
    }

    if (!agreeTeam) {
      setMessage(t('RegistrationForm-field-error-agreeTeam'));
      return false;
    }

    return true;
  };

  const [signup] = useSignUpMutation();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsDisabled(true);

    const data = {
      ...stateForm,
      stoID: Number(Auth.STO),
      ...(brokerID && { brokerID }),
    };

    if (validation(data)) {
      signup({ variables: { data } })
        .then(() => setSuccess(true))
        .catch((error) => {
          setIsDisabled(false);
          const gqlError = getTranslationKeyOfApiError(error).message;
          setMessage('Fix Error and Try Again.');
          setMessage(t(gqlError));
        });
    } else {
      setIsDisabled(false);
    }
  };

  if (success) {
    return <RegistrationSuccess email={stateForm.email} />;
  }

  const { isRegistrationFormStepsInstructionsEnabled } = appData;
  return (
    <div>
      <Markup content={registrationText} />
      {isRegistrationFormStepsInstructionsEnabled ? <RegistrationInstructions title={title} /> : null}
      <Form onSubmit={handleSubmit} style={{ width: '40%' }} className="m-auto">
        <h3 className="text-center">{t('RegistrationForm-card-form-header')}</h3>
        <FormGroup>
          <label>{t('RegistrationForm-label-firstName')}</label>
          <Input
            max="60"
            name="firstName"
            onChange={onChange}
            value={stateForm.firstName || ''}
            invalid={message.includes('first name')}
          />
          <FormFeedback className="h6 text-lowercase">{errors.firstName} </FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>{t('RegistrationForm-label-lastName')}</label>
          <Input
            max="60"
            name="lastName"
            value={stateForm.lastName || ''}
            onChange={onChange}
            invalid={message.includes('last name')}
          />
          <FormFeedback className="h6 text-lowercase">{errors.lastName} </FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>{t('RegistrationForm-label-email')}</label>
          <Input
            max="70"
            name="email"
            onChange={onChange}
            value={stateForm.email || ''}
            autoComplete="new-email"
            invalid={!!errors.email || message.includes('email')}
          />
          <FormFeedback className="h6 text-lowercase">{errors.email} </FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>{t('RegistrationForm-label-password')}</label>
          <SecretInput
            inputName="password"
            onChange={onChange}
            inputValue={stateForm.password || ''}
            placeholderText="Login-placeholder-password"
            invalidCheck={!!errors.password || message.startsWith('Password')}
          />
          <label>{` ${t('RegistrationForm-label-password-requirements')}`}</label>
          <FormFeedback className="mt-n2 h6 text-lowercase">{errors.password} </FormFeedback>
        </FormGroup>
        <FormGroup>
          <label>{t('RegistrationForm-label-password-retype')}</label>
          <SecretInput
            inputName="rePassword"
            setInputValue={setRePassword}
            inputValue={rePassword || ''}
            placeholderText="Login-placeholder-password"
            invalidCheck={!!message.startsWith('Passwords')}
          />
        </FormGroup>
        <hr className="mt-mb-3" />
        <FormGroup>
          <CustomInput
            id="id_0"
            className="z-none"
            type="checkbox"
            checked={agree}
            onChange={() => setAgree(!agree)}
            label={
              <>
                <Markup content={t('marketSpaceForm-termsAndConditions')} />
                <a href={termsAndConditionsLink}> {termsAndConditionstext}</a>
              </>
            }
            />

        </FormGroup>
        <FormGroup>
          <CustomInput
            id="id_1"
            className="z-none"
            type="checkbox"
            checked={agreeTeam}
            onChange={() => setAgreeTeam(!agreeTeam)}
            label={
              <Markup content={t('marketSpaceForm-riskyInvestmentNotice')} />
            }
          />
        </FormGroup>
        {message && <span style={{ color: 'red', fontSize: '16px' }}>{message}</span>}
        <br />
        <div className="text-center">
          <Button disabled={isDisabled} size="md" onClick={handleSubmit}>
            {t('RegistrationForm-button-submit')}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default RegistrationForm;
