import React, { useEffect, useState } from 'react';
import { BsSwal, Button, CardBody, Col, Loading, Row } from 'atoms';
import { CardH4, CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import WALLET from 'assets/img/blockchainwallet.png';
import { Card, Label, Modal, Spinner } from 'reactstrap';
import Web3 from 'web3';
import { InvestorBuyPropertyShareType } from 'services/apollo/multisto';
import {
  GetAllSharesWalletsDocument,
  GetSharesWalletsDocument,
  useAutomatedWalletWhitelistMutation,
  useFindAllBlockchainProtocolsQuery,
  useGetAllSharesWalletsQuery,
  useInvestorPublicKeyAddMutation,
} from 'services/apollo';
import { BLOCKCHAIN_ID } from 'pages/settings/components/EthereumKeys';
import { BLOCKCHAIN_PROTOCOL } from 'pages/exchange/constants';
import WhitelistProgressionDisplay from 'pages/settings/components/WhitelistProgressionDisplay';
import WhitelistProgressionBar from 'pages/settings/components/WhitelistProgressionBar';
import { useHistory } from 'react-router-dom';
import { Address, useAccount } from 'wagmi';

interface WhitelistWalletFormProps {
  hideModal: () => void;
  shareType: InvestorBuyPropertyShareType;
}

const WhitelistWalletForm: React.FC<WhitelistWalletFormProps> = ({ hideModal, shareType }) => {
  const { data: whiteListedWalletsData, loading: whitelistedWalletsLoading } = useGetAllSharesWalletsQuery();
  const { data: blockchainProtocolsData, loading: blockchainProtocolsLoading } = useFindAllBlockchainProtocolsQuery();
  const [addKey] = useInvestorPublicKeyAddMutation({
    refetchQueries: [{ query: GetAllSharesWalletsDocument }],
  });
  const { t } = useTranslation();
  const [publickey, setPublickey] = useState('');
  const [automatedWalletWhitelist] = useAutomatedWalletWhitelistMutation();
  const [isWhitelistingProcessing, setIsWhitelistingProcessing] = useState(false);
  const [isTokenAdded, setIsTokenAdded] = useState(false);
  const [walletAdded, setWalletAdded] = useState(false);
  const { isConnected, address } = useAccount();
  const history = useHistory();
  useEffect(() => {
    if (isConnected) {
      setPublickey(address as string);
    }
    if (isTokenAdded) {
      setInterval(() => {
        history.go(0);
      }, 5000);
    }
  }, [address, isConnected, isTokenAdded]);

  if (
    whitelistedWalletsLoading ||
    !whiteListedWalletsData?.getSharesWallets ||
    blockchainProtocolsLoading ||
    !blockchainProtocolsData
  ) {
    return <Loading />;
  }

  const getBlockchainID = (shareProtocols?: BLOCKCHAIN_PROTOCOL) => {
    const protocols = blockchainProtocolsData.findAllBlockchainProtocols;
    const found = protocols?.find((p) => shareProtocols === p.ID);
    return found?.blockchainID || 0;
  };
  const radioEthereumStatus = getBlockchainID(Number(shareType.blockchainProtocolID));
  const whitelistedWallets = whiteListedWalletsData.getSharesWallets;

  const importTokenToWallet = async (inputAddress: string, symbol: string, decimals: number) => {
    if (window.ethereum) {
      const isAdded = await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: inputAddress as Address,
            symbol,
            decimals,
          },
        },
      });
      setIsTokenAdded(isAdded);
    }
  };

  const onAdd = async () => {
    if (!isConnected) {
      return BsSwal.fire({
        title: t('Please-enter-new-public-key'),
        icon: 'error',
      });
    }
    if (!shareType.ID) {
      return BsSwal.fire({
        title: t('EthereumKeys-popUp-shareTypeID-not-selected'),
        icon: 'error',
      });
    }

    if (radioEthereumStatus === 0) {
      return BsSwal.fire({
        title: t('please-select-address-type'),
        icon: 'error',
      });
    }

    const shareHasWallet = whitelistedWallets.filter((obj) => {
      return obj.shareType.ID === shareType.ID;
    });
    if (shareHasWallet.length > 0) {
      return BsSwal.fire({
        title: t('EthereumKeys-popUp-onlyOneWalletPerShareType'),
        icon: 'error',
      });
    }

    if (
      radioEthereumStatus !== BLOCKCHAIN_ID.RAVENCOIN /** Ignore checksum on Ravencoin */ &&
      !Web3.utils.isAddress(publickey)
    ) {
      return BsSwal.fire({
        title: t('invalid-ethereum-address'),
        icon: 'error',
      });
    }

    if (
      [BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX].includes(radioEthereumStatus) &&
      !isConnected
    ) {
      return BsSwal.fire({
        title: t('metamask-disconnected-verify'),
        icon: 'error',
      });
    }

    if (
      [BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX].includes(radioEthereumStatus) &&
      publickey?.toLowerCase() !== address?.toLowerCase()
    ) {
      return BsSwal.fire({
        title: t('incorrect-metamask-account-selected'),
        icon: 'error',
      });
    }

    if (radioEthereumStatus === BLOCKCHAIN_ID.RAVENCOIN && publickey.length <= 32) {
      return BsSwal.fire({
        title: t('invalid-ravencoin-address'),
        icon: 'error',
      });
    }

    setPublickey(publickey);
    setIsWhitelistingProcessing(true);
    await saveNewKey();
  };

  const saveNewKey = async () => {
    try {
      await addKey({
        variables: { title: address as string, blockchainID: radioEthereumStatus },
      });
      await doWhitelisting();
    } catch (err) {
      setIsWhitelistingProcessing(false);
      await BsSwal.fire({
        title: (err as Error)?.message,
        icon: 'error',
      });
    }
  };
  const doWhitelisting = async () => {
    // only automatically whitelist ethereum wallets
    if (![BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX].includes(radioEthereumStatus)) {
      setIsWhitelistingProcessing(false);
      return;
    }
    const whitelistResult = await automatedWalletWhitelist({
      variables: {
        shareTypeID: shareType.ID,
        walletAddress: publickey,
      },
      refetchQueries: [{ query: GetSharesWalletsDocument }],
    });
    setIsWhitelistingProcessing(false);
    const isWhitelistCompleted = whitelistResult.data?.whitelistWallet;
    if (isWhitelistCompleted) {
      setWalletAdded(true);
      await BsSwal.fire({
        title: t('EthereumKeys-popUp-wallet-successfully-whitelisted'),
        timer: 8000,
        icon: 'success',
      });
    } else {
      await BsSwal.fire({
        title: t('EthereumKeys-popUp-manualApproval'),
        icon: 'success',
        timer: 8000,
      });
    }
  };

  return (
    <>
      <CardHeader text={t('Shared-Ethereum-Public-Keys')} imgSrc={WALLET} />
      <CardBody>
        <WhitelistProgressionBar
          radioEthereumStatus={radioEthereumStatus}
          publickey={publickey}
          walletAdded={walletAdded}
          isTokenAdded={isTokenAdded}
          isConnected={isConnected}
        />
        <Label className="mt-2">{t('Enter-a-new-key-from-your-wallet')}</Label>
        <Row>
          <Col>
            {publickey.length ? (
              <CardH4>{publickey}</CardH4>
            ) : (
              <b>
                <Label className="mt-2">{t('EthereumKeys-Body-NoAddressPleaseConnect')}</Label>
              </b>
            )}
          </Col>
        </Row>
        {[BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX].includes(radioEthereumStatus) &&
        !publickey.length ? (
          <>
            <WhitelistProgressionDisplay
              condition={!!publickey.length}
              translationKey="EthereumKeys-walletWhitelist-step1"
            />
          </>
        ) : null}
        {publickey.length && !walletAdded ? (
          <>
            <WhitelistProgressionDisplay condition={walletAdded} translationKey="EthereumKeys-walletWhitelist-step2" />
            <Row>
              <Col>
                <Button onClick={onAdd}>{t('Add-Key')}</Button>
              </Col>
            </Row>
          </>
        ) : null}
        {walletAdded && !isTokenAdded ? (
          <>
            <WhitelistProgressionDisplay condition={isTokenAdded} translationKey="EthereumKeys-walletWhitelist-step3" />
            <Row>
              <Col>
                <Button
                  onClick={() =>
                    importTokenToWallet(
                      shareType.ethereumContractAddress ?? '',
                      shareType.assetTag !== '' ? shareType.assetTag ?? 'TKN' : 'TKN',
                      shareType.blockchaindecimals,
                    )
                  }
                >
                  {t('SharesWallet-button-importTokens')}
                </Button>
              </Col>
              <Col md={2}>
                <Button disabled={isWhitelistingProcessing} onClick={() => hideModal()}>
                  {t('Cancel')}
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
        {isTokenAdded ? (
          <WhitelistProgressionDisplay
            condition={isTokenAdded}
            translationKey="WhitelistWalletForm-walletWhitelist-done"
          />
        ) : null}
      </CardBody>
      <Modal isOpen={isWhitelistingProcessing}>
        <Card>
          <CardHeader text={t('EthereumKeys-modal-title-processing')} />
          <CardBody>
            <Row>
              <Col style={{ display: 'flex', justifyContent: 'center' }}>
                <Spinner style={{ color: '#00cece' }} />
              </Col>
            </Row>
            <br />
            <Row>
              <Col>{t('EthereumKeys-modal-text-processing')}</Col>
            </Row>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};

export default WhitelistWalletForm;
