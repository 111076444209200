import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MetaValues, StoMetaValue } from 'services/apollo';
import MetaContent from 'pages/active-properties/components/MetaContent';
import { Card } from 'atoms';
import { StyledDynamicContainer } from 'pages/active-properties/components/rightLayout/styled/styled';
import styled from 'styled-components';
import Tabs from 'pages/active-properties/components/rightLayout/Tabs';

export interface DynamicTabContentProps {
  meta: Array<StoMetaValue> | Array<MetaValues>;
  keys: string[];
  tabs: TabContentType[] | [];
}

export interface TabContentType {
  key: string;
  tabs: MetaValues[];
}

const DynamicTabContent: React.FC<DynamicTabContentProps> = ({ meta, keys, tabs }) => {
  const { t } = useTranslation();
  const [active, setActive] = useState(keys[0]);

  const isChart = active === 'left_sidebar_chart';

  if (!keys.length) {
    return <></>;
  }

  return (
    <StyledDynamicContainer>
      <h5 className="dynamic-content__title"> {t('DynamicTabContent-title')}</h5>
      <StyledCard className="dynamic-content__content">
        <Tabs keys={keys} active={active} setActive={setActive} />
        <div className="dynamic-content__content-container">
          <MetaContent
            key={active}
            meta={meta}
            name={active}
            rightLayout
            isChart={isChart}
            currentTabContent={tabs}
            tabName={active}
          />
        </div>
      </StyledCard>
    </StyledDynamicContainer>
  );
};

export default DynamicTabContent;

const StyledCard = styled(Card)`
  background-color: ${(p) => p.theme.offeringDetailsCardBGColor};
`;
