import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import { FixedPluginRow } from 'components/fixed-plugin/components';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const FooterCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const sidebarFontsColor = [colors.white, colors.textGrey, colors.black];
  const fontWeigths = [t('FixedPlugin-lighter'), '300', t('FixedPlugin-normal'), t('FixedPlugin-bold')];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-Footer')}</h5>
        <SettingsBarCarcet className="ti-angle-left" open={collapse} />
      </div>

      <Collapse isOpen={collapse}>
        <FixedPluginRow
          values={sidebarFontsColor}
          property="fontColorFooter"
          handleChange={handleChange}
          title="FixedPluginRow-Title-FooterFontColor"
          currentColor={theme.fontColorFooter}
        />
        <FixedPluginRow
          values={fontWeigths}
          property="fontWeightFooter"
          handleChange={handleChange}
          title="FixedPluginRow-Title-FooterFontWeight"
          currentValue={theme.fontWeightFooter}
          notColors
        />
      </Collapse>
    </div>
  );
};

export default FooterCustomizer;
