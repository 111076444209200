import React, { useState } from 'react';

import { BsSwal, Button, Input, Select } from 'atoms';
import { useTranslation } from 'react-i18next';
import { useCreateTopUpAlertMutation } from 'services/apollo';
import { useActiveSto, useScrollBlock } from 'hooks';
import { stripToNumber } from 'lib/utils';
import { arrayRemoveDuplicates, getCurrencySymbol } from 'services/core/helpers';
import { AppData } from 'services/apollo/core';
import { InvestorWalletChannel } from 'services/apollo/multisto';
import { SelectOption } from 'components/navbar/StoSwitchList';
import { useHistory } from 'react-router-dom';

export interface WalletTopUpProps {
  appData: AppData;
  channels: InvestorWalletChannel[];
}

const WalletTopUp: React.FC<WalletTopUpProps> = ({ appData, channels }) => {
  const { sto } = useActiveSto();
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const [createTopUpAlert] = useCreateTopUpAlertMutation();
  const [amount, setAmount] = useState(0);
  const [selectedCurrencyID, setCurrency] = useState(0);
  const [blockScroll, allowScroll] = useScrollBlock();

  const options = arrayRemoveDuplicates(
    channels.map((channel) => {
      return {
        value: channel.currency.ID,
        label: `${channel.currency.symbol} (${channel.currency.abbreviation})`,
      };
    }),
    'value',
  );

  const handleSubmit = async () => {
    const amountDisplay = getCurrencySymbol({
      locale: i18n.language,
      data: {
        value: amount,
        currency: channels.find((channel) => channel.currency.ID === selectedCurrencyID)?.currency,
      },
      currencySymbolDisplayMode: appData.currencySymbolDisplayMode,
    });
    const { isConfirmed } = await BsSwal.fire({
      title: t('WalletTopUp-BsSwal-title'),
      text: t('WalletTopUp-BsSwal-text', { amountDisplay }),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('WalletTopUp-BsSwal-Confirm'),
      cancelButtonText: t('WalletTopUp-BsSwal-Cancel'),
    });
    if (!isConfirmed) {
      return;
    }
    const { data: topUpResult } = await createTopUpAlert({
      variables: {
        data: {
          stoID: sto,
          amount,
          currencyID: selectedCurrencyID,
        },
      },
    });

    await BsSwal.fire({
      title: t('WalletTopUp-redirectToInvoice-popUp-title'),
      text: t('WalletTopUp-redirectToInvoice-popUp-text'),
      icon: 'success',
      confirmButtonText: t('WalletTopUp-redirectToInvoice-popUp-confirm'),
      timer: 5000,
      didClose: () => {
        history.push(`/investor/invoice/${topUpResult?.createTopUpAlert.ID}`);
      },
    });
  };

  const changeValueFromInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputAmount = stripToNumber(0, e);
    setAmount(inputAmount);
  };

  const onChangeSelect = (option: SelectOption) => {
    setCurrency(+option.value);
  };

  return (
    <>
      <Select class="form-control border-input" style={{ zIndex: '100' }} options={options} onChange={onChangeSelect} />
      <br />
      <Input
        type="number"
        min={0}
        step={1}
        onChange={changeValueFromInput}
        onFocus={blockScroll}
        onBlur={allowScroll}
        value={amount || ''}
      />
      <Button size="sm" onClick={handleSubmit}>
        {t('WalletTopUp-button-topUp')}
      </Button>
    </>
  );
};

export default WalletTopUp;
