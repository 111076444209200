import React, { FC, useRef, useState } from 'react';
import { ChromePicker } from 'react-color';
import styled, { css } from 'styled-components';
import { ThemeType } from 'lib/themes/themeTypes';

type ColorPickerProps = {
  currentColor: string | undefined;
  property: keyof ThemeType;
  handleChangeColor: (name: keyof ThemeType, value: string) => void;
  notActive: boolean;
};

export const ColorPicker: FC<ColorPickerProps> = ({ currentColor, property, handleChangeColor, notActive }) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const popoverRef = useRef<HTMLDivElement>(null);
  const swatchRef = useRef<HTMLDivElement>(null);
  const [fromTop, setFromTop] = useState(false);

  const parentEl = document.querySelector('#ModalSideBar');

  const containerEl = document.querySelector('#ScrollContainer');

  const calculateFromTop = () => {
    // It gets how far the Swatch is from the top of the window
    const swatchFromTop = swatchRef.current?.getBoundingClientRect().top || 0;

    // It gets how far the top of the container from the top of the window
    const containerFromTop = containerEl?.getBoundingClientRect().top || 0;

    // Their difference will determine whether the color picker has enough space or not
    const result = swatchFromTop - containerFromTop;

    // Based on the result the picker will be positioned either from top or the the bottom
    setFromTop(result < 250);
  };

  if (containerEl) {
    containerEl.addEventListener('scroll', calculateFromTop);
  }

  if (parentEl) {
    parentEl.addEventListener('click', (e) => {
      if (e.target instanceof Element && e.target.closest(`.color-picker`)) {
        return;
      }
      setDisplayColorPicker(false);
    });
  }

  return (
    <div className="ml-2">
      <Swatch
        ref={swatchRef}
        className={notActive ? '' : 'active'}
        onClick={() => {
          calculateFromTop();
          setDisplayColorPicker(!displayColorPicker);
        }}
      >
        <ColorBlock currentColor={currentColor} />
      </Swatch>
      {displayColorPicker ? (
        <Popover className="color-picker" ref={popoverRef} fromTop={fromTop}>
          <ChromePicker color={currentColor} onChange={(e) => property && handleChangeColor(property, e.hex)} />
        </Popover>
      ) : null}
    </div>
  );
};

export default ColorPicker;

const Popover = styled.div<{ fromTop: boolean }>`
  position: absolute;
  right: 90%;
  z-index: 2;

  ${(p) => {
    if (p.fromTop) {
      return css`
        top: 0%;
      `;
    }
    return css`
      bottom: 0%;
    `;
  }};
`;

const Swatch = styled.div`
  border: 1px solid white;
  border-radius: 4px;
  box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
  display: inline-block;
  cursor: pointer;
  transition: all 150ms ease-in-out;

  &.active {
    box-shadow: none;
    border: 2px solid #00bbff;

    &:hover {
      box-shadow: none;
    }
  }

  &:hover {
    box-shadow: 1px 4px 7px 1px rgba(0, 0, 0, 0.4);
  }
`;

const ColorBlock = styled.div<{ currentColor: string | undefined }>`
  width: 60px;
  height: 20px;
  border-radius: 2px;
  background: ${(p) => p.currentColor};
`;
