import React from 'react';
import { Card, CardBody, Label, Loading } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';

import { SellSideEntity } from 'services/apollo/sell-side-entity';
import styled, { useTheme } from 'styled-components';
import { ThemeType } from 'lib/themes/themeTypes';
import { useInvestorActivePropertiesQuery } from 'services/apollo';
import ActiveProperty from 'pages/active-properties/ActiveProperty';

export interface ManagedStosTableProps {
  sellEntity: SellSideEntity;
}

const ManagedStosTomstones: React.FC<ManagedStosTableProps> = (props) => {
  const { t } = useTranslation();
  const { sellEntity } = props;
  const theme: ThemeType = useTheme() as ThemeType;
  const { data: activePropertyData, loading: activePropertyLoading } = useInvestorActivePropertiesQuery({
    variables: {
      buyEnabledFilterInput: {
        isBuyStoEnabled: true,
      },
    },
    fetchPolicy: 'no-cache',
  });
  if (!activePropertyData || activePropertyLoading) {
    return <Loading />;
  }

  const managedStoIds = sellEntity.getStos?.map((sto) => sto.ID) ?? [];
  const properties = activePropertyData.investorActiveProperties.filter((p) => managedStoIds.includes(p.ID));
  const cardStyle = {
    backgroundColor: theme.backgroundSideBar,
    fontWeight: theme.fontWeightSideBar,
    color: theme.fontColorSideBar,
  };

  return (
    <div className="content">
      <Card style={cardStyle}>
        <CardHeader
          text={t('SellSideEntityDetails-managingStos-card-header', { count: sellEntity.getStos?.length })}
          imgSrc="/img/agendaitem.png"
        />
        <CardBody>
          {sellEntity.getStos?.length ? (
            <Content>
              {properties.map((sto) => (
                <Item key={sto.ID}>
                  <ActiveProperty props={sto} />
                </Item>
              ))}
            </Content>
          ) : (
            <Label>{t('SellSideEntityDetails-no-managed-stos')}</Label>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ManagedStosTomstones;

const Content = styled.div`
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`;

const Item = styled.div`
  align-content: center;
  vertical-align: top;
`;
