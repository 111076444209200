import React, { useEffect, useState } from 'react';
import styled, { useTheme, css } from 'styled-components';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useHotkeys } from 'react-hotkeys-hook';

import routes, { InternalRoutes } from 'routes';
import { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import verifyRoutes from 'lib/routing/route-sets/verify-routes';
import Sidebar from 'components/sidebar/Sidebar';
import InvestorNavbar from 'components/navbar/InvestorNavbar';
import ModalForm, { ModalProvider } from 'components/modals/Modal';
import FixedPlugin from 'components/fixed-plugin/FixedPlugin';
import { isSingle } from 'services/core/helpers';
import Footer from 'components/Footer';
import { delay } from 'lodash';
import useScript from 'hooks/useScript';

const InvestorLayout = (props) => {
  const { investorAppParameters } = props;
  const { t, i18n } = useTranslation();
  const { toggleThemeEditor, defaultLoginRoute, investorPageScriptProps, isInvestorNavigationDisabled } =
    investorAppParameters;
  useScript(investorPageScriptProps);

  const [showStyling, setShowStyling] = useState(toggleThemeEditor);
  useHotkeys('ctrl+k', () => setShowStyling(toggleThemeEditor && !showStyling), {}, [showStyling]);
  const [state, setState] = useState({
    language: 'English',
    isOpen: false,
    leftSideMenuFont: '',
    poweredByLabel: '',
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  });

  const handleChangeLang = (lg) => {
    i18n.changeLanguage(lg);
  };

  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const theme = useTheme();

  const getRoutes = (routs) =>
    routs.map(({ show, featureFlagName, ...prop }) => {
      if (show && show() === false) {
        return null;
      }
      if (featureFlagName && !investorAppParameters[featureFlagName]) {
        return <Redirect exact from={prop.layout + prop.path} to="/" key={prop.path} />;
      }
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === '/investor') {
        return <Route path={prop.layout + prop.path} component={prop.component} key={prop.path} />;
      }
      return null;
    });

  const { language } = state;

  const isProfileLocation = Boolean(verifyRoutes.find((e) => pathname.includes(e.path)));

  const currentRoutes = (isProfileLocation && verifyRoutes) || routes(t);
  if (pathname === '/investor') {
    if (isSingle()) {
      return <Redirect to="/investor/overview" />;
    }
    if (defaultLoginRoute && defaultLoginRoute !== '') {
      return <Redirect to={defaultLoginRoute} />;
    }
    return <Redirect to="/investor/active-properties" />;
  }

  const { isOpen, modalData, modalRedirect } = state;
  const showModal = (data, redirectModal) =>
    setState({
      isOpen: true,
      modalData: data,
      modalRedirect: redirectModal,
    });
  const hideModal = () => {
    setState({ isOpen: false });
    if (modalRedirect) {
      delay(() => {
        modalRedirect();
      }, 200);
    }
  };

  return (
    <div className="wrapper">
      {!isInvestorNavigationDisabled ? (
        <Sidebar
          routes={currentRoutes}
          showBtnDashboard={isProfileLocation}
          investorAppParameters={investorAppParameters}
        />
      ) : null}

      <MainPanel className="main-panel" nav={isInvestorNavigationDisabled} background={theme.backgroundApp}>
        <ModalProvider
          value={{
            showModal,
            hideModal,
          }}
        >
          <SkeletonTheme duration={4} baseColor="#DADADA" highlightColor="#F9F9F9" borderRadius={4}>
            {!isInvestorNavigationDisabled ? (
              <InvestorNavbar language={language} changeLanguage={handleChangeLang} />
            ) : null}
            <Switch>
              {getRoutes(routes(t))}
              {getRoutes(InternalRoutes(t))}
            </Switch>

            {showStyling && <FixedPlugin />}
            <ModalForm isOpen={isOpen} hideModal={hideModal} data={modalData} isSideBar />
          </SkeletonTheme>
        </ModalProvider>
        <Footer />
      </MainPanel>
    </div>
  );
};

const MainPanel = styled.div`
  background: ${(p) => (p.background ? p.background : 'none')};
  ${(p) =>
    p.nav
      ? css`
          width: 100%;
          .content {
            margin-top: 30px;
          }
        `
      : ''};
`;

export default InvestorLayout;
