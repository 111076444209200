import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useCreateNewSellOrderMutation, useGetInvestorShareQuery } from 'services/apollo';

import { CardHeader } from 'components/card-header/CardHeader';
import { Button, Card, CardBody, Col, Form, Row, Loading, BsSwal, BrandIcon } from 'atoms';
import SellOrderMainInfo from './components/new-sell-order/SellOrderMainInfo';
import useSellOrderState from './components/new-sell-order/useSellOrderState';
import SellOrderSharesSelect from './components/new-sell-order/SellOrderSharesSelect';

const NewSellOrder: React.FC = () => {
  const params = useParams<{ shareID: string }>();
  const ID = parseInt(params.shareID, 10);
  const { t } = useTranslation();
  const history = useHistory();
  const state = useSellOrderState();
  const [disabled, setDisabled] = useState<boolean>(true);

  const { shareTypeID, atomicSwapTokenAddressAcceptable, atomicSwapSharesWalletID, rateFrom } = state.order;

  useEffect(() => {
    if (!shareTypeID) {
      setDisabled(true);
      return;
    }
    if (atomicSwapSharesWalletID && !atomicSwapTokenAddressAcceptable) {
      setDisabled(true);
      return;
    }

    if (!rateFrom) {
      setDisabled(true);
      return;
    }

    setDisabled(false);
  }, [shareTypeID, atomicSwapTokenAddressAcceptable, atomicSwapSharesWalletID, rateFrom]);

  const [createSellOrder] = useCreateNewSellOrderMutation();
  const { loading, data } = useGetInvestorShareQuery({ variables: { ID } });

  if (loading || !data || !data.investorShare) {
    return <Loading />;
  }
  const { shareType, shares } = data.investorShare;

  // Pre-selected non-tokenized shares order state
  if (!atomicSwapSharesWalletID && !shareTypeID && !shareType.isBlockchain) {
    state.onChange({ shareTypeID: shareType.ID, atomicSwapTokenAddressAcceptable: '' });
  }

  // TODO: DIG-1879 Pre-selected tokenized shares order state with one wallet (needs to handle both EVM and POLYMESH cases)

  const handleCancel = () => {
    history.push(`/investor/trading`);
  };

  const handleSubmit = (): void => {
    BsSwal.fire({
      title: t('NewSellOrderPage-BsSwal-OrderConfirmation'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('NewSellOrderPage-BsSwal-Confirm'),
      cancelButtonText: t('NewSellOrderPage-BsSwal-Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        createSellOrder({ variables: { order: state.order } })
          .then(() => {
            BsSwal.fire({
              title: t('NewSellOrderPage-BsSwal-OrderCreated'),
              icon: 'success',
            });
            history.push(`/investor/trading`);
          })
          .catch((err) => {
            BsSwal.fire({
              icon: 'error',
              text: err.message,
            });
          });
      }
    });
  };

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('NewSellOrderPage-card-header-title')}
          caption={t('NewSellOrderPage-card-header-caption')}
          icon={<BrandIcon icon="info" color="cyan" pill />}
        />
        <CardBody>
          <Form className="m-2">
            <SellOrderMainInfo shareType={shareType} {...state} />
            <SellOrderSharesSelect shareType={shareType} shares={shares} {...state} />
            <Row>
              <Col md={6}>
                <Button size="m" onClick={handleSubmit} className="mr-2" disabled={disabled}>
                  {t('NewSellOrderPage-button-submit')}
                </Button>
                <Button size="m" onClick={handleCancel}>
                  {t('NewSellOrderPage-button-cancel')}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default NewSellOrder;
