import React, { useEffect } from 'react';
import { Button, Select, Col, FontAweIcon, Row } from 'atoms';
import { InvestorBuyPropertyShareType } from 'services/apollo/multisto';
import { useTranslation } from 'react-i18next';
import { History } from 'history';
import { InvestorInvestingEntity } from 'services/apollo/investor-entity';

interface InvestingEntitySelectorProps {
  entities: InvestorInvestingEntity[];
  share: InvestorBuyPropertyShareType;
  selectOptions: { value: number; label: string }[];
  entity: InvestorInvestingEntity | undefined;
  value: number;
  id: string;
  onChangeEntity: (ent: { value: number }) => void;
  history: History<unknown>;
  loadLatestEntity: boolean;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  setEntity: React.Dispatch<React.SetStateAction<InvestorInvestingEntity | undefined>>;
  text?: string;
}
const InvestingEntitySelector: React.FC<InvestingEntitySelectorProps> = (props) => {
  const {
    entities,
    share,
    selectOptions,
    entity,
    value,
    id,
    onChangeEntity,
    history,
    text,
    loadLatestEntity,
    setValue,
    setEntity,
  } = props;
  const { t } = useTranslation();
  useEffect(() => {
    const maxVal = Math.max(value, share.minimumSharesToBuyByInvestor);
    setValue(maxVal);
    if (entities && entities?.length > 0) {
      // check if this is a redirect from the create entity form
      if (loadLatestEntity) {
        // select last entity
        setEntity(entities[entities.length - 1]);
      } else {
        // else resume normal operation
        setEntity(entities[0]);
      }
    }
  }, [share.minimumSharesToBuyByInvestor, setValue, entities, loadLatestEntity]);
  return (
    <Row className="mb-4">
      <Col md={4}>
        <div className="mt-3">{t('BuyProperty-investorEntityLabel')}</div>
      </Col>
      <Col md={4}>
        {entities.length === 0 ? (
          <Button
            size="sm"
            onClick={() => {
              history.push({
                pathname: '/investor/investor-ownership',
                state: {
                  value,
                  shareId: share.ID,
                  id,
                  entity,
                  text,
                },
              });
            }}
          >
            <FontAweIcon className="mr-2" icon="plus" />
            {t('entityManagementAddNewEntity')}
          </Button>
        ) : (
          <Select
            name="InvestorEntity"
            style={{ zIndex: '100' }}
            overrideStyles={{ fontSize: '1.1em' }}
            options={selectOptions}
            value={{
              value: entity?.ID || '',
              label: entity?.name || '',
            }}
            onChange={onChangeEntity}
          />
        )}
      </Col>
    </Row>
  );
};

export default InvestingEntitySelector;
