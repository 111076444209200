import React from 'react';
import { Card, CardBody, Col, Loading, Row } from 'atoms';
import { useParams } from 'react-router-dom';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import { useGetFilterOptionsQuery, useGetSellEntityByIdQuery, useInvestorAppDataQuery } from 'services/apollo';
import MarkupIframe from 'pages/MarkupIframe';

import { ImgCard } from 'pages/active-properties/components/previewCards/MPXCard';
import ManagedStosTable from './components/ManagedStosTable';
import ManagedStosTombstones from './components/ManagedStosTombstones';
import SellSideEntityMetaKeys from './components/SellSideEntityMetaKeys';

const SellSideEntityDetails: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ entityID: string }>();
  const entityID = parseInt(params.entityID, 10);

  const { data: entityData, loading: entityLoading } = useGetSellEntityByIdQuery({
    variables: { ID: entityID },
  });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  const { data: metaKeysData, loading: metaKeysLoading } = useGetFilterOptionsQuery({
    fetchPolicy: 'no-cache',
  });

  if (
    entityLoading ||
    !entityData?.getSellEntityById ||
    !appData ||
    appDataLoading ||
    !metaKeysData ||
    metaKeysLoading
  ) {
    return <Loading />;
  }
  const sellEntity = entityData.getSellEntityById;
  const { isSellSideEntityManagedStosTombstoneViewEnabled: isTombstoneEnabled } = appData.investorAppParameters;

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('SellSideEntityDetails-card-header', { title: sellEntity.title })}
          imgSrc="/img/agendaitem.png"
        />
        <CardBody>
          <Row>
            <Col md={9}>
              <MarkupIframe content={sellEntity.details} />
            </Col>
            <Col md={3}>
              <ImgCard className="w-100 pb-2" alt={sellEntity.title} src={sellEntity.logoUrl} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <div className="pb-4">
        <SellSideEntityMetaKeys sellEntity={sellEntity} />
      </div>

      {isTombstoneEnabled ? (
        <ManagedStosTombstones sellEntity={sellEntity} />
      ) : (
        <ManagedStosTable sellEntity={sellEntity} filterOptions={metaKeysData.getFilterMetaKeys} />
      )}
    </div>
  );
};

export default SellSideEntityDetails;
