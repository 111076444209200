import { EthereumClient, modalConnectors, walletConnectProvider } from '@web3modal/ethereum';
import { Web3Modal } from '@web3modal/react';
import { Loading } from 'atoms';
import { libex, libexTestnet } from 'lib/wagmiHelper';
import React from 'react';
import { configureChains, createClient, WagmiConfig } from 'wagmi';
import { bsc, bscTestnet, fantom, fantomTestnet, polygon, polygonMumbai, goerli, mainnet, sepolia } from 'wagmi/chains';
import { useInvestorAppDataQuery } from 'services/apollo';

const WagmiContextProvider: React.FC = ({ children }) => {
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery();
  const chains = [
    sepolia,
    goerli,
    mainnet,
    polygon,
    polygonMumbai,
    fantom,
    fantomTestnet,
    bsc,
    bscTestnet,
    libex,
    libexTestnet,
  ];
  const chainsMainnet = [mainnet, polygon, fantom, bsc, libex];
  const chainsTestnet = [sepolia, goerli, polygonMumbai, fantomTestnet, bscTestnet, libexTestnet];

  if (appDataLoad || !appData) {
    return <Loading />;
  }
  const { walletConnectProjectKey, isBlockchainTestnetEnabled } = appData.investorAppParameters;
  const { provider } = configureChains(chains, [walletConnectProvider({ projectId: walletConnectProjectKey })]);
  const wagmiClient = createClient({
    autoConnect: true,
    connectors: modalConnectors({
      projectId: walletConnectProjectKey,
      version: '2',
      appName: 'DigiShares',
      chains,
    }),
    provider,
  });

  const ethereumClient = new EthereumClient(wagmiClient, isBlockchainTestnetEnabled ? chainsTestnet : chainsMainnet);

  return (
    <>
      <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>
      <Web3Modal
        projectId={walletConnectProjectKey}
        ethereumClient={ethereumClient}
        themeZIndex={9999}
        enableNetworkView
      />
    </>
  );
};
export default WagmiContextProvider;
