import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { InvestorWalletChannel } from 'services/apollo/multisto';

import { Button, Col, Row } from 'atoms';
import { PaymentChannelType } from 'services/apollo';
import { Markup } from 'interweave';
import { useReactToPrint } from 'react-to-print';
import { AppData } from 'services/apollo/core';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

interface ChannelItemProps {
  channel: InvestorWalletChannel;
  deposit: () => void;
  withdraw: () => void;
  appData: AppData;
  openDepositModalAutomatically: boolean;
}

const ChannelItem: React.FC<ChannelItemProps> = ({
  channel,
  deposit,
  withdraw,
  appData,
  openDepositModalAutomatically = false,
}) => {
  const { t } = useTranslation();
  const componentRef = useRef(null);
  const history = useHistory<{ selectedChannelID: number }>();

  const download = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${t('ChannelItem-download-document-title')} - ${channel.title}`,
  });
  const isBlockchain = channel.channelType === PaymentChannelType.Metamask;
  const { IsMarketSpace: isMarketSpace } = appData;
  // if this page is reached from the "my invoices" view, where a channel was already selected, then click open the channel details directly
  const isChannelAlreadySelected = history.location?.state?.selectedChannelID === channel.ID;
  useEffect(() => {
    if (openDepositModalAutomatically || isChannelAlreadySelected) {
      deposit();
    }
  }, []);
  return (
    <HighlightBlinker>
      <Row className={isChannelAlreadySelected ? 'blink' : 'border-bottom pb-2 mb-2'}>
        <Col md={8}>
          <b>{channel.title} </b>
          {channel.channelType === PaymentChannelType.Mercury ? (
            <label style={{ color: 'gray' }}>- {t('ChannelItem-MercuryChannelLabel')}</label>
          ) : null}
          <br />
          <b>{t('ChannelItem-Currency-Label')}</b>: {channel.currency?.abbreviation} ( {channel.currency?.symbol} )
          <br />
          <div ref={componentRef}>
            <Markup content={channel.details || ''} />
          </div>
        </Col>
        <Col md={4}>
          {!isBlockchain && (
            <Button onClick={download} size="sm">
              {t('ChannelItem-download-button')}
            </Button>
          )}
          {!isMarketSpace ? (
            <>
              <Button onClick={deposit} size="sm">
                {t('channelItemDepositFunds')}
              </Button>
              {channel.canWithdrawFunds ? (
                <Button onClick={withdraw} size="sm">
                  {t('channelItemWithdrawFunds')}
                </Button>
              ) : null}
            </>
          ) : null}
        </Col>
      </Row>
    </HighlightBlinker>
  );
};

const HighlightBlinker = styled.div`
  .blink {
    animation: blinkMe 2s linear 3;
  }
  @keyframes blinkMe {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`;

export default ChannelItem;
