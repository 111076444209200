import { Container, Navbar } from 'reactstrap';
import styled from 'styled-components';

export const StyledNavBar = styled(Navbar)`
  flex-wrap: unset;

  @media (max-width: 991px) {
    .container-fluid {
      flex-wrap: unset;
    }
  }
`;

export const StyledContainer = styled(Container)<{ isSwitch: boolean }>`
  display: flex;
  justify-content: space-between;
  height: 80px;

  .empty-logo {
    display: block;
  }

  @media (max-width: 991px) {
    .empty-logo {
      display: none;
    }
  }
`;

export const StyledNav = styled.div`
  display: flex;
  list-style: none;
  align-items: baseline;

  .navbar-item {
    display: flex;

    &:hover {
      span,
      a,
      i {
        color: ${(p) => p.theme.navBarHoverColor};
      }
    }

    &:hover > .initial-circle {
      background-color: ${(p) => p.theme.profileIconBHoverColor};
      color: ${(p) => p.theme.profileIconFontColor};
    }
  }

  .initial-circle {
    display: none;
    width: 1.44rem;
    height: 1.44rem;
    border-radius: 50%;
    background-color: ${(p) => p.theme.profileIconBGColor};
    justify-content: center;
    align-items: center;
    color: ${(p) => p.theme.profileIconFontColor};
  }

  .profile-icon {
    display: block;
  }

  span,
  a {
    text-transform: uppercase;
  }

  a {
    padding: 0.5rem;
  }

  .navbar-item__content {
    display: block;
    font-size: 13px;
    margin-left: 0.5rem;
  }

  @media (max-width: 600px) {
    align-items: center;

    .navbar-item__content {
      display: none;
    }

    .navbar-item__icon {
      font-size: 23px;
      cursor: pointer;
    }

    .ti-user {
      display: none;
    }

    .initial-circle {
      display: flex;
    }
  }
`;

export const StyledNavBrand = styled.span`
  display: flex;
  text-align: center;
  height: 80px;
  align-items: center;
  p {
    font-size: 22px;
    font-weight: 500;
    color: #66615b;

    @media (max-width: 500px) {
      font-size: 18px;
    }
    @media (max-width: 370px) {
      font-size: 16px;
    }
  }
`;
