import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useInvestorWalletQuery, useRefreshInvestorBalanceMutation } from 'services/apollo/graphql';
import { InvestorPortfolioShares } from 'services/apollo/multisto';

import { Card, CardBody, Col, Row, CenteredCol, Loading } from 'atoms';
import { useActiveSto } from 'hooks';
import { AppData } from 'services/apollo/core';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import PopoverHover from 'components/PopoverHover';

export interface Balances {
  abbreviation: string;
  symbol: string;
  amount: number;
  isBlockchainBased: boolean;
}

interface PortfolioOverviewProps {
  totalShares: number;
  totalBalances: Array<Balances>;
  shares: InvestorPortfolioShares[];
  monthlyDividend: number;
  appData: AppData;
}

const PortfolioOverview: React.FC<PortfolioOverviewProps> = ({
  totalShares,
  totalBalances,
  monthlyDividend,
  shares,
  appData,
}) => {
  const { i18n, t } = useTranslation();
  const { sto } = useActiveSto();
  const { data, loading } = useInvestorWalletQuery({
    variables: { _id: sto },
    fetchPolicy: 'no-cache',
  });
  const [updateExternalBalance] = useRefreshInvestorBalanceMutation();
  const [extBal, setExtBal] = useState('');
  const handleUpdateExternalBalance = async () => {
    const balData = await updateExternalBalance();
    const bal = balData.data?.refreshInvestorBalance;
    const balString = bal === null ? `` : `${bal}`;
    setExtBal(balString);
  };
  useEffect(() => {
    handleUpdateExternalBalance();
  }, []);

  if (!totalBalances || loading || !data?.investorBalances) {
    return <Loading />;
  }
  return (
    <>
      <Card>
        <CardBody className="mb-2">
          <Row className="text-muted">
            <Col>
              <label>{t('PortfolioOverview-label-totalShares')}</label>
            </Col>
            <Col>
              <PopoverHover
                label={t('PortfolioOverview-label-portfolioValue')}
                target={<i className="ti-help-alt pl-1" />}
                body={t('PortfolioOverview-label-portfolioValue-body')}
              />
            </Col>
            {appData.isInvestorPortfolioDividendsSwitchEnabled ? (
              <Col>
                <label>{t('PortfolioOverview-label-monthlyDividendIncome')}</label>
              </Col>
            ) : null}
            {appData.isInvestorPortfolioBalanceSwitchEnabled ? (
              <Col>
                <label>{t('PortfolioOverview-label-walletBalance')}</label>
              </Col>
            ) : null}
          </Row>
          <Row>
            <CenteredCol>
              <h5>
                {totalShares.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                })}
              </h5>
            </CenteredCol>
            <CenteredCol>
              {totalBalances.length > 1 ? (
                <ul>
                  {totalBalances.map((balance) => {
                    return (
                      <li key={balance.symbol}>
                        <h5>
                          <CurrencySymbolDisplay value={balance.amount} currency={balance} />
                        </h5>
                      </li>
                    );
                  })}
                </ul>
              ) : (
                <h5>
                  {totalBalances.length === 1 ? (
                    <CurrencySymbolDisplay value={totalBalances[0].amount} currency={totalBalances[0]} />
                  ) : null}
                </h5>
              )}
            </CenteredCol>
            {appData.isInvestorPortfolioDividendsSwitchEnabled ? (
              <CenteredCol>
                {shares.length > 0 ? (
                  <h5>
                    <CurrencySymbolDisplay value={monthlyDividend} currency={shares[0].shareType?.currency} />
                  </h5>
                ) : null}
              </CenteredCol>
            ) : null}
            {appData.isInvestorPortfolioBalanceSwitchEnabled ? (
              <CenteredCol>
                <h5>
                  <Row>
                    {data.investorBalances.map((balance) => (
                      <ColinCol key={balance.ID}>
                        <CurrencySymbolDisplay value={balance.amount} currency={balance.currency} />
                      </ColinCol>
                    ))}
                  </Row>
                  {extBal}
                </h5>
              </CenteredCol>
            ) : null}
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default PortfolioOverview;

const ColinCol = styled(Col)`
  padding: 0;
  margin: 0;
  border-radius: 0;
`;
