import React from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  useGetExchangeOfferDetailQuery,
  ExchangeType,
  AtomicSwapStatus,
  useInvestorAppDataQuery,
  useFindAllBlockchainProtocolsQuery,
} from 'services/apollo';
import Auth from 'services/core/auth';

import { Card, CardBody, Loading, Col, BrandIcon } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import NewOffer from './components/offer-details/NewOffer';
import AtomicSwapComponent from './components/offer-details/AtomicSwapComponent';
import PolymeshSwapComponent from './components/polymesh/PolymeshSwapComponent';
import { BLOCKCHAIN_PROTOCOL, isProtocolERC1404 } from './constants';
import OrderDetail from './components/offer-details/OrderDetail';
import ExistingOffer from './components/offer-details/ExistingOffer';

const OfferDetailPage: React.FC = () => {
  const params = useParams<{ orderID: string }>();
  const orderID = parseInt(params.orderID, 10);
  const history = useHistory();
  const { t } = useTranslation();

  const { loading, data } = useGetExchangeOfferDetailQuery({ variables: { orderID }, fetchPolicy: 'network-only' });
  const { data: appData, loading: loadingAppData } = useInvestorAppDataQuery();
  const { data: blockchainProtocolData, loading: loadingBlockchainProtocolData } = useFindAllBlockchainProtocolsQuery();

  if (
    loading ||
    !data ||
    loadingAppData ||
    !appData ||
    !blockchainProtocolData ||
    !blockchainProtocolData.findAllBlockchainProtocols ||
    loadingBlockchainProtocolData
  ) {
    return <Loading />;
  }

  const { isBlockchainTestnetEnabled } = appData.investorAppParameters;
  const { getExchangeOrder: order, getExchangeOffer: offer } = data;
  const blockchainProtocol = blockchainProtocolData.findAllBlockchainProtocols.find(
    (protocol) => protocol.ID === order.shareType.blockchainProtocolID,
  );

  if (Auth.payload.ID === order.investorID) {
    history.push(`/investor/order-detail/${orderID}`);
  }

  let showAtomicSwapComponent = false;
  if (order.type === ExchangeType.Sell) {
    showAtomicSwapComponent = [AtomicSwapStatus.SellerSent, AtomicSwapStatus.BuyerCommitted].includes(
      order.atomicSwapCurrentStatus,
    );
  }
  if (order.type === ExchangeType.Buy) {
    showAtomicSwapComponent = [AtomicSwapStatus.BuyerCompleted, AtomicSwapStatus.SellerCommitted].includes(
      order.atomicSwapCurrentStatus,
    );
  }

  const renderSwapComponent = (): React.ReactElement => {
    if (isProtocolERC1404(order.shareType.blockchainProtocolID || 0)) {
      return (
        <AtomicSwapComponent
          order={order}
          offer={offer}
          isTestnetEnabled={isBlockchainTestnetEnabled}
          blockchainProtocol={blockchainProtocol}
        />
      );
    }
    if (order.shareType.blockchainProtocolID === BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
      return <PolymeshSwapComponent order={order} offer={offer} />;
    }
    return <></>;
  };

  return (
    <Col className="content">
      <OrderDetail order={order} />

      <Card>
        <CardHeader
          text={t('OfferDetailPage-card-header-title')}
          caption={`${t('OfferDetailPage-card-header-caption')} ${t(`OfferDetailPage-type_${order.type}`)}`}
          icon={<BrandIcon icon="info" color="cyan" pill />}
        />
        <CardBody>
          {offer ? <ExistingOffer order={order} offer={offer} /> : <NewOffer order={order} />}
          {order.atomicSwapAcceptable && showAtomicSwapComponent ? renderSwapComponent() : null}
        </CardBody>
      </Card>
    </Col>
  );
};

export default OfferDetailPage;
