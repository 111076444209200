import React, { useEffect, useState } from 'react';
import { SpinnerSmall, Button, Row } from 'atoms';
import { useTranslation } from 'react-i18next';
import {
  SharePurchaseModeEnum,
  useDownloadSignedDocuSignLazyQuery,
  useDownloadSignedHelloSignLazyQuery,
} from 'services/apollo';
import { useHistory } from 'react-router-dom';
import { SubmittedDocuments } from 'services/apollo/documents';

interface ViewDocumentButtonProps {
  doc: SubmittedDocuments;
  provider: SharePurchaseModeEnum;
  newPath: string;
}

function convertBase64ToBlob(base64Pdf: string) {
  const decodedData = window.atob(base64Pdf);
  const uInt8Array = new Uint8Array(decodedData.length);
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i);
  }
  return new Blob([uInt8Array], { type: 'application/pdf' });
}

const ViewDocumentButton: React.FC<ViewDocumentButtonProps> = ({ doc, newPath, provider }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [getHelloSignUrl, { data }] = useDownloadSignedHelloSignLazyQuery();
  const [getDocuSignString, { data: docuSignData }] = useDownloadSignedDocuSignLazyQuery();
  const [downloadClicked, setDownloadClicked] = useState(false);
  const [viewClicked, setViewClicked] = useState(false);
  const [downloaded, setDownloaded] = useState('');

  useEffect(() => {
    const openNewTab = (base64: string) => {
      const file = convertBase64ToBlob(base64);
      const urlObject = URL.createObjectURL(file);
      setDownloaded(urlObject);
      window.open(urlObject, '_blank');
      setViewClicked(false);
    };

    const downloadFile = (base64: string) => {
      const element = document.createElement('a');
      element.href = `data:application/pdf;base64,${base64}`;
      element.download = `${doc.document.title}.pdf`;
      document.body.appendChild(element); // Required for this to work in FireFox
      element.click();
      setDownloadClicked(false);
    };
    if (!(downloadClicked || viewClicked)) {
      return;
    }
    const docuSignString = docuSignData?.downloadSignedDocuSign;
    const helloSignString = data?.downloadSignedHelloSign;
    const base64 = docuSignString || (helloSignString?.split(' ')[1] ?? '');
    if (downloadClicked && base64.length > 1) {
      downloadFile(base64);
    }
    if (viewClicked && base64.length > 1) {
      openNewTab(base64);
    }
  }, [doc.document.title, data, docuSignData, downloadClicked, viewClicked, newPath]);

  const handleDownloadedClick = (documentProvider: SharePurchaseModeEnum, isView: boolean) => {
    if (isView) {
      setViewClicked(true);
    } else {
      setDownloadClicked(true);
    }
    switch (documentProvider) {
      case SharePurchaseModeEnum.Hellosign:
        getHelloSignUrl({ variables: { fileID: newPath } });
        break;
      case SharePurchaseModeEnum.Docusign:
        getDocuSignString({ variables: { envelopeID: newPath } });
        break;
      case SharePurchaseModeEnum.Internal:
        if (isView) {
          window.open(`/investor/signed-contract/${doc.ID}`, '_blank');
          setViewClicked(false);
        } else {
          history.push(`/investor/signed-contract/${doc.ID}`, { print: true });
          setDownloadClicked(false);
        }
        break;
      default:
        break;
    }
  };

  return (
    <Row>
      <Button size="sm" className="m-2" onClick={() => handleDownloadedClick(provider, true)}>
        {t('ViewDocumentButton-View')}
      </Button>
      <Button className="m-2" onClick={() => handleDownloadedClick(provider, false)}>
        {t('ViewDocumentButton-Download')}
      </Button>
      <div className="m-2">{(downloadClicked || viewClicked) && !downloaded && <SpinnerSmall />}</div>
    </Row>
  );
};

export default ViewDocumentButton;
