import React from 'react';

import { Button, Card, CardBody, CardHeader, Col, GrayDot, Row } from 'atoms';
import { useTranslation } from 'react-i18next';
import { getTranslationKeyOfApiError } from 'services/core/helpers';
import { ApolloError } from '@apollo/client';
import { Alert } from 'reactstrap';

export interface GCashErrorProps {
  error: ApolloError;
}

const GCashError: React.FC<GCashErrorProps> = (props) => {
  const { t } = useTranslation();
  const { error } = props;

  const translationKey = getTranslationKeyOfApiError(error).message;

  return (
    <Card>
      <CardHeader className="mt-3">
        <b>{t('Welcome to your Verification Process')}</b>
      </CardHeader>
      <CardBody className="mb-3">
        <Row>
          <Col xs="auto">
            <GrayDot />
          </Col>
          <Col tag="p">{t('You can logout anytime and re-login with your email and password to continue')}</Col>
        </Row>
        <Button disabled>{t('GCashKyc-button-verify')}</Button>
        <Alert color="danger">{t(translationKey)}</Alert>
      </CardBody>
    </Card>
  );
};

export default GCashError;
