import React from 'react';
import { useTranslation } from 'react-i18next';
import { BuyAlertStatus, useGetTxIdFromBuyAlertIdQuery, BlockchainProtocol } from 'services/apollo/graphql';
import { ActivePropertyItem, InvestorPortfolioBuyAlert } from 'services/apollo/multisto';
import { Loading, SpinnerSmall } from 'atoms';
import { AppData } from 'services/apollo/core';
import { prepareBlockchainData } from 'lib/helpers';

interface AlertItemProps {
  alert: InvestorPortfolioBuyAlert;
  property?: ActivePropertyItem;
  appData: AppData;
  statusColor: string;
  blockchainProtocol?: BlockchainProtocol;
}

const AlertItemStatus: React.FC<AlertItemProps> = ({ alert, appData, blockchainProtocol, statusColor }) => {
  const { t } = useTranslation();
  const { data: txId, loading: txIdLoading } = useGetTxIdFromBuyAlertIdQuery({
    variables: { buyAlertID: alert.ID },
    fetchPolicy: 'no-cache',
  });
  const { isBlockchainTestnetEnabled } = appData;

  if (txIdLoading) {
    return <Loading />;
  }

  const getScannerUrl = (): string => {
    const { blockchainExplorer } = prepareBlockchainData(isBlockchainTestnetEnabled, blockchainProtocol?.blockchain);
    if (blockchainExplorer !== '' && txId?.getTxIdFromBuyAlertID) {
      return `${blockchainExplorer}tx/${txId.getTxIdFromBuyAlertID}`;
    }
    return '';
  };

  return txId?.getTxIdFromBuyAlertID && getScannerUrl() !== '' ? (
    <a target="_blank" rel="noreferrer" href={getScannerUrl()}>
      {t(`AlertItem-status-${alert.status}`)}{' '}
    </a>
  ) : (
    <>
      <label className="mr-2" style={{ color: statusColor }}>
        {t(`AlertItem-status-${alert.status}`)}
      </label>
      {alert.status !== BuyAlertStatus.PendingDocuments ? <SpinnerSmall /> : null}
    </>
  );
};

export default AlertItemStatus;
