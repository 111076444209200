import React, { Fragment } from 'react';
import { Label } from 'atoms';
import { useTranslation } from 'react-i18next';
import { StoMetaValue, useGetCategoryOrderMetaKeysQuery, useGetFilterOptionsQuery } from 'services/apollo';
import { useGetSortedMetaKeys } from 'hooks/useGetSortedMetaKeys';
import styled, { css } from 'styled-components';
import SkeletonMetaKey from './skeletons/SkeletonMetaKey';

interface MetaTagsCardProps {
  metaContent: Partial<StoMetaValue>[];
  noImg?: boolean;
  isDetail?: boolean;
}

const MetaTagsCard: React.FC<MetaTagsCardProps> = ({ metaContent, noImg, isDetail = false }) => {
  const { t } = useTranslation();

  const { data, loading } = useGetFilterOptionsQuery();

  const { data: categoriesData, loading: categoriesLoading } = useGetCategoryOrderMetaKeysQuery();

  if (loading || !data || categoriesLoading || !categoriesData) {
    return <SkeletonMetaKey />;
  }

  const { getFilterMetaKeys } = data;
  const { getCategoryMetaKeys } = categoriesData;
  const { groupedMetaFilters, categoryKeys } = useGetSortedMetaKeys(
    getFilterMetaKeys,
    metaContent,
    true,
    getCategoryMetaKeys,
  );
  if (!groupedMetaFilters || !categoryKeys.length) {
    return null;
  }

  return (
    <MetaKeyContainer
      isDetail={isDetail}
      className={categoryKeys.length > 3 && !isDetail ? 'scrollable' : ''}
      noImg={noImg}
    >
      {categoryKeys.map((categoryKey: keyof typeof groupedMetaFilters) =>
        categoryKey ? (
          <Fragment key={categoryKey}>
            <div className="metakey-container">
              <div>
                <Label color="secondary">
                  <b>{t(`${categoryKey}`)}</b>
                </Label>
              </div>
              {groupedMetaFilters[categoryKey]?.map((item) => (
                <MetakeyValue key={`col-${item.key}`}>
                  {item.type === 'filter_checkbox'
                    ? t(item.key)
                    : metaContent.find((filter) => filter.key === item.key)?.value}
                </MetakeyValue>
              ))}
            </div>
          </Fragment>
        ) : null,
      )}
    </MetaKeyContainer>
  );
};

export default MetaTagsCard;

const MetaKeyContainer = styled.div<{ noImg?: boolean; isDetail?: boolean }>`
  display: flex;
  gap: ${(p) => (p.noImg ? '10px' : '')};
  flex-direction: column;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 0.5rem;
  margin-bottom: ${(p) => (p.noImg ? '20px' : '0.5rem')};

  &.scrollable {
    max-height: ${(p) => (p.noImg ? '220px' : '100px')};
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #212427;
      border-radius: 6px;
    }

    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
      background-color: #0e1011;
    }

    @media (max-width: 800px) {
      max-height: ${(p) => (p.noImg ? '190px' : '100px')};
    }
  }

  .metakey-container {
    display: flex;
    justify-content: space-between;
  }

  ${(p) => {
    if (p.isDetail) {
      return css`
        border: none;
        padding: 0rem;

        .metakey-container {
          margin-bottom: 0.5rem;
        }
      `;
    }
  }}
`;

const MetakeyValue = styled.div`
  font-weight: 600;
`;
