import { BsSwal, Button, FontAweIcon } from 'atoms';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { useSwitchNetwork } from 'wagmi';

interface BlockchainNetworkSwitchButtonProps {
  chainID: number;
}

const BlockchainNetworkSwitchButton: React.FC<BlockchainNetworkSwitchButtonProps> = ({ chainID }) => {
  const { t } = useTranslation();
  const { switchNetwork, isLoading: isNetworkSwitchLoading } = useSwitchNetwork({
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  return (
    <>
      <Button
        disabled={isNetworkSwitchLoading}
        onClick={() => {
          switchNetwork?.(chainID);
        }}
      >
        {t('BlockchainNetworkSwitchButton-Switch-Network')}
      </Button>
      <p>
        {isNetworkSwitchLoading ? (
          <>
            <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
            {t('BlockchainNetworkSwitchButton-Loading-Network-Switching')}
          </>
        ) : (
          <>
            <FontAweIcon icon="circle" className="text-danger mr-2" />
            {t('BlockchainNetworkSwitchButton-Wrong-Network-Selected')}
          </>
        )}
      </p>
    </>
  );
};

export default BlockchainNetworkSwitchButton;
