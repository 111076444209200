import React from 'react';

import { SellSideEntity } from 'services/apollo/sell-side-entity';
import DynamicContent from 'pages/active-properties/components/DynamicContent';

export interface SellSideEntityMetaKeysProps {
  sellEntity: SellSideEntity;
}

const SellSideEntityMetaKeys: React.FC<SellSideEntityMetaKeysProps> = (props) => {
  const { sellEntity } = props;

  return <div className="content">{sellEntity.meta.length ? <DynamicContent meta={sellEntity.meta} /> : null}</div>;
};

export default SellSideEntityMetaKeys;
