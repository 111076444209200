import React from 'react';
import { Card, CardBody, Label } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';

import { SellSideEntity } from 'services/apollo/sell-side-entity';
import SortTable from 'pages/exchange/components/SortTable';
import { MetaKeyTypes, Sto } from 'services/apollo';
import { Link as RouterLink } from 'react-router-dom';
import MarkupIframe from 'pages/MarkupIframe';
import { FilterOption } from 'services/apollo/investor-meta';
import { useGetSortedMetaKeys } from 'hooks/useGetSortedMetaKeys';
import CategoryValuesDisplay from './CategoryValuesDisplay';

export interface ManagedStosTableProps {
  sellEntity: SellSideEntity;
  filterOptions: FilterOption[];
}

const ManagedStosTable: React.FC<ManagedStosTableProps> = (props) => {
  const { t } = useTranslation();
  const { sellEntity, filterOptions } = props;

  const first = sellEntity?.getStos ?? [];
  const metaTableColumns = first[0].meta.filter((m) => m.isManagedStoTableColumn);
  const { groupedMetaFilters: categorizedFilters } = useGetSortedMetaKeys(filterOptions);

  const metaColumns = metaTableColumns.map((key) => {
    return {
      Header: t(`metakey-${key.key}`),
      accessor: (sto: Sto) => {
        const metaKey = sto.meta.find((m) => m.key === key.key);

        if (!metaKey) {
          return '';
        }
        switch (metaKey.type) {
          case MetaKeyTypes.Richtext:
            return <MarkupIframe content={metaKey.value} />;
          case MetaKeyTypes.Category: {
            const possibleValues = categorizedFilters[metaKey.key].map((v) => v.key);
            const foundMetaValues = sto.meta.filter((m) => possibleValues.includes(m.key) && m.value === 'true');
            return <CategoryValuesDisplay values={foundMetaValues} />;
          }
          default:
            return metaKey.value;
        }
      },
    };
  });
  const columns = React.useMemo(
    () => [
      {
        Header: t('ManagedStosTable-table-header-id'),
        accessor: (sto: Sto) => {
          return sto.ID;
        },
      },
      {
        Header: t('ManagedStosTable-table-header-title'),
        accessor: (sto: Sto) => {
          return <RouterLink to={`/investor/detail-property/${sto.ID}`}>{sto.title}</RouterLink>;
        },
      },
      ...metaColumns,
      {
        Header: t('ManagedStosTable-table-header-createdAt'),
        accessor: (sto: Sto) => {
          const date = new Date(sto.createdAt);
          return date.toLocaleDateString();
        },
      },
      {
        Header: t('ManagedStosTable-table-header-active'),
        accessor: (sto: Sto) => {
          return sto.isActive
            ? t('ManagedStosTable-table-header-active-1')
            : t('ManagedStosTable-table-header-active-0');
        },
      },
    ],
    [t],
  );

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('SellSideEntityDetails-managingStos-card-header', { count: sellEntity.getStos?.length })}
          imgSrc="/img/agendaitem.png"
        />
        <CardBody>
          {sellEntity.getStos?.length ? (
            <SortTable columns={columns} data={sellEntity.getStos} />
          ) : (
            <Label>{t('SellSideEntityDetails-no-managed-stos')}</Label>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ManagedStosTable;
