import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';

import { PolymeshTransactionItem } from 'services/apollo/polymesh';
import SortTable from '../SortTable';
import MyTransactionActionCell from './MyTransactionActionCell';

interface MyTransactionsProps {
  data: PolymeshTransactionItem[];
}

const MyTransactions: React.FC<MyTransactionsProps> = ({ data }) => {
  const { t } = useTranslation();

  const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

  const columns = React.useMemo(
    () => [
      {
        Header: t('MyTransactions-column-header-title'),
        accessor: 'title',
      },
      {
        Header: t('MyTransactions-column-header-shareTypeID'),
        accessor: 'shareTypeID',
      },
      {
        Header: t('MyTransactions-column-header-amount'),
        accessor: 'amount',
      },
      {
        Header: t('MyTransactions-column-header-swapTokenTag'),
        accessor: 'swapTokenTag',
      },
      {
        Header: t('MyTransactions-column-header-token'),
        accessor: (item: PolymeshTransactionItem) => {
          return `${item.swapAmount} ${t('MyTransactions-column-header-usd')}`;
        },
      },
      {
        Header: t('MyTransactions-column-header-dateSent'),
        accessor: (item: PolymeshTransactionItem) => {
          return `${format(item.dateCreated, dateTimeFormat)}`;
        },
      },
      {
        accessor: 'ID',
        Cell: MyTransactionActionCell,
      },
    ],
    [t],
  );

  if (!data.length) {
    return (
      <>
        <br />
        <label>{t('No-Records-Found')}</label>
      </>
    );
  }

  return <SortTable columns={columns} data={data} />;
};

export default MyTransactions;
