import React, { useEffect, useState } from 'react';
import { useAccount, useConnect, useDisconnect } from 'wagmi';
import { Button } from 'atoms';
import { useWeb3Modal } from '@web3modal/react';
import styled, { css } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface Web3ConnectButtonProps {
  isWalletConnectEnabled: boolean;
}

const Web3ConnectButton: React.FC<Web3ConnectButtonProps> = ({ isWalletConnectEnabled }) => {
  const { t } = useTranslation();
  const { address, isConnected } = useAccount();
  const { open } = useWeb3Modal();
  const { disconnect } = useDisconnect();
  const { connect, connectors } = useConnect();
  const [publicKey, setPublicKey] = useState('');

  useEffect(() => {
    if (isConnected) {
      setPublicKey(address as string);
    } else {
      setPublicKey('');
    }
  }, [address, isConnected]);

  const handleWalletConnect = async () => {
    if (!isConnected) {
      await open();
    } else {
      disconnect();
    }
  };

  const handleInjectedWalletConnect = async () => {
    if (!isConnected) {
      await connect({ connector: connectors[1] });
    } else {
      disconnect();
    }
  };

  return (
    <>
      {isWalletConnectEnabled ? (
        <Button onClick={handleWalletConnect}>
          {isConnected && address && publicKey.length
            ? publicKey
            : t('DepositFormSendTxBlockchain-button-connectMetaMask')}
        </Button>
      ) : (
        <StyledButton onClick={handleInjectedWalletConnect} connected={!!(isConnected && address && publicKey.length)}>
          {isConnected && address && publicKey.length ? (
            <>
              <i className="ti-wallet" />
              <i className="ti-check" />
              <span className="navbar-item__content-wallet"> {publicKey} </span>
            </>
          ) : (
            <>
              <i className="ti-wallet" />
              <i className="ti-link" />
              <span className="navbar-item__content-connect">
                {t('DepositFormSendTxBlockchain-button-connectMetaMask')}
              </span>
            </>
          )}
        </StyledButton>
      )}
    </>
  );
};

export default Web3ConnectButton;

const StyledButton = styled(Button)<{ connected: boolean }>`
  display: flex;
  border-radius: 5px;
  padding: 0.25rem 0.5rem;
  margin-right: 1rem;
  align-items: center;

  @media (max-width: 991px) {
    margin-right: 1rem;
  }

  .ti-link {
    font-size: 16px;
    display: none;
  }

  .ti-check {
    display: none;
  }

  .ti-wallet {
    font-size: 16px;
    margin-right: 0.5rem;
  }

  .navbar-item__content-wallet {
    margin-left: 0.5rem;
  }

  @media (max-width: 450px) {
    .ti-link {
      display: block;
    }

    .navbar-item__content-connect {
      display: none;
    }
  }

  ${(p) => {
    if (p.connected) {
      return css`
        .ti-wallet {
          margin-right: 0rem;
        }

        @media (max-width: 1060px) {
          .navbar-item__content-wallet {
            display: none;
          }
          .ti-check {
            display: block;
          }
          .ti-wallet {
            margin-right: 0.5rem;
          }
        }

        @media (max-width: 600px) {
          .ti-wallet {
            font-size: 16px;
          }

          .ti-check {
            font-size: 16px;
          }
        }
      `;
    }
  }}
`;
