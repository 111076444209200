import styled, { css } from 'styled-components';

export const StyledDetails = styled.div<{ noMetaKeys?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  color: ${(p) => p.theme.colors.textGrey};
  font-size: 16px;

  .detail-header {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    justify-content: stretch;
    gap: 2rem;
    margin-top: 1rem;

    @media (max-width: 1400px) {
      grid-template-columns: 1fr;
    }

    &__content {
      max-width: 900px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      &-title {
        margin: 2rem 0rem 2rem;
        font-size: 28px;
        font-weight: 500;

        @media (max-width: 1500px) {
          margin: 0rem 0rem 2rem;
        }
      }

      ${(p) => {
        if (p.noMetaKeys) {
          return css`
            justify-content: center;

            gap: 2rem;
          `;
        }
      }}
    }
  }
  .detail-img {
    align-self: center;
  }
`;
