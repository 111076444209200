import React from 'react';
import { useTranslation } from 'react-i18next';
import { Cell } from 'react-table';

import { InvestorExchangeOrder } from 'services/apollo/exchange';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

const MyBuyOrderTypeCell: React.FC<Cell<InvestorExchangeOrder>> = ({ row: { original } }) => {
  const { t } = useTranslation();

  if (original.atomicSwapAcceptable) {
    return (
      <>
        {original.pricePerShare} {t('MyBuyOrderTypeCell', { count: original.pricePerShare })}
      </>
    );
  }

  return <CurrencySymbolDisplay value={original.pricePerShare} currency={original.shareType.currency} />;
};

export default MyBuyOrderTypeCell;
