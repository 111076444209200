import { createGlobalStyle } from 'styled-components';
import styledNormalize from 'styled-normalize';

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}
`;

export default GlobalStyle;

const maxMobileWidth = '480px';
const maxTabletWidth = '990px'; // 1024px must will be but for this navbar it good
const maxMiniDesktopWidth = '1280px';
const maxDesktopWidth = '1600px';
const maxTabletWithoutNavbarWidth = '880px';

const mobile = `(max-width: ${maxMobileWidth})`;
const tablet = `(max-width: ${maxTabletWidth})`;
const miniDesktop = `(max-width: ${maxMiniDesktopWidth})`;
const maxDesktop = `(max-width: ${maxDesktopWidth})`;
const tabletNoNavbar = `(max-width: ${maxTabletWithoutNavbarWidth})`;

export { mobile, tablet, miniDesktop, maxDesktop, tabletNoNavbar };
