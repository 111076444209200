import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, Loading, Row } from 'atoms';
import { useActiveSto } from 'hooks';
import { useInvestorMeetingQuery } from 'services/apollo';
import { CardHeader } from 'components/card-header/CardHeader';
import MarkupIframe from 'pages/MarkupIframe';
import VotingOptions from './VotingDetails/VotingOptions';

const VotingDetails: React.FC = () => {
  const { _id } = useParams<{ _id: string }>();
  const votingID = Number(_id);
  const { t } = useTranslation();
  const { sto } = useActiveSto();

  const { data, loading } = useInvestorMeetingQuery({
    variables: {
      meetingId: votingID,
      _id: sto,
    },
  });

  if (loading || !data) {
    return <Loading />;
  }

  const { investorMeeting, findSto } = data;
  if (!investorMeeting || !findSto) {
    return null;
  }
  const { companyType } = findSto;

  const isClosed = new Date(investorMeeting.closeDate) < new Date();

  return (
    <div className="content">
      <Card>
        <CardHeader text={investorMeeting.title} imgSrc="/img/voting.png" />
        <CardBody>
          <div className="bg-light w-100 p-2 fs-18 ">{t('VotingDetails-Information')}</div>
          <Row className="mb-2">
            <Col md={3} className="fs-16 minw-200">
              <label>{t('Time')}</label>
            </Col>
            <Col md={6}>
              <div className="ml-2 minw-200 d-inline-block mb-1">
                <img src="/img/date.png" width="20px;" className="mr-2" />
                {new Date(investorMeeting.openDate).toDateString()}
              </div>
              <div className="minw-200 d-inline-block mb-1 ml-2">
                <img src="/img/time.png" width="20px;" className="mr-2" />
                <span className="mr-1">{new Date(investorMeeting.openDate).toLocaleTimeString()}</span>
                to
                <span className="ml-1">{new Date(investorMeeting.closeDate).toLocaleTimeString()}</span>
                <span className="fa-w-12 ml-2 px-1 badges badge-info rounded">
                  {investorMeeting.timezone} - {investorMeeting.timePadding}
                </span>
              </div>
            </Col>
          </Row>

          <div className="bg-light w-100 p-2 fs-18 ">{t('VotingDetails-Description')}</div>
          <Row className="mb-2 ml-2">
            <MarkupIframe content={investorMeeting.content || ''} />
          </Row>

          <VotingOptions votingID={votingID} companyType={companyType} isClosed={isClosed} />
        </CardBody>
      </Card>
    </div>
  );
};

export default VotingDetails;
