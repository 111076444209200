import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

import { signIn } from 'lib/routing/route-sets/public-routes';
import { useInvestorAppDataQuery, useInvestorSetPasswordMutation } from 'services/apollo';

import { Button, Container, Form, FormGroup, Input, Loading, StoLogo } from 'atoms';
import { BsSwal } from 'atoms/Swal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import useGqlErrorExtractor from 'hooks/useGqlErrorExtractor';

const SetNewPassword: React.FC = () => {
  const { t } = useTranslation();
  const [password, setPassword] = useState('');
  const [repeat, setRepeat] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const history = useHistory();
  const params = useParams<{ token: string }>();
  const [error, setGqlError] = useGqlErrorExtractor({ password: '' });

  const [changePassword] = useInvestorSetPasswordMutation();
  useEffect(() => {
    if (error.password && error.password !== '') {
      BsSwal.fire({
        title: error.password,
        icon: 'error',
      });
    }
  }, [error]);

  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (appDataLoading || !appData) {
    return <Loading />;
  }

  const { backgroundImageUrl } = appData.investorAppParameters;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setGqlError(null);
    const errs = [];
    if (!password) {
      errs.push('password');
    }

    if (!repeat) {
      errs.push('repeat');
    }

    if (errs.length) {
      setErrors(errs);
      return;
    }

    if (password !== repeat) {
      return BsSwal.fire({
        title: t('SetNewPassword-popUp-error-nonMatchingPassword'),
        icon: 'error',
      });
    }
    const data = { token: params.token, password };
    changePassword({ variables: { data } })
      .then(() =>
        BsSwal.fire({
          title: t('SetNewPassword-popUp-success'),
          icon: 'success',
        }).then(() => history.push(signIn.path)),
      )
      .catch((err) => {
        setGqlError(err);
      });
  };

  const handlePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = errors.indexOf('password');
    if (index !== -1) {
      errors.splice(index, 1);
      setErrors(errors);
    }
    setPassword(e.currentTarget.value);
  };

  const handleRepeat = (e: React.ChangeEvent<HTMLInputElement>) => {
    const index = errors.indexOf('repeat');
    if (index !== -1) {
      errors.splice(index, 1);
      setErrors(errors);
    }
    setRepeat(e.currentTarget.value);
  };

  const setBackgroundImage = () => {
    const backgroundImageUrlObject = JSON.parse(backgroundImageUrl);
    if (backgroundImageUrlObject.login) {
      const backgroundImage = backgroundImageUrlObject.login;
      const backgroundStyle = `{"backgroundImage": "url('${backgroundImage}')" , "backgroundSize": "cover"}`;
      return JSON.parse(backgroundStyle);
    }
    return {};
  };

  return (
    <div className="login-page" style={setBackgroundImage()}>
      <Container>
        <NewPasswordForm>
          <div className="login-form">
            <div className="main-div">
              <div className="panel">
                <div className="mb-1 logo-img red-swan-custom">
                  <StoLogo />
                </div>
                <h1>{t('SetNewPassword-card-header')}</h1>
                <p>{t('SetNewPassword-label-enterPassword')}</p>
              </div>

              <Form onSubmit={onSubmit}>
                <FormGroup>
                  <Input
                    invalid={errors.includes('password')}
                    type="password"
                    placeholder={t('SetNewPassword-placeholder-password')}
                    value={password}
                    onChange={handlePassword}
                  />
                </FormGroup>
                <FormGroup>
                  <Input
                    invalid={errors.includes('repeat')}
                    type="password"
                    placeholder={t('SetNewPassword-placeholder-repeatPassword')}
                    value={repeat}
                    onChange={handleRepeat}
                  />
                </FormGroup>
                <Button color="info" block size="lg">
                  {t('SetNewPassword-button-submit')}
                </Button>
              </Form>
              <RouterLink to={signIn.getPath()}>{t('ForgotPassword-login-link')}</RouterLink>
            </div>
          </div>
        </NewPasswordForm>
      </Container>
    </div>
  );
};

export default SetNewPassword;

const NewPasswordForm = styled.div`
  height: 80vh;
  text-align: center;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
