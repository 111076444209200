import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const NoUpdates: React.FC<{ offering: string }> = ({ offering }) => {
  const { t } = useTranslation();

  return (
    <EmptyContainer>
      <p>
        {t('NoUpdates-Paragraph-NoNews')} {offering} {t('NoUpdates-Paragraph-Offering')}
      </p>
    </EmptyContainer>
  );
};

export default NoUpdates;

const EmptyContainer = styled.div`
  height: 60rem;

  @media (max-height: 1450px) {
    height: 50rem;
  }

  @media (max-height: 1150px) {
    height: 35rem;
  }
  @media (max-height: 950px) {
    height: 28rem;
  }

  @media (max-height: 800px) {
    height: 18rem;
  }
  @media (max-height: 600px) {
    height: 8rem;
  }
`;
