import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetSharesWalletsQuery, useFetchFeesQuery, FeeBeneficiary, FeeType } from 'services/apollo';
import { InvestorShareShareType } from 'services/apollo/shares';
import { BLOCKCHAIN_PROTOCOL } from 'pages/exchange/constants';

import { Label, Col, FormGroup, Row } from 'atoms';
import SellOrderOption from './SellOrderOptionLabel';
import PolymeshSwapTokenSelect from '../polymesh/PolymeshSwapTokenSelect';
import SellOrderRate from './SellOrderRate';

import { UseSellOrderState } from './useSellOrderState';

interface SharesWalletSelectProps extends UseSellOrderState {
  shareType: InvestorShareShareType;
  shares: number;
}

const SellOrderSharesSelect: React.FC<SharesWalletSelectProps> = ({ shareType, order, onChange, shares }) => {
  const { t } = useTranslation();

  const { data, loading } = useGetSharesWalletsQuery({ variables: { shareTypeID: shareType.ID } });

  const { data: feeData, loading: feeLoading } = useFetchFeesQuery({
    variables: { stoID: shareType.stoID, beneficiary: FeeBeneficiary.Platform, type: FeeType.SellExchange },
    fetchPolicy: 'network-only',
  });

  if (loading || !data || feeLoading || !feeData?.fetchFees) {
    return <></>;
  }

  const { getSharesWallets: wallets } = data;
  const { fetchFees: fee } = feeData;

  // TODO: check with oleg why do we need to subtract the shares from the sharesWalletSum
  // const shareWalletSum = wallets.reduce((sum, next) => sum + (next.shares || 0), 0);
  // const availableShare = shares - shareWalletSum;

  const renderLabel = (): React.ReactElement => {
    if (shareType.blockchainProtocolID !== BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
      return (
        <>
          <h4>{t('SellOrderSharesSelect-label-MetaMask-title')}</h4>
          <p>{t('SellOrderSharesSelect-label-MetaMask-description')}</p>
        </>
      );
    }
    if (shareType.blockchainProtocolID === BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
      return (
        <>
          <h4>{t('SellOrderSharesSelect-label-Polymesh-title')}</h4>
          <p>{t('SellOrderSharesSelect-label-Polymesh-description')}</p>
        </>
      );
    }
    return <></>;
  };

  const renderSwapTokenSelect = (): React.ReactElement => {
    if (shareType.blockchainProtocolID !== BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
      order.atomicSwapTokenAddressAcceptable = shareType.currency.Address;
      return <>{shareType.currency.symbol}</>;
    }
    if (shareType.blockchainProtocolID === BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
      return (
        <PolymeshSwapTokenSelect
          value={order.atomicSwapTokenAddressAcceptable || ''}
          onChange={(value) => onChange({ atomicSwapTokenAddressAcceptable: value })}
        />
      );
    }
    return <></>;
  };

  return (
    <>
      {!shareType.isBlockchain ? (
        <>
          <h4>{t('SellOrderSharesSelect-Header-OpenSellOrder')}</h4>
          <SellOrderOption
            isChecked
            id="option-share"
            shareLabel={t('SellOrderSharesSelect-label-own')}
            shareAmountText={`${shares} ${t('SellOrderSharesSelect-label-share', { count: shares })}`}
            onChange={() =>
              onChange({
                shareTypeID: shareType.ID,
                atomicSwapSharesWalletID: null,
                atomicSwapTokenAddressAcceptable: '',
              })
            }
          />
        </>
      ) : (
        <>
          {renderLabel()}
          {wallets.map((wallet) => (
            <SellOrderOption
              isChecked={false}
              key={wallet.ID}
              id={`option-share-${wallet.ID}`}
              shareLabel={t('SellOrderSharesSelect-label-walletAddress')}
              shareAmountText={
                wallet.publicKey === 'platform'
                  ? t('SellOrderSharesSelect-text-InternalWallet')
                  : wallet.publicKey || ''
              }
              label2={t('SellOrderSharesSelect-label-shareInThisWallet')}
              text2={`${wallet.shares}`}
              onChange={() => onChange({ shareTypeID: shareType.ID, atomicSwapSharesWalletID: wallet.ID })}
            />
          ))}
        </>
      )}

      <Row className="my-2">
        {order.shareTypeID ? (
          <SellOrderRate
            currency={shareType.currency}
            shares={shares}
            wallets={wallets}
            order={order}
            fee={fee}
            premiumValue={shareType.premiumValue}
            onChange={onChange}
          />
        ) : null}
        {order.atomicSwapSharesWalletID ? (
          <Col>
            <FormGroup>
              <Label>
                <b>{t('SellOrderSharesSelect-label-currency')}</b>
              </Label>
              {renderSwapTokenSelect()}
            </FormGroup>
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default SellOrderSharesSelect;
