import { Loading } from 'atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Label, Row } from 'reactstrap';
import { StoMetaValue, useGetFilterOptionsQuery } from 'services/apollo';

interface MetaFilterPropertiesProps {
  metaContent: Partial<StoMetaValue>[];
}

const MetaFilterProperties: React.FC<MetaFilterPropertiesProps> = ({ metaContent }) => {
  const { t } = useTranslation();
  const metaKeys: { [x: string]: string[] } = {};

  const { data: filterData, loading: loadingFilterData } = useGetFilterOptionsQuery();

  if (loadingFilterData) {
    return <Loading />;
  }

  if (!filterData || !filterData.getFilterMetaKeys.length) {
    return null;
  }

  const { getFilterMetaKeys } = filterData;

  const sorted = [...getFilterMetaKeys].sort((a, b) => a.order - b.order);
  sorted.forEach((key) => {
    const category = key.categoryKey ?? '';
    metaContent.forEach((item) => {
      if (item.key === key.key && item.value === 'true') {
        if (!metaKeys[category]) {
          metaKeys[category] = [];
        }
        metaKeys[category].push(item.key);
      }
    });
  });

  return (
    <Row>
      {Object.keys(metaKeys).map((category) => (
        <Col md={3} key={category}>
          <Label>
            <b>{category}</b>
          </Label>
          {metaKeys[category].map((key) => (
            <Label key={key} id={key}>
              {t(key)}
            </Label>
          ))}
        </Col>
      ))}
    </Row>
  );
};

export default MetaFilterProperties;
