import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Loading } from 'atoms';
import { useInvestorAppDataQuery } from 'services/apollo';
import { Markup } from 'interweave';

const WizardComplete: React.FC = () => {
  const { t } = useTranslation();
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery();

  if (appDataLoad || !appData) {
    return <Loading />;
  }

  const { wizardCompleteHtml } = appData.investorAppParameters;

  return (
    <Card>
      <CardBody>
        {wizardCompleteHtml ? <Markup content={wizardCompleteHtml} /> : <img src="/img/done.jpg" width="100%" />}
        <p className="mt-3">{t('WizardComplete-line1')}</p>
        <p>{t('WizardComplete-line2')}</p>
        <p>{t('WizardComplete-line3')}</p>
        {t('WizardComplete-line4')}
      </CardBody>
    </Card>
  );
};

export default WizardComplete;
