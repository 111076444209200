import React from 'react';
import { Card, CardBody, Col, Loading, Row } from 'atoms';

import { ActivePropertyItem } from 'services/apollo/multisto';
import { InvoiceStatusType, useGetInvestorInvoicesQuery } from 'services/apollo';
import { CardHeader } from 'components/card-header/CardHeader';
import InvoiceItem from 'pages/invoice/components/InvoiceItem';
import SUMMARY from 'assets/img/summary.png';
import { useTranslation } from 'react-i18next';
import InvoiceItemWithResolver from './components/InvoiceItemWithResolver';

interface InvoiceProps {
  properties: Map<number, ActivePropertyItem>;
  isHistorical?: boolean;
}

const Invoice: React.FC<InvoiceProps> = (props) => {
  const { t } = useTranslation();
  const { properties, isHistorical } = props;
  const { data, loading } = useGetInvestorInvoicesQuery({
    fetchPolicy: 'no-cache',
    variables: { isHistorical, isOngoing: true },
  });
  if (!data || loading) {
    return <Loading />;
  }
  const invoices = data.getInvestorInvoices;

  return invoices.length ? (
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader
            text={isHistorical ? t('Invoice-Header-resolvedInvoices') : t('Invoice-Header-pendingInvoices')}
            caption={isHistorical ? t('Invoice-CardHeader-caption-resolved') : t('Invoice-CardHeader-caption')}
            imgSrc={SUMMARY}
          />
          <CardBody className="mb-2">
            <Row className="text-muted">
              <Col md={2}>
                <label>{t('invoice-table-header-date')}</label>
              </Col>
              <Col md={2}>
                <label>{t('invoice-table-header-offering')}</label>
              </Col>
              <Col md={3}>
                <label>{t('invoice-table-header-share')}</label>
              </Col>
              <Col md={1}>
                <label>{t('invoice-table-header-shareCount')}</label>
              </Col>
              <Col md={1}>
                <label>{t('invoice-table-header-totalPrice')}</label>
              </Col>
              <Col>
                <label>{t('invoice-table-header-status')}</label>
              </Col>
              <Col md={3} />
            </Row>
            {invoices.map((invoice) =>
              invoice.resolver ||
              [InvoiceStatusType.Paid, InvoiceStatusType.PaymentFailure].includes(invoice.status) ? (
                // TODO: temporary distinction made until we change all flows to follow the new payment resolver method
                <InvoiceItemWithResolver
                  key={invoice.ID}
                  invoice={invoice}
                  properties={properties}
                  isHistorical={isHistorical}
                />
              ) : (
                <InvoiceItem key={invoice.ID} invoice={invoice} properties={properties} />
              ),
            )}
          </CardBody>
        </Card>
      </Col>
    </Row>
  ) : null;
};

export default Invoice;
