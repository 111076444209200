import React from 'react';
import { useTranslation } from 'react-i18next';

import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { ExchangeOfferDetailOrder } from 'services/apollo/exchange';

import { CardHeader } from 'components/card-header/CardHeader';
import { Card, CardBody, BrandIcon, Col, Row } from 'atoms';

export interface OrderDetailProps {
  order: ExchangeOfferDetailOrder;
}

const OrderDetail: React.FC<OrderDetailProps> = ({ order }) => {
  const { t } = useTranslation();

  const type = t(`OrderDetail-type_${order.type}`);

  return (
    <Card>
      <CardHeader
        text={`${type} ${t('OrderDetail-card-header')}`}
        icon={<BrandIcon icon="info" color="cyan" pill />}
        caption={t('OrderDetail-card-caption')}
      />
      <CardBody>
        <Row>
          <Col md={2}>
            <b>{t('OrderDetail-label-shareType')}</b>
          </Col>
          <Col>
            <p>{order.shareType.title}</p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <b>{t('OrderDetail-label-date')}</b>
          </Col>
          <Col>
            <p>
              {order.dateFrom} - {order.dateTo}
            </p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <b>{t('OrderDetail-label-shareCount')}</b>
          </Col>
          <Col>
            <p>{order.shares}</p>
          </Col>
        </Row>
        <Row>
          <Col md={2}>
            <b>{t('OrderDetail-label-askingAmount')}</b>
          </Col>
          <Col>
            <p>
              <CurrencySymbolDisplay value={order.rateFrom} currency={order.shareType.currency} />
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default OrderDetail;
