import React, { useState } from 'react';

import { Button, CardBody, Col, Card, Row, FontAweIcon, BsSwal } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import BANK from 'assets/img/bank.png';
import { useTranslation } from 'react-i18next';
import { useTheme } from 'styled-components';
import { ThemeType } from 'lib/themes/themeTypes';
import { usePayInvoiceMutation, XenditMode } from 'services/apollo';
import { useHistory } from 'react-router-dom';
import { getTranslationKeyOfApiError, GqlError } from 'services/core/helpers';

interface DepositFormXenditProps {
  invoiceID: number;
  hideModal: () => void;
}

interface ErrorExtension {
  existingUrl: string;
}

const DepositFormXendit: React.FC<DepositFormXenditProps> = (props) => {
  const { t } = useTranslation();
  const [payInvoice] = usePayInvoiceMutation();
  const history = useHistory();
  const theme: ThemeType = useTheme() as ThemeType;
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const defaultXenditRedirect = `${window.location.origin}/investor/xendit`;

  const { invoiceID, hideModal } = props;
  const handle = async (paymentMethod: XenditMode) => {
    try {
      setIsPaymentInProgress(true);
      const mutationResponse = await payInvoice({
        variables: {
          invoiceID,
          data: {
            paymentMode: paymentMethod,
          },
          redirectUrl: defaultXenditRedirect,
        },
      });
      setIsPaymentInProgress(false);
      goToXendit(mutationResponse.data?.payInvoice || '');
    } catch (e) {
      const gqlError = getTranslationKeyOfApiError<ErrorExtension>(e as GqlError<ErrorExtension>);
      goToXendit(gqlError.data.existingUrl);
    }
  };

  const goToXendit = (checkoutUrl: string) => {
    BsSwal.fire({
      title: t('DepositFormXendit-bsswal-title'),
      icon: 'success',
      confirmButtonText: t('DepositFormXendit-bsswal-confirm'),
      didClose() {
        hideModal();
        const newWindow = window.open(checkoutUrl, '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
        history.push(`/investor/Portfolio`);
      },
    });
  };
  const style = {
    background: theme.backgroundApp,
  };

  return (
    <Card style={style}>
      <CardHeader imgSrc={BANK} text={t('DepositFormXendit-card-header')} />
      <CardBody>
        <Row>
          <Col>
            <Card>
              <CardHeader
                text={t('DepositFormXendit-CreditCard-header')}
                caption={t('DepositFormXendit-CreditCard-caption')}
              />
              <CardBody>
                <Row>
                  <Col>
                    <Button id="invest" size="sm" onClick={() => handle(XenditMode.CreditCard)}>
                      {t('DepositFormXendit-CreditCard-button')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
          <Col>
            <Card>
              <CardHeader text={t('DepositFormXendit-GCash-header')} caption={t('DepositFormXendit-GCash-caption')} />
              <CardBody>
                <Row>
                  <Col>
                    <Button id="invest" size="sm" onClick={() => handle(XenditMode.GCash)}>
                      {t('DepositFormXendit-GCash-button')}
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        {isPaymentInProgress ? (
          <Row className="h5 d-flex justify-content-center mx-auto">
            <FontAweIcon size="lg" icon="circle-notch" spin className="text-primary mr-2" />
            {t('DepositFormXendit-TransactionInProgressDeposit')}
          </Row>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default DepositFormXendit;
