import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, Button, Badge } from 'atoms';
import { InvestingEntityMember } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';
import InvestingEntityMemberForm from './InvestingEntityMemberForm';

interface InvestingEntityMembersProps {
  entityID: number;
  members: InvestingEntityMember[];
  canModifyEntity?: boolean;
  appData: AppData;
}

const InvestingEntityMembers: React.FC<InvestingEntityMembersProps> = ({
  entityID,
  members,
  canModifyEntity,
  appData,
}) => {
  const { t } = useTranslation();
  const { isNewEntityMemberEmailEnabled } = appData;

  const [isOpen, setOpen] = useState(false);
  return (
    <>
      <h3 className="pt-4">{t('entityItemRowEntityMembers')}</h3>
      {isNewEntityMemberEmailEnabled && <p>{t('entityItemRowEntityMembersNote')}</p>}
      <span style={{ display: 'flex' }}>
        <Badge color="gold" />
        <p>{t('InvestingEntityMembers-entityMemberRowLegend-OwnerColor')}</p>
      </span>
      <Row>
        {members.map((member) => (
          <InvestingEntityMemberForm
            key={member.ID}
            entityID={entityID}
            member={member}
            canModifyEntity={canModifyEntity}
          />
        ))}
        {isOpen ? (
          <Col md={4}>
            <InvestingEntityMemberForm
              entityID={entityID}
              close={() => setOpen(false)}
              canModifyEntity={canModifyEntity}
            />
          </Col>
        ) : (
          <Col md={4}>
            <Button onClick={() => setOpen(true)} disabled={!canModifyEntity}>
              {t('entityItemRowAddNewMember')}
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
};

export default InvestingEntityMembers;
