/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import english from './en.json';
import france from './fr.json';
import german from './gr.json';

export const languages = new Map();

/**
 * It takes an array of language codes, and for each language code, it looks up the native name of that
 * language and adds it to the languages Map
 * @param langs - An array of language codes.
 */
export const loadNativeNames = (langs) => {
  const languageNames = new Intl.DisplayNames(['en'], { type: 'language' });
  langs.forEach((ele) => {
    languages.set(ele, { nativeName: languageNames.of(ele) });
  });
};

i18n.use(initReactI18next).init({
  debug: false,
  fallbackLng: ['en', 'fr', 'de'],
  resources: {
    en: {
      translation: {
        ...english,
      },
    },
    fr: {
      translation: {
        ...france,
      },
    },
    de: {
      translation: {
        ...german,
      },
    },
  },
});

export { i18n };
