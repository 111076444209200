import React from 'react';

import styled from 'styled-components';
import { Button, Card, CardBody, Row, CenteredCol } from 'atoms';
import { ActivePropertyItem } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';
import { canSeeNDADataRequired } from 'services/core/helpers';
import NdaModal from 'pages/nda/NdaModal';
import InterestedSwitch from 'pages/active-properties/components/interestedSwitch/InterestedSwitch';
import { useTranslation } from 'react-i18next';
import SellEntitySubTitleLink from '../SellEntitySubTitleLink';
import MetaTagsCard from '../MetaTagsCard';

interface MPXCardNoImgProps {
  props: ActivePropertyItem;
  investorAppParameters: AppData;
  view: () => void;
  changeBuyButtonText: () => string;
  canSeeNDAData: (data: canSeeNDADataRequired) => boolean;
  buy: () => void;
  NDACheckData: canSeeNDADataRequired;
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  isProjectCardTagsDisplayEnabled: boolean;
  investorInterested: boolean;
  noImg: boolean;
  isBuyStoEnabled: boolean;
}

const MPXCardNoImg: React.FC<MPXCardNoImgProps> = ({
  props,
  investorAppParameters,
  view,
  changeBuyButtonText,
  canSeeNDAData,
  NDACheckData,
  buy,
  isOpen,
  setOpen,
  isProjectCardTagsDisplayEnabled,
  investorInterested,
  noImg,
  isBuyStoEnabled,
}) => {
  const { t } = useTranslation();
  const { ID, title, details, meta, sellSideEntity } = props;

  return (
    <StyledCard width="100%" wmax="400px" height="100%">
      <StyledCardHeader className="mb-2">
        <h4>{title}</h4>
        <InterestedSwitch isInterested={isBuyStoEnabled} appData={investorAppParameters} stoID={ID} />
      </StyledCardHeader>

      <StyledCardBody className="px-2 pb-2 d-flex flex-column justify-content-between">
        <div>
          <SellEntitySubTitleLink
            appData={investorAppParameters}
            title={sellSideEntity?.title}
            ID={sellSideEntity?.ID}
          />
          {isProjectCardTagsDisplayEnabled ? <MetaTagsCard metaContent={meta} noImg={noImg} /> : null}
          <p>{details}</p>
        </div>
        <Row className="justify-content-center">
          <CenteredCol md={6} className="justify-content-center">
            <Button outline size="sm" wmin="80px" style={{ width: '100%', wordWrap: 'normal' }} onClick={view}>
              {!canSeeNDAData(NDACheckData)
                ? t('ActiveProperty-ViewDetails-nda-not-signed')
                : t('ActiveProperty-ViewDetails')}
            </Button>
          </CenteredCol>
          {investorInterested ? (
            <CenteredCol md={6} className="justify-content-center">
              <Button size="sm" wmin="80px" style={{ width: '100%', wordWrap: 'normal' }} onClick={buy}>
                {changeBuyButtonText()}
              </Button>
            </CenteredCol>
          ) : null}
        </Row>
        <NdaModal stoID={ID} isOpen={isOpen} setOpen={setOpen} />
      </StyledCardBody>
    </StyledCard>
  );
};

export default MPXCardNoImg;

const StyledCard = styled(Card)`
  display: flex;
`;

const StyledCardBody = styled(CardBody)`
  flex: 1;

  &.action-container {
    margin-bottom: 0.5rem;
  }
`;

const StyledCardHeader = styled.div`
  padding: 22px 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  margin-bottom: 0px;
  text-align: left;
  align-items: start;

  h4 {
    margin-top: 0;
    margin-bottom: 0;
  }

  ${(p) => {
    if (p.theme.colorControlsFont) {
      return `
          color: ${p.theme.colorControlsFont};
          background-color: ${p.theme.colorControls};
       `;
    }
  }}
`;
