import React from 'react';
import { useTranslation } from 'react-i18next';

import { Col, Row, Button, CenteredCol } from 'atoms';
import MessageBox from 'pages/chat/components/MessageBox';
import NdaModal from 'pages/nda/NdaModal';
import { showBuyButton } from 'services/core/helpers';

import PropertyGallery from 'pages/active-properties/components/PropertyGallery';
import PropertyDocuments from 'pages/active-properties/components/PropertyDocuments';
import MetaChart from 'pages/active-properties/components/MetaChart';
import MetaContent from 'pages/active-properties/components/MetaContent';
import DynamicContent from 'pages/active-properties/components/DynamicContent';
import MetaFilterProperties from 'pages/active-properties/components/MetaFilterProperties';
import PropertyDetailActionsButtonGroup from 'pages/active-properties/components/PropertyDetailActionsButtonGroup';
import PDFViewer from 'pages/active-properties/components/PDFViewer';
import SellEntitySubTitleLink from 'pages/active-properties/components/SellEntitySubTitleLink';
import InterestedSwitch from 'pages/active-properties/components/interestedSwitch/InterestedSwitch';
import { PropertyLayoutDetailProps } from 'pages/active-properties/detailPage/MarketSpacePropertyDetail';

const LeftLayoutDetailProperty: React.FC<PropertyLayoutDetailProps> = ({
  themeStyling,
  sto,
  parameters,
  isLeft,
  NDACheckData,
  changeBuyButtonText,
  isBuyStoEnabled,
  pictureUrl,
  buy,
  setOpen,
  canSignNDA,
  seeNDAData,
  metaList,
  stoId,
  goToPortfolio,
  detail,
  isOpen,
  investorId,
}) => {
  const { t } = useTranslation();

  const { isMetakeysFilterTagsDisplayEnabled, isProjectPDFPresentationEnabled, showPropertyDetailReturnButton } =
    parameters;

  return (
    <div className="content">
      <Row>
        <Col md={8} style={themeStyling}>
          <h1>{sto.title}</h1>
          <SellEntitySubTitleLink appData={parameters} title={sto.sellSideEntity?.title} ID={sto.sellSideEntity?.ID} />
        </Col>
        <hr />
        <CenteredCol>
          {isLeft && showBuyButton(NDACheckData) ? (
            <Button wmin="200px" hmin="130px" className="m-1 text-nowrap" onClick={buy}>
              {changeBuyButtonText()}
            </Button>
          ) : null}
          {!isLeft && showPropertyDetailReturnButton ? (
            <Button wmin="200px" hmin="130px" className="m-1 text-nowrap" onClick={goToPortfolio}>
              {t('MarketSpacePropertyDetails-button-goToPortfolio')}
            </Button>
          ) : null}
        </CenteredCol>
        <CenteredCol>
          <InterestedSwitch isInterested={isBuyStoEnabled} appData={parameters} stoID={stoId} />
        </CenteredCol>
      </Row>
      <Row>
        <Col md={isLeft ? 8 : 12}>
          {isProjectPDFPresentationEnabled && sto.isProjectPDFPresentationEnabled ? (
            <PDFViewer fileUrl={sto.presentation?.url} />
          ) : (
            sto.images.length > 0 && (
              <PropertyGallery
                classNames={!isLeft ? 'w-100 h-50' : ''}
                logo={`${pictureUrl}/img/stologo/${sto.logo}`}
                images={sto.images || []}
              />
            )
          )}
          <PropertyDetailActionsButtonGroup
            buy={buy}
            setOpen={setOpen}
            documents={sto.documents ?? []}
            canSignNDA={canSignNDA()}
            canSeeNDAData={seeNDAData}
            stoID={sto.ID || 0}
            isLeft={isLeft}
            appData={parameters}
          />
          <MetaContent meta={detail} name="project_overview" />
          <DynamicContent meta={metaList} isBuyEnabled={sto.isBuyButtonEnabled} />
        </Col>
        {isLeft ? (
          <Col md={4} style={themeStyling}>
            {isMetakeysFilterTagsDisplayEnabled && (
              <div className="mt-4">
                <MetaFilterProperties metaContent={sto.meta} />
              </div>
            )}
            <div className="mt-4">
              <b>Details</b>
            </div>
            <p>{sto.details}</p>

            <PropertyDocuments documents={sto.documents || []} />
            <MetaChart meta={sto.meta} name="left_sidebar_chart" />
            <MessageBox stoID={sto.ID} investorID={investorId} />
          </Col>
        ) : null}
      </Row>
      <NdaModal stoID={sto.ID} isOpen={isOpen} setOpen={setOpen} />
    </div>
  );
};

export default LeftLayoutDetailProperty;
