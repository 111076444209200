import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledTab } from 'pages/active-properties/components/rightLayout/styled/styled';

interface TabsProps {
  keys: string[];
  active: string;
  setActive: (active: string) => void;
}

const Tabs: React.FC<TabsProps> = ({ keys, active, setActive }) => {
  const { t } = useTranslation();

  return (
    <div className="dynamic-content__content--tabs">
      {keys.map((k) => (
        <StyledTab active={k === active} onClick={() => setActive(k)} key={k}>
          {t(k)}
        </StyledTab>
      ))}
    </div>
  );
};

export default Tabs;
