import React, { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormFeedback } from 'reactstrap';
import * as math from 'mathjs';

import { useScrollBlock } from 'hooks';
import {
  useInvestorInvestingEntitiesQuery,
  useInvestorBuyAlertMarketSpaceMutation,
  useGetSharesWalletsQuery,
  BuyAlertStatus,
  useGetInvestorSharesByTypeQuery,
} from 'services/apollo';
import { InvestorBuyPropertyShareType } from 'services/apollo/multisto';
import { InvestorInvestingEntity } from 'services/apollo/investor-entity';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

import { BsSwal, Button, Col, Input, Row, Select, Loading } from 'atoms';
import { AppData } from 'services/apollo/core';
import InvestingEntitySelector from 'pages/active-properties/components/InvestingEntitySelector';
import { ApolloError } from '@apollo/client';

interface PropertyShareProps {
  sto: number;
  share: InvestorBuyPropertyShareType;
  setLoadingRequest: (isActive: boolean) => void;
  appData: AppData;
}

const BuyPropertyShareMarketspace: React.FC<PropertyShareProps> = (props) => {
  const { i18n, t } = useTranslation();
  const { sto, share, setLoadingRequest, appData } = props;
  const { doAutomaticPurchase: isAutomaticPurchase, isEntityOptional, isShareOwnershipLimitEnabled } = appData;
  const [errors, setErrors] = useState({ shareCount: '' });
  const [value, setValue] = useState<number>(share.minimumSharesToBuyByInvestor);
  const [totalPrice, setTotalPrice] = useState<number>(share.totalPrice * share.minimumSharesToBuyByInvestor);
  const [text, setText] = useState<string>('');
  const [entity, setEntity] = useState<InvestorInvestingEntity>();
  const [publicKey, setPublicKey] = useState<string>('-');
  const [loadLatestEntity, setLoadLatestEntity] = useState<boolean>(false);
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState<boolean>(false);

  const [buyMarketSpace] = useInvestorBuyAlertMarketSpaceMutation();

  const history = useHistory();
  const location = useLocation<{ shareId: number; value: number; text: string; loadLatestEntity: boolean }>();
  const { _id: id } = useParams<{ _id: string }>();
  const { data, loading } = useInvestorInvestingEntitiesQuery();

  const { data: whiteListedWallets } = useGetSharesWalletsQuery({
    variables: { shareTypeID: share.ID, platform: false },
  });

  const maximumInvestmentAllowed = math.number(
    math.multiply(
      math.divide(math.bignumber(share.shareOwnershipLimit), math.bignumber(100)),
      math.bignumber(share.totalShares),
    ) as number,
  );

  const { data: sharesData, loading: sharesLoading } = useGetInvestorSharesByTypeQuery({
    variables: { shareTypeID: share.ID },
    fetchPolicy: 'no-cache',
  });
  const { investorShare } = sharesData || {};
  const investorCurrentOwnership = investorShare?.shares || 0;

  useEffect(() => {
    if (
      isShareOwnershipLimitEnabled &&
      maximumInvestmentAllowed &&
      investorCurrentOwnership >= maximumInvestmentAllowed
    ) {
      setErrors({ shareCount: t(`BuyPropertyShares-shareCount-inputError-exceedMaximumInvestmentAllowed`) });
      setIsBuyButtonDisabled(true);
      setTotalPrice(0);
    }
  });

  const updateTotal = useCallback(
    (newInvestmentOffer: number) => {
      const amount = Math.max(Math.floor(newInvestmentOffer / share.totalPrice), share.minimumSharesToBuyByInvestor);
      setValue(amount);
      setTotalPrice(Math.max(newInvestmentOffer, share.minimumSharesToBuyByInvestor));
    },
    [share.totalPrice, share.minimumSharesToBuyByInvestor],
  );

  const { investorInvestingEntities: entities } = data || {};

  const [blockScroll, allowScroll] = useScrollBlock();

  const changeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    setText(e.currentTarget.value);
  };

  const checkIfBuyIsValid = async () => {
    setLoadingRequest(true);
    if ((entities?.length === 0 || !entity) && !isEntityOptional) {
      return BsSwal.fire({
        title: t('BuyPropertyShare-missingInvestingEntity-popUp-title'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: t('BuyPropertyShare-createNewEntity-popUp-title'),
        cancelButtonText: t('Cancel'),
      }).then((result) => {
        setLoadingRequest(false);
        if (result.isConfirmed) {
          history.push({
            pathname: '/investor/investor-ownership',
            state: {
              value,
              text,
              shareId: share.ID,
              id,
            },
          });
        }
      });
    }
    if (!value) {
      return BsSwal.fire({
        title: t('BuyPropertyShare-missingSharesNumber-popUp-title'),
        icon: 'error',
      });
    }
    return sendBuyRequest();
  };

  if (history.location.state !== undefined) {
    if (location?.state?.shareId === share.ID) {
      setValue(location.state.value);
      setTotalPrice(location.state.value * share.totalPrice);
      setText(location.state.text);
      setLoadLatestEntity(location.state.loadLatestEntity);
      window.history.replaceState(undefined, '');
      history.location.state = null;
    }
  }

  const sendBuyRequest = async () => {
    try {
      const result = await buyMarketSpace({
        variables: {
          data: {
            stoID: Number(sto),
            shareTypeID: share.ID,
            shares: value,
            details: text,
            entityID: Number(entity?.ID),
            publicKey,
            status: BuyAlertStatus.PendingDocuments,
          },
        },
      });
      if (result.data?.investorBuyAlertMarketSpace) {
        BsSwal.fire({
          title: t('BuyPropertyShare-success-popUp-title'),
          icon: 'success',
        }).then(() => {
          history.push(`/investor/share-purchase-signing/${result?.data?.investorBuyAlertMarketSpace}`);
        });
      }
      return true;
    } catch (err) {
      const errorComponents: string[] = (err as Error)?.message.split('/');
      const gqlErr = (err as ApolloError)?.graphQLErrors[0]?.extensions?.translationKey;
      BsSwal.fire({
        title: t(gqlErr ?? errorComponents[0]),
        icon: 'error',
        confirmButtonText: errorComponents.length > 1 ? t('BuyPropertyShare-popUp-continuePurchase') : t('ok'),
        cancelButtonText: t('Cancel'),
      }).then((result) => {
        setLoadingRequest(false);
        if (result.isConfirmed && errorComponents.length > 1) {
          history.push(`/investor/share-purchase-signing/${errorComponents[1]?.split('=')[1]}`);
        } else {
          history.push(`/investor/buy-property/${Number(sto)}`);
        }
      });
      return false;
    }
  };

  const onChangeEntity = (ent: { value: number }) => {
    if (!ent.value) return;
    if (ent.value === -1) {
      history.push({
        pathname: '/investor/investor-ownership',
        state: {
          value,
          text,
          shareId: share.ID,
          id,
          loadLatestEntity: true,
        },
      });
      return;
    }
    setEntity(entities?.find((e) => ent.value === e.ID));
  };

  const onChangePublicKey = (key: { value: string }) => {
    setPublicKey(key.value);
  };

  if (loading || !entities || !whiteListedWallets || sharesLoading || !sharesData) {
    return <Loading />;
  }

  const walletOptions = whiteListedWallets.getSharesWallets
    .filter((e) => !e.isBlocked)
    .map((e) => ({
      label: e.publicKey,
      value: e.publicKey,
    }));
  walletOptions?.unshift({ label: 'Off-chain', value: '-' });

  const selectOptions = entities.map((e) => ({
    value: e.ID,
    label: e.name,
  }));
  selectOptions.push({ value: -1, label: t('BuyProperty-add-new-entity-select-option') });

  const minimumInvestmentAmount = share.minimumSharesToBuyByInvestor * share.totalPrice ?? 0;

  const handleOnBlur = () => {
    allowScroll();
    const amount = Math.max(Math.floor(totalPrice / share.totalPrice), share.minimumSharesToBuyByInvestor);
    setValue(amount);
    updateTotal(amount * share.totalPrice);
    if (
      isShareOwnershipLimitEnabled &&
      maximumInvestmentAllowed &&
      math.add(investorCurrentOwnership, amount) > maximumInvestmentAllowed
    ) {
      setErrors({ shareCount: t(`BuyPropertyShares-shareCount-inputError-exceedMaximumInvestmentAllowed`) });
      setIsBuyButtonDisabled(true);
      setTotalPrice(math.multiply(math.subtract(maximumInvestmentAllowed, investorCurrentOwnership), share.totalPrice));
    } else {
      setErrors({ shareCount: '' });
      setIsBuyButtonDisabled(false);
    }
  };

  const changeValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ shareCount: '' });
    setIsBuyButtonDisabled(false);
    const amount = Math.max(Math.floor(+e.currentTarget.value / share.totalPrice), share.minimumSharesToBuyByInvestor);
    if (
      isShareOwnershipLimitEnabled &&
      maximumInvestmentAllowed &&
      math.add(investorCurrentOwnership, amount) > maximumInvestmentAllowed
    ) {
      setErrors({ shareCount: t(`BuyPropertyShares-shareCount-inputError-exceedMaximumInvestmentAllowed`) });
      setIsBuyButtonDisabled(true);
      e.currentTarget.value = String(
        math.multiply(math.subtract(maximumInvestmentAllowed, investorCurrentOwnership), share.totalPrice),
      );
    }
    setTotalPrice(parseFloat(e.currentTarget.value));
  };

  return (
    <>
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-price/share')}</p>
        </Col>
        <Col md={8}>
          <p style={{ fontSize: '1.1em' }}>
            <CurrencySymbolDisplay value={share.totalPrice} currency={share.currency} />
          </p>
        </Col>
      </Row>
      {isShareOwnershipLimitEnabled && maximumInvestmentAllowed ? (
        <>
          <Row>
            <Col md={4}>
              <p>{t('BuyPropertyShare-maximumInvestmentAllowed')}</p>
            </Col>
            <Col md={8}>
              <p className="text-danger">
                {maximumInvestmentAllowed?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p>{t('BuyPropertyShare-investorCurrentOwnership')}</p>
            </Col>
            <Col md={8}>
              <p>
                {investorCurrentOwnership?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </Col>
          </Row>
        </>
      ) : null}
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-minimumInvestment')}</p>
        </Col>
        <Col md={8}>
          <p style={{ fontSize: '1.1em' }}>
            <CurrencySymbolDisplay value={minimumInvestmentAmount} currency={share.currency} />
          </p>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={4}>
          <p>{t('BuyPropertyShare-investmentAmount')}</p>
        </Col>
        <Col md={8}>
          <Input
            style={{ fontSize: '1.1em' }}
            type="number"
            min={minimumInvestmentAmount}
            step={share.totalPrice}
            onChange={changeValue}
            onFocus={blockScroll}
            onBlur={handleOnBlur}
            value={totalPrice}
            invalid={errors.shareCount}
          />
          <FormFeedback>{errors.shareCount} </FormFeedback>
        </Col>
      </Row>
      <InvestingEntitySelector
        entities={entities}
        share={share}
        selectOptions={selectOptions}
        entity={entity}
        value={value}
        id={id}
        onChangeEntity={onChangeEntity}
        history={history}
        loadLatestEntity={loadLatestEntity}
        setValue={setValue}
        setEntity={setEntity}
        text={text}
      />
      {share.isBlockchain && share.channelIDForAutoPayments !== -1 && isAutomaticPurchase ? (
        <Row>
          <Col md={4}>
            <div className="mt-3">{t('Investor Wallets')}</div>
          </Col>
          <Col>
            <Select options={walletOptions} onChange={onChangePublicKey} />
          </Col>
        </Row>
      ) : null}
      <br />
      <Row className="mb-4">
        <Col md={4}>
          <p>{t('BuyPropertyShare-adminMessage')}</p>
        </Col>
        <Col md={8}>
          <Input type="textarea" rows={3} onChange={changeText} value={text} />
        </Col>
      </Row>
      <Row>
        <Col md={9} />
        <Col className="ml-auto" md={3}>
          <div className="float-right">
            <Button disabled={isBuyButtonDisabled} onClick={checkIfBuyIsValid}>
              {t('BuyProperty-send-button')}
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default BuyPropertyShareMarketspace;
