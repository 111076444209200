import React from 'react';
import { useTranslation } from 'react-i18next';

import { MetaValues } from 'services/apollo';
import { MetaStateUpdate, MetaState } from 'components/meta-values/useMetaState';
import MetaValueField from 'components/meta-values/MetaValueField';
import { Row } from 'atoms';

interface InvestingEntityFormMetaProps {
  meta: MetaValues[];
  state: MetaState;
  entityID?: number;
  onChange: MetaStateUpdate;
}

const InvestingEntityFormMeta: React.FC<InvestingEntityFormMetaProps> = ({ entityID, meta, state, onChange }) => {
  const { t } = useTranslation();
  if (!meta.length || !entityID) {
    return null;
  }

  return (
    <>
      <br />
      <h3>{t('entityItemRowMetaInformationLabel')}</h3>
      <Row>
        {meta.map((m) => (
          <MetaValueField
            key={m.key}
            label={t(m.key)}
            id={`${entityID}-${m.key}`}
            type={m.type}
            value={state[m.key]}
            update={(v: string) => onChange(v, m.key)}
          />
        ))}
      </Row>
      <hr />
    </>
  );
};

export default InvestingEntityFormMeta;
