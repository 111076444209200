import React, { useState } from 'react';
import { formatISO, parseISO } from 'date-fns';
import {
  InvestorProfileInput,
  MeDocument,
  InvestorSto,
  useInvestorProfileMutation,
  Investor,
  useInvestorMetaValuesUpdateMutation,
} from 'services/apollo/graphql';

import { BsSwal, Button, Col, DatePicker, Form, FormGroup, Input, Row, Select } from 'atoms/index';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import { AppData, MeData } from 'services/apollo/core';
import useMetaState from 'components/meta-values/useMetaState';
import InvestorMetaKyc from 'pages/kyc-process/components/InvestorMetaKyc';

export type Country = { value?: string; label?: string };

interface PersonalInformationFormProps {
  investor: MeData['investor'];
  investorSTO: Partial<InvestorSto>;
  countries: Country[];
  nextStep?(): void;
  appData: AppData;
}

type FormState = Partial<Investor> & Partial<InvestorSto>;

const defaultInfo: (investor: MeData['investor'], investorSTO: Partial<InvestorSto>) => FormState = (
  investor,
  investorSTO,
) => ({
  firstName: investor.firstName || '',
  lastName: investor.lastName || '',
  address: investor.address || '',
  zip: investor.zip || '',
  town: investor.town || '',
  state: investor.state || '',
  phone: investor.phone,
  passportNumber: investor.passportNumber || '',
  nationalID: investor.nationalID || '',
  driversLicenseID: investor.driversLicenseID || '',
  kinname: investor.kinname || '',
  kinphone: investor.kinphone || '',
  kinemail: investor.kinemail || '',
  notes: investorSTO.notes || '',
});

const getDOB: (investor: MeData['investor']) => Date | null = ({ birthDate = null }) => {
  return birthDate ? parseISO(birthDate) : null;
};

const optionalFields = ['kinname', 'kinphone', 'kinemail', 'notes', 'nationalID', 'passportNumber', 'driversLicenseID'];

const getEmpty = (data: InvestorProfileInput) => {
  const errors = (Object.keys(data) as Array<keyof InvestorProfileInput>)
    .map((key) => (data[key] ? '' : key))
    .filter(Boolean);

  return errors.filter((x) => !optionalFields.includes(x));
};

const PersonalInformationForm: React.FC<PersonalInformationFormProps> = ({
  investor,
  investorSTO,
  countries,
  appData,
  nextStep,
}) => {
  const { t } = useTranslation();
  const [empty, setEmpty] = useState<string[]>([]);
  const [state, setState] = useState<FormState>(defaultInfo(investor, investorSTO));
  const [metaState, setMetaState] = useMetaState(investor.meta);
  const [dob, setDob] = useState<Date | null>(getDOB(investor));
  const [country, setCountry] = useState<string>(investor.country || '');
  const [succsees, setSucsees] = useState<boolean>(false);
  const [update] = useInvestorProfileMutation({ refetchQueries: [{ query: MeDocument }] });
  const [updateMeta] = useInvestorMetaValuesUpdateMutation({ refetchQueries: [{ query: MeDocument }] });

  const onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void = (e) => {
    const index = empty.indexOf(e.currentTarget.name);
    if (index !== -1) {
      empty.splice(index, 1);
      setEmpty(empty);
    }
    const newState: {
      [x: string]: string;
    } = { [e.currentTarget.name]: e.currentTarget.value };
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const onChangeDob = (date: Date) => {
    const index = empty.indexOf('birthDate');
    if (index !== -1) {
      empty.splice(index, 1);
      setEmpty(empty);
    }
    setDob(date);
  };

  const onChangeCountry = (ctr: Country) => {
    const index = empty.indexOf('country');
    if (index !== -1) {
      empty.splice(index, 1);
      setEmpty(empty);
    }
    if (!ctr.value) return;
    setCountry(ctr.value);
  };

  const { isDriversLicenseEnabled, isSaveAndNextAtPersonalInfoEnabled: isSAN } = appData;

  const handleSubmit: (e: React.FormEvent) => void = (e) => {
    e.preventDefault();
    const data = {
      ...state,
      birthDate: dob ? formatISO(dob, { representation: 'date' }) : '',
      country,
    } as InvestorProfileInput;

    const errors = getEmpty(data);
    if (errors.length) {
      setEmpty(errors);
      return;
    }

    const values = Object.keys(metaState).map((m) => ({ key: m, value: metaState[m] }));

    update({ variables: { data } })
      .then(() => updateMeta({ variables: { values } }))
      .then(() => {
        if (isSAN && nextStep) {
          nextStep();
        } else {
          setSucsees(true);
          setTimeout(() => {
            setSucsees(false);
          }, 4000);
        }
      })
      .catch((err) => {
        let text = '';
        const message = err.graphQLErrors[0].extensions.exception.validationErrors[0].constraints ?? null;
        if (message) {
          const textKey = Object.keys(message)[0];
          text = message[textKey];
        }
        return BsSwal.fire({
          title: err.message,
          text,
          icon: 'error',
        });
      });
  };

  return (
    <Form className="mt-2" onSubmit={handleSubmit}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <label>{t('My Investor ID')}</label>
            <span className="ml-3">{investor.ID}</span>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <label>{t('First-Name')} *</label>
            <Input
              invalid={empty.includes('firstName')}
              max="60"
              name="firstName"
              placeholder={t('PersonalInformationForm-placeholder-firstName')}
              onChange={onChange}
              value={state.firstName || ''}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <label>{t('Last-Name')} *</label>
            <Input
              invalid={empty.includes('lastName')}
              max="60"
              name="lastName"
              onChange={onChange}
              placeholder={t('PersonalInformationForm-placeholder-lastName')}
              value={state.lastName || ''}
            />
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col>
          <FormGroup>
            <label>
              {t('Passport Number')} * ( {t('Either Passport or National ID required')} ){' '}
            </label>
            <Input
              max="45"
              invalid={empty.includes('passportNumber')}
              name="passportNumber"
              placeholder={t('PersonalInformationForm-placeholder-passportNumber')}
              value={state.passportNumber}
              onChange={onChange}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <label>{t('National ID')} *</label>
            <Input
              invalid={empty.includes('nationalID')}
              max="60"
              name="nationalID"
              placeholder={t('PersonalInformationForm-placeholder-idNumber')}
              value={state.nationalID}
              onChange={onChange}
            />
          </FormGroup>
        </Col>
        {isDriversLicenseEnabled ? (
          <Col md={4}>
            <FormGroup>
              <label>{t('PersonalInformationForm-DriversLicenseID')} *</label>
              <Input
                max="60"
                invalid={empty.includes('driversLicenseID')}
                name="driversLicenseID"
                placeholder={t('PersonalInformationForm-DriversLicenseID')}
                value={state.driversLicenseID}
                onChange={onChange}
              />
            </FormGroup>
          </Col>
        ) : null}
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <label>{t('Street Address')} *</label>
            <Input
              invalid={empty.includes('address')}
              max="150"
              name="address"
              placeholder={t('PersonalInformationForm-placeholder-address')}
              value={state.address}
              onChange={onChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <label>{t('Zip')} *</label>
            <Input
              invalid={empty.includes('zip')}
              max="28"
              name="zip"
              placeholder={t('PersonalInformationForm-placeholder-zip')}
              value={state.zip}
              onChange={onChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <label>{t('City')} *</label>
            <Input
              invalid={empty.includes('town')}
              max="50"
              name="town"
              placeholder={t('PersonalInformationForm-placeholder-city')}
              value={state.town}
              onChange={onChange}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <label>{t('State')} *</label>
            <Input
              invalid={empty.includes('state')}
              max="50"
              name="state"
              placeholder={t('PersonalInformationForm-placeholder-state')}
              value={state.state}
              onChange={onChange}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <label>{t('Country')} *</label>

            <Select
              invalid={empty.includes('country')}
              name="country"
              options={countries}
              value={{ value: country, label: country }}
              onChange={onChangeCountry}
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <FormGroup>
            <label>{t('Phone Number')} *</label>
            <Input
              invalid={empty.includes('phone')}
              max="30"
              name="phone"
              onChange={onChange}
              placeholder={t('PersonalInformationForm-placeholder-phone')}
              value={state.phone}
            />
          </FormGroup>
        </Col>
        <Col md={2} />
        <Col md={3}>
          <FormGroup>
            <label>{t('Date of Birth')} *</label>
            <DatePicker
              invalid={empty.includes('birthDate')}
              dateFormat="dd.MM.yyyy"
              showPopperArrow={false}
              selected={dob}
              onChange={onChangeDob}
            />
          </FormGroup>
        </Col>
      </Row>

      <hr />
      <h4>{t('Next of Kin')}</h4>
      <label>{t('Please-specify-who-is-your-next-of-kin')}</label>

      <Row>
        <Col>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label>{t('Full-Name')}</label>
                <Input
                  type="text"
                  max="70"
                  id="kinname"
                  name="kinname"
                  placeholder={t('PersonalInformationForm-placeholder-kinName')}
                  value={state.kinname}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>

            <Col md={6}>
              <FormGroup>
                <label>{t('Phone Number')}</label>
                <Input
                  type="text"
                  max="20"
                  id="kinphone"
                  name="kinphone"
                  placeholder={t('PersonalInformationForm-placeholder-kinPhone')}
                  value={state.kinphone}
                  onChange={onChange}
                />
              </FormGroup>
            </Col>
          </Row>

          <Row>
            <div className="col-md-6">
              <FormGroup>
                <label>{t('Email')}</label>
                <Input
                  type="text"
                  max="30"
                  id="kinemail"
                  name="kinemail"
                  placeholder={t('PersonalInformationForm-placeholder-kinEmail')}
                  value={state.kinemail}
                  onChange={onChange}
                />
              </FormGroup>
            </div>
          </Row>
        </Col>
      </Row>
      <InvestorMetaKyc entityID={investor.ID} meta={investor.meta} state={metaState} onChange={setMetaState} />

      <hr />
      <h4>{t('Investor Notes')}</h4>
      <Row>
        <Col>
          <FormGroup>
            <label>{t('Enter-any-notes-additional-information-you-may-want')}</label>
            <Input
              type="textarea"
              id="notes"
              name="notes"
              onChange={onChange}
              placeholder={t('PersonalInformationForm-placeholder-notes')}
              rows={8}
              value={state.notes || ''}
            />
          </FormGroup>
        </Col>
      </Row>
      {succsees && <Alert color="success">{t('PersonalInformationForm-alert-success')}</Alert>}
      <Row>
        <Col>
          <FormGroup>
            <Button size="md" type="submit">
              {t(isSAN ? 'SaveAndNext' : 'Save')}
            </Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default PersonalInformationForm;
