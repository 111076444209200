import React from 'react';

import { InvestorWalletBalance, InvestorWalletChannel } from 'services/apollo/multisto';

import WALLET from 'assets/img/wallet.png';
import { Card, CardBody, Col, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { MetaMaskProvider } from 'metamask-react';
import AccountItem from 'pages/wallet/components/AccountItem';
import EthereumKeys from 'pages/settings/components/EthereumKeys';
import ExternalWalletManagement from 'pages/wallet/components/ExternalWalletManagement';
import { InternalWalletMode, PaymentChannelType } from 'services/apollo';
import { AppData } from 'services/apollo/core';
import WalletTopUp from 'pages/wallet/components/WalletTopUp';
import DividendPayoutSetup from 'components/payment-integration/DividendPayoutSetup';

interface WalletManagementProps {
  channels: InvestorWalletChannel[];
  accounts: InvestorWalletBalance[];
  sto: number;
  setSto: (value: number) => void;
  appData: AppData;
}

const WalletManagement: React.FC<WalletManagementProps> = ({ channels, accounts, appData }) => {
  const { platformConfiguration, platformWalletMode, activePaymentProcessor } = appData;

  const { t } = useTranslation();

  const isBlockchainChannelsEnabled = channels.find((c) => c.channelType === PaymentChannelType.Metamask);
  return (
    <StyledCard>
      <CardHeader
        text={t('')}
        imgSrc={platformConfiguration === 3 && platformWalletMode !== InternalWalletMode.Disabled ? WALLET : ''}
      />
      <CardBody className="mb-2">
        {platformWalletMode !== InternalWalletMode.Disabled && (
          <Row>
            <Col md={4}>
              <h4 className="mt-0 pb-2"> {t('WalletManagement-CurrentBalance')} </h4>
              {accounts.length > 0 ? (
                <>
                  <label>{t('WalletManagement-CurrentBalanceInProject')}</label>
                  {accounts.map((acc) => (
                    <AccountItem key={acc.ID} account={acc} />
                  ))}
                </>
              ) : (
                <label>{t('WalletManagement-NoBalance')}</label>
              )}
            </Col>
            <Col>
              <WalletTopUp appData={appData} channels={channels} />
            </Col>
          </Row>
        )}
        <ExternalWalletManagement appData={appData} />
        {isBlockchainChannelsEnabled ? (
          <Row>
            <Col>
              <MetaMaskProvider>
                <EthereumKeys />
              </MetaMaskProvider>
            </Col>
          </Row>
        ) : null}
        {
          // TODO: find a better place to display DividendPayoutSetup component - DIG-1775
        }
        <DividendPayoutSetup activePaymentProcessor={activePaymentProcessor} />
      </CardBody>
    </StyledCard>
  );
};

export default WalletManagement;

const StyledCard = styled(Card)`
  overflow: visible;
`;
