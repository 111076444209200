import React from 'react';
import 'react-step-progress-bar/styles.css';
import { ProgressBar } from 'react-step-progress-bar';
import { findLastIndex } from 'services/core/helpers';
import styled from 'styled-components';

export interface TiIcon {
  tiIcon: string;
  size?: string;
  color?: string;
}

export interface StepProps {
  condition: boolean;
  srcImage?: string;
  tiIcon?: TiIcon;
  text?: string;
  displayIndexAsImage?: boolean;
}

export interface StepProgressBarProps {
  orderedSteps: StepProps[];
  color?: string;
}

const StepProgressBar: React.FC<StepProgressBarProps> = (props) => {
  const { orderedSteps, color } = props;
  if (!orderedSteps.length) {
    return null;
  }

  const currentStep = findLastIndex(orderedSteps, (a) => a.condition);
  const progress = ((currentStep + 1) * 100) / (orderedSteps.length - 1);

  return (
    <Wrapper>
      <ProgressBar percent={progress} filledBackground={color || `linear-gradient(to right, #009a9a, #00cece)`}>
        {orderedSteps.map((s, index) => (
          <Steps key={index}>
            {s.tiIcon ? (
              <TiIcon
                className={s.tiIcon.tiIcon}
                color={s.tiIcon.color}
                size={s.tiIcon.size}
                condition={s.condition && currentStep >= index}
              />
            ) : null}
            {s.displayIndexAsImage ? (
              <CircledNumber condition={!s.condition && currentStep >= index}>{index + 1}</CircledNumber>
            ) : null}
            {s.srcImage ? <Icon src={s.srcImage} condition={!s.condition && currentStep >= index} /> : null}
            {s.text ? <div>{s.text}</div> : null}
          </Steps>
        ))}
      </ProgressBar>
    </Wrapper>
  );
};

export default StepProgressBar;

const Wrapper = styled.div`
  margin-top: 7%;
  text-align: center;
`;

const Steps = styled.div`
  position: relative;
  top: -450%;
`;

const CircledNumber = styled(({ ...props }) => <div {...props} />)`
  filter: grayscale(${(p) => (p.condition ? 0 : 80)}%);
  border-radius: 50%;
  width: 36px;
  height: 36px;

  background: #fff;
  border: 2px solid #666;
  color: #666;

  font: 32px Arial, sans-serif;
`;

const Icon = styled(({ ...props }) => <img {...props} alt="" />)`
  filter: grayscale(${(p) => (p.condition ? 0 : 80)}%);
  width: ${(p) => p.size || '25px'};
  height: ${(p) => p.size || '25px'};
  margin-right: 8px;
`;

const TiIcon = styled(({ ...props }) => <i {...props} />)`
  filter: grayscale(${(p) => (p.condition ? 0 : 80)}%);
  font-size: ${(p) => p.size || '25px'};
  margin-right: 8px;
  color: ${(p) => p.color || 'red'};
`;
