import React from 'react';

import { Markup, Node } from 'interweave';

const MarkupIframe: React.FC<{ content: string }> = ({ content }) => {
  const handleIframe = (node: HTMLElement, children: Node[]): React.ReactNode => {
    if (node.tagName === 'IFRAME') {
      return (
        <iframe
          width={node.getAttribute('width') ?? 'auto'}
          height={node.getAttribute('height') ?? 'auto'}
          title={node.title}
          src={node.getAttribute('src') ?? ''}
          sandbox="allow-scripts allow-same-origin"
        >
          {children}
        </iframe>
      );
    }
  };

  return <Markup content={content} transform={handleIframe} />;
};

export default MarkupIframe;
