import { DropdownToggle, Button } from 'atoms';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { ButtonGroup, DropdownItem, DropdownMenu, UncontrolledDropdown } from 'reactstrap';
import { PropertyFile } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';
import styled, { css } from 'styled-components';

interface PropertyDetailActionsButtonGroupProps {
  buy: () => void;
  setOpen: (isOpen: boolean) => void;
  documents: PropertyFile[];
  canSeeNDAData: boolean;
  canSignNDA: boolean;
  stoID: number;
  isLeft: boolean;
  appData: AppData;
  rightLayout?: boolean;
}

const PropertyDetailActionsButtonGroup: React.FC<PropertyDetailActionsButtonGroupProps> = ({
  canSeeNDAData,
  buy,
  setOpen,
  documents = [],
  canSignNDA = false,
  stoID,
  isLeft,
  appData,
  rightLayout = false,
}) => {
  const { t } = useTranslation();
  const { isRegDRegSSeparationEnabled } = appData;
  const history = useHistory();
  if (isLeft) {
    return null;
  }

  const chatURL = appData.isRegDRegSSeparationEnabled ? `/investor/support` : `/investor/chat/${stoID}`;

  return (
    <StyledBtnGroup rightLayout={rightLayout}>
      <Button
        outline
        className="details-action__btn"
        onClick={buy}
        disabled={(!canSeeNDAData || !canSignNDA) && !isRegDRegSSeparationEnabled}
      >
        {t('MarketSpacePropertyDetails-button-investNow')}
      </Button>
      <Button className="details-action__btn" onClick={() => history.push(chatURL)}>
        {t('MarketSpacePropertyDetails-Button-MessageSellingAgent')}
      </Button>
      <UncontrolledDropdown group>
        <DropdownToggle className="details-action__btn" caret>
          {t('MarketSpacePropertyDetails-Button-DownloadTeaser')}
        </DropdownToggle>
        <DropdownMenu>
          {documents.map((doc, index) => {
            return (
              <DropdownItem key={doc.ID} className="text-wrap" tag="a" href={doc.url} target="_blank">
                {doc.title
                  ? doc.title
                  : `${t('PropertyDetailActionsButtonGroup-DropdownItem-DefaultDocumentTitle')} ${++index}`}
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </UncontrolledDropdown>
      {!canSeeNDAData && (
        <Button className="details-action__btn" onClick={() => setOpen(true)} disabled={!canSignNDA}>
          {t('MarketSpacePropertyDetails-Button-UploadNDA')}
        </Button>
      )}
    </StyledBtnGroup>
  );
};

export default PropertyDetailActionsButtonGroup;

const StyledBtnGroup = styled(ButtonGroup)<{ rightLayout?: boolean }>`
  display: flex;
  justify-content: center;
  align-content: center;
  gap: 2rem;
  margin-top: 2rem;

  ${(p) => {
    if (p.rightLayout) {
      return css`
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: stretch;
      `;
    }
  }}

  .details-action__btn {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;

    border-radius: ${(p) => {
      switch (p.theme.typeAppCorner) {
        case 'boxy':
          return '0px !important';
        case 'light-round':
          return '5px !important';
        case 'rounded':
          return '26px !important';
        default:
          return '5px !important';
      }
    }};
  }
`;
