import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentProcessor, useIsDpayOnboardCompleteQuery } from 'services/apollo';
import { Button, Label, Loading } from 'atoms';

interface DividendPayoutSetupProps {
  activePaymentProcessor: PaymentProcessor;
}

// TODO: refactor this component so it is generic for dividend payout setup instead of being stripe specific - DIG-1775
const DividendPayoutSetup: React.FC<DividendPayoutSetupProps> = ({ activePaymentProcessor }) => {
  const { t } = useTranslation();
  const { data: isOnboardedData, loading: isOnboardedLoading } = useIsDpayOnboardCompleteQuery();

  if (!isOnboardedData || isOnboardedLoading) {
    return <Loading />;
  }

  const { isOnboardComplete: isOnboarded } = isOnboardedData;

  const labelColor = isOnboarded ? 'green' : 'red';
  const labelText = t(`DividendPayoutSettings-onboardLabel-${isOnboarded}`);
  // TODO: generically determine what to show based on the dividends payment channel configured by the admin - DIG-1775
  if (activePaymentProcessor !== PaymentProcessor.Stripe) {
    return null;
  }
  return (
    <>
      <Label color={labelColor}> {labelText} </Label>{' '}
      <Button
        onClick={() => {
          window.location.href = `dpay/onboarding`;
        }}
      >
        {t(`DividendPayoutSettings-Button-startOnboarding`)}
      </Button>
      <Button
        onClick={() => {
          window.open(`https://connect.stripe.com/`);
        }}
      >
        {t(`DividendPayoutSettings-Button-stripeDashboard`)}
      </Button>
    </>
  );
};
export default DividendPayoutSetup;
