import React from 'react';

import { useInvestorActivePropertiesQuery, useInvestorAppDataQuery } from 'services/apollo';
import { Loading } from 'atoms';
import FilterActiveProperties from 'pages/active-properties/FilterActiveProperties';
import ActivePropertiesLayout from 'pages/active-properties/components/ActivePropertiesLayout';

export enum OFFERINGHTMLIDS {
  ACTIVE_OFFERING = 'activeOffering',
  CLOSED_OFFERING = 'closedOffering',
  PROFILE_MATCHES = 'profileMathces',
}

const ActiveProperties: React.FC = () => {
  const { data, loading } = useInvestorActivePropertiesQuery({
    variables: {
      buyEnabledFilterInput: {
        isBuyStoEnabled: true,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (!data || loading || !appData || appDataLoading) {
    return <Loading />;
  }
  const { investorActiveProperties } = data;

  const { isFilterPreferencesEnabled } = appData.investorAppParameters;

  return (
    <>
      {isFilterPreferencesEnabled ? (
        <FilterActiveProperties properties={investorActiveProperties} parameters={appData.investorAppParameters} />
      ) : (
        <ActivePropertiesLayout propertiesArray={investorActiveProperties} parameters={appData.investorAppParameters} />
      )}
    </>
  );
};

export default ActiveProperties;
