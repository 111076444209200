import React from 'react';

import { AtomicSwapStatus, useUpdateOrderStatusMutation } from 'services/apollo/graphql';

import { BsSwal, Button, FontAweIcon } from 'atoms';
import { useTranslation } from 'react-i18next';
import {
  Address,
  useAccount,
  useContractWrite,
  useNetwork,
  usePrepareContractWrite,
  useWaitForTransaction,
} from 'wagmi';
import erc1404TokenAbi from 'abis/erc1404TokenAbi.json';
import { toTokenPowerOf } from 'lib/helpers';
import BlockchainNetworkSwitchButton from 'components/BlockchainNetworkSwitchButton';

interface AtomicSwapApproveButtonProps {
  chainID: number;
  blockchainExplorer: string;
  swapContractAddress: string;
  tokenAmount: number;
  tokenContractAddress: string;
  setAtomicSwapCurrentStatus: (atomicSwapStatus: AtomicSwapStatus) => void;
  orderID: number;
  isSell: boolean;
  isOrderCreator: boolean;
  atomicSwapStatus: AtomicSwapStatus;
}

const AtomicSwapApproveButton: React.FC<AtomicSwapApproveButtonProps> = ({
  chainID,
  blockchainExplorer,
  swapContractAddress,
  tokenAmount,
  tokenContractAddress,
  setAtomicSwapCurrentStatus,
  orderID,
  isSell,
  isOrderCreator,
  atomicSwapStatus,
}) => {
  const { t } = useTranslation();
  const [updateOrderStatus] = useUpdateOrderStatusMutation();
  const parsedTokenAmount = toTokenPowerOf(tokenContractAddress, tokenAmount, chainID);
  const { isConnected } = useAccount();
  const correctAtomicSwapStatus =
    (!isOrderCreator && isSell && [AtomicSwapStatus.SellerSent].includes(atomicSwapStatus)) ||
    (!isOrderCreator && !isSell && [AtomicSwapStatus.BuyerCompleted].includes(atomicSwapStatus)) ||
    [AtomicSwapStatus.Accepted].includes(atomicSwapStatus);
  const { chain } = useNetwork();

  const { config } = usePrepareContractWrite({
    address: tokenContractAddress as Address,
    abi: erc1404TokenAbi,
    enabled: correctAtomicSwapStatus && isConnected,
    functionName: 'approve',
    args: [swapContractAddress, parsedTokenAmount],
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  const {
    write,
    isLoading,
    data: writeContractData,
  } = useContractWrite({
    ...config,
    chainId: chainID,
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  const { isLoading: transactionLoading } = useWaitForTransaction({
    hash: writeContractData?.hash,
    enabled: correctAtomicSwapStatus,
    onSuccess(data) {
      const variables = {
        orderId: orderID,
        atomicSwapCurrentStatus:
          (isOrderCreator && isSell) || (!isOrderCreator && !isSell)
            ? AtomicSwapStatus.SellerCommitted
            : AtomicSwapStatus.BuyerCommitted,
      };
      updateOrderStatus({ variables });
      setAtomicSwapCurrentStatus(variables.atomicSwapCurrentStatus);
      BsSwal.fire({
        title: t('useAtomicSwap-approval-PopUp-success-title'),
        text: t('useAtomicSwap-approval-PopUp-success-description'),
        icon: 'success',
        footer: `<a target="_blank" href="${blockchainExplorer}tx/${data.transactionHash}">
                ${t('useAtomicSwap-approval-PopUp-success-footer')}</a>`,
      });
    },
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  return chain?.id !== chainID && isConnected && correctAtomicSwapStatus ? (
    <BlockchainNetworkSwitchButton chainID={chainID} />
  ) : (
    <>
      <Button
        size="lg"
        disabled={transactionLoading || isLoading || !correctAtomicSwapStatus}
        onClick={() => write?.()}
      >
        {t('AtomicSwapComponent-button-ethereumTransfer-approveTransfer')}
      </Button>
      {(transactionLoading || isLoading) && (
        <p>
          <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
          {t('AtomicSwapComponent-label-step2Loading')}
        </p>
      )}
    </>
  );
};

export default AtomicSwapApproveButton;
