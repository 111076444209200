import React from 'react';
import { useTranslation } from 'react-i18next';

import { useActiveSto } from 'hooks';
import { useGetExchangeOrdersQuery } from 'services/apollo';
import { ExchangeOrderItem } from 'services/apollo/exchange';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

import { Card, CardBody, Col, Loading, Row, Label, BrandIcon } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import SortTable from '../SortTable';
import ExchangeOrderTypeCell from './ExchangeOrderTypeCell';
import ExchangeOrderActionCell from './ExchangeOrderActionCell';
import ExchangeOrderChatCell from './ExchangeOrderChatCell';

const ExchangeOrders: React.FC = () => {
  const { sto } = useActiveSto();
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('ExchangeOrders-column-header-dateFrom'),
        accessor: 'dateFrom',
      },
      {
        Header: t('ExchangeOrders-column-header-title'),
        accessor: 'shareType.title',
      },
      {
        Header: t('ExchangeOrders-column-header-shareCount'),
        accessor: (item: ExchangeOrderItem) => {
          return `${item.shares} ${t('ExchangeOrders-label-share', { count: item.shares })}`;
        },
        id: 'shares',
      },
      {
        Header: t('ExchangeOrders-column-header-type'),
        accessor: 'type',
        cell: ExchangeOrderTypeCell,
      },
      {
        Header: t('ExchangeOrders-column-header-askingPrice'),
        accessor: (item: ExchangeOrderItem) => {
          return <CurrencySymbolDisplay value={item.rateFrom} currency={item.shareType.currency} />;
        },
        id: 'rateFrom',
      },
      {
        accessor: 'ID',
        Cell: ExchangeOrderActionCell,
      },
      {
        accessor: 'chat',
        Cell: ExchangeOrderChatCell,
      },
    ],
    [t],
  );
  const { data, loading } = useGetExchangeOrdersQuery({
    variables: {
      stoID: sto,
    },
    fetchPolicy: 'no-cache',
  });

  if (loading || !data) {
    return <Loading />;
  }
  const { getExchangeOrders: orders } = data;

  return (
    <Card>
      <CardHeader
        text={t('ExchangeOrders-card-header-text')}
        caption={t('ExchangeOrders-CardHeader-Caption')}
        icon={<BrandIcon icon="shopping-cart" pill color="#314f75" />}
      />
      <CardBody className="mb-2">
        <Row>
          <Col md={12}>
            {orders.length ? (
              <SortTable columns={columns} data={orders} />
            ) : (
              <Label className="text-info">{t('ExchangeOrders-Label-NoOrdersFound')}</Label>
            )}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default ExchangeOrders;
