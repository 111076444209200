import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useInvestorActivePropertiesQuery,
  useGetLastResolvedInvoiceSubscription,
  InvoiceStatusType,
  DpayResolver,
} from 'services/apollo';

import { Loading, BsSwal } from 'atoms';
import Invoice from './Invoice';

const InvoiceOverview: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: activeProperties, loading: activePropsLoading } = useInvestorActivePropertiesQuery({
    variables: {
      buyEnabledFilterInput: {
        isBuyStoEnabled: true,
      },
    },
    fetchPolicy: 'no-cache',
  });

  const { data: allProperties, loading: allPropsLoading } = useInvestorActivePropertiesQuery({
    variables: {
      buyEnabledFilterInput: {
        isBuyStoEnabled: true,
      },
      showAllProps: true,
    },
    fetchPolicy: 'no-cache',
  });

  const { data: subscriptionData } = useGetLastResolvedInvoiceSubscription();
  const lastResolvedInvoice = subscriptionData?.getLastResolvedInvoice;

  const invoiceResolvedStates: InvoiceStatusType[] = [
    InvoiceStatusType.Paid,
    InvoiceStatusType.Cancelled,
    InvoiceStatusType.PaymentFailure,
    InvoiceStatusType.Declined,
  ];

  useEffect(() => {
    if (lastResolvedInvoice && invoiceResolvedStates.includes(lastResolvedInvoice.status)) {
      BsSwal.fire({
        title: t('InvoiceOverview-BsSwal-InvoiceResolved'),
        icon: 'info',
      });
      if (lastResolvedInvoice.resolver === DpayResolver.ExchangeOffer) {
        history.push(`/investor/trading`);
      }
      if (lastResolvedInvoice.resolver === DpayResolver.BuyAlert) {
        history.push(`/investor/Portfolio`);
      }
    }
  }, [lastResolvedInvoice]);

  if (!activeProperties || !allProperties || activePropsLoading || allPropsLoading) {
    return <Loading />;
  }

  const { investorActiveProperties } = activeProperties;
  const { investorActiveProperties: investorAllProperties } = allProperties;

  const activePropsMap = new Map(investorActiveProperties.map((property) => [property.ID, property]));
  const allPropsMap = new Map(investorAllProperties.map((property) => [property.ID, property]));

  return (
    <div className="content">
      <Invoice properties={activePropsMap} />
      <Invoice properties={allPropsMap} isHistorical />
    </div>
  );
};

export default InvoiceOverview;
