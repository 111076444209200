import React from 'react';
import { useGetSumSubInvestorTokenQuery, useMeQuery, useRefreshInvestorDataMutation } from 'services/apollo';

import { BsSwal, Card, CardBody, CardHeader, Col, GrayDot, Loading, Row } from 'atoms';
import { useTranslation } from 'react-i18next';
import SumsubWebSdk from '@sumsub/websdk-react';
import { useHistory } from 'react-router-dom';
import { AnyEventName, AnyEventPayload } from '@sumsub/websdk/types/types';

type SumSubEventPayloadType = {
  reviewId: string;
  attemptId: string;
  attemptCnt: number;
  elapsedSincePendingMs: number;
  elapsedSinceQueuedMs: number;
  reprocessing: boolean;
  levelName: string;
  createDate: string;
  reviewDate: string;
  reviewResult: {
    reviewAnswer: string;
  };
  reviewStatus: ReviewStatus;
  priority: number;
  autoChecked: boolean;
};

enum ReviewStatus {
  ON_HOLD = 'onHold',
  COMPLETED = 'completed',
}

const SumSubKyc: React.FC = () => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const { data: dataMe, loading: loadingMe } = useMeQuery({ fetchPolicy: 'network-only' });
  const [refreshInvestorData] = useRefreshInvestorDataMutation();
  const { investor, sto } = dataMe?.investorUser || {};
  const { data, loading } = useGetSumSubInvestorTokenQuery();
  if (loading || loadingMe || !investor || !sto || !data) {
    return <Loading />;
  }
  i18n.on('languageChanged', () => history.go(0)); // refresh the page on language change so the sumsub widget updated as well
  const token = data.getSumSubInvestorToken;
  const config = {
    lang: i18n.language,
    email: investor.email,
  };

  const expirationHandler = (): Promise<string> => {
    return Promise.resolve(token);
  };

  const messageHandler = async (type: AnyEventName, payload: AnyEventPayload): Promise<void> => {
    if (type === 'idCheck.applicantStatus') {
      const { data: kycUpdated } = await refreshInvestorData();
      if (
        (kycUpdated?.refreshInvestorData || !dataMe?.investorUser?.investorSto.isKYC) &&
        (payload as SumSubEventPayloadType).reviewResult?.reviewAnswer === 'GREEN' &&
        (payload as SumSubEventPayloadType).reviewStatus === ReviewStatus.COMPLETED
      ) {
        BsSwal.fire({
          title: t('sumSubKyc-accountApproved'),
          icon: 'success',
          confirmButtonText: t('ok'),
        }).then(async (result) => {
          if (result.isConfirmed) {
            history.push('/investor/active-properties');
          }
        });
      }
    }
  };

  return (
    <>
      <Card>
        <CardHeader className="mt-3">
          <b>{t('Welcome to your Verification Process')}</b>
        </CardHeader>
        <CardBody className="mb-3">
          <Row>
            <Col xs="auto">
              <GrayDot />
            </Col>
            <Col tag="p">{t('You can logout anytime and re-login with your email and password to continue')}</Col>
          </Row>
          <Row>
            <Col>
              <SumsubWebSdk
                accessToken={token}
                config={config}
                expirationHandler={expirationHandler}
                onMessage={messageHandler}
                options={{ adaptIframeHeight: true }}
              />
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default SumSubKyc;
