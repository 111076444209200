import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const SkeletonActivityProperty: React.FC<{ isMs?: boolean }> = ({ isMs }) => {
  return (
    <StyledSkeleton>
      <div className="skeleton-image">
        <Skeleton height={isMs ? 300 : 200} />
      </div>
      <div className="skeleton-body">
        <div className="skeleton-body--content">
          <StyledTitle titleWidth={isMs ? '30%' : '100%'}>
            <Skeleton height={20} />
          </StyledTitle>
          <StyledBody bodyWidth={isMs ? '60%' : '100%'}>
            <Skeleton count={2} height={20} />
          </StyledBody>
        </div>
        <div className="skeleton-body-action">
          <div className="skeleton-body-action_item">
            <Skeleton height={25} />
          </div>
          <div className="skeleton-body-action_item">
            <Skeleton height={25} />
          </div>
        </div>
      </div>
    </StyledSkeleton>
  );
};

export default SkeletonActivityProperty;

const StyledSkeleton = styled.div`
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  border-radius: 6px;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  gap: 0.5rem;
  box-shadow: 2px 2px 16px rgba(227, 226, 226, 0.75);

  .skeleton-image {
    border-radius: 5px;
    flex: 1;
  }

  .skeleton-body {
    flex: 2;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 5px;

    &-action {
      display: flex;
      justify-content: space-between;

      &_item {
        min-width: 7rem;
      }
    }
  }
`;

const StyledTitle = styled.h3<{ titleWidth: string }>`
  width: ${(p) => p.titleWidth};
`;
const StyledBody = styled.div<{ bodyWidth: string }>`
  width: ${(p) => p.bodyWidth};
`;
