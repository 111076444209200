import React from 'react';
import { useTranslation } from 'react-i18next';

import { CurrencySymbolDisplayModeEnum, useFindShareTypesQuery, useInvestorAppDataQuery } from 'services/apollo';
import { getCurrencySymbol, GetCurrencySymbolString } from 'services/core/helpers';

import { Select } from 'atoms';
import { BLOCKCHAIN_PROTOCOL } from 'pages/exchange/constants';

interface ShareTypeSelectProps {
  value: number;
  onChange: (
    value: number,
    symbol: string,
    abbreviation: string,
    stoID: number,
    currencyAddress: string,
    blockchainProtocol?: BLOCKCHAIN_PROTOCOL,
  ) => void;
  invalid: boolean;
  useBlockchainOnly?: boolean;
}

interface SelectOption {
  value: number;
  stoID: number;
  label: string;
  symbol: string;
  abbreviation: string;
  currencyAddress: string;
  blockchainProtocol: BLOCKCHAIN_PROTOCOL;
}

const ShareTypeSelect: React.FC<ShareTypeSelectProps> = ({ value, onChange, invalid, useBlockchainOnly = false }) => {
  const { t, i18n } = useTranslation();
  const premiumTitle = t('ShareTypeSelect-label-premium');
  const nominalTitle = t('ShareTypeSelect-label-nominal');

  const { data, loading } = useFindShareTypesQuery({ fetchPolicy: 'no-cache' });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (loading || !data || appDataLoading || !appData) {
    return <></>;
  }

  const filteredShares = useBlockchainOnly
    ? data.findShareTypes.filter((s) => {
        return s.isBlockchain && s.sto.isActive && s.isEnabled;
      })
    : data.findShareTypes;

  if (!filteredShares || !filteredShares.length) {
    return <b style={{ color: 'red' }}>{t('ShareTypeSelect-NoBlockchain-ShareTypes-Found')}</b>;
  }

  const options = filteredShares.map((item) => {
    const currencyAddress = item.currency.Address;
    const { symbol, abbreviation } = item.currency;
    const currencySymbolProps: GetCurrencySymbolString = {
      data: {
        currency: item.currency,
        value: item.premiumValue,
      },
      currencySymbolDisplayMode:
        appData.investorAppParameters.currencySymbolDisplayMode || CurrencySymbolDisplayModeEnum.LocalBased,
      locale: i18n.language,
    };
    const currencyValue = getCurrencySymbol(currencySymbolProps);
    let label = item.title;
    label += ` ${item.premiumValue > 0 ? premiumTitle : nominalTitle} : ${currencyValue}`;

    return {
      value: item.ID,
      stoID: item.stoID,
      label,
      symbol,
      abbreviation,
      currencyAddress,
      blockchainProtocol: item.blockchainProtocolID,
    };
  });

  const selected = value
    ? options.find((i) => i.value === value)
    : onChange(
        options[0].value,
        options[0].symbol,
        options[0].abbreviation,
        options[0].stoID,
        options[0].currencyAddress || '',
        options[0].blockchainProtocol || 0,
      );

  return (
    <Select
      class="form-control border-input"
      invalid={invalid}
      name="shareTypeDropdown"
      options={options}
      value={selected}
      placeholder={t('ShareTypeSelect-placeholder')}
      onChange={(option: SelectOption) =>
        onChange(
          option.value,
          option.symbol,
          option.abbreviation,
          option.stoID,
          option.currencyAddress,
          option.blockchainProtocol,
        )
      }
    />
  );
};

export default ShareTypeSelect;
