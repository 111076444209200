import React from 'react';
import ImageGallery from 'react-image-gallery';

import { PropertyFile } from 'services/apollo/multisto';

interface PropertyGalleryProps {
  logo: string;
  images: PropertyFile[];
  classNames?: string | undefined;
  rightLayout?: boolean;
}

const PropertyGallery: React.FC<PropertyGalleryProps> = ({ images, classNames, rightLayout = false }) => {
  const items = [
    ...images?.map((img) => ({
      original: img.url,
      thumbnail: img.url,
    })),
  ];

  return <ImageGallery additionalClass={classNames} items={items} showThumbnails={!rightLayout} />;
};

export default PropertyGallery;
