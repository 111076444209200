import React from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  text-align: center;
  h3 {
    position: absolute;
    top: 85%;
    left: 50%;
    color: green;
    transform: translate(-50%, -50%);
  }
`;

const VerificationBanner: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <img src="/img/2stepverification_banner.png" width="100%" alt="2FA" />
      <h3>{t('VerificationBanner-2FA-Text')}</h3>
    </Container>
  );
};

export default VerificationBanner;
