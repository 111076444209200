import { Label as BootLabel } from 'reactstrap';
import styled from 'styled-components';

const Label = styled(BootLabel)`
  color: ${(p) => (p.color ? p.color : p.theme.marketPlaceLabelFontColor)};
  min-width: 120px;
  margin-right: 10px;

  b {
    font-weight: 400;
  }
`;

export default Label;
