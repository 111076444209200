import React from 'react';
import { Col, Row } from 'atoms';

import { FetchFees } from 'services/apollo/fee';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { useTranslation } from 'react-i18next';
import { DiscountQueryResult } from 'services/apollo/discount';
import { InvestorSharesCurrency } from 'services/apollo/shares';
import PopoverHover from 'components/PopoverHover';
import DiscountDetails from './DiscountDetails';
import FeeDetails from './FeeDetails';

export enum TransformerType {
  DISCOUNT,
  FEE,
}

export interface Transformer {
  type: TransformerType;
  discounts?: DiscountQueryResult[];
  fees?: FetchFees[];
}

export interface PriceTransformerDetailsProps {
  shareID: number;
  transformer: Transformer;
  currency: InvestorSharesCurrency;
  subtotal: number;
  transformerPrice?: number;
}

const PriceTransformerDetails: React.FC<PriceTransformerDetailsProps> = (prop) => {
  const { shareID, transformer, currency, subtotal, transformerPrice = 0 } = prop;
  const { fees = [], discounts = [] } = transformer;
  const { t } = useTranslation();

  const getDetails = () => {
    switch (transformer.type) {
      case TransformerType.DISCOUNT:
        return <DiscountDetails shareID={shareID} discounts={discounts} currency={currency} subtotal={subtotal} />;
      case TransformerType.FEE:
        return (
          <PopoverHover
            popoverID={`BuyPropertyShare-TransactionFee-${shareID}`}
            label={t('BuyPropertyShare-TransactionFee')}
            title={t('Fees Breakdown')}
            target={<i className="ti-help-alt" />}
            body={<FeeDetails fees={fees} currency={currency} />}
          />
        );
      default:
    }
  };

  return (
    <Row>
      <Col md={4}>{getDetails()}</Col>
      <Col md={8}>
        <p>
          <CurrencySymbolDisplay value={transformerPrice} currency={currency} />
        </p>
      </Col>
    </Row>
  );
};

export default PriceTransformerDetails;
