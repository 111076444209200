import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { GetChatsDocument, ReceiverType, SenderType, useMessageCreateMutation } from 'services/apollo';
import { BsSwal, Button, Form } from 'atoms';
import { useTranslation } from 'react-i18next';
import Input from 'atoms/Input';

interface MessageBoxProps {
  stoID: number;
  investorID: number;
}

const MessageBox: React.FC<MessageBoxProps> = ({ stoID, investorID }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [message, setMessage] = useState('');
  const [createMessage] = useMessageCreateMutation({ refetchQueries: [{ query: GetChatsDocument }] });

  const sendMessage = (e: React.FormEvent) => {
    e.preventDefault();
    const newData = {
      sender: SenderType.Investor,
      receiver: ReceiverType.Admin,
      investorID,
      adminID: 0,
      stoID,
      message,
    };
    if (message.length > 0) {
      createMessage({ variables: { data: newData } })
        .then(() => {
          setMessage('');
          BsSwal.fire({
            title: 'Successfully sent',
            icon: 'success',
            showCancelButton: true,
            confirmButtonText: t('ContactCompany-BsSwal-Confirm'),
            cancelButtonText: t('ContactCompany-BsSwal-cancelButton'),
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/investor/chat/${stoID}`);
            }
          });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <section className="mt-4">
      <h4>{t('Contact the company')}</h4>
      <Form onSubmit={sendMessage}>
        <Input
          type="textarea"
          rows="6"
          autoComplete="off"
          required
          maxLength="2000"
          name="message"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMessage(e.currentTarget.value || '')}
          value={message}
          placeholder={t('Enter-Message-Here')}
        />

        <Button type="submit">{t('Send  inquiry')}</Button>
      </Form>
    </section>
  );
};

export default MessageBox;
