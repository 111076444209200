import React from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { InvestorShareShareType } from 'services/apollo/shares';
import { Row, Col, Label, DatePicker, FormGroup } from 'atoms';
import { UseSellOrderState } from './useSellOrderState';

interface SellOrderMainInfoProps extends UseSellOrderState {
  shareType: InvestorShareShareType;
}

const SellOrderMainInfo: React.FC<SellOrderMainInfoProps> = ({ shareType, dates, onChangeDate }) => {
  const { t } = useTranslation();

  return (
    <>
      <Row className="font-weight-bold">
        <StyledCol xs="auto">
          <Label>{t('SellOrderMainInfo-label-title')}:</Label>
          <p>{shareType.title}</p>
        </StyledCol>
        <StyledCol xs="auto">
          <Label>{t('SellOrderMainInfo-label-nominal')}:</Label>
          <p>
            <CurrencySymbolDisplay value={shareType.nominalValue} currency={shareType.currency} />
          </p>
        </StyledCol>
        <StyledCol xs="auto">
          <Label>{t('SellOrderMainInfo-label-premium')}:</Label>
          <p>
            <CurrencySymbolDisplay value={shareType.premiumValue} currency={shareType.currency} />
          </p>
        </StyledCol>
      </Row>
      <hr />
      <Row className="mb-2">
        <Col xs={12}>
          <h6>{t('SellOrderMainInfo-label-date-title')}</h6>
          <p>{t('SellOrderMainInfo-label-date-description')}</p>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              <b>{t('SellOrderMainInfo-label-openDate')}</b>
            </Label>
            <DatePicker
              onChange={(dateFrom: Date) => onChangeDate({ dateFrom })}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd.MM.yyyy"
              showPopperArrow={false}
              selected={dates.dateFrom}
              selectsStart
              startDate={dates.dateFrom}
              endDate={dates.dateTo}
              minDate={new Date()}
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label>
              <b>{t('SellOrderMainInfo-label-closeDate')}</b>
            </Label>
            <DatePicker
              onChange={(dateTo: Date) => onChangeDate({ dateTo })}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="dd.MM.yyyy"
              showPopperArrow={false}
              selected={dates.dateTo}
              startDate={dates.dateFrom}
              endDate={dates.dateTo}
              minDate={dates.dateFrom}
            />
          </FormGroup>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default SellOrderMainInfo;

const StyledCol = styled(Col)`
  display: flex;
  label {
    min-width: 0;
    margin-right: 5px;
  }

  p {
    font-weight: 500;
  }
`;
