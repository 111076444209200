import React from 'react';
import { Button, Col, Label, Row } from 'atoms';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { AppData } from 'services/apollo/core';
import { InvestorBuyPropertyBalance } from 'services/apollo/multisto';
import AccountItem from 'pages/wallet/components/AccountItem';

interface InternalWalletBalancesProps {
  appData: AppData;
  accounts: InvestorBuyPropertyBalance[];
}
const InternalWalletBalances: React.FC<InternalWalletBalancesProps> = (props) => {
  const { appData, accounts } = props;
  const { IsInternalWalletDisabled } = appData;
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Row>
      {IsInternalWalletDisabled ? (
        <Col md={4}>
          {
            // empty
          }
        </Col>
      ) : (
        <Col md={4}>
          <h5 className="mt-3">
            <Label>{t('BuyProperty-balancesTitle')}</Label>
          </h5>
          {accounts.length > 0 ? (
            <>
              <label>{t('BuyProperty-currentBalanceLabel')}</label>
              {accounts.map((acc) => (
                <AccountItem key={acc.ID} account={acc} />
              ))}
            </>
          ) : (
            <label>{t('BuyProperty-noBalance')}</label>
          )}
          <Label>{t('BuyProperty-depositFundsLabel')} </Label>
          <Button className="mb-2" onClick={() => history.push('/investor/wallet')}>
            {t('channelItemDepositFunds')}
          </Button>
        </Col>
      )}
    </Row>
  );
};

export default InternalWalletBalances;
