import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useCheckInvoiceStatusQuery } from 'services/apollo';
import { BsSwal, Loading } from 'atoms';
import { getTranslationKeyOfApiError } from 'services/core/helpers';

const XenditRedirect: React.FC = () => {
  const { t } = useTranslation();
  const params = useParams<{ alertID: string; invoiceID: string }>();
  const alertID = params.alertID.replace(/^\D+/g, '');
  const invoiceID = params.invoiceID.replace(/^\D+/g, '');

  const {
    data: statusData,
    loading: statusLoading,
    error: statusError,
  } = useCheckInvoiceStatusQuery({
    variables: {
      invoiceID: parseInt(invoiceID, 10),
      alertID: parseInt(alertID, 10),
    },
  });

  if (statusLoading) {
    return <Loading />;
  }
  if (statusError) {
    const gqlError = getTranslationKeyOfApiError(statusError);
    BsSwal.fire({
      title: t(gqlError.message),
      icon: 'error',
      confirmButtonText: t('XenditRedirect-bsswal-success-confirm'),
    }).then((popUpResult) => {
      if (popUpResult.isConfirmed) {
        window.close();
      }
    });
  }
  if (statusData) {
    BsSwal.fire({
      title: t('XenditRedirect-bsswal-success-title'),
      text: t('XenditRedirect-bsswal-success-text'),
      icon: 'success',
      confirmButtonText: t('XenditRedirect-bsswal-success-confirm'),
    }).then((popUpResult) => {
      if (popUpResult.isConfirmed) {
        window.close();
      }
    });
  }
  return null;
};

export default XenditRedirect;
