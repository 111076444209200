import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { AtomicSwapStatus, BlockchainProtocol, ExchangeType, useMeQuery } from 'services/apollo';
import Auth from 'services/core/auth';
import { ExchangeOfferDetailOffer, ExchangeOfferDetailOrder } from 'services/apollo/exchange';
import { FontAweIcon, Loading } from 'atoms';
import { prepareBlockchainData } from 'lib/helpers';
import { useAccount } from 'wagmi';
import AtomicSwapApproveButton from './AtomicSwapApproveButton';
import AtomicSwapOpenButton from './AtomicSwapOpenButton';
import AtomicSwapCloseButton from './AtomicSwapCloseButton';

const AtomicSwapComponent: React.FC<{
  order: ExchangeOfferDetailOrder;
  offer: ExchangeOfferDetailOffer;
  isTestnetEnabled: boolean;
  blockchainProtocol?: BlockchainProtocol;
}> = ({ order, offer, isTestnetEnabled, blockchainProtocol }) => {
  const { t } = useTranslation();
  const [atomicSwapCurrentStatus, setAtomicSwapCurrentStatus] = useState(order.atomicSwapCurrentStatus);
  const [isStepOneEnabled, setIsStepOneEnabled] = useState(false);
  const [isStepTwoEnabled, setIsStepTwoEnabled] = useState(false);
  const [isStepThreeEnabled, setIsStepThreeEnabled] = useState(false);
  const { loading, data: investorData } = useMeQuery();
  const { isConnected, address } = useAccount();

  const isSell = order.type === ExchangeType.Sell;
  const isBuy = order.type === ExchangeType.Buy;

  useEffect(() => {
    if (isSell) {
      if (Auth.payload.ID === order.investorID) {
        setIsStepOneEnabled(
          [AtomicSwapStatus.Accepted, AtomicSwapStatus.SellerCommitted].includes(atomicSwapCurrentStatus),
        );
        setIsStepTwoEnabled(isStepOneEnabled && isConnected);
        setIsStepThreeEnabled([AtomicSwapStatus.SellerCommitted].includes(atomicSwapCurrentStatus) && isConnected);
      } else {
        setIsStepOneEnabled(
          [AtomicSwapStatus.SellerSent, AtomicSwapStatus.BuyerCommitted].includes(atomicSwapCurrentStatus),
        );
        setIsStepTwoEnabled(isStepOneEnabled && isConnected);
        setIsStepThreeEnabled([AtomicSwapStatus.BuyerCommitted].includes(atomicSwapCurrentStatus) && isConnected);
      }
    }
    if (isBuy) {
      if (Auth.payload.ID === order.investorID) {
        setIsStepOneEnabled(
          [AtomicSwapStatus.Accepted, AtomicSwapStatus.BuyerCommitted].includes(atomicSwapCurrentStatus),
        );
        setIsStepTwoEnabled(isStepOneEnabled && isConnected);
        setIsStepThreeEnabled([AtomicSwapStatus.BuyerCommitted].includes(atomicSwapCurrentStatus) && isConnected);
      } else {
        setIsStepOneEnabled(
          [AtomicSwapStatus.BuyerCompleted, AtomicSwapStatus.SellerCommitted].includes(atomicSwapCurrentStatus),
        );
        setIsStepTwoEnabled(isStepOneEnabled && isConnected);
        setIsStepThreeEnabled([AtomicSwapStatus.SellerCommitted].includes(atomicSwapCurrentStatus) && isConnected);
      }
    }
  }, [atomicSwapCurrentStatus, isConnected, isStepOneEnabled, order.investorID, order.type]);

  if (
    loading ||
    !investorData ||
    !investorData.investorUser ||
    !order.shareType.ethereumContractAddress ||
    !order.atomicSwapTokenAddressAcceptable ||
    !blockchainProtocol ||
    !offer ||
    !offer.atomicSwapSecret ||
    !offer.atomicBuyerPublicKey ||
    !order.dateTo ||
    !order.atomicSwapSharesWallet ||
    !order.atomicSwapSharesWallet.publicKey
  ) {
    return <Loading />;
  }

  const { chainID, blockchainExplorer, swapContractAddress } = prepareBlockchainData(
    isTestnetEnabled,
    blockchainProtocol.blockchain,
  );
  const isOrderCreator = investorData.investorUser.investor.ID === order.investorID;
  const isSeller = (isOrderCreator && isSell) || (!isOrderCreator && isBuy);

  if (order.atomicSwapCurrentStatus === AtomicSwapStatus.NotInitialized) {
    return <></>;
  }

  return (
    <>
      {isOrderCreator ? null : (
        <>
          <h4>{t('AtomicSwapComponent-header')}</h4>
          <hr />
        </>
      )}
      <StepPanel disabled={!isStepOneEnabled} color="#f6fadc">
        <h5>{t('AtomicSwapComponent-stepPanel-step1')}</h5>
        {isConnected ? (
          <p>
            <FontAweIcon icon="circle" className="text-success mr-2" />
            {t('AtomicSwapComponent-label-metaMaskConnection')}
          </p>
        ) : (
          <p>
            <FontAweIcon icon="circle" className="text-danger mr-2" />
            {t('AtomicSwapComponent-label-metaMaskConnection-notConnected')}
          </p>
        )}
        <p>
          <b>{t('AtomicSwapComponent-label-walletAddress')}</b>
          <span id="selectedAccount"> {address}</span>
        </p>
      </StepPanel>
      <hr />
      <StepPanel disabled={!isStepTwoEnabled} color="#faf0f3">
        <h5>{t('AtomicSwapComponent-stepPanel-step2')}</h5>
        <AtomicSwapApproveButton
          chainID={chainID}
          blockchainExplorer={blockchainExplorer}
          swapContractAddress={swapContractAddress}
          tokenAmount={isSeller ? order.shares : order.rateFrom}
          tokenContractAddress={
            isSeller ? order.shareType.ethereumContractAddress : order.atomicSwapTokenAddressAcceptable
          }
          isOrderCreator={isOrderCreator}
          isSell={isSell}
          setAtomicSwapCurrentStatus={setAtomicSwapCurrentStatus}
          orderID={order.ID}
          atomicSwapStatus={atomicSwapCurrentStatus}
        />
      </StepPanel>
      <hr />
      <StepPanel disabled={!isStepThreeEnabled} color="#f2fcf9">
        <h5>{t('AtomicSwapComponent-stepPanel-step3')}</h5>
        {isOrderCreator ? (
          <AtomicSwapOpenButton
            chainID={chainID}
            blockchainExplorer={blockchainExplorer}
            swapContractAddress={swapContractAddress}
            setAtomicSwapCurrentStatus={setAtomicSwapCurrentStatus}
            offerID={offer.ID}
            isSell={isSell}
            shareTypeContractAddress={order.shareType.ethereumContractAddress}
            currencyContractAddress={order.atomicSwapTokenAddressAcceptable}
            swapSecret={offer.atomicSwapSecret}
            swapExpiryDate={order.dateTo}
            executorWalletAddress={offer.atomicBuyerPublicKey}
            offeredSharesAmount={offer.sharesPartial}
            offeredCurrencyAmount={offer.rateFrom}
            atomicSwapStatus={atomicSwapCurrentStatus}
          />
        ) : (
          <AtomicSwapCloseButton
            chainID={chainID}
            blockchainExplorer={blockchainExplorer}
            swapContractAddress={swapContractAddress}
            setAtomicSwapCurrentStatus={setAtomicSwapCurrentStatus}
            offerID={offer.ID}
            isSell={isSell}
            swapSecret={offer.atomicSwapSecret}
            originatorWalletAddress={order.atomicSwapSharesWallet.publicKey}
            atomicSwapStatus={atomicSwapCurrentStatus}
          />
        )}
      </StepPanel>
    </>
  );
};

const StepPanel = styled.div<{ disabled: boolean; color: string }>`
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  background-color: ${(p) => p.color};
`;

export default AtomicSwapComponent;
