import React, { useContext } from 'react';
import { ThemeContext, ThemeContextType } from 'lib/themes/themeController';
import { useTranslation } from 'react-i18next';
import { ThemeType } from 'lib/themes/themeTypes';
import { ThemePropertyButton } from '../../styled/styled';

interface DefaultThemesType {
  title: string;
  value: ThemeType;
}

interface PredefinedThemesProps {
  defaultThemes: DefaultThemesType[];
  theme: ThemeType;
  setIsChanged: (value: boolean) => void;
}

const PredefinedThemesCustomizer: React.FC<PredefinedThemesProps> = ({ defaultThemes, theme, setIsChanged }) => {
  const { t } = useTranslation();

  const { setTheme } = useContext<ThemeContextType>(ThemeContext);

  return (
    <div className="settings-content__item--predefined">
      <h5 className="header-small settings-content__item--title">{t('FixedPlugin-SubTitle-PredefinedTheme')}</h5>
      <div className="settings-content__item--predefined-action">
        {defaultThemes.map((defTheme) => (
          <ThemePropertyButton
            key={defTheme.title}
            onClick={() => {
              setIsChanged(true);
              setTheme(defTheme.value);
            }}
            background={defTheme.value.backgroundSideBar}
            className={theme.backgroundSideBar === defTheme.value.backgroundSideBar ? 'active' : ''}
          >
            {defTheme.title}
          </ThemePropertyButton>
        ))}
      </div>
    </div>
  );
};

export default PredefinedThemesCustomizer;
