import React from 'react';
import { Loading } from 'atoms';
import { useTranslation } from 'react-i18next';
import { useGetMeetingsQuery } from 'services/apollo';
import { useActiveSto } from 'hooks';
import VotingElement from './components/VotingDetails/VotingElement';
import CorporateStoSelect from './components/CorporateStoSelect';

const Voting: React.FC = () => {
  const { t } = useTranslation();
  const { sto, setSto } = useActiveSto();
  const { data, loading } = useGetMeetingsQuery({
    variables: {
      stoId: Number(sto),
    },
  });
  if (loading || !data) return <Loading />;
  const { investorAllMeeting } = data;
  const { current, future, past } = investorAllMeeting || {};

  return (
    <div className="content">
      <CorporateStoSelect sto={sto} setSto={setSto} />
      <VotingElement
        corporateActions={future}
        cardTitle={t('Future-Votings-Meetings')}
        cardImagePath="/img/voting.jpg"
      />
      <VotingElement
        corporateActions={current}
        cardTitle={t('Current-Polls-and-Meetings')}
        cardImagePath="/img/polls.png"
      />
      <VotingElement corporateActions={past} cardTitle={t('Past-Votings-Meetings')} cardImagePath="/img/voting.png" />
    </div>
  );
};

export default Voting;
