import { Card, CardBody, Col, Loading, Row, Select } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  InvestorDocument,
  useGetInvestorDocumentsQuery,
  useInvestorAppDataQuery,
  useInvestorStoHistoryQuery,
} from 'services/apollo';
import { DocumentGroup } from './DocumentGroup';

export const InvestorDocuments: FC = () => {
  const [activeFilter, setActiveFilter] = useState({
    value: -1,
    label: '',
  });
  const [activeDocumentGroup, setActiveDocumentGroup] = useState<InvestorDocument[]>();
  const [activeSto, setActiveSto] = useState(-1);

  const { t } = useTranslation();
  const { data, loading } = useGetInvestorDocumentsQuery();
  const { data: stoData, loading: stoLoading } = useInvestorStoHistoryQuery();
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  useEffect(() => {
    setActiveSto(-1);
    setActiveDocumentGroup(data?.getInvestorDocuments);
  }, [data?.getInvestorDocuments, stoData?.investorRelatedSto]);

  if (loading || stoLoading || appDataLoading || !stoData || !data || !appData) {
    return <Loading />;
  }

  const hideSto0 = appData.investorAppParameters.isPlatformOperatorHidden;

  const selectOptions = [
    { value: -1, label: t('InvestorDocuments-select-showAll') },
    ...stoData.investorRelatedSto
      .filter((sto) => !(sto.ID === 0 && hideSto0))
      .map((sto) => ({
        value: sto.ID,
        label: sto.title,
      })),
  ];

  const onChangeSTO = (ent: { value: number; label: string }) => {
    if (ent.value !== -1) {
      setActiveSto(ent.value);
      setActiveDocumentGroup(data.getInvestorDocuments.filter((doc) => doc.stoID === ent.value));
    } else {
      setActiveSto(-1);
      setActiveDocumentGroup(data.getInvestorDocuments);
    }
    setActiveFilter({ value: ent.value, label: ent.label });
  };

  const getRelevantDocuments = (stoID: number) => {
    return activeDocumentGroup?.filter((document) => document.stoID === stoID) ?? [];
  };

  const documentGroups = stoData.investorRelatedSto
    .filter((sto) => !(sto.ID === 0 && hideSto0))
    .filter((sto) => !(activeSto !== -1 && sto.ID !== activeSto)) // if the investor wants to see only 1 sto, then remove all others
    .map((sto) => {
      return {
        ...sto,
        relevantDocuments: getRelevantDocuments(sto.ID),
      };
    })
    .sort((groupA, groupB) => {
      // descending order based on how many documents were signed
      return groupB.relevantDocuments.length - groupA.relevantDocuments.length;
    });

  return (
    <Card>
      <CardHeader
        text={t('InvestorDocuments-Title')}
        caption={t('InvestorDocuments-Description')}
        imgSrc="/img/contracts.png"
      />
      <CardBody className="mb-2">
        <Row>
          <Col>
            <Select
              name="DocumentTitle"
              style={{ zIndex: '100' }}
              options={selectOptions}
              value={{
                value: activeFilter.value,
                label: activeFilter.label || t('InvestorDocuments-select-default-text'),
              }}
              onChange={onChangeSTO}
            />
          </Col>
        </Row>
        <br />
        {documentGroups.map((group) => {
          return (
            <DocumentGroup
              key={`docGroup_${group.ID}`}
              stoID={group.ID}
              stoTitle={group.title}
              activeDocumentGroup={getRelevantDocuments(group.ID)}
            />
          );
        })}
      </CardBody>
    </Card>
  );
};

export default InvestorDocuments;
