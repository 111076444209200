import React from 'react';
import { Container, Row } from 'reactstrap';

import {
  useGetRevisionNumberQuery,
  useGetThemeConfigQuery,
  useMeQuery,
  useInvestorAppDataQuery,
} from 'services/apollo';
import { Markup } from 'interweave';
import { ThemeType } from 'lib/themes/themeTypes';
import { useTheme } from 'styled-components';
import { useTranslation } from 'react-i18next';

interface FooterProps {
  default?: string;
  fluid?: boolean;
}

const Footer: React.FC<FooterProps> = ({ default: def, fluid }) => {
  const { data, loading } = useMeQuery();
  const { data: dt } = useGetThemeConfigQuery();
  const { investorAppParameters: params } = dt || {};
  const { data: Revision } = useGetRevisionNumberQuery();
  const { data: AppData } = useInvestorAppDataQuery();
  const theme: ThemeType = useTheme() as ThemeType;
  const { t } = useTranslation();

  if (loading || !data?.investorUser || !AppData) {
    return <></>;
  }
  const { isFooterTextCentered } = AppData.investorAppParameters;
  const { sto } = data?.investorUser;

  const themeStyling = {
    color: theme.fontColorFooter,
    fontWeight: theme.fontWeightFooter,
  };

  return (
    <footer className={`${isFooterTextCentered ? 'footer' : ''}${def ? ' footer-default' : ''}`} style={themeStyling}>
      <Container fluid={fluid}>
        <Row>
          <div className={isFooterTextCentered ? 'w-100 footer-default text-sm-center' : 'w-100 footer-default'}>
            <p>
              <Markup content={sto.disclaimer || ''} />
            </p>
          </div>
          <div className="w-100 footer-default text-sm-center">
            <span className="text-sm-center" style={{ fontSize: '13px' }}>
              {params?.poweredByLabel ? (
                <Markup content={params.poweredByLabel} />
              ) : (
                <p>
                  {t('Footer-Powered-by')} <a href="https://digishares.io"> DigiShares </a>
                </p>
              )}
              <p>
                {t('Footer-Revision')}: {Revision?.getRevisionNumber}
              </p>
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
};

export default Footer;
