import React, { useState } from 'react';
import { BsSwal, Button, CardBody, Col, FontAweIcon, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import BANK from 'assets/img/bank.png';
import transferERC20Abi from 'abis/transferERC20Abi.json';
import { InvestorInvoiceAlert, InvestorWalletChannel } from 'services/apollo/multisto';
import { Markup } from 'interweave';
import { prepareBlockchainData, toTokenPowerOf } from 'lib/helpers';
import { Blockchains } from 'services/apollo';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import {
  useAccount,
  usePrepareContractWrite,
  useContractWrite,
  useWaitForTransaction,
  Address,
  useBalance,
  useNetwork,
  useSwitchNetwork,
} from 'wagmi';

interface DepositFormSendTxBlockchainProps {
  invoice: InvestorInvoiceAlert;
  hideModal: () => void;
  channel: InvestorWalletChannel;
  onSubmit: () => void;
  isTestnetEnabled: boolean;
  shareValue?: number;
  blockchain: Blockchains;
  currencyAddress: string;
}

const DepositFormSendTxBlockchain: React.FC<DepositFormSendTxBlockchainProps> = ({
  hideModal,
  channel,
  onSubmit,
  invoice,
  isTestnetEnabled,
  shareValue,
  blockchain,
  currencyAddress,
}) => {
  const { t } = useTranslation();
  const [isPaymentInProgress, setIsPaymentInProgress] = useState(false);
  const [investorBalance, setInvestorBalance] = useState('');
  const { isConnected, address } = useAccount();
  const { chainID } = prepareBlockchainData(isTestnetEnabled, blockchain);
  const { chain } = useNetwork();
  const parsedAmount = toTokenPowerOf(currencyAddress, invoice.amountToPay, chainID);
  const isIncorrectChainProvided = chain?.id !== chainID && isConnected;
  useBalance({
    address,
    token: currencyAddress as Address,
    enabled: isConnected,
    onSuccess(data) {
      setInvestorBalance(data.value._hex);
    },
  });

  const { config } = usePrepareContractWrite({
    address: channel.currency.Address as Address,
    abi: transferERC20Abi,
    functionName: 'transfer',
    enabled: BigInt(investorBalance) >= BigInt(parsedAmount),
    args: [channel.currency.cryptoReceivingAddress, parsedAmount],
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  const {
    write,
    isLoading,
    data: writeContractData,
  } = useContractWrite({
    ...config,
    chainId: chainID,
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'warning',
        text: error.message,
        willClose: () => setIsPaymentInProgress(false),
      });
    },
  });

  const { switchNetwork, isLoading: isNetworkSwitchLoading } = useSwitchNetwork({
    onSuccess() {
      setIsPaymentInProgress(false);
    },
    onError(error) {
      setIsPaymentInProgress(false);
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  const { isLoading: transactionLoading } = useWaitForTransaction({
    hash: writeContractData?.hash,
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
        willClose: () => setIsPaymentInProgress(false),
      });
    },
    onSuccess(data) {
      invoice.invoiceDescription = data.transactionHash;
      onSubmit();
    },
  });

  return (
    <>
      <CardHeader text={t(`depositFormSend-header-metamaskRequest`)} imgSrc={BANK} />
      <CardBody>
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <Markup content={channel.details} />
        </div>
        {isLoading || transactionLoading || isPaymentInProgress ? (
          <Row className="h5 d-flex justify-content-center mx-auto">
            <FontAweIcon size="lg" icon="circle-notch" spin className="text-primary mr-2" />
            {t('DepositFormTxBlockhain-TransactionInProgressDeposit')}
          </Row>
        ) : (
          <>
            {invoice ? (
              <div>
                <Row>
                  <Col>{t('InvoiceReview-Shares')}</Col>
                  <Col>{t('InvoiceReview-PricePerShare')}</Col>
                  <Col>{t('InvoiceReview-Quantity')}</Col>
                  <Col>{t('InvoiceReview-Total')}</Col>
                </Row>
                <hr />
                <Row>
                  <Col>{invoice.shareType.title}</Col>
                  <Col>
                    <CurrencySymbolDisplay value={shareValue ?? 0} currency={channel.currency} />
                  </Col>
                  <Col>{invoice.shares}</Col>
                  <Col>
                    <CurrencySymbolDisplay value={invoice.amountToPay} currency={channel.currency} />
                  </Col>
                </Row>
                <hr />
              </div>
            ) : null}
            <Row className="d-flex justify-content-lg-end">
              <Col>
                <Row>
                  <Col>
                    {isConnected ? (
                      <>
                        <FontAweIcon icon="circle" className="text-success mr-2" />
                        {t('DepositFormSendTxBlockchain-label-metaMaskConnection')}
                      </>
                    ) : (
                      <>
                        <FontAweIcon icon="circle" className="text-danger mr-2" />
                        {t('DepositFormSendTxBlockchain-label-metaMaskConnection-notConnected')}
                      </>
                    )}
                    <br />
                    <span id="selectedAccount">
                      <b>{address}</b>
                    </span>
                    <br />
                    {isIncorrectChainProvided && (
                      <>
                        {isNetworkSwitchLoading ? (
                          <>
                            <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
                            {t('BlockchainNetworkSwitchButton-Loading-Network-Switching')}
                          </>
                        ) : (
                          <>
                            <FontAweIcon icon="circle" className="text-danger mr-2" />
                            {t('BlockchainNetworkSwitchButton-Wrong-Network-Selected')}
                          </>
                        )}
                      </>
                    )}
                  </Col>
                  {isIncorrectChainProvided ? (
                    <Button
                      disabled={isPaymentInProgress || !isConnected || isLoading || transactionLoading}
                      onClick={() => {
                        setIsPaymentInProgress(true);
                        switchNetwork?.(chainID);
                      }}
                    >
                      {t('BlockchainNetworkSwitchButton-Switch-Network')}
                    </Button>
                  ) : (
                    <Button
                      disabled={isPaymentInProgress || !isConnected || isLoading || transactionLoading}
                      onClick={() => {
                        setIsPaymentInProgress(true);
                        write?.();
                      }}
                    >
                      {t('DepositFormSendTxBlockchain-button-sendPayment')}
                    </Button>
                  )}
                </Row>
              </Col>
              <Col md={2}>
                <Button disabled={isPaymentInProgress || isLoading || transactionLoading} onClick={() => hideModal()}>
                  {t('Cancel')}
                </Button>
              </Col>
            </Row>
          </>
        )}
      </CardBody>
    </>
  );
};

export default DepositFormSendTxBlockchain;
