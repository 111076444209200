import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FormFeedback } from 'reactstrap';
import * as math from 'mathjs';

import { useScrollBlock } from 'hooks';
import {
  useGetSharesWalletsQuery,
  useInvestorInvestingEntitiesQuery,
  useGetInvestorSharesByTypeQuery,
} from 'services/apollo';
import { InvestorBuyPropertyShareType, InvestorBuyPropertyPaymentChannel } from 'services/apollo/multisto';
import { FetchFees } from 'services/apollo/fee';

import { Col, Input, Loading, Row, Select } from 'atoms';
import { AppData } from 'services/apollo/core';
import { stripToNumber } from 'lib/utils';
import { DiscountQueryResult } from 'services/apollo/discount';
import InvestingEntitySelector from 'pages/active-properties/components/InvestingEntitySelector';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { InvestorInvestingEntity } from 'services/apollo/investor-entity';
import BuyButton from './components/BuyButton';
import PriceTransformerDetails, { TransformerType } from './components/priceTransformer/PriceTransformerDetails';
import { getMinMax, updatePurchasePrice, UpdatePurchasePriceProps } from './helper/updatePrice';

interface PropertyShareProps {
  sto: number;
  share: InvestorBuyPropertyShareType;
  hasFunds: boolean;
  fee: FetchFees[];
  discounts: DiscountQueryResult[];
  setLoadingRequest: (isActive: boolean) => void;
  appData: AppData;
  channels?: InvestorBuyPropertyPaymentChannel[];
}

const BuyPropertyShare: React.FC<PropertyShareProps> = (props) => {
  const { sto, share, hasFunds, fee, discounts, setLoadingRequest, appData, channels } = props;
  const { isDiscountsEnabled, isFeesEnabled, isInvestingEntityEnabled, isShareOwnershipLimitEnabled } = appData;
  const [value, setValue] = useState<number>(share.minimumSharesToBuyByInvestor);
  const [totalPrice, setTotalPrice] = useState<number>(share.totalPrice * share.minimumSharesToBuyByInvestor);
  const [feePrice, setFeePrice] = useState<number>(0);
  const [selectedChannel, setSelectedChannel] = useState<InvestorBuyPropertyPaymentChannel | undefined>(undefined);
  const [discountPrice, setDiscountPrice] = useState<number>(0);
  const [errors, setErrors] = useState({ shareCount: '' });
  // entity toggle consts
  const [entity, setEntity] = useState<InvestorInvestingEntity>();
  const { _id: id } = useParams<{ _id: string }>();
  const [loadLatestEntity, setLoadLatestEntity] = useState<boolean>(false);
  const [isBuyButtonDisabled, setIsBuyButtonDisabled] = useState<boolean>(false);

  const { i18n, t } = useTranslation();
  const location = useLocation<{ shareId: number; value: number; text: string; loadLatestEntity: boolean }>();

  const history = useHistory();
  const { data, loading } = useInvestorInvestingEntitiesQuery();

  const maximumInvestmentAllowed = math.number(
    math.multiply(
      math.divide(math.bignumber(share.shareOwnershipLimit), math.bignumber(100)),
      math.bignumber(share.totalShares),
    ) as number,
  );

  const { data: sharesData, loading: sharesLoading } = useGetInvestorSharesByTypeQuery({
    variables: { shareTypeID: share.ID },
    fetchPolicy: 'no-cache',
  });
  const { investorShare } = sharesData || {};
  const investorCurrentOwnership = investorShare?.shares || 0;

  const updateProps: UpdatePurchasePriceProps = {
    amount: 0,
    fee,
    discounts,
    appData,
    share,
    setValue,
    setFeePrice,
    setTotalPrice,
    setDiscountPrice,
  };

  const { data: whiteListedWallets } = useGetSharesWalletsQuery({
    variables: { shareTypeID: share.ID, platform: false },
    fetchPolicy: 'no-cache',
  });

  const { investorInvestingEntities: entities } = data || {};
  const [blockScroll, allowScroll] = useScrollBlock();

  const getErrorMessage = (): string => {
    if (share.minimumSharesToBuyByInvestor) {
      if (share.maximumSharesToBuyByInvestor) {
        return t(`BuyPropertyShares-shareCount-inputError-between`);
      }
      return t(`BuyPropertyShares-shareCount-inputError-min`);
    }
    if (share.maximumSharesToBuyByInvestor) {
      return t(`BuyPropertyShares-shareCount-inputError-max`);
    }
    return '';
  };

  const isWalletDropDown = share.isBlockchain && share.channelIDForAutoPayments !== -1;

  const onChangeEntity = (ent: { value: number }) => {
    if (!ent.value) return;
    if (ent.value === -1) {
      history.push({
        pathname: '/investor/investor-ownership',
        state: {
          value,
          shareId: share.ID,
          id,
          loadLatestEntity: true,
        },
      });
      return;
    }
    setEntity(entities?.find((e) => ent.value === e.ID));
  };

  if (history.location.state !== undefined) {
    if (location?.state?.shareId === share.ID) {
      updatePurchasePrice({ ...updateProps, amount: location.state.value });
      setLoadLatestEntity(location.state.loadLatestEntity);
      window.history.replaceState(undefined, '');
      history.location.state = null;
    }
  }

  const [conversion, setConversion] = useState({
    selectedCurrency: share.currency,
    currencyPrice: share.totalPrice,
    currencyFeePrice: feePrice,
    currencyDiscountPrice: discountPrice,
    currencyTotalPrice: totalPrice,
  });

  useEffect(() => {
    updateRates();
  }, [totalPrice, feePrice, selectedChannel]);

  useEffect(() => {
    if (
      isShareOwnershipLimitEnabled &&
      maximumInvestmentAllowed &&
      investorCurrentOwnership >= maximumInvestmentAllowed
    ) {
      setErrors({ shareCount: t(`BuyPropertyShares-shareCount-inputError-exceedMaximumInvestmentAllowed`) });
      setIsBuyButtonDisabled(true);
      setValue(0);
    }
  });

  const updateRates = () => {
    const channel = selectedChannel;
    if (channel) {
      const rate = 1 / +channel.conversionRate;
      setConversion({
        selectedCurrency: channel.currency,
        currencyPrice: share.totalPrice * rate,
        currencyFeePrice: feePrice * rate,
        currencyDiscountPrice: discountPrice * rate,
        currencyTotalPrice: totalPrice * rate,
      });
    } else {
      setConversion({
        selectedCurrency: share.currency,
        currencyPrice: share.totalPrice,
        currencyFeePrice: feePrice,
        currencyDiscountPrice: discountPrice,
        currencyTotalPrice: totalPrice,
      });
    }
    updatePurchasePrice({ ...updateProps, amount: value });
  };
  const onChangeChannel = (key: { value: number | null }) => {
    const channel = channels?.find((c) => c.ID === +(key.value ?? 0));
    setSelectedChannel(channel);
  };

  if (loading || !entities || !whiteListedWallets || sharesLoading || !sharesData) {
    return <Loading />;
  }

  const selectOptions = entities.map((e) => ({
    value: e.ID,
    label: e.name,
  }));
  selectOptions.push({ value: -1, label: t('BuyProperty-add-new-entity-select-option') });

  const investorWallet = whiteListedWallets.getSharesWallets.find((e) => !e.isBlocked);

  const handleOnBlur = () => {
    const properAmount = getMinMax(value, share.minimumSharesToBuyByInvestor, share.maximumSharesToBuyByInvestor);
    if (
      isShareOwnershipLimitEnabled &&
      maximumInvestmentAllowed &&
      math.add(investorCurrentOwnership, value) > maximumInvestmentAllowed
    ) {
      setErrors({ shareCount: t(`BuyPropertyShares-shareCount-inputError-exceedMaximumInvestmentAllowed`) });
      setIsBuyButtonDisabled(true);
      setValue(math.subtract(maximumInvestmentAllowed, investorCurrentOwnership));
    } else if (properAmount !== value) {
      updatePurchasePrice({ ...updateProps, amount: properAmount });
      setErrors({ shareCount: getErrorMessage() });
    } else {
      setErrors({ shareCount: '' });
      setIsBuyButtonDisabled(false);
    }
    allowScroll();
  };

  const changeValueFromInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setErrors({ shareCount: '' });
    setIsBuyButtonDisabled(false);
    if (share.maximumSharesToBuyByInvestor && +e.currentTarget.value > share.maximumSharesToBuyByInvestor) {
      e.currentTarget.value = String(share.maximumSharesToBuyByInvestor);
    }
    if (
      isShareOwnershipLimitEnabled &&
      maximumInvestmentAllowed &&
      math.add(investorCurrentOwnership, +e.currentTarget.value) > maximumInvestmentAllowed
    ) {
      setErrors({ shareCount: t(`BuyPropertyShares-shareCount-inputError-exceedMaximumInvestmentAllowed`) });
      setIsBuyButtonDisabled(true);
      e.currentTarget.value = String(math.subtract(maximumInvestmentAllowed, investorCurrentOwnership));
    }
    const amount = stripToNumber(share.blockchaindecimals, e);
    updatePurchasePrice({ ...updateProps, amount });
  };

  let currencyOptions: {
    value: number | undefined;
    label: string;
  }[] = channels
    ? channels.map((channel) => ({
        value: channel.ID,
        label: `${channel.currency.abbreviation} (${channel.currency.symbol})`,
      }))
    : [];
  if (currencyOptions.length) {
    // Add the default currency and make unique
    currencyOptions = [
      {
        value: undefined,
        label: `${share.currency.abbreviation} (${share.currency.symbol})`,
      },
      ...currencyOptions,
    ].filter((c, i, arr) => i === arr.findIndex((c2) => c.label === c2.label));
  }
  return (
    <>
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-SharesAvailable')}</p>
        </Col>
        <Col md={8}>
          <p>{(share.availableShare ?? 0).toLocaleString(i18n.language)}</p>
        </Col>
      </Row>
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-price/share')}</p>
        </Col>
        <Col md={8}>
          <p>
            <CurrencySymbolDisplay value={conversion.currencyPrice ?? 0} currency={conversion.selectedCurrency} />
          </p>
        </Col>
      </Row>
      {isShareOwnershipLimitEnabled && maximumInvestmentAllowed ? (
        <>
          <Row>
            <Col md={4}>
              <p>{t('BuyPropertyShare-maximumInvestmentAllowed')}</p>
            </Col>
            <Col md={8}>
              <p className="text-danger">
                {maximumInvestmentAllowed?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <p>{t('BuyPropertyShare-investorCurrentOwnership')}</p>
            </Col>
            <Col md={8}>
              <p>
                {investorCurrentOwnership?.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                })}
              </p>
            </Col>
          </Row>
        </>
      ) : null}
      {share.maximumSharesToBuyByInvestor > 0 ? (
        <Row>
          <Col md={4}>
            <p>{t('BuyPropertyShare-maximumSharesToBuyByInvestor')}</p>
          </Col>
          <Col md={8}>
            <p>{share.maximumSharesToBuyByInvestor}</p>
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-minimumShareQuantityLabel')}</p>
        </Col>
        <Col md={8}>
          <p>{(share.minimumSharesToBuyByInvestor ?? 0).toLocaleString(i18n.language)}</p>
        </Col>
      </Row>

      {isInvestingEntityEnabled ? (
        <InvestingEntitySelector
          entities={entities}
          share={share}
          selectOptions={selectOptions}
          entity={entity}
          value={value}
          id={id}
          onChangeEntity={onChangeEntity}
          history={history}
          loadLatestEntity={loadLatestEntity}
          setValue={setValue}
          setEntity={setEntity}
        />
      ) : null}

      <Row className="mb-4">
        <Col md={4}>
          <p>{t('BuyPropertyShare-numberOfSharesToBuy')}</p>
        </Col>
        <Col md={4}>
          <Input
            type="number"
            min={0}
            step={1}
            onChange={changeValueFromInput}
            onFocus={blockScroll}
            onBlur={handleOnBlur}
            value={value || ''}
            invalid={errors.shareCount}
          />
          <FormFeedback>{errors.shareCount} </FormFeedback>
        </Col>
      </Row>
      {currencyOptions.length ? (
        <Row className="mb-4">
          <Col md={4}>
            <p>{t('BuyPropertyShare-PaymentCurrency')}</p>
          </Col>
          <Col md={4}>
            <Select options={currencyOptions} defaultValue={currencyOptions[0]} onChange={onChangeChannel} />
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-subTotalPrice')}</p>
        </Col>
        <Col md={4}>
          <p>
            <CurrencySymbolDisplay
              value={math.multiply(conversion.currencyPrice, value) ?? 0}
              currency={conversion.selectedCurrency}
            />
          </p>
        </Col>
      </Row>
      {isFeesEnabled ? (
        <PriceTransformerDetails
          shareID={share.ID}
          currency={conversion.selectedCurrency}
          transformerPrice={conversion.currencyFeePrice}
          transformer={{ type: TransformerType.FEE, fees: fee }}
          subtotal={math.multiply(value, conversion.currencyPrice)}
        />
      ) : null}
      {isDiscountsEnabled ? (
        <PriceTransformerDetails
          shareID={share.ID}
          currency={conversion.selectedCurrency}
          transformerPrice={conversion.currencyDiscountPrice}
          transformer={{ type: TransformerType.DISCOUNT, discounts }}
          subtotal={math.multiply(value, conversion.currencyPrice)}
        />
      ) : null}
      <Row>
        <Col md={4}>
          <p>{t('BuyPropertyShare-totalPrice')}</p>
        </Col>
        <Col md={4}>
          <p>
            <CurrencySymbolDisplay value={conversion.currencyTotalPrice ?? 0} currency={conversion.selectedCurrency} />
          </p>
        </Col>
      </Row>
      {isWalletDropDown && investorWallet ? (
        <>
          <Row>
            <Col md={4}>
              <div className="mt-3">{t('BuyPropertyShare-receiver-wallet')}</div>
            </Col>
            <Col>
              <div className="mt-3">{investorWallet.publicKey}</div>
            </Col>
          </Row>
        </>
      ) : null}
      <BuyButton
        sto={sto}
        hasFunds={hasFunds}
        disabled={isBuyButtonDisabled}
        setLoadingRequest={setLoadingRequest}
        appData={appData}
        isWalletDropDown={isWalletDropDown}
        shareCount={value}
        shareType={share}
        publicKey={investorWallet?.publicKey?.toString()}
        entityID={isInvestingEntityEnabled ? entity?.ID : undefined}
        selectedChannel={selectedChannel}
        totalPrice={conversion.currencyTotalPrice ?? 0}
      />
    </>
  );
};

export default BuyPropertyShare;
