import { Button, TD } from 'atoms';
import { parseISO } from 'date-fns';
import { useLocalDate } from 'hooks';
import NdaModal from 'pages/nda/NdaModal';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import { useResetOfferingProgressMutation } from 'services/apollo';
import { AppData } from 'services/apollo/core';
import { ActivePropertyItem, InvestorPortfolioShares } from 'services/apollo/multisto';
import { useGetPortfolioStoData } from 'hooks/useGetPortfolioStoData';

interface PortfolioPropertyStepItemProps {
  activeProperty: ActivePropertyItem;
  shares: InvestorPortfolioShares[];
  appData: AppData;
}

const PortfolioPropertyStepItem: React.FC<PortfolioPropertyStepItemProps> = ({ activeProperty, shares, appData }) => {
  const { t } = useTranslation();
  const toLocalDate = useLocalDate();
  const history = useHistory();
  const [isOpen, setOpen] = useState(false);
  const [resetProgress] = useResetOfferingProgressMutation();

  const portfolioSto = useGetPortfolioStoData({ stoID: activeProperty.ID, appData });

  const {
    isNDASigned,
    isManagementMeetingsEnabled,
    hasReceivedTermSheet,
    hasReceivedSubscriptionAgreements,
    isClosing,
  } = portfolioSto;

  const invitedToSto = activeProperty.isInvestorInvited;
  const hasDealRoomAcess = activeProperty.isBuyButtonEnabled && (isNDASigned || !activeProperty.isNDAFlowEnabled);
  const { stoDetailsInvestNowLink } = activeProperty;
  const hasShares = shares.filter((s) => s.shares > 0).length > 0;
  const {
    isEntityManagementMeetingFieldEnabled,
    isEntityReceivedTermSheetFieldEnabled,
    isEntityReceivedSubscriptionAgreementFieldEnabled,
    isEntityClosingFieldEnabled,
  } = appData;

  const ndaStatus = () => {
    let NDA;
    if (!activeProperty.isNDAFlowEnabled) {
      NDA = t('PortfolioPropertyStepItem-No-NDA-Required');
    } else if (isNDASigned) {
      NDA = t('PortfolioPropertyStepItem-Yes');
    } else {
      NDA = t('PortfolioPropertyStepItem-No');
    }
    return NDA;
  };

  const changeBuyButtonText = () => {
    if (!isNDASigned && activeProperty.isNDAFlowEnabled) {
      return t('PortfolioPropertyStepItem-Buy-No-NDA');
    }
    if (stoDetailsInvestNowLink) {
      return t('PortfolioPropertyStepItem-AccessDealRoom');
    }
    if (!hasShares) {
      return t('PortfolioPropertyStepItem-Buy-No-Shares');
    }
    return t('PortfolioPropertyStepItem-Buy');
  };

  const buy = () => {
    if (!isNDASigned) {
      setOpen(true);
    } else if (stoDetailsInvestNowLink && stoDetailsInvestNowLink.length > 0) {
      window.location.href = stoDetailsInvestNowLink;
    } else {
      history.push(`/investor/buy-property/${activeProperty.ID}`);
    }
  };

  const remove = async () => {
    await resetProgress({
      variables: {
        isEnabled: false,
        stoId: activeProperty.ID,
      },
    });
    history.go(0);
  };

  return (
    <>
      <TD>
        <RouterLink to={`/investor/detail-property/${activeProperty.ID}`}>{activeProperty.title}</RouterLink>
      </TD>
      <TD>{toLocalDate(parseISO(activeProperty.createdAt))}</TD>
      <TD>{invitedToSto ? t('PortfolioPropertyStepItem-Yes') : t('PortfolioPropertyStepItem-No')}</TD>
      {
        // TODO: Enable when it is possible to track NDA status as requested
        // <TD>{activeProperty.isNDAFlowEnabled ? t('PortfolioPropertyStepItem-Yes') : t('PortfolioPropertyStepItem-No')}</TD>
      }
      <TD>{ndaStatus()}</TD>
      <TD>{hasDealRoomAcess ? t('PortfolioPropertyStepItem-Yes') : t('PortfolioPropertyStepItem-No')}</TD>
      {isEntityManagementMeetingFieldEnabled && <TD>{isManagementMeetingsEnabled ? 'Yes' : 'No'}</TD>}
      {isEntityReceivedTermSheetFieldEnabled && <TD>{hasReceivedTermSheet ? 'Yes' : 'No'}</TD>}
      {isEntityReceivedSubscriptionAgreementFieldEnabled && <TD>{hasReceivedSubscriptionAgreements ? 'Yes' : 'No'}</TD>}
      {isEntityClosingFieldEnabled && <TD>{isClosing ? 'Yes' : 'No'}</TD>}
      <TD>
        <Button size="sm" wmin="80px" style={{ width: '80%', wordWrap: 'normal' }} className="m-2" onClick={buy}>
          {changeBuyButtonText()}
        </Button>
      </TD>
      <TD>
        <Button size="sm" wmin="80px" style={{ width: '80%', wordWrap: 'normal' }} className="m-2" onClick={remove}>
          {t('PortfolioPropertyStepItem-Remove')}
        </Button>
      </TD>
      <NdaModal stoID={activeProperty.ID} isOpen={isOpen} setOpen={setOpen} />
    </>
  );
};

export default PortfolioPropertyStepItem;
