import React, { useState } from 'react';

import { Markup } from 'interweave';
import { useTranslation } from 'react-i18next';
import { ThemeType } from 'lib/themes/themeTypes';
import { CustomInput, FormGroup } from 'reactstrap';
import PopoverHover from 'components/PopoverHover';
import { FixedPluginChoices } from 'components/fixed-plugin/components';

interface FixedPluginRowProps {
  values?: string[];
  property: keyof ThemeType;
  handleChange: (property: keyof ThemeType, cl: string | boolean) => void;
  title: string;
  notColors?: boolean;
  switchValue?: boolean;
  popOver?: string;
  currentColor?: string;
  currentValue?: string;
}

const FixedPluginRow: React.FC<FixedPluginRowProps> = ({
  values,
  property,
  handleChange,
  title,
  notColors,
  popOver,
  switchValue,
  currentColor,
  currentValue,
}) => {
  const { t } = useTranslation();
  const [badgeActive, setBadgeActive] = useState(!!values?.find((vl) => currentColor === vl) || false);

  const handleActive = (e: React.MouseEvent<HTMLSpanElement>) => {
    const rowBadges = document.querySelectorAll(`.${property}-badge`);
    if (e.target instanceof Element) {
      const clicked = e.target.closest(`.${property}-badge`);

      if (!clicked) {
        return;
      }
      setBadgeActive(true);

      rowBadges.forEach((badge) => badge.classList.remove('active'));
      clicked.classList.add('active');
    }
  };

  return (
    <div className="settings-content__item--row">
      <div className="settings-content__item--row-title">
        <p>{t(title)}</p>
        {popOver && (
          <PopoverHover
            popoverID={title}
            label=""
            target={<i className="ti-info-alt pl-1 pop-over-container" />}
            body={<Markup content={t(popOver)} />}
          />
        )}
      </div>
      <div className="settings-content__item--row-action">
        {!values ? (
          <FormGroup switch>
            <CustomInput
              id={`two-factor-${property}`}
              type="switch"
              inline
              onChange={() => {
                handleChange(property, !switchValue);
              }}
              checked={switchValue}
            />
          </FormGroup>
        ) : (
          <FixedPluginChoices
            values={values}
            badgeActive={badgeActive}
            notColors={notColors}
            property={property}
            handleActive={handleActive}
            handleChange={handleChange}
            currentValue={notColors ? currentValue : currentColor}
          />
        )}
      </div>
    </div>
  );
};

export default FixedPluginRow;
