import React from 'react';
import { Badge } from 'reactstrap';
import styled from 'styled-components';

import { useGetPendingPolymeshTransactionsCountQuery, PolymeshSwapBetween } from 'services/apollo';

interface PolymeshTransactionsBadgeProps {
  sender: PolymeshSwapBetween;
}

const PolymeshTransactionsBadge: React.FC<PolymeshTransactionsBadgeProps> = ({ sender }) => {
  const { data } = useGetPendingPolymeshTransactionsCountQuery({
    variables: { between: sender },
    fetchPolicy: 'network-only',
  });

  return data?.getPendingPolymeshTransactionsCount !== 0 ? (
    <NotificationBadge pill>{data?.getPendingPolymeshTransactionsCount}</NotificationBadge>
  ) : null;
};

export default PolymeshTransactionsBadge;

export const NotificationBadge = styled(Badge)`
  font-size: 12px;
  background: #106e3a;
  border: none;
  margin-left: 0.5rem;
`;
