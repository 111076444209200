import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useFindAllStoQuery } from 'services/apollo';
import { useActiveSto } from 'hooks';

import { CardHeader, Col, Row, Loading, Select } from 'atoms';
import { SelectOption } from 'components/navbar/StoSwitchList';

const StoSelect: React.FC = () => {
  const { t } = useTranslation();

  const { data, loading } = useFindAllStoQuery();
  const { sto, setSto } = useActiveSto();

  useEffect(() => {
    setSto({ sto: 0 });
  }, []);

  if (loading || !data) {
    return <Loading />;
  }

  const stos: SelectOption[] = data.findAllSto
    .filter((_sto) => _sto.isActive)
    .map((_sto) => ({
      value: String(_sto.ID),
      label: _sto.ID === 0 ? t('StoSelect-SelectOption-AllProjects') : _sto.title,
    }));

  const activeSto = stos.find((s) => s.value === sto.toString()) || stos[0];

  const onChangeSto = (ent: { value: string; label: string }) => {
    setSto({ sto: parseInt(ent.value, 10) });
  };

  return (
    <Wrapper>
      <Row className="d-flex align-items-center">
        <Col xs="auto" md="auto">
          <p className="text-dark my-0">{t('StoSelect-Label-ProjectList')}</p>
        </Col>
        <Col xs="auto" md={6}>
          <Select
            name="StoSelect"
            style={{ zIndex: '1' }}
            options={stos}
            value={{
              value: activeSto.value,
              label: activeSto.label,
            }}
            onChange={onChangeSto}
          />
        </Col>
      </Row>
    </Wrapper>
  );
};

const Wrapper = styled(CardHeader)`
  background: ${(p) => p.theme.colors.white};
  margin-bottom: 20px;
`;

export default StoSelect;
