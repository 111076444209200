import React from 'react';

import { useGetPolymeshSwapTokensQuery } from 'services/apollo';
import { Select } from 'atoms';

interface PolymeshSwapTokenSelectProps {
  value: string;
  onChange: (value: string) => void;
}

interface SelectOption {
  value: string;
  label: string;
}

const PolymeshSwapTokenSelect: React.FC<PolymeshSwapTokenSelectProps> = ({ value, onChange }) => {
  const { loading, data } = useGetPolymeshSwapTokensQuery();

  if (loading || !data) {
    return <></>;
  }

  const options = data.getPolymeshSwapTokens.map((item) => ({
    value: item.assetTag,
    label: item.name,
  }));

  const selected = options.find((i) => i.value === value);

  return (
    <Select
      class="form-control border-input"
      name="polymeshSwapToken"
      options={options}
      value={selected}
      onChange={(option: SelectOption) => onChange(option.value)}
    />
  );
};

export default PolymeshSwapTokenSelect;
