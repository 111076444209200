import { colors } from './colors';
import { ThemeType } from './themeTypes';

export const defaultTheme: ThemeType = {
  name: 'defaultTheme',
  colors,
  appFont: "'Montserrat', sans-serif",
  colorControls: colors.cyan,
  colorControlsFont: colors.white,
  controlBorder: false,
  colorControlBorder: colors.cyan,
  fontActiveItemSideBar: colors.cyan,
  backgroundControls: colors.gray,
  errorColor: colors.red,
  backgroundSideBar: '#1E1E1E',
  fontColorSideBar: colors.white,
  backgroundApp: colors.gray,
  cardRounded: '',
  topBarColor: '',
  fontSizeApp: '',
  extendedNavigationBar: false,
  fontColorRightPanel: colors.black,
  fontWeightRightPanel: '300',
  fontColorFooter: colors.black,
  fontWeightFooter: '300',
  navBarHoverColor: colors.systemBlue,
  profileIconBGColor: colors.systemBlue,
  profileIconBHoverColor: '#3c75a9',
  profileIconFontColor: colors.white,
  messagesDateTimeColor: colors.grayDark,
  marketPlaceIconBGColor: '#bada55',
  marketPlaceIconFontColor: colors.black,
  marketPlaceDeleteIconFontColor: colors.white,
  marketPlaceDeleteIconBGColor: colors.paleRed,
  marketPlaceLabelFontColor: colors.textGrey,
  offeringsCardBGColor: colors.white,
  offeringsDBCardBGColor: colors.gray,
  offeringsDBActiveCardBGColor: colors.white,
  offeringsDBActiveCardBorderColor: colors.green,
  offeringsDBClosedCardBorderColor: colors.red,
  offeringsDBProfileCardBorderColor: colors.blue,
  offeringDetailDetailsTabActiveBorderColor: colors.bootStrapBlue,
  offeringDetailDetailsTabActiveBGColor: colors.white,
  offeringDetailDetailsTabActiveFontColor: colors.textGrey,
  offeringDetailDetailsTabBGColor: colors.gray,
  offeringDetailDetailsTabHoverBGColor: colors.white,
  offeringDetailDetailsTabFontColor: colors.textGrey,
  offeringDetailsCardBGColor: colors.white,
  interestedBtnActiveColor: colors.yellow,
  interestedBtnColor: colors.white,
  interestedPopoverBGColor: colors.gray,
  interestedPopoverFontColor: colors.textGrey,
  interestedDetailsBGColor: colors.silver,
};

export const MarketSpaceTheme: ThemeType = {
  name: 'MarketSpaceTheme',
  colors,

  /* ---- Navigation menu ----- */
  backgroundSideBar: '#002d56',
  fontColorSideBar: colors.white,
  fontActiveItemSideBar: '#002d56',
  fontWeightSideBar: '800',

  /* ---- Navbar ----- */
  navBarHoverColor: colors.systemBlue,
  profileIconBGColor: colors.systemBlue,
  profileIconBHoverColor: '#3c75a9',
  profileIconFontColor: colors.white,

  /* ---- App ----- */
  appFont: "'Montserrat', sans-serif",
  backgroundApp: colors.gray,
  backgroundControls: colors.gray,
  colorControls: '#3F92D4',
  colorControlsFont: colors.white,
  controlBorder: false,
  colorControlBorder: '#3F92D4',
  errorColor: colors.red,
  typeAppCorner: 'light-round',
  /* ---- other ----- */
  cardRounded: '',
  topBarColor: '',
  fontSizeApp: '',
  extendedNavigationBar: false,
  fontColorRightPanel: colors.black,
  fontWeightRightPanel: '300',
  fontColorFooter: colors.black,
  fontWeightFooter: '300',
  /* ---- investing entity ----- */
  investingEntityBackground: colors.white,
  investingEntityRowBackground: colors.white,

  messagesDateTimeColor: colors.grayDark,
  /* ---- marketplace ----- */

  marketPlaceIconBGColor: '#bada55',
  marketPlaceIconFontColor: colors.black,
  marketPlaceDeleteIconFontColor: colors.white,
  marketPlaceDeleteIconBGColor: colors.paleRed,
  marketPlaceLabelFontColor: colors.textGrey,
  /* ---- active offerings ----- */
  offeringsCardBGColor: colors.white,
  offeringsDBCardBGColor: colors.gray,
  offeringsDBCardFontColor: colors.textGrey,
  offeringsDBCardViewFontColor: colors.blue,
  offeringsDBActiveCardFontColor: colors.textGrey,
  offeringsDBActiveCardBGColor: colors.white,
  offeringsDBActiveCardBorderColor: colors.green,
  offeringsDBClosedCardBorderColor: colors.red,
  offeringsDBProfileCardBorderColor: colors.blue,
  interestedBtnActiveColor: colors.yellow,
  interestedBtnColor: colors.white,
  interestedPopoverBGColor: colors.gray,
  interestedPopoverFontColor: colors.textGrey,
  interestedDetailsBGColor: colors.silver,
  /* ---- active offerings detail page ----- */
  offeringDetailDetailsTabActiveBorderColor: colors.bootStrapBlue,
  offeringDetailDetailsTabActiveBGColor: colors.white,
  offeringDetailDetailsTabActiveFontColor: colors.textGrey,
  offeringDetailDetailsTabBGColor: colors.gray,
  offeringDetailDetailsTabHoverBGColor: colors.white,
  offeringDetailDetailsTabFontColor: colors.textGrey,
  offeringDetailsCardBGColor: colors.white,
};
