import React, { useEffect, useState } from 'react';
import { Col, Label, Modal, Row } from 'reactstrap';
import Web3 from 'web3';

import {
  GetAllSharesWalletsDocument,
  GetSharesWalletsDocument,
  useAutomatedWalletWhitelistMutation,
  useFindAllBlockchainProtocolsQuery,
  useGetAllSharesWalletsQuery,
  useInvestorPublicKeyAddMutation,
} from 'services/apollo';
import styled from 'styled-components';

import { BsSwal, Button, Card, CardBody, Loading } from 'atoms';
import { CardH4, CardHeader } from 'components/card-header/CardHeader';
import WALLET from 'assets/img/blockchainwallet.png';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { BLOCKCHAIN_PROTOCOL } from 'pages/exchange/constants';
import ShareTypeSelect from 'pages/exchange/components/new-buy-order/ShareTypeSelect';
import SharesWalletList from 'pages/wallet/components/SharesWallets';
import { useAccount } from 'wagmi';
import WhitelistProgressionDisplay from './WhitelistProgressionDisplay';
import WhitelistProgressionBar from './WhitelistProgressionBar';

interface ShareTypeState {
  ID: number;
  blockchainProtocol?: BLOCKCHAIN_PROTOCOL;
}

const fillShareTypeState = (share: ShareTypeState) => {
  return {
    ...share,
  };
};

export enum BLOCKCHAIN_ID {
  // these are taken from the Blockchain table, but we need a better way of defining these
  ETHEREUM = 1,
  BINANCE = 2,
  POLYGON = 3,
  BITCOIN = 4,
  RAVENCOIN = 5,
  POLYMESH = 6,
  LIBEX = 7,
  FANTOM = 8,
}

const EthereumKeys: React.FC = () => {
  const { data: whiteListedWalletsData, loading: whitelistedWalletsLoading } = useGetAllSharesWalletsQuery();
  const { data: blockchainProtocolsData, loading: blockchainProtocolsLoading } = useFindAllBlockchainProtocolsQuery();
  const [addKey] = useInvestorPublicKeyAddMutation({
    refetchQueries: [{ query: GetAllSharesWalletsDocument }],
  });
  const { t } = useTranslation();
  const [publickey, setPublickey] = useState('');
  const [automatedWalletWhitelist] = useAutomatedWalletWhitelistMutation();
  const history = useHistory<{ shareId: string; id: string }>();
  const initialShareTypeID = parseInt(history?.location?.state?.shareId, 10) || 0;
  const [shareTypeState, setShareTypeState] = useState(fillShareTypeState({ ID: initialShareTypeID }));
  const [isWhitelistingProcessing, setIsWhitelistingProcessing] = useState(false);
  const [radioEthereumStatus, setradioEthereumStatus] = React.useState(0);
  const [isTokenAdded, setIsTokenAdded] = useState(false);
  const [walletAdded, setWalletAdded] = useState(false);
  const { isConnected, address } = useAccount();
  useEffect(() => {
    if (isConnected) {
      setPublickey(address as string);
    }
  }, [address, isConnected]);

  if (
    whitelistedWalletsLoading ||
    !whiteListedWalletsData?.getSharesWallets ||
    blockchainProtocolsLoading ||
    !blockchainProtocolsData
  ) {
    return <Loading />;
  }

  const whitelistedWallets = whiteListedWalletsData.getSharesWallets;

  const onAdd = async () => {
    if (!publickey) {
      return BsSwal.fire({
        title: t('Please-enter-new-public-key'),
        icon: 'error',
      });
    }
    if (!shareTypeState.ID) {
      return BsSwal.fire({
        title: t('EthereumKeys-popUp-shareTypeID-not-selected'),
        icon: 'error',
      });
    }

    if (radioEthereumStatus === 0) {
      return BsSwal.fire({
        title: t('please-select-address-type'),
        icon: 'error',
      });
    }

    const shareHasWallet = whitelistedWallets.filter((obj) => {
      return obj.shareType.ID === shareTypeState.ID;
    });
    if (shareHasWallet.length > 0) {
      return BsSwal.fire({
        title: t('EthereumKeys-popUp-onlyOneWalletPerShareType'),
        icon: 'error',
      });
    }

    if (
      radioEthereumStatus !== BLOCKCHAIN_ID.RAVENCOIN /** Ignore checksum on Ravencoin */ &&
      !Web3.utils.isAddress(publickey)
    ) {
      return BsSwal.fire({
        title: t('invalid-ethereum-address'),
        icon: 'error',
      });
    }

    if (
      [BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX, BLOCKCHAIN_ID.FANTOM].includes(
        radioEthereumStatus,
      ) &&
      !isConnected
    ) {
      return BsSwal.fire({
        title: t('metamask-disconnected-verify'),
        icon: 'error',
      });
    }

    if (
      [BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX, BLOCKCHAIN_ID.FANTOM].includes(
        radioEthereumStatus,
      ) &&
      publickey?.toLowerCase() !== address?.toLowerCase()
    ) {
      return BsSwal.fire({
        title: t('incorrect-metamask-account-selected'),
        icon: 'error',
      });
    }

    if (radioEthereumStatus === BLOCKCHAIN_ID.RAVENCOIN && publickey.length <= 32) {
      return BsSwal.fire({
        title: t('invalid-ravencoin-address'),
        icon: 'error',
      });
    }

    setIsWhitelistingProcessing(true);
    setPublickey(publickey);
    await saveNewKey();
  };

  const saveNewKey = async () => {
    try {
      await addKey({
        variables: { title: publickey, blockchainID: radioEthereumStatus },
        refetchQueries: [{ query: GetAllSharesWalletsDocument }],
      });
      await doWhitelisting();
      if (history?.location?.state?.shareId) {
        history.push({
          pathname: `/investor/buy-property/${history.location.state.id}`,
          state: { ...history.location.state },
        });
      }
    } catch (err) {
      setIsWhitelistingProcessing(false);
      await BsSwal.fire({
        title: (err as Error)?.message,
        icon: 'error',
      });
    }
  };
  const doWhitelisting = async () => {
    // only automatically whitelist ethereum wallets
    if (
      ![BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX, BLOCKCHAIN_ID.FANTOM].includes(
        radioEthereumStatus,
      )
    ) {
      setIsWhitelistingProcessing(false);
      return;
    }
    const whitelistResult = await automatedWalletWhitelist({
      variables: {
        shareTypeID: shareTypeState.ID,
        walletAddress: publickey,
      },
      refetchQueries: [{ query: GetAllSharesWalletsDocument }, { query: GetSharesWalletsDocument }],
    });
    setIsWhitelistingProcessing(false);
    const isWhitelistCompleted = whitelistResult.data?.whitelistWallet;
    if (isWhitelistCompleted) {
      setWalletAdded(true);
      await BsSwal.fire({
        title: t('EthereumKeys-popUp-wallet-successfully-whitelisted'),
        icon: 'success',
      });
    } else {
      await BsSwal.fire({
        title: t('EthereumKeys-popUp-manualApproval'),
        icon: 'success',
      });
    }
  };

  const getBlockchainID = (shareProtocols?: BLOCKCHAIN_PROTOCOL) => {
    const protocols = blockchainProtocolsData.findAllBlockchainProtocols;
    const found = protocols?.find((p) => shareProtocols === p.ID);
    return found?.blockchainID || 0;
  };

  const onChange = (
    value: number,
    symbol: string,
    abbreviation: string,
    stoID: number,
    currencyAddress: string,
    blockchainProtocol?: BLOCKCHAIN_PROTOCOL,
  ) => {
    setShareTypeState({ ID: value, blockchainProtocol });
    setradioEthereumStatus(getBlockchainID(blockchainProtocol));
  };

  return (
    <StyledCard>
      <CardHeader
        text={t('Shared-Ethereum-Public-Keys')}
        caption={t('Please-provide-the-list-of-public-keys-from-your-wallet')}
        imgSrc={WALLET}
      />
      <CardBody>
        <SharesWalletList whitelistedWallets={whitelistedWallets} setIsTokenAdded={setIsTokenAdded} />
        <WhitelistProgressionBar
          radioEthereumStatus={radioEthereumStatus}
          publickey={publickey}
          walletAdded={walletAdded}
          isTokenAdded={isTokenAdded}
          isConnected={isConnected}
        />
        <br />
        <Label for="shareType">{t('EthereumKeys-shareType-select')}</Label>
        <Row>
          <Col>
            <ShareTypeSelect invalid={false} value={shareTypeState.ID} onChange={onChange} useBlockchainOnly />
          </Col>
        </Row>

        <Label className="mt-2">{t('Enter-a-new-key-from-your-wallet')}</Label>
        <Row>
          <Col>
            {publickey.length ? (
              <CardH4>{publickey}</CardH4>
            ) : (
              <b>
                <Label className="mt-2">{t('EthereumKeys-Body-NoAddressPleaseConnect')}</Label>
              </b>
            )}
          </Col>
        </Row>
        {[BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX, BLOCKCHAIN_ID.FANTOM].includes(
          radioEthereumStatus,
        ) && !publickey.length ? (
          <WhitelistProgressionDisplay
            condition={!!publickey.length}
            translationKey="EthereumKeys-walletWhitelist-step1"
          />
        ) : null}
        {publickey.length && !walletAdded ? (
          <>
            <WhitelistProgressionDisplay condition={walletAdded} translationKey="EthereumKeys-walletWhitelist-step2" />
            <Row>
              <Col>
                <Button onClick={onAdd} disabled={isWhitelistingProcessing}>
                  {t('Add-Key')}
                </Button>
              </Col>
            </Row>
          </>
        ) : null}
        {walletAdded && !isTokenAdded ? (
          <>
            <WhitelistProgressionDisplay condition={isTokenAdded} translationKey="EthereumKeys-walletWhitelist-step3" />
          </>
        ) : null}
        {isTokenAdded ? (
          <WhitelistProgressionDisplay condition={isTokenAdded} translationKey="EthereumKeys-walletWhitelist-done" />
        ) : null}
      </CardBody>
      <Modal backdrop="static" keyboard={false} isOpen={isWhitelistingProcessing}>
        <Card>
          <CardHeader text={t('EthereumKeys-modal-title-processing')} />
          <CardBody>
            {' '}
            {t('EthereumKeys-modal-text-processing')}
            <Loading />
          </CardBody>
        </Card>
      </Modal>
    </StyledCard>
  );
};

export default EthereumKeys;

const StyledCard = styled(Card)`
  overflow: visible;
`;
