import { format, intlFormat, parseISO } from 'date-fns';
import { AppData } from 'services/apollo/core';
import { BsSwal } from 'atoms';
import { ApolloError } from '@apollo/client';
import { CurrencySymbolDisplayProps } from 'components/CurrencySymbolDisplay';
import { CurrencySymbolDisplayModeEnum } from 'services/apollo';

export const environment = {
  REACT_APP_GRAPHQL_URI: process.env.REACT_APP_GRAPHQL_URI,
  REACT_APP_STO: process.env.REACT_APP_STO,
  REACT_APP_STO_MODE: process.env.REACT_STO_MODE,
  REACT_APP_SHOW_STO_STYLING: process.env.REACT_APP_SHOW_STO_STYLING,
  // Overwrite with injected values from window.env if present
  ...(
    window as unknown as {
      env: {
        REACT_APP_GRAPHQL_URI?: string;
        REACT_APP_STO?: string;
        REACT_APP_STO_MODE?: string;
        REACT_APP_SHOW_STO_STYLING?: string;
      };
    }
  ).env,
};

export interface canSeeNDADataRequired {
  isStoNDAFlowEnabled: boolean;
  isNDASigned?: boolean;
  investorCountry?: string;
  appData: AppData;
  isBuyButtonEnabled?: boolean;
  isBuyStoEnabled?: boolean;
}

export const canSeeNDAData = (data: canSeeNDADataRequired): boolean => {
  const { isNDAFlowEnabled, accreditationRequiringCountries, isRegDRegSSeparationEnabled } = data.appData;
  const { isNDASigned, isStoNDAFlowEnabled, investorCountry } = data;
  if (!isNDAFlowEnabled || !isStoNDAFlowEnabled) {
    return true;
  }
  if (isNDASigned) {
    return true;
  }
  return isRegDRegSSeparationEnabled && !accreditationRequiringCountries.includes(investorCountry ?? '');
};

export const showBuyButton = (data: canSeeNDADataRequired): boolean => {
  const { isBuyButtonEnabled, isBuyStoEnabled, investorCountry } = data;
  const { isRegDRegSSeparationEnabled, isBuyStoWhitelistPerInvestorEnabled, accreditationRequiringCountries } =
    data.appData;

  if (!isBuyButtonEnabled) {
    return false;
  }
  if (isRegDRegSSeparationEnabled && !accreditationRequiringCountries.includes(investorCountry ?? '')) {
    return true;
  }
  return !(isBuyStoWhitelistPerInvestorEnabled && !isBuyStoEnabled);
};

export const isMulti: () => boolean = () =>
  // Assume 'MULTI' by default
  environment.REACT_APP_STO_MODE === 'MULTI' || !environment.REACT_APP_STO_MODE;

export const isSingle: () => boolean = () => environment.REACT_APP_STO_MODE === 'SINGLE';

export const formatDate: (date: string) => string = (date) => format(parseISO(date), 'MMMM dd yyyy');

export const unixTimeToDate = (timeStamp: string): string =>
  timeStamp ? new Date(Number(timeStamp)).toDateString() : '';

export const isDev: () => boolean = () => process.env.NODE_ENV === 'development';

export const unixTimeToLocalDate = (timeStamp: string | undefined, locale: string): string =>
  timeStamp
    ? intlFormat(
        parseInt(timeStamp, 10) ?? 0,
        {
          weekday: 'short',
          year: 'numeric',
          month: 'short',
          day: 'numeric',
        },
        {
          locale,
        },
      )
    : '';

/** Split str as { key, args } for use in t(key, args) if str is api arguments string. Split on \, */
export const tArgs = (str: string): { key: string; args: { [key: string]: string } } => {
  if (str.startsWith('api-arg-')) {
    const [msg, args] = str.split('\\,');
    return { key: msg, args: JSON.parse((args ?? '{}').replaceAll('\\"', '"')) };
  }
  return { key: str, args: {} };
};

/**
 * Returns the unique elements contained in both arrays (unsorted). If comparing objects, their references are compared,
 * therefore should not be used for objects. O(n) time and space complexity
 * @param array1
 * @param array2
 */
export const intersectArrays = <T>(array1: T[], array2: T[]): T[] => {
  // make a map for O(1) access
  const array2Map = new Map(array2.map((el) => [el, el]));
  const intersectionDuplicates = array1.filter((value) => array2Map.get(value) !== undefined);
  return arrayRemoveDuplicates(intersectionDuplicates);
};

/**
 * Returns the unique elements of an array. It should not be used for removing duplicate objects, since their references are compared.
 * If a property is provided, it will remove objects that have duplicates of the property.
 * @param array
 * @param property - optional
 */
export const arrayRemoveDuplicates = <T>(array: T[], property?: keyof T): T[] => {
  const map = new Map();
  for (const item of array) {
    if (property) {
      map.set(item[property], item);
    } else {
      map.set(item, item);
    }
  }
  return Array.from(map.values());
};

export interface GqlError<T = null> {
  graphQLErrors: [
    {
      message: string;
      path: string[];
      extensions: {
        data: T;
        translationKey: string;
        code: string;
      };
    },
  ];
  message: string;
}
export interface ErrorExtensions<T = null> {
  message: string;
  data: T;
}
export const getTranslationKeyOfApiError = <T>(error: GqlError<T> | ApolloError): ErrorExtensions<T> => {
  // TODO: DIG-1742 add support for parameters in the frontend gql errors
  const { translationKey } = error?.graphQLErrors[0]?.extensions || '';
  const data: T = error?.graphQLErrors[0]?.extensions as T;
  return {
    message: translationKey || error.message,
    data,
  };
};

/**
 * Returns the index of the last element in the array where predicate is true, and -1
 * otherwise.
 * @param array The source array to search in
 * @param predicate find calls predicate once for each element of the array, in descending
 * order, until it finds one where predicate returns true. If such an element is found,
 * findLastIndex immediately returns that element index. Otherwise, findLastIndex returns -1.
 */
export const findLastIndex = <T>(
  array: Array<T>,
  predicate: (value: T, index: number, obj: T[]) => boolean,
): number => {
  let l = array.length;
  while (l--) {
    if (predicate(array[l], l, array)) return l;
  }
  return -1;
};

export interface VerifyInvestorKeylessBsSwalProps {
  appData: AppData;
  investorStatus: number;
  investorCountry: string;
  popUpTitle: string;
  popUpConfirm: string;
  popUpCancel: string;
}
export const verifyInvestorKeylessBsSwal = (props: VerifyInvestorKeylessBsSwalProps): boolean => {
  const { investorStatus, appData, popUpTitle, popUpCancel, popUpConfirm, investorCountry } = props;
  const { verifyInvestorComKeylessIntegration, isRegDRegSSeparationEnabled, accreditationRequiringCountries } = appData;
  const { isEnabled, redirectUrl } = verifyInvestorComKeylessIntegration;
  if (
    isRegDRegSSeparationEnabled &&
    isEnabled &&
    investorStatus !== 3 &&
    accreditationRequiringCountries.includes(investorCountry)
  ) {
    BsSwal.fire({
      title: popUpTitle,
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: popUpConfirm,
      cancelButtonText: popUpCancel,
    }).then((result) => {
      if (result.isConfirmed) {
        const newWindow = window.open(redirectUrl || '', '_blank', 'noopener,noreferrer');
        if (newWindow) {
          newWindow.opener = null;
        }
      }
    });
    return true;
  }
  return false;
};

export interface GetCurrencySymbolString {
  data: CurrencySymbolDisplayProps;
  currencySymbolDisplayMode: CurrencySymbolDisplayModeEnum;
  locale: string;
}

/**
 * returns the locally formatted symbol value combo.
 * Use this helper when you need the result as a string (e.g. inside a <Select> options)
 * Use the <CurrencySymbolDisplay> component in any other time
 * @param props
 */
export const getCurrencySymbol = (props: GetCurrencySymbolString): string => {
  const { data, locale, currencySymbolDisplayMode } = props;
  const { value, currency } = data;
  if (!currency) {
    return `${value}`;
  }
  const { symbol, abbreviation, isBlockchainBased } = currency;

  const localeValue = value?.toLocaleString(locale, {
    minimumFractionDigits: 2,
  });
  try {
    switch (currencySymbolDisplayMode) {
      case CurrencySymbolDisplayModeEnum.SymbolFirst:
        return `${symbol} ${localeValue}`;
      case CurrencySymbolDisplayModeEnum.NumberFirst:
        return `${localeValue} ${symbol}`;
      case CurrencySymbolDisplayModeEnum.LocalBased:
      default: {
        if (isBlockchainBased) {
          return `${localeValue} ${symbol}`;
        }
        return `${value?.toLocaleString(locale, { style: 'currency', currency: abbreviation?.substring(0, 3) })}`;
      }
    }
  } catch (e) {
    console.error(`${e} - error occurred in getCurrencySymbol`);
    return `${localeValue} ${symbol}`;
  }
};

const nthPosition = (input: string, search: string, nth: number, _currentOccurrence = 1, foundIndex = 0): number => {
  const index = input.indexOf(search);
  if (index < 0) {
    return -1;
  }
  if (_currentOccurrence === nth && index) {
    return index + search.length;
  }
  return nthPosition(
    input.slice(index + search.length, input.length),
    search,
    nth,
    ++_currentOccurrence,
    foundIndex + index + search.length,
  );
};

/**
 * finds the nth occurrence of a search string, inside the input
 * @param input
 * @param search
 * @param nth
 */
export const getNthPosition = (input: string, search: string, nth: number): number => {
  return nthPosition(input, search, nth);
};
