import { CalculateShareFeesAndTotal } from 'lib/utils';
import { InvestorBuyPropertyShareType } from 'services/apollo/multisto';
import { FetchFees } from 'services/apollo/fee';
import { DiscountQueryResult } from 'services/apollo/discount';
import { AppData } from 'services/apollo/core';
import { max, min } from 'mathjs';

export interface UpdatePurchasePriceProps {
  setValue: (value: number) => void;
  setFeePrice: (value: number) => void;
  setDiscountPrice: (value: number) => void;
  setTotalPrice: (value: number) => void;
  share: InvestorBuyPropertyShareType;
  amount: number;
  fee: FetchFees[];
  discounts: DiscountQueryResult[];
  appData: AppData;
}

export const updatePurchasePrice = (props: UpdatePurchasePriceProps): void => {
  const { setValue, setFeePrice, setDiscountPrice, setTotalPrice, share, amount, fee, discounts, appData } = props;
  const { isFeesEnabled, isDiscountsEnabled } = appData;
  const properAmount = getMinMax(amount, share.minimumSharesToBuyByInvestor, share.maximumSharesToBuyByInvestor);
  setValue(amount);
  if (((fee.length && isFeesEnabled) || (discounts.length && isDiscountsEnabled)) && properAmount) {
    const calculateFeesAndTotal = CalculateShareFeesAndTotal({
      fee,
      discounts,
      shareTotalPrice: share.totalPrice,
      amount: properAmount,
      isSellBack: false,
      isFeesEnabled,
      isDiscountsEnabled,
    });
    setFeePrice(calculateFeesAndTotal.fees);
    setDiscountPrice(calculateFeesAndTotal.discounts);
    setTotalPrice(calculateFeesAndTotal.total);
  } else {
    setTotalPrice(share.totalPrice * properAmount);
  }
};

export const getMinMax = (amount: number, minAmount: number, maxAmount: number): number => {
  const minPurchaseAmount = minAmount > 0 ? max(minAmount, amount) : amount;
  const maxPurchaseAmount = maxAmount > 0 ? min(maxAmount, minPurchaseAmount) : minPurchaseAmount;
  return maxPurchaseAmount;
};
