import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import { FixedPluginRow } from 'components/fixed-plugin/components';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const SidebarCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const sidebarBGColors = [colors.blue, colors.marketSpaceBlue, colors.black];
  const sidebarFontsColor = [colors.white, colors.textGrey, colors.black];
  const sidebarFontsActiveColor = [colors.white, colors.blue, colors.black];

  const fontWeigths = [t('FixedPlugin-lighter'), '800', t('FixedPlugin-normal'), t('FixedPlugin-bold')];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-SidebarMenu')}</h5>
        <SettingsBarCarcet className="ti-angle-left" open={collapse} />
      </div>

      <Collapse isOpen={collapse}>
        <FixedPluginRow
          values={sidebarBGColors}
          property="backgroundSideBar"
          handleChange={handleChange}
          title="FixedPluginRow-Title-SidebarBackground"
          currentColor={theme.backgroundSideBar}
        />
        <FixedPluginRow
          values={sidebarFontsColor}
          property="fontColorSideBar"
          handleChange={handleChange}
          title="FixedPluginRow-Title-SidebarFontColor"
          currentColor={theme.fontColorSideBar}
        />
        <FixedPluginRow
          values={sidebarFontsActiveColor}
          property="fontActiveItemSideBar"
          handleChange={handleChange}
          title="FixedPluginRow-Title-SidebarFontColorActiveItem"
          currentColor={theme.fontActiveItemSideBar}
        />
        <FixedPluginRow
          values={fontWeigths}
          property="fontWeightSideBar"
          handleChange={handleChange}
          title="FixedPluginRow-Title-SidebarFontWeight"
          currentValue={theme.fontWeightSideBar}
          notColors
        />
      </Collapse>
    </div>
  );
};

export default SidebarCustomizer;
