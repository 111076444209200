import { CardHeader } from 'components/card-header/CardHeader';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Select } from 'atoms';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import { AppData } from 'services/apollo/core';
import { ActivePropertyItem } from 'services/apollo/multisto';
import { OFFERINGHTMLIDS } from 'pages/active-properties/ActiveProperties';
import PropertiesGrid from 'pages/active-properties/components/PropertiesGrid';
import OfferingsHeader from 'pages/active-properties/components/summary/OfferingsHeader';

interface ActivePropertiesLayoutProps {
  parameters: AppData;
  propertiesArray: ActivePropertyItem[];
  filteredPropertiesArray?: ActivePropertyItem[];
  isProfileMatching?: boolean;
}

const ActivePropertiesLayout: React.FC<ActivePropertiesLayoutProps> = ({
  parameters,
  propertiesArray,
  isProfileMatching,
  filteredPropertiesArray,
}) => {
  const { t } = useTranslation();
  const [sort, setSort] = useState('');
  const [activePropertyGrid, setActivePropertyGrid] = useState(OFFERINGHTMLIDS.ACTIVE_OFFERING);

  useEffect(() => {
    const defaultSort = parameters.defaultPropertyOrder.default;
    if (defaultSort && defaultSort !== '') {
      setSort(defaultSort);
    }
  }, [parameters]);

  const {
    isPropertySortingEnabled,
    defaultPropertyOrder,
    isInvestingEntityEnabled,
    isSellSideEntityEnabled,
    isClosedOfferingsCardEnabled,
    isInvestorOfferingsDashboard,
  } = parameters;

  const getValue = (firstValue: ActivePropertyItem, name: string) => {
    // dates must be first converted to date for proper comparison
    switch (name) {
      case 'createdAt':
        return new Date(firstValue[name]);
      default:
        return firstValue[name as keyof typeof firstValue];
    }
  };

  const handleSorting = (array: ActivePropertyItem[]): ActivePropertyItem[] | [] => {
    return sort
      ? [...array].sort((a, b) => {
          const [name, type] = sort.split('-') as ['projectCost' | 'popularity' | 'createdAt', 'asc' | 'desc'];
          return type === 'asc' ? getValue(a, name) - getValue(b, name) : getValue(b, name) - getValue(a, name);
        })
      : [];
  };

  const onChange = ({ value }: { value: string }) => {
    setSort(value);
  };

  const properties = handleSorting(propertiesArray).length ? handleSorting(propertiesArray) : propertiesArray;
  let filteredProperties: ActivePropertyItem[] = [];
  if (filteredPropertiesArray) {
    filteredProperties = handleSorting(filteredPropertiesArray).length
      ? handleSorting(filteredPropertiesArray)
      : filteredPropertiesArray;
  }

  const buyPorperties: ActivePropertyItem[] = [];
  const nonBuyPorperties: ActivePropertyItem[] = [];

  properties.map((p) =>
    !p.isBuyButtonEnabled || (isInvestingEntityEnabled && isSellSideEntityEnabled && !p.isInvestorInvited)
      ? nonBuyPorperties.push(p)
      : buyPorperties.push(p),
  );

  const sortOptions = defaultPropertyOrder.options.map((o) => {
    return {
      ...o,
      // converting to i18n translation
      label: t(o.label),
    };
  });

  const selected = sortOptions.find((c) => c.value === sort);

  const isDashBoard = isInvestorOfferingsDashboard ? isProfileMatching || isClosedOfferingsCardEnabled : false;

  return (
    <div className="content">
      <StyledDashboard twoItem={!isClosedOfferingsCardEnabled || !isProfileMatching}>
        {isDashBoard && (
          <OfferingsHeader
            activeOffering={buyPorperties.length}
            closedOffering={nonBuyPorperties.length}
            profileMatchesOffering={filteredProperties.length || 0}
            appData={parameters}
            setActivePropertyGrid={setActivePropertyGrid}
            activePropertyGrid={activePropertyGrid}
          />
        )}

        {isPropertySortingEnabled ? (
          <StyledCardSelect noBoxShadow>
            <CardHeader text={t('ActivePropertiesLayout-Sorting-Title')} fontWeight="500" />
            <CardBody>
              <StyledSelect options={sortOptions} value={selected} onChange={onChange} />
            </CardBody>
          </StyledCardSelect>
        ) : null}
      </StyledDashboard>
      <PropertiesGrid
        shown={isInvestorOfferingsDashboard ? OFFERINGHTMLIDS.ACTIVE_OFFERING === activePropertyGrid : true}
        htmlID={OFFERINGHTMLIDS.ACTIVE_OFFERING}
        properties={buyPorperties}
        appData={parameters}
      />
      {isProfileMatching ? (
        <PropertiesGrid
          shown={isInvestorOfferingsDashboard ? OFFERINGHTMLIDS.PROFILE_MATCHES === activePropertyGrid : true}
          htmlID={OFFERINGHTMLIDS.PROFILE_MATCHES}
          properties={filteredProperties}
          isFilter
          appData={parameters}
        />
      ) : null}
      <PropertiesGrid
        shown={isInvestorOfferingsDashboard ? OFFERINGHTMLIDS.CLOSED_OFFERING === activePropertyGrid : true}
        htmlID={OFFERINGHTMLIDS.CLOSED_OFFERING}
        properties={nonBuyPorperties}
        isClosedOffering
        appData={parameters}
      />
    </div>
  );
};

export default ActivePropertiesLayout;

const StyledDashboard = styled.div<{ twoItem: boolean }>`
  max-width: 2000px;
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 1rem;
  margin-bottom: 2rem;

  @media (max-width: 1450px) {
    margin-bottom: 1rem;
  }

  ${(p) => {
    if (p.twoItem) {
      return css`
        grid-template-columns: 1fr 1fr;
        @media (max-width: 1200px) {
          grid-template-columns: 1fr;
        }
      `;
    }
  }}

  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;
const StyledCardSelect = styled(Card)`
  background-color: ${(p) => p.theme.offeringsCardBGColor};
  overflow: visible;
  max-width: 600px;
`;
const StyledSelect = styled(Select)`
  max-width: 40rem;
`;
