import { Card, TH, THEAD, TR } from 'atoms';
import PopoverHover from 'components/PopoverHover';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';
import { InvestmentsCalculation, InvestorPortfolioSharesCurrency } from 'services/apollo/multisto';
import PortfolioValueCalculationRow from './PortfolioValueCalculationRow';

interface PortfolioValueCalculationsProps {
  investmentsCalculations: InvestmentsCalculation[];
  currency: InvestorPortfolioSharesCurrency;
}

const PortfolioValueCalculationsView: React.FC<PortfolioValueCalculationsProps> = ({
  investmentsCalculations,
  currency,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <Card>
        <Table responsive hover>
          <THEAD>
            <TR>
              <TH>#</TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-Offering')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-Offering')}
                />
              </TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-EntityName')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-EntityName')}
                />
              </TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-InvestmentAmount')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-InvestmentAmount')}
                />
              </TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-InvestmentYearlyRate')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-InvestmentYearlyRate')}
                />
              </TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-InvestmentValue')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-InvestmentValue')}
                />
              </TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-DistributionsReceived')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-DistributionsReceived')}
                />
              </TH>
              <TH>
                <PopoverHover
                  label={t('PortfolioValueCalculationsView-Header-OwedBeforeProfitSharing')}
                  target={<i className="ti-help-alt pl-1" />}
                  body={t('PortfolioValueCalculationRow-Header-Info-OwedBeforeProfitSharing')}
                />
              </TH>
            </TR>
          </THEAD>
          <tbody>
            {investmentsCalculations.map((calculation, index) => {
              return (
                <PortfolioValueCalculationRow
                  key={`${calculation.investmentName}${index}`}
                  currency={currency}
                  investmentCalculation={calculation}
                  rowNum={index}
                />
              );
            })}
          </tbody>
        </Table>
      </Card>
    </>
  );
};

export default PortfolioValueCalculationsView;
