import { StoMetaValue, StosMetaKeys } from 'services/apollo';
import { FilterOption } from 'services/apollo/investor-meta';
import { arrayRemoveDuplicates, intersectArrays } from 'services/core/helpers';

export interface MetaKeysFilters {
  [x: string]: FilterOption[];
}

/**
 * Sort the categories in ascending order by the order field and then returns a string[] of the ordered keys
 * @param categories - object containing key and order fields
 */
const orderCategories = (categories: Pick<StosMetaKeys, 'key' | 'order'>[]): string[] => {
  const sortedCats = [...categories].sort((a, b) => a.order - b.order);
  return sortedCats.map((category) => category.key);
};
export const useGetSortedMetaKeys = (
  getFilterMetaKeys: Pick<StosMetaKeys, 'categoryKey' | 'key' | 'order' | 'type'>[],
  metaContent: Partial<StoMetaValue>[] = [],
  propertyFilter?: boolean,
  categories?: Pick<StosMetaKeys, 'key' | 'order'>[],
): { groupedMetaFilters: MetaKeysFilters; categoryKeys: string[] } => {
  const sorted = [...getFilterMetaKeys].sort((a, b) => a.order - b.order);
  const metaKeys: MetaKeysFilters = {};

  const categoryKeysFromFilterKeys = sorted?.map((key) => key.categoryKey || '');
  const uniqCatKeys = arrayRemoveDuplicates(categoryKeysFromFilterKeys);
  // only use the categories with order if they were provided as a param
  const categoryKeys = categories ? intersectArrays(orderCategories(categories), uniqCatKeys) : uniqCatKeys;

  sorted.forEach((key) => {
    const categoryKey: string = key.categoryKey ?? '';
    if (propertyFilter) {
      metaContent?.forEach((item) => {
        if (item.key === key.key && item.value !== '') {
          if (!metaKeys[categoryKey]) {
            metaKeys[categoryKey] = [];
          }
          metaKeys[categoryKey].push(key);
        }
      });
    } else {
      if (!metaKeys[categoryKey]) {
        metaKeys[categoryKey] = [];
      }
      metaKeys[categoryKey].push(key);
    }
  });

  return { groupedMetaFilters: metaKeys, categoryKeys };
};
