import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CardImg } from 'reactstrap';
import styled, { useTheme } from 'styled-components';

import { useInvestorAppDataQuery, useInvestorStoHistoryQuery, useUpdatesQuery } from 'services/apollo';

import { ThemeType } from 'lib/themes/themeTypes';
import { Loading, Select, Col, Row, Card, CardBody } from 'atoms';
import REAL from 'assets/img/realestate.jpg';
import { useTranslation } from 'react-i18next';
import NoUpdates from './components/NoUpdates';

const CompanyUpdates: React.FC = () => {
  const { t } = useTranslation();
  const [activeFilter, setActiveFilter] = useState({
    value: -1,
    label: '',
  });

  const [activeSto, setActiveSto] = useState(-1);

  const { data, loading } = useUpdatesQuery({
    variables: { _id: Number(activeSto), limit: 20 },
    fetchPolicy: 'no-cache',
  });
  const history = useHistory();
  const { data: stoData, loading: stoLoading } = useInvestorStoHistoryQuery();
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (loading || stoLoading || appDataLoading || !stoData || !data || !appData) {
    return <Loading />;
  }

  const hideSto0 = appData.investorAppParameters.isPlatformOperatorHidden;

  const stoIDs: number[] = [];
  const selectOptions = [
    { value: -1, label: t('CompanyUpdate-select-showAll') },
    ...stoData.investorRelatedSto
      .filter((sto) => !(sto.ID === 0 && hideSto0))
      .map((cursto) => {
        stoIDs.push(cursto.ID);
        return {
          value: cursto.ID,
          label: cursto.title,
        };
      }),
  ];

  const onChangeSTO = (ent: { value: number; label: string }) => {
    if (ent.value !== -1) {
      setActiveSto(ent.value);
    } else {
      setActiveSto(-1);
    }
    setActiveFilter({ value: ent.value, label: ent.label });
  };

  if (loading || !data?.investorUpdates) {
    return <Loading />;
  }
  const theme: ThemeType = useTheme() as ThemeType;

  const themeStyling = {
    color: theme.fontColorRightPanel,
    fontWeight: theme.fontWeightRightPanel,
  };

  const updates = data.investorUpdates.filter((update) => {
    return stoIDs.includes(update.stoID);
  });

  return (
    <MainContent style={themeStyling}>
      <Content1>
        <Row>
          <Col md={4}>
            <Select
              name="DocumentTitle"
              options={selectOptions}
              value={{
                value: activeFilter.value,
                label: activeFilter.label || t('CompanyUpdate-select-default-text'),
              }}
              onChange={onChangeSTO}
            />
          </Col>
        </Row>
      </Content1>
      <Content>
        {updates.length === 0 ? (
          <NoUpdates offering={activeFilter.label} />
        ) : (
          updates.map((elem, index) => (
            <MainCard key={index}>
              <CardImg alt={elem.date} src={elem.coverphoto ? elem.coverphoto : REAL} />
              <CardBody>
                <MainText>
                  <b>{elem.title}</b>
                </MainText>
                <p>{elem.title}</p>
                <Button onClick={() => history.push(`/investor/company-update/${elem.ID}`)}>
                  {t('CompanyUpdates-button-readMore')}
                </Button>
              </CardBody>
            </MainCard>
          ))
        )}
      </Content>
      {/* <Card>
        <CardHeader
          text={t('Company-Updates')}
          caption={t('Company-News-Updates-from-Administration')}
          imgSrc={LIST}
        />
        <CardBody className="mb-2">
          <TABLE responsive>
            <THEAD>
              <tr>
                <TH min="150px" max="20%">
                  {t('Date')}
                </TH>
                <TH min="180px" width="80%">
                  {t('Title')}
                </TH>
                <TH min="150px" width="150px">
                  Actions
                </TH>
              </tr>
            </THEAD>
            <tbody>
              {updates.map((elem) => (
                <TR key={elem.ID}>
                  <TD>{toLocalDate(parseISO(elem.date))}</TD>
                  <TD>{elem.title}</TD>
                  <TD min="120px">
                    <Button size="sm" onClick={() => history.push(`/investor/company-update/${elem.ID}`)}>
                      {t('View-Details')}
                    </Button>
                  </TD>
                </TR>
              ))}
            </tbody>
          </TABLE>
        </CardBody>
      </Card> */}
    </MainContent>
  );
};

const MainContent = styled.div`
  padding: 30px;
  overflow: auto;
`;

const MainText = styled.div`
  font-size: 16px;
  font-weight: bold;
`;

const Button = styled.a`
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-weight: bold;
  width: 11rem;
  text-decoration: none;
  cursor: pointer;
  &:hover,
  &:active {
    color: blue;
    text-decoration: none;
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;
  padding: 20px 30px 30px 30px;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 650px) {
    grid-template-columns: 1fr;
  }
`;

const Content1 = styled.div`
  padding: 100px 30px 30px 30px;
`;

const MainCard = styled(Card)`
  border-radius: 0;
  max-width: 400px;
  max-height: 500px;

  img {
    max-width: 400px;
    height: 200px;
    object-fit: cover;
  }
`;

export default CompanyUpdates;
