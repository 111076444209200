import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { QueryInfo } from '@apollo/client/core/QueryInfo';
import { BsSwal } from 'atoms';

import { InvestorInvoiceAlert, InvestorWalletChannel } from 'services/apollo/multisto';
import {
  InvestorDepositWithdrawAlertInput,
  InvestorWalletDocument,
  PaymentChannelType,
  PintPreconfiguredProcessors,
  useInvestorDepositWithdrawAlertMutation,
  useVerifyTransactionFromBlockchainMutation,
  VerifyCryptoReciepeInput,
} from 'services/apollo';
import { AppData } from 'services/apollo/core';
import { Blockchain } from 'services/apollo/blockchain';
import DepositFormSendTxBlockchain from 'pages/wallet/components/Forms/depositForms/DepositFormSendTxBlockchain';
import DepositFormMercury from 'pages/wallet/components/Forms/depositForms/DepositFormMercury';
import DepositFormXendit from 'pages/wallet/components/Forms/depositForms/DepositFormXendit';
import DepositFormVerifyTxBlockchain from 'pages/wallet/components/Forms/depositForms/DepositFormVerifyTxBlockchain';
import DepositFormBank from 'pages/wallet/components/Forms/depositForms/DepositFormBank';
import { useGqlErrorExtractor } from 'hooks';
import { DepositFormDwollaPlaid } from 'pages/wallet/components/Forms/depositForms/DepositFormDwollaPlaid';
import PayInvoiceExternal from 'pages/my-portfolio/components/PayInvoiceExternal';

const fillBlock = (curID: number, channelID: number, stoID: number): VerifyCryptoReciepeInput => {
  return {
    stoID,
    transactionHash: '',
    details: '',
    currencyID: curID,
    amount: 0,
    channelID,
  };
};
const fillState = (channelID = 0, stoID = 0): InvestorDepositWithdrawAlertInput => {
  return {
    stoID,
    channelID: channelID ?? 0,
    amount: 0,
    details: '',
    bankName: '',
    swiftCode: '',
    bankAccount: '',
    transactionID: '',
    isWithdrawRequest: false,
    transactionHash: undefined,
  };
};

interface PaymentModalProps {
  channel: InvestorWalletChannel;
  isBlockchain: boolean;
  blockchain: Blockchain;
  appData: AppData;
  invoice?: InvestorInvoiceAlert;
  hideModal: () => void;
  onSubmit: (isWithdrawRequest: boolean, title: string) => void;
  onChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  stoID: number;
  investorID: number;
  state: InvestorDepositWithdrawAlertInput;
  shareValue?: number;
  defaultAmount?: number;
}

const PaymentModalSwitch: React.FC<PaymentModalProps> = (props) => {
  const {
    channel,
    isBlockchain,
    blockchain,
    appData,
    invoice,
    hideModal,
    onSubmit,
    onChange,
    stoID,
    shareValue,
    defaultAmount,
    investorID,
    state,
  } = props;
  const {
    doAutomaticPurchase,
    isInvoicingEnabled,
    isBlockchainTestnetEnabled,
    doAutomaticBlockchainTransactionChecks,
  } = appData;
  const history = useHistory();
  const { t } = useTranslation();

  const [form, setForm] = useState(fillBlock(channel.currencyID, channel.ID, stoID));
  const [error, setGqlError] = useGqlErrorExtractor(fillState(channel.ID, stoID));
  const [verifyBlockchain] = useVerifyTransactionFromBlockchainMutation({
    refetchQueries: [{ query: InvestorWalletDocument, variables: { _id: stoID } }],
  });
  const [investorAlert] = useInvestorDepositWithdrawAlertMutation({
    refetchQueries: [{ query: InvestorWalletDocument, variables: { _id: stoID } }],
  });

  const onMetamaskSubmit = (isWithdrawRequest = false, title: string) => {
    const data = {
      stoID,
      channelID: channel.ID,
      isWithdrawRequest,
      amount: Number(invoice?.amountToPay),
      buyAlertID: invoice?.buyAlertID,
      transactionHash: invoice?.invoiceDescription,
    };
    investorAlert({ variables: { data } })
      .then(() => {
        hideModal();
        return BsSwal.fire({
          title,
          icon: 'success',
        }).then((result) => {
          if (result.isConfirmed) {
            history.push(`/investor/Portfolio`);
          }
        });
      })
      .catch((err: QueryInfo) => {
        setGqlError(err);
      });
  };

  const onBlockChainChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    if (e.currentTarget.name === 'amount') {
      const newState = { [e.currentTarget.name]: Number(e.currentTarget.value) };
      setForm((prevState) => ({ ...prevState, ...newState }));
    } else {
      const newState = { [e.currentTarget.name]: e.currentTarget.value };
      setForm((prevState) => ({ ...prevState, ...newState }));
    }
  };

  const onBlockChainSubmit = () => {
    const data: VerifyCryptoReciepeInput = form;
    if (doAutomaticBlockchainTransactionChecks) {
      return verifyBlockchain({ variables: { data } })
        .then(() => {
          hideModal();
          return BsSwal.fire({
            title: 'Transaction is processed successfully. Funds are transferred in your internal wallet',
            icon: 'success',
          });
        })
        .catch((err) => {
          hideModal();
          return BsSwal.fire({
            title: (err as Error)?.message,
            icon: 'error',
          });
        });
    }
    const alertData: InvestorDepositWithdrawAlertInput = {
      stoID: form.stoID,
      channelID: form.channelID,
      amount: form.amount,
      details: form.details,
      isWithdrawRequest: false,
      transactionID: form.transactionHash,
    };

    return investorAlert({ variables: { data: alertData } })
      .then(() => {
        hideModal();
        return BsSwal.fire({
          title: t('walletManagement-depositWithDrawForm-popup-transactionSubmitted'),
          icon: 'success',
        });
      })
      .catch((err) => {
        setGqlError(err);
      });
  };

  switch (channel.channelType) {
    case PaymentChannelType.Metamask:
      if (isBlockchain && blockchain && channel.currency.Address) {
        const title =
          isInvoicingEnabled && doAutomaticPurchase
            ? t(`walletManagement-depositWithDrawForm-popup-transactionSubmitted-metamask-auto`)
            : t(`walletManagement-depositWithDrawForm-popup-transactionSubmitted-metamask-manual`);
        return (
          <DepositFormSendTxBlockchain
            invoice={invoice as InvestorInvoiceAlert}
            channel={channel}
            hideModal={hideModal}
            onSubmit={() => onMetamaskSubmit(false, title)}
            isTestnetEnabled={isBlockchainTestnetEnabled}
            shareValue={shareValue}
            blockchain={blockchain}
            currencyAddress={channel.currency.Address}
          />
        );
      }
      BsSwal.fire({
        title: t('PaymentModalSwitch-Metamask-Title'),
        text: t('PaymentModalSwitch-Metamask-Description'),
        icon: 'error',
      }).then((result) => {
        if (result.isConfirmed) {
          hideModal();
          history.push('/investor/Portfolio');
        }
      });
      return <></>;

    case PaymentChannelType.Mercury:
      return (
        <DepositFormMercury
          hideModal={hideModal}
          investorID={investorID}
          stoID={stoID}
          invoiceID={invoice?.ID}
          channel={channel}
        />
      );

    case PaymentChannelType.Xendit:
      return <DepositFormXendit hideModal={hideModal} invoiceID={invoice?.ID || 0} />;
    case PaymentChannelType.PintCustom:
      switch (channel.pintProcessorTitle) {
        case PintPreconfiguredProcessors.Dwolla:
          return (
            <DepositFormDwollaPlaid
              hideModal={hideModal}
              channel={channel}
              investorID={investorID}
              stoID={stoID}
              invoiceID={invoice?.ID}
            />
          );
        default:
          console.error(
            `Payment channel ID:${channel.ID} "${channel.title}" of type "PintCustom" can not initalize payment\
processor "${channel.pintProcessorTitle}" on the frontend, as no component matches it.`,
          );
          return (
            <>
              {BsSwal.fire({
                title: t('api-internal-server-error'),
                icon: 'error',
              })}
            </>
          );
      }
    case PaymentChannelType.Stripe:
      if (invoice) {
        return <PayInvoiceExternal invoice={invoice} isAuto hideModal={hideModal} />;
      }
      return null;
    default: // TODO: DIG-1687 redefine to manual deposit
      if (isBlockchain) {
        return (
          <DepositFormVerifyTxBlockchain
            hideModal={hideModal}
            channel={channel}
            investorID={investorID}
            state={form}
            error={error}
            onChange={onBlockChainChange}
            onSubmit={onBlockChainSubmit}
          />
        );
      }
      return (
        <DepositFormBank
          hideModal={hideModal}
          channel={channel}
          investorID={investorID}
          state={state}
          error={error}
          onChange={onChange}
          onSubmit={() => onSubmit(false, t(`walletManagement-depositWithDrawForm-popup-transactionSubmitted-deposit`))}
          defaultAmount={defaultAmount}
        />
      );
  }
};

export default PaymentModalSwitch;
