import React, { useEffect } from 'react';
import { MeDocument, useGCashUpdateInvestorDataMutation } from 'services/apollo';

import { BsSwal } from 'atoms';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { gCashProcess } from 'lib/routing/route-sets/verify-routes';

const FinishedKycRedirect: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [update] = useGCashUpdateInvestorDataMutation({
    refetchQueries: [{ query: MeDocument }],
  });
  const searchParams = new URLSearchParams(document.location.search);
  const queryParams = searchParams.get('data') || '';
  if (!queryParams) {
    BsSwal.fire({
      title: t('FinishedKycRedirect-PopUp-title-user-canceled'),
      timer: 8000,
      icon: 'error',
      didClose: () => {
        history.push(gCashProcess.path);
      },
    });
    return null;
  }
  const { data } = JSON.parse(atob(queryParams));
  const { iv, encrypted_data: encryptedData } = data;
  const gCashData = {
    signature: searchParams.get('signature') || '',
    iv,
    encryptedData,
  };

  useEffect(() => {
    update({
      variables: {
        gCashData: {
          ...gCashData,
        },
      },
    })
      .then(() => {
        BsSwal.fire({
          title: t('finishedKycRedirect-gCash-success-popUp-title'),
          timer: 8000,
          icon: 'success',
          didClose: () => {
            history.push(`/investor/active-properties`);
          },
        });
      })
      .catch((err) =>
        BsSwal.fire({
          title: err,
          icon: 'error',
        }),
      );
  }, []);

  return null;
};

export default FinishedKycRedirect;
