import React, { useEffect, useState } from 'react';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import _ from 'lodash';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Direction } from 'reactstrap/lib/Dropdown';
import { Countries } from '@ferranblanche/standards';
import { Country } from '@ferranblanche/standards/lib/types';
import { Data } from 'popper.js';
import * as Locales from 'date-fns/locale';
import { languages } from '../lib/i18';

interface LanguageSelectorProps {
  navigationBar: boolean;
  enableCountryFlags: boolean;
  color?: string;
  outline?: boolean;
  openDirection?: Direction;
}

const LanguageSelector: React.FC<LanguageSelectorProps> = ({
  navigationBar,
  enableCountryFlags,
  color,
  outline,
  openDirection,
}) => {
  const { i18n } = useTranslation();
  const countries = new Countries();
  const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage') ?? '');

  const getCountryCode = (lg: string) => {
    let country: Country | undefined;
    if (lg === 'en') {
      country = countries.get('gb');
    } else if (lg === 'zh' || lg === 'zh-Hant') {
      country = countries.get('cn');
    } else if (countries.contains(lg)) {
      country = countries.get(lg);
    } else {
      country = countries.search(languages.get(lg)?.nativeName);
    }
    return country?.code || '❔';
  };

  const getTranslatedLanguageName = (language: string) => {
    const languageName = new Intl.DisplayNames([selectedLanguage || 'en'], { type: 'language', fallback: 'none' }).of(
      language || 'en',
    );
    return _.capitalize(languageName);
  };

  useEffect(() => {
    localStorage.setItem('selectedLanguage', selectedLanguage);
    i18n.changeLanguage(selectedLanguage);
    registerLocale(selectedLanguage, Locales[selectedLanguage as keyof typeof Locales]);
    setDefaultLocale(selectedLanguage);
  }, [i18n, selectedLanguage]);

  return (
    <UncontrolledDropdown className="pt-1 navbar-item" nav={navigationBar} direction={openDirection}>
      <StyledDropDownToggle
        aria-haspopup
        caret
        outline={outline}
        color={color ?? 'default'}
        data-toggle="dropdown"
        id="navbarDropdownMenuLink"
        nav={navigationBar}
      >
        {enableCountryFlags ? (
          <>
            <img
              src={`https://flagcdn.com/16x12/${getCountryCode(i18n.language)}.png`}
              alt={getCountryCode(i18n.language)}
            />{' '}
          </>
        ) : null}
        <i className="ti-world navbar-item__icon language-icon" />
        <span className="navbar-item__content">{getTranslatedLanguageName(selectedLanguage)}</span>
      </StyledDropDownToggle>
      <StyledDropDownMenu
        aria-labelledby="navbarDropdownMenuLink"
        modifiers={{
          setMaxHeight: {
            enabled: true,
            order: 890,
            fn: (data: Data) => {
              return {
                ...data,
                styles: {
                  ...data.styles,
                  overflow: 'auto',
                  maxHeight: '20vh',
                },
              };
            },
          },
        }}
      >
        {Array.from(languages.keys()).map((lg: string) => (
          <DropdownItem
            href="#pablo"
            key={lg}
            onClick={() => {
              setSelectedLanguage(lg);
            }}
          >
            {enableCountryFlags ? (
              <>
                <img src={`https://flagcdn.com/16x12/${getCountryCode(lg)}.png`} alt={getCountryCode(lg)} />{' '}
              </>
            ) : null}
            {getTranslatedLanguageName(lg)}
          </DropdownItem>
        ))}
      </StyledDropDownMenu>
    </UncontrolledDropdown>
  );
};

export default LanguageSelector;

const StyledDropDownToggle = styled(DropdownToggle)`
  display: flex;
  align-items: center;

  .language-icon {
    @media (max-width: 600px) {
      margin-bottom: 0.3rem;
    }
  }
`;
const StyledDropDownMenu = styled(DropdownMenu)`
  box-shadow: 1px 2px 7px 1px rgba(0, 0, 0, 0.125) !important;
  @media (max-width: 991px) {
    top: 100% !important;
    left: -60% !important;
  }
  @media (max-width: 600px) {
    top: 100% !important;
    left: -250% !important;
  }
`;
