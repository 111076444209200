import ForumRoute from 'lib/routing/ForumRoute';
import ThreadList from 'pages/forum/ThreadList';
import ThreadContent from 'pages/forum/ThreadContent';

export const openForum = new ForumRoute({
  path: '/forum',
  Component: ThreadList,
});

export const openThread = new ForumRoute({
  path: '/thread/:threadID',
  Component: ThreadContent,
});

export default [openForum, openThread];
