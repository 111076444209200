import { AnimatedArrowToggle, Col, Row, TD, TR } from 'atoms';
import { delay } from 'lodash';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { InvestmentsCalculation, InvestorPortfolioSharesCurrency } from 'services/apollo/multisto';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import * as math from 'mathjs';

interface PortfolioValueCalculationRowProps {
  currency: InvestorPortfolioSharesCurrency;
  investmentCalculation: InvestmentsCalculation;
  rowNum: number;
}

const PortfolioValueCalculationRow: React.FC<PortfolioValueCalculationRowProps> = ({
  investmentCalculation,
  rowNum,
  currency,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { i18n, t } = useTranslation();
  const toggle = () => {
    if (isLoaded) {
      setIsOpen(!isOpen);
      delay(() => setIsLoaded(!isLoaded), 250);
    } else {
      setIsLoaded(!isLoaded);
      delay(() => setIsOpen(!isOpen), 150);
    }
  };
  const formatNumber = (number: number | undefined) => {
    return Number(number).toLocaleString(i18n.language, {
      minimumFractionDigits: 2,
    });
  };

  return (
    <>
      <TR
        onClick={() => {
          toggle();
        }}
      >
        <TD>{rowNum}</TD>
        <TD>{investmentCalculation.originatingProjectName}</TD>
        <TD>{investmentCalculation.entityName}</TD>
        <TD>
          <CurrencySymbolDisplay value={investmentCalculation.investmentValue} currency={currency} />
        </TD>
        <TD>{investmentCalculation.yearlyRate * 100}%</TD>
        <TD>
          <CurrencySymbolDisplay value={investmentCalculation.totalProfit} currency={currency} />
        </TD>
        <TD>
          <CurrencySymbolDisplay value={investmentCalculation.dividendPayout} currency={currency} />
        </TD>
        <TD>
          <CurrencySymbolDisplay
            value={math.subtract(investmentCalculation.totalProfit, investmentCalculation.dividendPayout)}
            currency={currency}
          />
          <AnimatedArrowToggle toggle={isOpen} pullRight />
        </TD>
      </TR>
      <TR>
        {isLoaded && (
          <TD colSpan={9}>
            <Collapse isOpen={isOpen}>
              <Col>
                <Row>{t('PortfolioValueCalculationRow-Description-Row')}</Row>
                <Row>
                  <Col md={1} />
                  <Col>{t('PortfolioValueCalculationRow-NormalDaysProfit-Row')}</Col>
                </Row>
                <Row>
                  <Col md={2} />
                  <Col>
                    {`
                    ${investmentCalculation.investmentValue} * 
                    ${investmentCalculation.normalDays} * 
                    (${investmentCalculation.yearlyRate} / 365) =
                    ${formatNumber(investmentCalculation.normalProfit)}
                    `}
                  </Col>
                </Row>
                <Row>
                  <Col md={1} />
                  <Col>{t('PortfolioValueCalculationRow-LeapDaysProfit-Row')}</Col>
                </Row>
                <Row>
                  <Col md={2} />
                  <Col>
                    {`
                    ${investmentCalculation.investmentValue} *
                    ${investmentCalculation.leapDays} * 
                    (${investmentCalculation.yearlyRate} / 365) =
                    ${formatNumber(investmentCalculation.leapProfit)}
                     `}
                  </Col>
                </Row>
                <Row>
                  <Col md={1} />
                  <Col>{t('PortfolioValueCalculationRow-TotalProfit-Row')}</Col>
                </Row>
                <Row>
                  <Col md={2} />
                  <Col>
                    {`
                    ${formatNumber(investmentCalculation.normalProfit)} + 
                    ${formatNumber(investmentCalculation.leapProfit)} =
                    ${formatNumber((investmentCalculation.normalProfit || 0) + (investmentCalculation.leapProfit || 0))}
                    `}
                  </Col>
                </Row>
                <Row>
                  <Col md={1} />
                  <Col>{t('PortfolioValueCalculationRow-TotalValue-Row')}</Col>
                </Row>
                <Row>
                  <Col md={2} />
                  <Col>
                    {`
                    ${investmentCalculation.investmentValue} + 
                    ${formatNumber(investmentCalculation.normalProfit)} + 
                    ${formatNumber(investmentCalculation.leapProfit)} =
                    ${formatNumber(investmentCalculation.totalProfit)}
                    `}
                  </Col>
                </Row>
              </Col>
            </Collapse>
          </TD>
        )}
      </TR>
    </>
  );
};

export default PortfolioValueCalculationRow;
