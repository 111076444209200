import React from 'react';
import { CenteredCol, Row } from 'atoms';
import { InvestorPortfolioShares } from 'services/apollo/multisto';
import { i18n } from 'lib/i18';

interface ShareQuantityProps {
  balance: InvestorPortfolioShares;
}

const ShareQuantity: React.FC<ShareQuantityProps> = ({ balance }) => {
  const bordered: React.CSSProperties = {
    borderBottom: '1px solid rgba(0,0,0,0.33)',
  };
  return (
    <>
      <Row className="mb-2">
        <CenteredCol md={8} style={bordered}>
          <p style={{ fontSize: '13px' }} className="font-italic mb-0">
            {balance.shareType.title}
          </p>
        </CenteredCol>
        <CenteredCol md={4} style={bordered}>
          <p style={{ fontSize: '13px' }} className="font-weight-bold mb-0">
            {balance.shares.toLocaleString(i18n.language, {
              minimumFractionDigits: 2,
            })}
          </p>
        </CenteredCol>
      </Row>
    </>
  );
};

export default ShareQuantity;
