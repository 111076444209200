import React from 'react';
import { Card, Col, Row, Loading } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useModal } from 'components/modals/Modal';
import {
  useInvestorInvoiceAlertQuery,
  useMeQuery,
  useInvestorWalletQuery,
  useInvestorAppDataQuery,
} from 'services/apollo';
import { InvestorInvoiceAlert, InvestorWalletChannel } from 'services/apollo/multisto';
import ChannelItem from 'pages/wallet/components/ChannelItem';
import DepositWithdrawFormManagement from 'pages/wallet/components/Forms/DepositWithdrawFormManagement';

const InvoiceReview: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const invoiceID: number = parseInt(id, 10);
  const modal = useModal();
  const { t } = useTranslation();

  const { data: investorInfo, loading: load2 } = useMeQuery();
  const { data: invoiceData, loading: load1 } = useInvestorInvoiceAlertQuery({
    variables: { id: invoiceID },
    fetchPolicy: 'no-cache',
  });
  const { data: investorPaymentChannels, loading: load3 } = useInvestorWalletQuery({
    variables: { _id: Number(invoiceData?.investorInvoiceAlert?.stoID ?? 0) },
    fetchPolicy: 'no-cache',
  });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (
    load1 ||
    load2 ||
    load3 ||
    appDataLoading ||
    !invoiceData ||
    !investorInfo ||
    !investorPaymentChannels ||
    !appData
  ) {
    return <Loading />;
  }
  const { isFastTrackPaymentEnabled, isStoSpecificPaymentChannelsEnabled } = appData.investorAppParameters;

  const invoice = invoiceData.investorInvoiceAlert;
  // TODO: pass the invoice ID when fetching channels, to make sure only the relevant usages appear - DIG-1715
  const channels = investorPaymentChannels.investorPaymentChannels
    .filter(
      // active and matches currency of the invoice
      (chan) =>
        chan.isActive && chan.currencyID === (invoice.paymentChannel?.currencyID ?? invoice.shareType.currencyID),
    )
    .filter((chan) => chan.stoID === 0 || chan.stoID === invoice.stoID || !isStoSpecificPaymentChannelsEnabled); // sto match

  const { investor } = investorInfo.investorUser || {};

  if (!invoice || !channels || !investor) {
    return <Loading />;
  }
  const openDepositModalAutomatically = isFastTrackPaymentEnabled && channels.length === 1;
  const handleShowModal = (channel: InvestorWalletChannel, isWithdraw: boolean) => {
    modal.showModal({
      noWrapper: true,
      className: 'w-50 mw-100 minw-400',
      bodyContent: ({ hideModal }: { hideModal: () => void }) => (
        <DepositWithdrawFormManagement
          channel={channel}
          stoID={channel.stoID}
          investorID={investor.ID}
          isWithdraw={isWithdraw}
          hideModal={hideModal}
          invoice={invoice as InvestorInvoiceAlert}
          shareValue={shareValue}
          defaultAmount={invoice.amountToPay}
        />
      ),
    });
  };

  const originalValue = invoice?.shareType.premiumValue;
  const isConversionEligible =
    invoice?.paymentChannel?.conversionEnabled === true &&
    invoice?.paymentChannel?.currencyToConvert?.ID === invoice?.shareType.currency.ID;
  const shareValue = isConversionEligible
    ? (1 / +(invoice.paymentChannel?.conversionRate ?? 1)) * originalValue
    : originalValue;

  return (
    <div className="content">
      <Card style={openDepositModalAutomatically ? { display: 'none' } : {}}>
        <CardHeader text={t('InvoiceReview-PaymentDetails')} />
        <Row className="mx-auto">
          <Col>
            <label>{t('WalletManagement-DepositFundsInAccountLabel')}</label>
            {channels.length > 0 ? (
              channels.map((chn) => (
                <ChannelItem
                  key={chn.ID}
                  channel={chn}
                  deposit={() => handleShowModal(chn, false)}
                  withdraw={() => handleShowModal(chn, true)}
                  appData={appData.investorAppParameters}
                  openDepositModalAutomatically={openDepositModalAutomatically}
                />
              ))
            ) : (
              <Row>
                <b>{t('WalletManagement-NoPaymentChannelFound')}</b>
              </Row>
            )}
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default InvoiceReview;

export {};
