import React, { useCallback, useEffect, useState } from 'react';
// import React, { useCallback, useEffect, useState } from "react";
import { InvestorWalletChannel } from 'services/apollo/multisto';
import { useTranslation } from 'react-i18next';
import {
  PintApiAction,
  PintBeginFlowInvestorMutation,
  PintPreconfFlows,
  // DpayEntity,
  // DwollaCustomActions,
  // PintApiAction,
  PintPreconfiguredProcessors,
  usePintBeginFlowInvestorMutation,
  usePintExecuteActionInvestorMutation,
  usePintGetAccountDataInvestorQuery,
} from 'services/apollo';
import { BsSwal, Loading } from 'atoms';
import { PlaidLinkOnSuccess, usePlaidLink } from 'react-plaid-link';
import { FetchResult } from '@apollo/client';
import { DwollaAccountState } from '../depositForms/DepositFormDwollaPlaid';

export interface DwollaAccountManagerProps {
  hideModal: () => void;
  investorID: number;
  stoID: number;
  invoiceID?: number;
  channel?: InvestorWalletChannel;
  accountState: DwollaAccountState;
  changeAccountState: (accountState: DwollaAccountState) => void;
}

// type DwollaSteps = {
//   didAccountLoad: boolean,
//   didCreateLinkToken: boolean,
//   didStartPlaidFlow: boolean,
//   didFinishPlaidFlow: boolean,
//   didGetProcessorToken: boolean,
//   didUploadPaymentSource: boolean,
// }

/**
 * Responsible for the Dwolla account and Dwolla/Plaid funding source flow:
 * - fetching the DpayEntity account
 *   - the account sub-flow is done by the backend:
 *    - if it doesn't exist:
 *      - create/upload it
 *    - else: pull/synchronize from remote
 *  - proceed with frontend logic:
 *   - if it doesn't have a funding source:
 *     - create a funding source:
 *       - invoke Plaid link to create a public token, through our server
 *         - get user bank account info
 *       - exchange it for a processor token, through our server
 *       - create Dwolla
 *   - once account and funding source are established, use setAccount and setAccountIsReady to return to upper component
 */
export const DwollaAccountManager: React.FC<DwollaAccountManagerProps> = ({
  hideModal,
  // accountState,
  changeAccountState,
  // changeAccount,
  // changeAccountIsReady,
}) => {
  const { t } = useTranslation();
  const [pintExecuteAction] = usePintExecuteActionInvestorMutation();
  const [pintBeginFlow] = usePintBeginFlowInvestorMutation();
  const [linkToken, setLinkToken] = useState<string | null>(null);
  const [plaidPublicToken, setPlaidPublicToken] = useState<string | null>(null);
  const [subAccountUploadMutation, setSubAccountUploadMutation] =
    useState<FetchResult<PintBeginFlowInvestorMutation> | null>(null);

  // STEP 0: Get local account synced
  const {
    data: accData,
    loading: accLoading,
    error: accError,
  } = usePintGetAccountDataInvestorQuery({
    variables: {
      processor: PintPreconfiguredProcessors.Dwolla,
    },
  });
  const DPayAccount = accData?.pint_getAccountData_Investor;

  // Setup for step 1
  useEffect(() => {
    if (DPayAccount?.topUpSourceId) {
      changeAccountState({ account: DPayAccount, isReady: true });
    } else if (DPayAccount) {
      // Invoke STEP 1
      createLinkToken()
        .catch((err) => {
          console.error(err);
        })
        .then((token) => {
          if (token) {
            setLinkToken(token);
          } else {
            console.error(`Couldn't open Plaid`);
            BsSwal.fire({
              title: t('plaid-generalError'),
              icon: 'error',
            });
            hideModal();
          }
        });
    }
  }, [DPayAccount]);

  /**
   * # STEP 1
   *
   * Creating a link token for Plaid:
   *
   * In order to integrate with Plaid Link, we will first need to create a link_token. A link_token is a short-lived,
   * one-time use token that is used to authenticate our app with Link.
   *
   * It's created from OUR SERVER's (processor storage) `client_id`, `secret`, and a few other required parameters.
   */
  const createLinkToken = useCallback(async (): Promise<string | null> => {
    try {
      const mutation = await pintExecuteAction({
        variables: {
          processor: PintPreconfiguredProcessors.Dwolla,
          action: PintApiAction.CustomAction,
          customAction: 'dwolla-getPlaidLinkToken', // DwollaCustomActions.GetPlaidLinkToken,
        },
      });
      const data = mutation.data?.pint_executeAction_Investor;
      if (!data.link_token) {
        console.error(
          `Dwolla-Plaid integration: Expected server to return a link_token, got:\n${JSON.stringify(data, null, 4)}`,
        );
        throw new Error(`plaid-link-token-error`);
      }
      console.log(`Created Link Token: ${data.link_token}`);
      setLinkToken(data.link_token);
      return data.link_token;
    } catch (e) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: t((e as Error).message),
      });
      return null;
    }
  }, [setLinkToken]);

  /**
   * # STEP 3
   *
   * Exchange Plaid's Link Token for a Process Token ON PLAID.
   *
   * AFTER PLAID
   * ask OUR SERVER to post to PLAID
   * then WE send it to DWOLLA
   *
   */
  const handlePlaidLinkSuccess: PlaidLinkOnSuccess = async (publicToken, metadata) => {
    /**
     * # STEP 3.1
     * Exchange Plaid's Public Token for a Processor Token : from our BACKEND to PLAID
     */

    /** Post to our API: Create a Dwolla Funding Source using Plaid's Processor Token via OUR BACKEND */

    exitPlaid();
    setPlaidPublicToken(publicToken);
    console.log(`Plaid: getting a PT for Plaid account:${metadata.accounts[0].id}`);
    try {
      setSubAccountUploadMutation(
        await pintBeginFlow({
          variables: {
            processor: PintPreconfiguredProcessors.Dwolla,
            flow: PintPreconfFlows.UploadPaymentSource,
            customData: {
              plaid_publicToken: publicToken,
              plaid_accountID: metadata.accounts[0].id,
              plaid_accountName: metadata.accounts[0].name,
            },
          },
        }),
      );
    } catch (e) {
      console.error(e);
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: t('dwolla-uploading-funding-source-failure'),
      });
      hideModal();
    }
  };

  /**
   * # STEP 2
   *
   * USE PLAID frontend package with OUR TOKEN we got from OUR BACKEND
   */
  const { open: openPlaidLink, exit: exitPlaid /* , ready: isPlaidLinkReady #use later maybe */ } = usePlaidLink({
    /**
     * Invoke Step 3
     */
    onSuccess: handlePlaidLinkSuccess,
    token: linkToken,
    onExit: () => {
      BsSwal.fire({
        text: t('plaid-cancelled'),
        icon: 'info',
      });
      hideModal();
    },
  });
  /**
   * # STEP 4
   * Create Dwolla funding source using our processor_token
   */
  useEffect(() => {
    if (subAccountUploadMutation?.data) {
      // console.warn(`ProcessorToken:${processorToken}`);

      BsSwal.fire({
        title: t('Success'),
        icon: 'success',
        text: `dwolla-uploading-funding-source-success`,
      });
      console.log(`Funding source created!`);
      changeAccountState({ account: DPayAccount, isReady: true });
    } else if (subAccountUploadMutation?.errors) {
      console.log(subAccountUploadMutation.errors);
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: t(`dwolla-uploading-funding-source-failure`),
      });
      hideModal();
    }
  }, [subAccountUploadMutation]);

  if (!accData || accLoading) {
    return (
      <>
        {t('dpay-synchronizingAccount')}
        <br />
        <Loading />
      </>
    );
  }
  if (accError) {
    BsSwal.fire({
      title: t('Error'),
      icon: 'error',
      text: t('dpay-accountLoadError'),
    });
    hideModal();
    return <></>;
  }
  if (!plaidPublicToken) {
    return (
      <>
        {t('plaid-connecting')}
        {openPlaidLink()}
      </>
    );
  }
  return <>{t('dwolla-uploading-plaid-token')}</>;
};
