import { useEffect } from 'react';

export interface UseScriptProps {
  src: string;
  id?: string;
}
const useScript = (props: UseScriptProps): void => {
  const { src, id } = props;
  useEffect(() => {
    if (!src) {
      return;
    }
    const script = document.createElement('script');

    script.src = src;
    script.id = id || '';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [src]);
};

export default useScript;
