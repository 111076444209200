import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { MetaMaskProvider } from 'metamask-react';

import Auth from 'services/core/auth';
import { tArgs } from 'services/core/helpers';
import {
  BuyAlertStatus,
  PaymentProcessor,
  useInvestorBuyAlertMutation,
  useInvestorBuyAlertsQuery,
  useInvestorWalletQuery,
  useCheckRegistrationFeeStatusQuery,
} from 'services/apollo';
import { AppData } from 'services/apollo/core';
import {
  InvestorBuyPropertyPaymentChannel,
  InvestorBuyPropertyShareType,
  InvestorWalletChannel,
} from 'services/apollo/multisto';

import { BsSwal, Button, Col, Loading, Row } from 'atoms';
import { useModal } from 'components/modals/Modal';
import DepositWithdrawFormManagement from 'pages/wallet/components/Forms/DepositWithdrawFormManagement';
import WhitelistWalletForm from './WhitelistWalletForm';

interface BuyButtonProps {
  sto: number;
  hasFunds: boolean;
  disabled: boolean;
  setLoadingRequest: (isActive: boolean) => void;
  appData: AppData;
  isWalletDropDown: boolean;
  shareCount: number;
  shareType: InvestorBuyPropertyShareType;
  publicKey?: string;
  entityID?: number;
  selectedChannel: InvestorBuyPropertyPaymentChannel | undefined;
  totalPrice?: number;
}
const BuyButton: React.FC<BuyButtonProps> = (props) => {
  const {
    sto,
    hasFunds,
    disabled,
    setLoadingRequest,
    appData,
    isWalletDropDown,
    shareCount,
    shareType,
    publicKey,
    selectedChannel,
    entityID,
    totalPrice,
  } = props;
  const { IsInternalWalletDisabled: isInternalWalletDisabled, activePaymentProcessor } = appData;

  const { t } = useTranslation();
  const history = useHistory();
  const modal = useModal();
  const { data: alertsData, loading: alertsLoading } = useInvestorBuyAlertsQuery({
    variables: { status: [BuyAlertStatus.Pending, BuyAlertStatus.KycRequired, BuyAlertStatus.AccreditationRequired] },
    fetchPolicy: 'no-cache',
  });

  const { data: channelsData, loading: channelsLoading } = useInvestorWalletQuery({
    variables: { _id: Number(sto) },
    fetchPolicy: 'no-cache',
  });

  const { data: feeStatusData, loading: feeStatusLoading } = useCheckRegistrationFeeStatusQuery();

  const [buy] = useInvestorBuyAlertMutation();

  if (alertsLoading || !alertsData || channelsLoading || !channelsData || !feeStatusData || feeStatusLoading) {
    return <Loading />;
  }

  const alertsCount = alertsData.investorBuyAlerts?.find(
    (item) => item.shareTypeID === shareType.ID && !item.isSellRequest,
  );

  const { investorPaymentChannels: channels } = channelsData;
  const targetPaymentChannel = channels.find((channel) => channel.currencyID === shareType.currencyID) || channels[0];

  const { checkRegistrationFeeStatus } = feeStatusData;

  const openDepositModal = (channel: InvestorWalletChannel) => {
    modal.showModal({
      noWrapper: true,
      className: 'w-50 mw-100 minw-400',
      bodyContent: ({ hideModal }: { hideModal: () => void }) => (
        <DepositWithdrawFormManagement
          channel={channel}
          stoID={sto}
          investorID={Auth.payload.ID}
          isWithdraw={false}
          hideModal={hideModal}
          defaultAmount={totalPrice}
        />
      ),
    });
  };

  const checkIfBuyIsValid = async () => {
    setLoadingRequest(true);
    if (!hasFunds && !isInternalWalletDisabled && activePaymentProcessor === PaymentProcessor.Off) {
      return BsSwal.fire({
        title: t('BuyPropertyShare-missingFundsInWallet-popUp-title'),
        text: t('BuyPropertyShare-missingFundsInWallet-popUp-text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('BuyPropertyShare-fundWallet-popUp-title'),
        cancelButtonText: t('Cancel'),
      }).then((result) => {
        setLoadingRequest(false);
        if (result.isConfirmed) {
          openDepositModal(targetPaymentChannel);
        }
      });
    }
    if (!shareCount) {
      return BsSwal.fire({
        title: t('BuyPropertyShare-missingSharesNumber-popUp-title'),
        icon: 'error',
      });
    }
    if (!publicKey?.length && isWalletDropDown) {
      return BsSwal.fire({
        title: t('BuyPropertyShare-popUp-specifyWallet'),
        icon: 'error',
      });
    }
    return sendBuyRequest();
  };

  const sendBuyRequest = async () => {
    try {
      const result = await buy({
        variables: {
          query: {
            stoID: Number(sto),
            shareTypeID: shareType.ID,
            shares: shareCount,
            publicKey,
            status: BuyAlertStatus.PendingDocuments,
            isBlockchain: shareType.isBlockchain,
            entityID,
            channelID: selectedChannel?.ID,
          },
        },
      });
      if (result.data?.investorBuyAlert) {
        BsSwal.fire({
          title: t('BuyPropertyShare-success-popUp-title'),
          icon: 'success',
        }).then(() => {
          history.push(`/investor/share-purchase-signing/${result?.data?.investorBuyAlert}`);
        });
      }
      return true;
    } catch (err) {
      const tObj = tArgs((err as Error).message);
      BsSwal.fire({
        title: t(tObj.key, tObj.args),
        icon: 'error',
      }).then((result) => {
        setLoadingRequest(false);
        if (result.isConfirmed) {
          history.push(`/investor/buy-property/${Number(sto)}`);
        }
      });
      return false;
    }
  };

  const handleShowModal = () => {
    modal.showModal({
      noWrapper: true,
      className: 'w-50 mw-100 minw-400',
      bodyContent: ({ hideModal }: { hideModal: () => void }) => (
        <MetaMaskProvider>
          <WhitelistWalletForm hideModal={hideModal} shareType={shareType} />
        </MetaMaskProvider>
      ),
    });
  };

  const continueFlow = async () => {
    if (alertsCount) {
      history.push('/investor/Portfolio');
    } else if (!publicKey && isWalletDropDown) {
      handleShowModal();
    } else if (checkRegistrationFeeStatus) {
      await checkIfBuyIsValid();
    } else {
      BsSwal.fire({
        title: t('BuyPropertyShare-BsSwal-RegistrationFee-Title'),
        text: t('BuyPropertyShare-BsSwal-RegistrationFee-Text'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: t('BuyPropertyShare-BsSwal-RegistrationFee-ConfirmButton'),
        cancelButtonText: t('Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          history.push('/investor/registration-fee-payment');
        }
      });
    }
  };

  const changeButtonText = () => {
    if (alertsCount) {
      return t('ActiveProperty-Resume-Purchase');
    }
    if (!publicKey && isWalletDropDown) {
      return t('BuyPropertyShare-WhitelistedWallets-AddMore');
    }
    return t('BuyProperty-send-button');
  };
  return (
    <Row>
      <Col className="ml-auto" md={5}>
        <Button disabled={disabled} onClick={continueFlow}>
          {changeButtonText()}
        </Button>
      </Col>
    </Row>
  );
};

export default BuyButton;
