import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { GetAllForumThreadsDocument, useCreateForumThreadMutation } from 'services/apollo';

import { Button, Input, Form, FormGroup, Label } from 'atoms';

import { SectionWrapper } from './components/forum-styles';

const ThreadCreate: React.FC = () => {
  const { t } = useTranslation();

  const [threadTitle, setThreadTitle] = useState('');
  const [threadBody, setThreadBody] = useState('');

  const [createForumThread] = useCreateForumThreadMutation({
    refetchQueries: [{ query: GetAllForumThreadsDocument }],
  });

  const createThread = () => {
    const newThread = {
      title: threadTitle,
      body: threadBody,
    };
    if (threadTitle.length > 0 && threadBody.length > 0) {
      createForumThread({ variables: { threadData: newThread } }).then(() => {
        setThreadTitle('');
        setThreadBody('');
      });
    }
  };

  return (
    <SectionWrapper>
      <Form>
        <FormGroup>
          <Label>{t('ThreadCreate-Label-Title')}</Label>
          <Input
            autoComplete="off"
            required
            maxLength="200"
            name="title"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setThreadTitle(e.currentTarget.value || '')}
            value={threadTitle}
            placeholder={t('ThreadCreate-TitleInput-Placeholder')}
          />
        </FormGroup>
        <FormGroup>
          <Label>{t('ThreadCreate-Label-Body')}</Label>
          <Input
            type="textarea"
            rows="5"
            autoComplete="off"
            required
            maxLength="5000"
            name="body"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setThreadBody(e.currentTarget.value || '')}
            value={threadBody}
            placeholder={t('ThreadCreate-BodyInput-Placeholder')}
          />
        </FormGroup>
        <Button onClick={createThread}>{t('ThreadCreate-Button-CreateThread')}</Button>
      </Form>
    </SectionWrapper>
  );
};

export default ThreadCreate;
