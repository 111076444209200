import React from 'react';
import { Col, Row } from 'atoms';

import { DiscountType, PriceTransformerTrigger } from 'services/apollo/graphql';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { DiscountQueryResult } from 'services/apollo/discount';
import { InvestorSharesCurrency } from 'services/apollo/shares';
import { useTranslation } from 'react-i18next';

interface PotentialDiscountsDetailsProps {
  discounts: DiscountQueryResult[];
  currency: InvestorSharesCurrency;
  subtotal?: number;
}

const PotentialDiscountsDetails: React.FC<PotentialDiscountsDetailsProps> = (prop) => {
  const { discounts, currency } = prop;
  const { t } = useTranslation();

  const conditionalText = (trigger: PriceTransformerTrigger) => {
    const { lowerBound, upperBound } = trigger;
    return t('PotentialDiscountsDetails-BETWEEN', { lowerBound, upperBound, currencySymbol: currency.symbol });
  };

  return (
    <>
      <h6>{t('PotentialDiscountsDetails-header')}</h6>
      {discounts.map((d) => (
        <Row key={d.ID} style={{ display: 'inline' }}>
          <ul>
            <li>
              <Col>
                <b>{t(d.appliesTo)}</b>
                <ul>
                  {d.triggers.map((trigger) => (
                    <li key={trigger.ID}>{conditionalText(trigger)}</li>
                  ))}
                </ul>
              </Col>
              <Col>
                {t('PotentialDiscountsDetails-discountValue')}
                <b>
                  {d.type === DiscountType.Percentage ? (
                    <label>{d.amount}%</label>
                  ) : (
                    <label>
                      <CurrencySymbolDisplay value={d.amount} currency={currency} />
                    </label>
                  )}
                </b>
              </Col>
            </li>
          </ul>
        </Row>
      ))}
    </>
  );
};

export default PotentialDiscountsDetails;
