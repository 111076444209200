import { Invoice } from 'services/apollo/core';
import { usePayInvoiceMutation } from 'services/apollo';
import { BsSwal, Loading } from 'atoms';
import React, { useEffect } from 'react';
import { Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';

interface PayInvoiceExternalProps {
  invoice: Invoice;
  isAuto?: boolean;
  hideModal?: () => void;
}

const PayInvoiceExternal: React.FC<PayInvoiceExternalProps> = ({ invoice, isAuto, hideModal }) => {
  const { t } = useTranslation();
  const [payInvoice, { loading }] = usePayInvoiceMutation();
  const handlePayInvoice = async () => {
    if (!invoice) {
      return;
    }
    try {
      const result = await payInvoice({ variables: { invoiceID: invoice.ID } });
      const checkoutUrl = result.data?.payInvoice;
      if (!checkoutUrl) {
        return;
      }
      window.location.replace(checkoutUrl);
    } catch (e) {
      BsSwal.fire({ title: (e as Error).message, icon: 'error', showConfirmButton: true });
    } finally {
      if (isAuto && hideModal) {
        hideModal();
      }
    }
  };

  useEffect(() => {
    if (isAuto) {
      handlePayInvoice();
    }
  }, []);

  if (loading) {
    return <Loading />;
  }

  return (
    <Button size="sm" onClick={handlePayInvoice}>
      {t('AlertItem-Button-PayExternally')}
    </Button>
  );
};

export default PayInvoiceExternal;
