import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { AtomicSwapStatus, GetExchangeOfferDetailDocument, useDeleteOfferMutation } from 'services/apollo';
import { ExchangeOfferDetail, ExchangeOfferDetailOrder } from 'services/apollo/exchange';

import { BsSwal, Button, Col, Label, Row } from 'atoms';

interface ExistingOfferProps {
  order: ExchangeOfferDetailOrder;
  offer: ExchangeOfferDetail;
}

const ExistingOffer: React.FC<ExistingOfferProps> = ({ order, offer }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteOffer] = useDeleteOfferMutation({
    refetchQueries: [{ query: GetExchangeOfferDetailDocument, variables: { orderID: order.ID } }],
  });

  const handleDelete = () => {
    return deleteOffer({ variables: { orderID: order.ID } })
      .then(() => {
        history.push('/investor/trading');
      })
      .catch((err) => {
        BsSwal.fire({
          icon: 'error',
          text: err.message,
        });
      });
  };

  const goToInvoice = () => {
    history.push('/investor/invoices');
  };

  return (
    <div className="content">
      <b className="d-block">{t('ExistingOffer-card-title')}</b>
      <Row>
        <Col>
          <Label>{t('ExistingOffer-label-shareCount')}</Label>
          {offer.sharesPartial}
        </Col>
        <Col>
          <Label>
            {order.atomicSwapAcceptable ? t('ExistingOffer-label-price') : t('ExistingOffer-label-rateFrom')}
          </Label>
          <CurrencySymbolDisplay value={offer.rateFrom} currency={order.shareType.currency} />
        </Col>
      </Row>
      {order.atomicSwapCurrentStatus === AtomicSwapStatus.NotInitialized ? (
        <Row>
          <Col>
            <Button size="sm" onClick={handleDelete}>
              {t('ExistingOffer-button-deleteOffer')}
            </Button>
          </Col>
        </Row>
      ) : null}
      {order.atomicSwapCurrentStatus === AtomicSwapStatus.AwaitingBuyerPayment ? (
        <Row>
          <Col>
            <Button size="sm" onClick={goToInvoice}>
              {t('ExistingOffer-button-viewInvoice')}
            </Button>
          </Col>
        </Row>
      ) : null}
    </div>
  );
};

export default ExistingOffer;
