import React, { useEffect, useState } from 'react';

import { Col, Row, Button, Label, Input, Loading } from 'atoms';
import { useTranslation } from 'react-i18next';
import { i18n } from 'lib/i18';
import {
  PaymentProcessor,
  useAddTopUpSourceIdMutation,
  useGenerateCardJwtLazyQuery,
  useGetDpayEntityInvestorQuery,
  useIsTopUpSourceExistingLazyQuery,
  useRefreshInvestorBalanceMutation,
  useTopUpInvestorBalanceMutation,
} from 'services/apollo';
import * as math from 'mathjs';
import {
  HTMLElementTypes,
  loadExternalSDK,
  topUpSDKUrlScripts,
  topUpSDKUrlStyles,
} from 'services/core/externalPaymentElements';
import { AppData } from 'services/apollo/core';

// this is so typescript knows that we have installed the payment provider's library and it has added the corresponding function to window
declare global {
  interface Window {
    // Kladot SDK
    AddFunds: (amount: string, token: string, username: string, testMode?: boolean) => unknown;
    // Fortress SDK
    FortressElementsJS: {
      createElementClient: (elementConfig: unknown) => { run: (jwt: string) => unknown };
      ElementNames: { CARD: unknown; KYC: unknown };
    };
  }
}

interface FortressElementMessage {
  type: FORTRESS_SDK_EVENT;
  payload?: FortressElementResult;
}

enum FORTRESS_SDK_EVENT {
  ELEMENT_STARTED = 'ELEMENT_STARTED',
  ELEMENT_EXITED = 'ELEMENT_EXITED',
  ELEMENT_UPDATED = 'ELEMENT_UPDATED',
  ELEMENT_FLOW_DECLINED = 'ELEMENT_FLOW_DECLINED',
}
type FortressElementResult = {
  status: 'initial' | 'success';
  card?: {
    externalAccountId?: string | null;
  };
};

interface ExternalWalletManagementProps {
  appData: AppData;
}

const ExternalWalletManagement: React.FC<ExternalWalletManagementProps> = ({ appData }) => {
  const { t } = useTranslation();
  const [topUpAmount, setTopUpAmount] = useState(0);
  const { data: dpayEntityData, loading: dpayEntityLoading } = useGetDpayEntityInvestorQuery();
  const [generateCardJwt] = useGenerateCardJwtLazyQuery();
  const [isTopUpSourceExisting] = useIsTopUpSourceExistingLazyQuery();
  const [topUpInvestorBalanceMutation] = useTopUpInvestorBalanceMutation();
  const [addTopUpSource] = useAddTopUpSourceIdMutation();
  const [isTopUpButtonDisabled, setIsTopUpButtonDisabled] = useState(false);
  const [updateExternalBalance] = useRefreshInvestorBalanceMutation();
  const [externalBalance, setExternalBalance] = useState(0);
  const [isComponentDisabled, setIsComponentDisabled] = useState(false);
  const handleUpdateExternalBalance = async () => {
    const balData = await updateExternalBalance();
    const bal = balData.data?.refreshInvestorBalance;
    // null balance means the current processor doesn't support balances
    const disableComponent = bal === null;
    setIsComponentDisabled(disableComponent);

    setExternalBalance(bal || 0);
    setIsTopUpButtonDisabled(false);
    setTopUpAmount(0);
  };
  useEffect(() => {
    handleUpdateExternalBalance();
  }, []);

  if (dpayEntityLoading || !dpayEntityData) {
    return <Loading />;
  }
  const dpayEntity = dpayEntityData.getDpayEntityInvestor;
  if (isComponentDisabled || dpayEntity === null) {
    return null;
  }
  if (!dpayEntity) {
    return <Loading />;
  }

  // specific implementations for top up validation of each payment processor/provider
  const validateTopUpAmountActions: {
    [key: string]: (amount: number) => number;
  } = {
    [PaymentProcessor.Kladot]: (amount: number) => {
      // ensure no more than 2 decimal places or kladot breaks. use math library for precision
      const amountTwoDecimals = math.floor(math.multiply(amount, 100)) / 100;
      // ensure amount is at least 0.5 or kladot breaks
      const validatedAmount = Math.max(amountTwoDecimals, 0.5);
      return validatedAmount;
    },
    // just as a placeholder
    [PaymentProcessor.Dwolla]: () => {
      return 0;
    },
    [PaymentProcessor.Fortress]: (amount: number) => {
      return amount;
    },
  };

  // specific implementations for top up procedure of each payment processor/provider
  const balanceTopUpActions: {
    [key: string]: () => unknown;
  } = {
    [PaymentProcessor.Kladot]: async () => {
      const { token, paymentAccountID } = dpayEntity;
      window.AddFunds(topUpAmount.toString(), token, paymentAccountID);
    },
    // just as a placeholder
    [PaymentProcessor.Dwolla]: () => {
      return 0;
    },
    [PaymentProcessor.Fortress]: async () => {
      const handleMessageEvent = async (message: FortressElementMessage) => {
        const success = message.payload?.status === 'success';
        const topUpSourceId = message.payload?.card?.externalAccountId;
        if (message.type === FORTRESS_SDK_EVENT.ELEMENT_UPDATED && success && topUpSourceId) {
          await addTopUpSource({ variables: { topUpSourceId } });
          await topUpViaApi();
        }
      };

      const { data: isCardLinked } = await isTopUpSourceExisting();
      // check if we need to link a new credit/debit card for this investor
      // specifically false, in case the data has not loaded.
      if (isCardLinked?.isTopUpSourceExisting === false) {
        const { data } = await generateCardJwt();
        const cardJwt = data?.generateCardJwt;
        if (cardJwt) {
          const EJS = window.FortressElementsJS;
          const elementClient = EJS.createElementClient({
            elementName: EJS.ElementNames.CARD,
            onMessage: handleMessageEvent,
            theme: { primaryColor: '#D70E0E', secondaryColor: '#CCC' },
            uiLabels: {
              statusScreenButton: 'OK',
            },
          });
          elementClient.run(cardJwt);
        }
      } else if (isCardLinked?.isTopUpSourceExisting === true) {
        await topUpViaApi();
      }
    },
  };

  // check if the actual processor supports top up.
  if (!balanceTopUpActions[dpayEntity.paymentProcessor]) {
    return null;
  }

  const topUpViaApi = async () => {
    await topUpInvestorBalanceMutation({ variables: { amount: topUpAmount } });
  };

  const isTopUpAmountValid = (): boolean => {
    return validateTopUpAmountActions[dpayEntity.paymentProcessor](topUpAmount) === topUpAmount;
  };

  const externalBalanceTopUp = () => {
    setIsTopUpButtonDisabled(true);
    if (isTopUpAmountValid()) {
      const balanceTopUp = async () => {
        await balanceTopUpActions[dpayEntity.paymentProcessor]();
        await handleUpdateExternalBalance();
      };
      loadExternalSDK(topUpSDKUrlStyles[dpayEntity.paymentProcessor], HTMLElementTypes.LINK);
      loadExternalSDK(
        topUpSDKUrlScripts[dpayEntity.paymentProcessor](appData.isPaymentProcessorSandbox),
        HTMLElementTypes.SCRIPT,
        balanceTopUp,
      );
    }
  };

  const handleTopUpAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseFloat(e.currentTarget.value);
    // validate the entered amount according to the payment processor's requirements
    const validatedAmount = validateTopUpAmountActions[dpayEntity.paymentProcessor](value);
    setTopUpAmount(validatedAmount);
  };

  return (
    <Row className="mb-3">
      <Col md={3}>
        <h4 className="mt-0 pb-2"> {t('WalletManagement-ExternalBalance')} </h4>
        <p>
          {(externalBalance || 0).toLocaleString(i18n.language, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </p>
      </Col>
      <Col md={3}>
        <Row align-items-center>
          <Label>{t('WalletManagement-TopUpAmountLabel')}</Label>
        </Row>
        <Row>
          <Col md={4}>
            <Input value={topUpAmount} type="number" onChange={handleTopUpAmountChange} />
          </Col>
          <Col md={6}>
            <Button disabled={isTopUpButtonDisabled} onClick={externalBalanceTopUp} height>
              {' '}
              {t('WalletManagement-TopUpAmountButton')}{' '}
            </Button>
          </Col>
        </Row>
      </Col>
      <Col md={2}>
        <Row />
      </Col>
    </Row>
  );
};

export default ExternalWalletManagement;
