import React from 'react';

import { useInvestorAppDataQuery } from 'services/apollo';

import { Loading } from 'atoms';
import StoSelect from './components/StoSelect';
import ExchangeOrders from './components/trading/ExchangeOrders';
import InternalExchange from './components/internal-exchange/InternalExchange';
import MyBuyOrders from './components/my-orders/MyBuyOrders';
import MySellOrders from './components/my-orders/MySellOrders';
import MyBuyOffers from './components/my-offers/MyBuyOffers';
import MySellOffers from './components/my-offers/MySellOffers';
import MyTransactions from './components/my-transactions/MyTransactions';

const TradingPage: React.FC = () => {
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  if (appDataLoading || !appData) {
    return <Loading />;
  }
  const { isTradingBuyOrdersEnabled } = appData.investorAppParameters;

  return (
    <div className="content">
      <StoSelect />
      <ExchangeOrders />
      <InternalExchange isTradingBuyOrdersEnabled={isTradingBuyOrdersEnabled} />
      {isTradingBuyOrdersEnabled ? <MyBuyOrders /> : null}
      <MySellOrders />
      <MyBuyOffers />
      <MySellOffers />
      <MyTransactions />
    </div>
  );
};

export default TradingPage;
