import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

import {
  useCreateNewBuyOrderMutation,
  useFetchFeesQuery,
  FeeBeneficiary,
  FeeType,
  CommissionType,
  useGetSharesWalletsQuery,
  useFindShareTypesQuery,
} from 'services/apollo';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

import { CardHeader } from 'components/card-header/CardHeader';
import {
  Button,
  Card,
  CardBody,
  BrandIcon,
  Col,
  Form,
  Input,
  Row,
  GrayDot,
  DatePicker,
  BsSwal,
  FormGroup,
  Label,
  FontAweIcon,
} from 'atoms';
import useBuyOrderState from './components/new-buy-order/useBuyOrderState';
import ShareTypeSelect from './components/new-buy-order/ShareTypeSelect';
import SellOrderOption from './components/new-sell-order/SellOrderOptionLabel';

const NewBuyOrderPage: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { order, dates, empty, onChange, onChangeDate, checkEmpty } = useBuyOrderState();
  const [symbol, setSymbol] = useState<string>('');
  const [abbreviation, setAbbreviation] = useState<string>('');
  const [stoID, setStoID] = useState(0);
  const [price, setPrice] = useState(0);
  const [currencyAddress, setCurrencyAddress] = useState('');
  const [totalAfterFee, setTotalAfterFee] = useState(0);

  const { data: feeData } = useFetchFeesQuery({
    variables: { stoID, beneficiary: FeeBeneficiary.Platform, type: FeeType.BuyExchange },
    fetchPolicy: 'network-only',
  });

  const { data, loading } = useGetSharesWalletsQuery({
    variables: { shareTypeID: order.shareTypeID },
  });

  const { data: shareTypeData, loading: shareTypeLoading } = useFindShareTypesQuery({
    variables: { ID: order.shareTypeID },
    fetchPolicy: 'network-only',
  });

  const [feeInfo] = feeData?.fetchFees || [];

  useEffect(() => {
    if (price && order.shares) {
      const total: number = price * order.shares;
      setTotalAfterFee(total);
      if (feeInfo) {
        if (feeInfo.status === CommissionType.Flat) {
          setTotalAfterFee(total + feeInfo.amount);
        } else {
          const feePrice = (feeInfo.amount * total) / 100;
          setTotalAfterFee(total + feePrice);
        }
      }
      onChange({ rateFrom: total });
    } else {
      setTotalAfterFee(0);
      onChange({ rateFrom: 0 });
    }
  }, [price, order.shares, feeInfo]);

  const [createBuyOrder] = useCreateNewBuyOrderMutation();

  const goBack = (): void => history.push(`/investor/trading`);

  if (loading || !data || shareTypeLoading || !shareTypeData) {
    return null;
  }

  const { getSharesWallets: wallets } = data;
  const [selectedShareType] = shareTypeData.findShareTypes;

  const handleBuy = (): void => {
    if (checkEmpty()) {
      return;
    }
    if (!selectedShareType.isInvestorTradable) {
      BsSwal.fire({
        title: t('InternalExchangeActionCell-NotTradablePopUp-Title'),
        text: t('InternalExchangeActionCell-NotTradablePopUp-Description'),
        icon: 'error',
      });
      return;
    }
    BsSwal.fire({
      title: t('NewBuyOrderPage-BsSwal-OrderConfirmation'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('NewBuyOrderPage-BsSwal-Confirm'),
      cancelButtonText: t('NewBuyOrderPage-BsSwal-Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        createBuyOrder({ variables: { order } })
          .then(() => {
            BsSwal.fire({
              title: t('NewBuyOrderPage-BsSwal-OrderCreated'),
              icon: 'success',
            });
            goBack();
          })
          .catch((err) => {
            BsSwal.fire({
              icon: 'error',
              text: err.message,
            });
          });
      }
    });
  };

  const handleChange = (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e) => {
      // expect NaN
      const value = parseFloat(e.currentTarget.value) || 0;
      onChange({ [key]: value });
    };
  };
  if (currencyAddress) {
    order.atomicSwapTokenAddressAcceptable = currencyAddress;
  }

  return (
    <Col className="content">
      <Card>
        <CardHeader text={t('NewBuyOrderPage-card-header-text')} icon={<BrandIcon icon="info" color="cyan" pill />} />
        <CardBody className="mb-2">
          <Form>
            <Row>
              <Col xs="auto">
                <GrayDot big />
              </Col>
              <Col>
                <label>{t('NewBuyOrderPage-label-shareType')}</label>
              </Col>
            </Row>
            <ShareTypeSelect
              invalid={empty.includes('shareTypeID')}
              value={order.shareTypeID}
              onChange={(shareTypeID, newSymbol, newAbbreviation, relatedStoID, currency) => {
                onChange({ shareTypeID });
                setStoID(relatedStoID);
                setSymbol(newSymbol);
                setAbbreviation(newAbbreviation);
                setCurrencyAddress(currency);
              }}
            />
            <br />
            {selectedShareType.isBlockchain ? (
              <Alert color="warning">
                <label>
                  <FontAweIcon icon="info-circle" size="lg" /> {t('NewBuyOrderPage-label-DefaultWallet')}
                </label>
              </Alert>
            ) : null}
            {symbol !== '' && selectedShareType.isBlockchain
              ? wallets.map((wallet) => (
                  <SellOrderOption
                    isChecked={false}
                    key={wallet.ID}
                    id={`option-share-${wallet.ID}`}
                    shareLabel={t('SellOrderSharesSelect-label-walletAddress')}
                    shareAmountText={
                      wallet.publicKey === 'platform'
                        ? t('SellOrderSharesSelect-text-InternalWallet')
                        : wallet.publicKey || ''
                    }
                    label2={t('SellOrderSharesSelect-label-shareInThisWallet')}
                    text2={`${wallet.shares}`}
                    onChange={() => onChange({ shareTypeID: order.shareTypeID, atomicSwapSharesWalletID: wallet.ID })}
                  />
                ))
              : null}
            <>
              <Row>
                <Col xs="auto">
                  <GrayDot big />
                </Col>
                <Col>
                  <p>{t('NewBuyOrderPage-label-date-description')}</p>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <FormGroup>
                    <Label>{t('NewBuyOrderPage-label-openDate')}</Label>
                    <DatePicker
                      onChange={(dateFrom: Date) => onChangeDate({ dateFrom })}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      invalid={empty.includes('dateFrom')}
                      dateFormat="dd.MM.yyyy"
                      showPopperArrow={false}
                      selected={dates.dateFrom}
                      selectsStart
                      startDate={dates.dateFrom}
                      endDate={dates.dateTo}
                      minDate={new Date()}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup>
                    <Label>{t('NewBuyOrderPage-label-closeDate')}</Label>
                    <DatePicker
                      onChange={(dateTo: Date) => onChangeDate({ dateTo })}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      invalid={empty.includes('dateTo')}
                      dateFormat="dd.MM.yyyy"
                      showPopperArrow={false}
                      selected={dates.dateTo}
                      startDate={dates.dateFrom}
                      endDate={dates.dateTo}
                      minDate={dates.dateFrom}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <br />
              <Row>
                <Col xs="auto" md={3}>
                  <FormGroup>
                    <Label>
                      <b>{t('NewBuyOrderPage-label-shareCount')}</b>
                    </Label>
                    <Input invalid={empty.includes('shares')} onChange={handleChange('shares')} value={order.shares} />
                  </FormGroup>
                </Col>
                <Col xs="auto" md={3}>
                  <FormGroup>
                    <Label>
                      <b>{t('NewBuyOrderPage-label-price')}</b>
                    </Label>
                    <Input
                      invalid={empty.includes('rateFrom')}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPrice(parseFloat(e.currentTarget.value) || 0)
                      }
                      value={price}
                    />
                  </FormGroup>
                </Col>
                <Col xs="auto" md={2}>
                  <FormGroup>
                    <Label className="mb-1">
                      <b>{t('NewBuyOrderPage-label-rateFrom')}</b>
                    </Label>
                    <h4 className="m-0">
                      <CurrencySymbolDisplay value={order.rateFrom} currency={{ symbol, abbreviation }} />
                    </h4>
                  </FormGroup>
                </Col>
                <Col xs="auto" md={2}>
                  <FormGroup>
                    <Label className="mb-1">
                      <b>{t('NewBuyOrderPage-label-marketplaceFee')}</b>
                    </Label>
                    <h4 className="m-0">
                      <CurrencySymbolDisplay
                        value={feeInfo?.amount ?? 0}
                        currency={{
                          symbol: feeInfo?.status === CommissionType.Percentage ? '%' : symbol,
                          abbreviation: feeInfo?.status === CommissionType.Percentage ? '%' : abbreviation,
                        }}
                      />
                    </h4>
                  </FormGroup>
                </Col>
                <Col xs="auto" md={2}>
                  <FormGroup>
                    <Label className="mb-1">
                      <b>{t('NewBuyOrderPage-label-totalAfterFee')}</b>
                    </Label>
                    <h4 className="m-0">
                      <CurrencySymbolDisplay value={totalAfterFee} currency={{ symbol, abbreviation }} />
                    </h4>
                  </FormGroup>
                </Col>
              </Row>
              {order.atomicSwapSharesWalletID ? (
                <Col>
                  <FormGroup>
                    <Label>
                      <b>{t('SellOrderSharesSelect-label-currency')}</b>
                    </Label>
                    {symbol}
                  </FormGroup>
                </Col>
              ) : null}
              <Row>
                <Col md={6}>
                  <Button size="m" onClick={handleBuy} className="mr-2">
                    {t('NewBuyOrderPage-button-buy')}
                  </Button>
                  <Button size="m" onClick={goBack}>
                    {t('NewBuyOrderPage-button-cancel')}
                  </Button>
                </Col>
              </Row>
            </>
          </Form>
        </CardBody>
      </Card>
    </Col>
  );
};

export default NewBuyOrderPage;
