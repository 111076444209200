import React, { ChangeEvent, useEffect, useState } from 'react';
import { BsSwal, Button, Card, CardBody, Col, FormGroup, Input, Row, Loading } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { Form, Label } from 'reactstrap';
import BANK from 'assets/img/bank.png';
import {
  GetInvestorBankAccountDocument,
  InvestorBankAccountInput,
  useAddOrEditInvestorBankAccountMutation,
  useGetInvestorBankAccountQuery,
} from 'services/apollo';
import { useTranslation } from 'react-i18next';
import { FetchBankData } from 'services/apollo/investorbanks';

const fillState = (bankData?: FetchBankData | undefined): InvestorBankAccountInput => {
  return {
    accountTitle: bankData?.accountTitle || '',
    accountNo: bankData?.accountNo || '',
    routingNumber: bankData?.routingNumber || '',
    iban: bankData?.iban || '',
    accountHolderName: bankData?.accountHolderName || '',
    accountHolderCity: bankData?.accountHolderCity || '',
    accountHolderCountry: bankData?.accountHolderCountry || '',
    accountHolderAddress: bankData?.accountHolderAddress || '',
    accountPostalCode: bankData?.accountPostalCode || '',
    bankName: bankData?.bankName || '',
    bankCity: bankData?.bankCity || '',
    bankCountry: bankData?.bankCountry || '',
    bankAddress: bankData?.bankAddress || '',
    swift: bankData?.swift || '',
  };
};

const BankAccount: React.FC = () => {
  const { t } = useTranslation();
  const [details, setDetails] = useState(fillState());
  const { data: bankData, loading: bankDataLoading } = useGetInvestorBankAccountQuery();

  const [addAccount] = useAddOrEditInvestorBankAccountMutation({
    refetchQueries: [{ query: GetInvestorBankAccountDocument }],
  });

  useEffect(() => {
    if (bankData?.getInvestorBankAccount) {
      setDetails(fillState(bankData?.getInvestorBankAccount));
    }
  }, [bankData, bankDataLoading]);

  if (!bankData || bankDataLoading) {
    return <Loading />;
  }

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newState = { [e.currentTarget.name]: e.currentTarget.value };
    setDetails((prevState) => ({ ...prevState, ...newState }));
  };

  const onSubmit = () => {
    addAccount({
      variables: { data: details },
    })
      .then(() => {
        BsSwal.fire({
          title: t('BankAccounts-success-text-on-creation'),
          icon: 'success',
        });
      })
      .catch((err) =>
        BsSwal.fire({
          title: err,
          icon: 'success',
        }),
      );
  };

  return (
    <Card>
      <CardHeader
        text={t('BankAccount-card-header-text')}
        imgSrc={BANK}
        caption={t('BankAccount-card-header-caption')}
      />
      <CardBody>
        <Form>
          <Row>
            <Col>
              <FormGroup>
                <Label for="bankName">{t('BankAccount-label-bankName')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="bankName"
                  placeholder={t('BankAccount-placeholder-bankName')}
                  value={details.bankName}
                  name="bankName"
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="bankCity">{t('BankAccount-label-bankCity')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="bankCity"
                  name="bankCity"
                  placeholder={t('BankAccount-placeholder-bankCity')}
                  value={details.bankCity}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="bankCountry">{t('BankAccount-label-bankCountry')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="bankCountry"
                  name="bankCountry"
                  placeholder={t('BankAccount-placeholder-bankCountry')}
                  value={details.bankCountry}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="bankAddress">{t('BankAccount-label-bankAddress')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="bankAddress"
                  name="bankAddress"
                  placeholder={t('BankAccount-placeholder-bankAddress')}
                  value={details.bankAddress}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={3}>
              <FormGroup>
                <Label for="exampleEmail">{t('BankAccount-label-holder-name')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountHolderName"
                  name="accountHolderName"
                  placeholder={t('BankAccount-placeholder-holder-name')}
                  value={details.accountHolderName}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">{t('BankAccount-label-holder-city')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountHolderCity"
                  name="accountHolderCity"
                  placeholder={t('BankAccount-placeholder-holder-city')}
                  value={details.accountHolderCity}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">{t('BankAccount-label-holder-country')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountHolderCountry"
                  name="accountHolderCountry"
                  placeholder={t('BankAccount-placeholder-holder-country')}
                  value={details.accountHolderCountry}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">{t('BankAccount-label-holder-address')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountHolderAddress"
                  name="accountHolderAddress"
                  placeholder={t('BankAccount-placeholder-holder-address')}
                  value={details.accountHolderAddress}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="examplePassword">{t('BankAccount-label-zip')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountPostalCode"
                  name="accountPostalCode"
                  placeholder={t('BankAccount-placeholder-holder-zip')}
                  value={details.accountPostalCode}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="exampleAddress">{t('BankAccount-label-accountTitle')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountTitle"
                  name="accountTitle"
                  placeholder={t('BankAccount-placeholder-accountTitle')}
                  value={details.accountTitle}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="exampleAddress">{t('BankAccount-label-accountNo')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="accountNo"
                  name="accountNo"
                  value={details.accountNo}
                  placeholder={t('BankAccount-placeholder-accountNo')}
                />
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col>
              <FormGroup>
                <Label for="exampleAddress">{t('BankAccount-label-routingNumber')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="routingNumber"
                  name="routingNumber"
                  value={details.routingNumber}
                  placeholder={t('BankAccount-placeholder-routingNumber')}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="exampleAddress">{t('BankAccount-label-iban')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="iban"
                  name="iban"
                  placeholder={t('BankAccount-placeholder-iban')}
                  value={details.iban}
                />
              </FormGroup>
            </Col>
            <Col>
              <FormGroup>
                <Label for="swift">{t('BankAccount-label-swift')}</Label>
                <Input
                  onChange={handleInputChange}
                  id="swift"
                  name="swift"
                  placeholder={t('BankAccount-placeholder-swift')}
                  value={details.swift}
                />
              </FormGroup>
            </Col>
          </Row>
          <Button onClick={onSubmit}>
            {bankData.getInvestorBankAccount ? t('save') : t('BankAccount-button-add')}
          </Button>
        </Form>
      </CardBody>
    </Card>
  );
};

export default BankAccount;
