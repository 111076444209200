import React, { ReactNode } from 'react';
import { generatePath, Link as RouterLink, Redirect as RouterRedirect } from 'react-router-dom';

type Params = { [x: string]: string | number | boolean | undefined };

type LinkParams = Params & { children?: ReactNode };

export interface IRoute {
  path: string;
  Component: React.ComponentType;
  featureFlagName?: string;
}

class Route {
  static routes: string[] = [];

  path: string;

  Component: React.ComponentType;

  featureFlagName = '';

  constructor({ path, Component, featureFlagName = '' }: IRoute) {
    this.path = path;
    this.Component = Component;
    this.featureFlagName = featureFlagName;

    if (Route.routes.includes(path)) {
      console.error(`There are routes with the same path: ${path}`);
      return;
    }

    Route.routes.push(path);
  }

  Link: (params: LinkParams) => React.ReactElement = ({ children, ...params }) => {
    return <RouterLink to={this.getPath(params)}>{children}</RouterLink>;
  };

  Redirect: (params?: Params) => React.ReactElement = (params) => {
    return <RouterRedirect to={this.getPath(params)} />;
  };

  getPath(params?: Params): string {
    return generatePath(this.path, params);
  }

  getRoute(): React.ReactElement {
    console.error('You should use another route class');
    return <></>;
  }
}

export default Route;
