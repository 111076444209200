import React from 'react';
import { CardHeader as CardHead } from 'reactstrap';
import styled from 'styled-components';

interface CardHeaderProps {
  text: string;
  caption?: string;
  imgSrc?: string;
  size?: string;
  icon?: React.ReactElement;
  rightButton?: React.ReactElement;
  captionFontSize?: string;
  fontWeight?: string;
}

export const CardHeader: React.FC<CardHeaderProps> = ({
  text,
  caption = '',
  imgSrc = '',
  size = '',
  icon = null,
  rightButton = null,
  captionFontSize = '0.8571em',
  fontWeight,
}) => {
  return (
    <CardHead>
      <CardH4 fontWeight={fontWeight}>
        {icon ? <span className="mr-2">{icon}</span> : null}
        {imgSrc && (
          <CardIcon size={size} src={imgSrc} alt={imgSrc.slice(imgSrc.lastIndexOf('/') + 1, imgSrc.length - 4)} />
        )}
        {text}
        {rightButton && <span className="ml-auto">{rightButton}</span>}
      </CardH4>
      {caption && <CardCaption style={{ fontSize: captionFontSize }}>{caption}</CardCaption>}
    </CardHead>
  );
};

const CardCaption = styled.span`
  margin-bottom: 5px;
  color: #9a9a9a;
`;

// const CardIcon = styled(({ size, ...props }) => <img {...props} />)`    size is not being used
const CardIcon = styled(({ ...props }) => <img {...props} />)`
  width: ${(p) => p.size || '25px'};
  height: ${(p) => p.size || '25px'};
  margin-right: 8px;
`;

export const CardH4 = styled.h4<{ fontWeight?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  word-break: break-word;
  margin-top: 0px;
  margin-bottom: 0;

  ${(p) => {
    if (p.fontWeight) {
      return `
          font-weight: ${p.fontWeight};
       `;
    }
  }}
`;
