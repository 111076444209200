import Card from 'atoms/Card';
import { OFFERINGHTMLIDS } from 'pages/active-properties/ActiveProperties';
import styled from 'styled-components';

export const StyledCard = styled(Card)<{ navid: OFFERINGHTMLIDS }>`
  padding: 1rem;
  max-width: 300px;
  display: flex;
  justify-content: space-between;
  gap: 0.5rem;
  cursor: ${(p) => {
    return p.canBeActive ? `pointer` : `default`;
  }};
  background-color: ${(p) => p.theme.offeringsDBCardBGColor};
  box-shadow: none;

  border-left: ${(p) => {
    switch (p.navid) {
      case OFFERINGHTMLIDS.ACTIVE_OFFERING:
        return `3px solid ${p.theme.offeringsDBActiveCardBorderColor}`;
      case OFFERINGHTMLIDS.CLOSED_OFFERING:
        return `3px solid ${p.theme.offeringsDBClosedCardBorderColor}`;
      case OFFERINGHTMLIDS.PROFILE_MATCHES:
        return `3px solid ${p.theme.offeringsDBProfileCardBorderColor}`;
      default:
        return `3px solid ${p.theme.offeringsDBActiveCardBorderColor}`;
    }
  }};

  .item-header {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .header-medium {
      color: ${(p) => p.theme.offeringsDBCardFontColor};
      word-wrap: break-word;
      font-size: 22px;
      font-weight: 500;
    }
    span {
      min-width: max-content;
      color: ${(p) => p.theme.offeringsDBCardViewFontColor};
    }
  }

  &.active {
    background-color: ${(p) => p.theme.offeringsDBActiveCardBGColor};
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    cursor: default;

    .item-header {
      h5 {
        color: ${(p) => p.theme.offeringsDBActiveCardFontColor};
      }
    }

    span {
      color: ${(p) => p.theme.offeringsDBActiveCardFontColor};
    }
  }

  &:hover {
    ${(p) => {
      if (p.canBeActive) {
        return `{
          background-color: ${p.theme.offeringsDBActiveCardBGColor};
          box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
          transition: all 150ms ease-in;
        }`;
      }
    }}
  }

  @media (max-width: 1400px) {
    gap: 0;
  }

  span {
    margin-bottom: 0;
    color: ${(p) => p.theme.offeringsDBCardFontColor};
  }
`;
