import React, { ChangeEvent, useEffect, useState } from 'react';
import { QueryInfo } from '@apollo/client/core/QueryInfo';

import {
  InvestorDepositWithdrawAlertInput,
  InvestorWalletDocument,
  useFindBlockchainQuery,
  useInvestorAppDataQuery,
  useInvestorDepositWithdrawAlertMutation,
  useUpdateInvoiceInvestorMutation,
} from 'services/apollo/graphql';
import { InvestorInvoiceAlert, InvestorWalletChannel } from 'services/apollo/multisto';
import { useGqlErrorExtractor } from 'hooks';
import PaymentModalSwitch from 'pages/payment-channels/PaymentModalSwitch';

import { useHistory } from 'react-router-dom';
import { BsSwal, Loading } from 'atoms';
import { useTranslation } from 'react-i18next';
import WithdrawFormBlockchain from './withdrawForms/WithdrawFormBlockchain';
import WithdrawFormBank from './withdrawForms/WithdrawFormBank';

interface DepositWithdrawFormManagementProps {
  channel: InvestorWalletChannel;
  stoID: number;
  isWithdraw: boolean;
  investorID: number;
  hideModal: () => void;
  invoice?: InvestorInvoiceAlert;
  shareValue?: number;
  defaultAmount?: number;
}

const fillState = (channelID = 0, stoID = 0): InvestorDepositWithdrawAlertInput => {
  return {
    stoID,
    channelID: channelID ?? 0,
    amount: 0,
    details: '',
    bankName: '',
    swiftCode: '',
    bankAccount: '',
    transactionID: '',
    isWithdrawRequest: false,
    transactionHash: undefined,
  };
};

const DepositWithdrawFormManagement: React.FC<DepositWithdrawFormManagementProps> = ({
  channel,
  stoID,
  investorID,
  isWithdraw,
  hideModal,
  invoice,
  shareValue,
  defaultAmount,
}) => {
  const { t } = useTranslation();
  const isBlockchain = channel.currency?.isBlockchainBased ?? false;
  const [state, setState] = useState(fillState(channel.ID, stoID));
  const [error, setGqlError] = useGqlErrorExtractor(fillState(channel.ID, stoID));
  const [investorAlert] = useInvestorDepositWithdrawAlertMutation({
    refetchQueries: [{ query: InvestorWalletDocument, variables: { _id: stoID } }],
  });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  const history = useHistory();
  const { data: blockchainData, loading: blockchainDataLoading } = useFindBlockchainQuery({
    variables: { id: channel.currency.blockchainID },
  });
  const [payWithManualDeposit] = useUpdateInvoiceInvestorMutation();

  useEffect(() => {
    // always link the payment channel to the invoice, when this modal is opened
    if (invoice?.ID) {
      payWithManualDeposit({
        variables: {
          invoiceID: invoice?.ID,
          paymentChannelID: state.channelID,
        },
      }).catch((e) => console.error(e));
    }
  }, [invoice]);

  if (appDataLoading || !appData || blockchainDataLoading || (!blockchainData && isBlockchain)) {
    return <Loading />;
  }
  const { isFastTrackPaymentEnabled } = appData.investorAppParameters;

  const onChange = (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
    const newState = { [e.currentTarget.name]: e.currentTarget.value };
    setState((prevState) => ({ ...prevState, ...newState }));
  };

  const onSubmit = async (isWithdrawRequest = false, title: string) => {
    const data = {
      ...state,
      isWithdrawRequest,
      amount: defaultAmount ?? Number(state.amount),
      buyAlertID: invoice?.buyAlertID,
      invoiceID: invoice?.ID,
    };

    investorAlert({ variables: { data } })
      .then(() => {
        hideModal();
        return BsSwal.fire({
          title,
          icon: 'success',
        }).then(() => isFastTrackPaymentEnabled && history.push(`/investor/portfolio`));
      })
      .catch((err: QueryInfo) => {
        setGqlError(err);
      });
  };

  if (isWithdraw) {
    const title = t(`walletManagement-depositWithDrawForm-popup-transactionSubmitted-withdraw`);
    if (isBlockchain) {
      return (
        <WithdrawFormBlockchain
          hideModal={hideModal}
          channel={channel}
          investorID={investorID}
          state={state}
          error={error}
          onChange={onChange}
          onSubmit={() => onSubmit(true, title)}
        />
      );
    }
    return (
      <WithdrawFormBank
        hideModal={hideModal}
        channel={channel}
        investorID={investorID}
        state={state}
        error={error}
        onChange={onChange}
        onSubmit={() => onSubmit(true, title)}
      />
    );
  }

  return (
    <PaymentModalSwitch
      stoID={stoID}
      investorID={investorID}
      state={state}
      onSubmit={onSubmit}
      onChange={onChange}
      appData={appData.investorAppParameters}
      channel={channel}
      hideModal={hideModal}
      invoice={invoice}
      isBlockchain={isBlockchain}
      shareValue={shareValue}
      blockchain={blockchainData?.findBlockchain}
      defaultAmount={defaultAmount}
    />
  );
};

export default DepositWithdrawFormManagement;
