import React from 'react';
import { useTranslation } from 'react-i18next';

import styled, { css } from 'styled-components';
import { MetaValues, StoMetaValue } from 'services/apollo';
import { Card } from 'atoms';
import MetaContent from 'pages/active-properties/components/MetaContent';

const OfferingOverviewContent: React.FC<{ detail: StoMetaValue[] | MetaValues[]; noMetakeys?: boolean }> = ({
  detail,
  noMetakeys = false,
}) => {
  const { t } = useTranslation();

  const content = detail.filter((x) => x.key === 'project_overview');

  if (!content[0]?.value) {
    return <></>;
  }

  return (
    <StyledOverview noMetakeys={noMetakeys}>
      <h5 className="detail-overview__title">{t('OfferingOverviewContent-title')} </h5>
      <StyledCard>
        <MetaContent meta={detail} currentMeta={content} name="project_overview" rightLayout />
      </StyledCard>
    </StyledOverview>
  );
};

export default OfferingOverviewContent;

const StyledCard = styled(Card)`
  padding: 1rem;
  background-color: ${(p) => p.theme.offeringDetailsCardBGColor};
`;

const StyledOverview = styled.div<{ noMetakeys?: boolean }>`
  .detail-overview__title {
    font-weight: 500;
    font-size: 20px;
  }

  ${(p) => {
    if (p.noMetakeys) {
      return css`
        margin-top: 4rem;
      `;
    }
  }}
`;
