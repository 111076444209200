import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import { FixedPluginRow } from 'components/fixed-plugin/components';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const NavbarCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const iconHoverColor = [colors.blue, colors.marketSpaceBlue, colors.black];
  const sidebarFontsColor = [colors.white, colors.textGrey, colors.black];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-Navbar')}</h5>
        <SettingsBarCarcet className="ti-angle-left" open={collapse} />
      </div>
      <Collapse isOpen={collapse}>
        <FixedPluginRow
          property="extendedNavigationBar"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ExtendNav"
          switchValue={theme.extendedNavigationBar}
          popOver="FixedPluginRow-PopOver-ExtendNav"
        />

        <FixedPluginRow
          values={iconHoverColor}
          property="navBarHoverColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-NavbarItemsHoverColor"
          currentColor={theme.navBarHoverColor}
        />
        <h6 className="settings-content__item--subtitle">{t('FixedPluginRow-SubTitle-Navbar-ProfileIcon')}</h6>
        <FixedPluginRow
          values={iconHoverColor}
          property="profileIconBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-NavbarProfileIconBGColor"
          popOver="FixedPluginRow-PopOver-NavbarProfileIconBGColor"
          currentColor={theme.profileIconBGColor}
        />
        <FixedPluginRow
          values={iconHoverColor}
          property="profileIconBHoverColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-NavbarProfileIconHoverColor"
          currentColor={theme.profileIconBHoverColor}
        />
        <FixedPluginRow
          values={sidebarFontsColor}
          property="profileIconFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-NavbarProfileIconFontColor"
          currentColor={theme.profileIconFontColor}
        />
      </Collapse>
    </div>
  );
};

export default NavbarCustomizer;
