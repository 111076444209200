import React from 'react';
import { Cell } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';

import { OfferStatus, useDeleteOfferMutation } from 'services/apollo';
import { InvestorExchangeOffer } from 'services/apollo/exchange';

import { BsSwal, Button } from 'atoms';
import StatusBadge from '../StatusBadge';

const MyOfferActionCell: React.FC<Cell<InvestorExchangeOffer>> = ({ row: { original } }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteOffer] = useDeleteOfferMutation();

  const { ID } = original.exchangeOrder;
  const { status } = original;

  const handleDetails = (): void => {
    history.push(`/investor/offer-detail/${ID}`);
  };

  const handleDelete = (): Promise<void> => {
    return deleteOffer({ variables: { orderID: ID } })
      .then(() => {
        history.go(0);
      })
      .catch((err) => {
        BsSwal.fire({
          icon: 'error',
          title: err.message,
        });
      });
  };

  const renderActionCell = (): React.ReactElement => {
    switch (status) {
      case OfferStatus.Pending: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="pending">{t('MyOfferActionCell-StatusLabel-Pending')}</StatusBadge>
            <ActionButton delete size="sm" onClick={handleDelete}>
              <i className="ti-trash" />
            </ActionButton>
            <ActionButton size="sm" onClick={handleDetails}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
      case OfferStatus.Succeeded: {
        return <StatusBadge color="succeeded">{t('MyOfferActionCell-StatusLabel-Succeeded')}</StatusBadge>;
      }
      case OfferStatus.Rejected: {
        return <StatusBadge color="rejected">{t('MyOfferActionCell-StatusLabel-Rejected')}</StatusBadge>;
      }
      case OfferStatus.AwaitingBuyerPayment: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="inProgress">{t('MyOfferActionCell-StatusLabel-AWAITING_BUYER_PAYMENT')}</StatusBadge>
            <ActionButton size="sm" onClick={handleDetails}>
              {t('MyOfferActionCell-button-pay')}
            </ActionButton>
          </div>
        );
      }
      default: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="inProgress">{t('MyOfferActionCell-StatusLabel-Accepted')}</StatusBadge>
            <ActionButton size="sm" onClick={handleDetails}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
    }
  };

  return <>{renderActionCell()}</>;
};

const ActionButton = styled(Button)<{ delete: string }>`
  color: ${(p) => p.theme.marketPlaceIconFontColor};
  font-size: 18px;
  background: ${(p) => p.theme.marketPlaceIconBGColor};
  ${(p) => {
    if (p.delete) {
      return css`
        background-color: ${p.theme.marketPlaceDeleteIconBGColor};
        color: ${p.theme.marketPlaceDeleteIconFontColor};
      `;
    }
  }}
`;

export default MyOfferActionCell;
