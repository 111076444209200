import { ThemeType } from 'lib/themes/themeTypes';
import React, { useState } from 'react';
import { Input } from 'reactstrap';
import { Markup } from 'interweave';
import PopoverHover from 'components/PopoverHover';
import { useTranslation } from 'react-i18next';

interface CustomFontProps {
  handleChange: (property: keyof ThemeType, cl: string | boolean) => void;
  currentFont: string;
}

const CustomFont: React.FC<CustomFontProps> = ({ handleChange, currentFont }) => {
  const { t } = useTranslation();
  const [value, setValue] = useState(currentFont);

  return (
    <div className="settings-content__item--row">
      <div className="settings-content__item--row-title">
        <p>{t('FixedPluginRow-Title-ApplicationCustomFont')}</p>
        <PopoverHover
          popoverID="customFont"
          label=""
          target={<i className="ti-info-alt pl-1 pop-over-container" />}
          body={<Markup content={t('FixedPluginRow-PopOver-ApplicationCustomFont')} />}
        />
      </div>
      <div className="settings-content__item--row-action">
        <Input
          value={value}
          name="customFont"
          onChange={(e: { currentTarget: { value: string } }) => {
            setValue(e.currentTarget.value);
            handleChange('appFont', e.currentTarget.value);
          }}
        />
      </div>
    </div>
  );
};

export default CustomFont;
