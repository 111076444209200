import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BuyAlertStatus,
  KycRequirementStep,
  useInvestorActiveStoQuery,
  useInvestorAppDataQuery,
  useInvestorBuyAlertsQuery,
  useMeQuery,
  useInvestorShareQuery,
  useCreateLogMutation,
  LogAction,
  useGetInvestorInvestingEntityPermissionsQuery,
} from 'services/apollo';
import { ActivePropertyItem } from 'services/apollo/multisto';

import { BsSwal } from 'atoms';
import { useTranslation } from 'react-i18next';
import { canSeeNDAData, canSeeNDADataRequired, verifyInvestorKeylessBsSwal } from 'services/core/helpers';
import { useGetPortfolioStoData } from 'hooks/useGetPortfolioStoData';

import SkeletonActivityProperty from './components/skeletons/SkeletonActivityProperty';
import MarketSpaceCard from './components/previewCards/MarketSpaceCard';
import MPXCard from './components/previewCards/MPXCard';
import MPXCardNoImg from './components/previewCards/MPXCardNoImg';

const ActiveProperty: React.FC<{
  props: ActivePropertyItem;
  isClosedOffering?: boolean;
  isFilteredOffering?: boolean;
}> = ({ props, isClosedOffering, isFilteredOffering }) => {
  const [isOpen, setOpen] = useState(false);
  const { ID, picture, isBuyButtonEnabled, isNDAFlowEnabled, stoDetailsInvestNowLink, isInvestorBuyEnabled } = props;
  const history = useHistory();
  const { data, loading } = useMeQuery({ fetchPolicy: 'network-only' });
  const { data: investorSto, loading: investorStoLoading } = useInvestorActiveStoQuery({ variables: { _id: ID } });
  const { data: appData } = useInvestorAppDataQuery();
  const { t } = useTranslation();

  const portfolioSto = useGetPortfolioStoData({ stoID: ID });
  const { isBuyStoEnabled } = portfolioSto;

  const { data: shares, loading: ShareLoading } = useInvestorShareQuery({
    variables: {
      stoID: ID,
    },
  });
  const { data: Alerts } = useInvestorBuyAlertsQuery({
    variables: {
      status: [
        BuyAlertStatus.Pending,
        BuyAlertStatus.KycRequired,
        BuyAlertStatus.AccreditationRequired,
        BuyAlertStatus.PendingDocuments,
      ],
    },
    fetchPolicy: 'no-cache',
  });

  const [investorAccessedDealRoom] = useCreateLogMutation();
  const { data: entityPermissions } = useGetInvestorInvestingEntityPermissionsQuery({ variables: { stoId: ID } });
  const noImg = !appData?.investorAppParameters.isProjectCardImageDisplayEnabled || picture.slice(-4) === 'null';
  if (
    loading ||
    !data ||
    !data.investorUser ||
    !appData ||
    !Alerts ||
    investorStoLoading ||
    ShareLoading ||
    !entityPermissions?.investorInvestingEntityPermissions
  ) {
    return appData?.investorAppParameters.IsMarketSpace ||
      appData?.investorAppParameters.isActivePropertyBigTombstoneEnabled ? (
      <SkeletonActivityProperty isMs />
    ) : (
      <SkeletonActivityProperty />
    );
  }

  const alertsCount = Alerts.investorBuyAlerts?.filter((share) => share.stoID === ID).length || 0;

  const view = () => {
    history.push(`/investor/detail-property/${ID}`);
  };
  const {
    KycRequirementStep: kycRequirementStep,
    IsMarketSpace: isMs,
    isBuyStoWhitelistPerInvestorEnabled,
    isProjectCardTagsDisplayEnabled,
    isActivePropertyBigTombstoneEnabled,
  } = appData.investorAppParameters;
  const { investor } = data.investorUser;
  const isNDASigned = !!investorSto?.investorSto.isNDASigned;

  const hasDealRoom = stoDetailsInvestNowLink?.length;
  const NDACheckData: canSeeNDADataRequired = {
    isStoNDAFlowEnabled: isNDAFlowEnabled,
    appData: appData.investorAppParameters,
    investorCountry: investor.country || investor.taxCountry || '',
    isNDASigned,
  };

  const changeBuyButtonText = () => {
    if (alertsCount > 0) {
      return t('ActiveProperty-Resume-Purchase');
    }
    if (!canSeeNDAData(NDACheckData)) {
      return t('ActiveProperty-Buy-nda-not-signed');
    }
    if (isNDAFlowEnabled && hasDealRoom) {
      return t('MarketSpacePropertyDetails-button-investNow');
    }
    if (!shares?.investorShare?.shares) {
      return t('detailPropertyButtonInvestInProperty');
    }
    return t('ActiveProperty-Buy');
  };

  const buy = () => {
    const isNoneKYC = data.investorUser?.investorSto && data.investorUser?.investorSto.isKYC === 0;
    if (kycRequirementStep === KycRequirementStep.OnPurchase && isNoneKYC) {
      BsSwal.fire({
        title: t('ActiveProperty-BsSwal-NoKYC'),
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: t('ActiveProperty-BsSwal-Confirm'),
        cancelButtonText: t('ActiveProperty-BsSwal-Cancel'),
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(`/investor/buy-property/${ID}`);
        }
      });
    }
    if (
      verifyInvestorKeylessBsSwal({
        appData: appData.investorAppParameters,
        investorStatus: data.investorUser?.investorSto.status || 0,
        popUpTitle: t('VerifyInvestorCom-bsswal-title'),
        popUpConfirm: t('VerifyInvestorCom-bsswal-confirm'),
        popUpCancel: t('VerifyInvestorCom-bsswal-cancel'),
        investorCountry: investor.country || '',
      })
    ) {
      return;
    }
    if (alertsCount && Alerts.investorBuyAlerts?.filter((alert) => !alert.isBuySharesSigned).length) {
      BsSwal.fire({
        icon: 'info',
        title: t('ActiveProperty-BsSwal-OngoingTransaction-NotSigned-Title'),
        text: t('ActiveProperty-BsSwal-OngoingTransaction-NotSigned-Text'),
        timer: 8000,
        didClose: () => {
          history.push(
            `/investor/share-purchase-signing/${Alerts.investorBuyAlerts?.find((alert) => alert.stoID === ID)?.ID}`,
          );
        },
      });
    } else if (alertsCount) {
      BsSwal.fire({
        icon: 'info',
        title: t('ActiveProperty-BsSwal-OngoingTransaction-Signed-Title'),
        text: t('ActiveProperty-BsSwal-OngoingTransaction-Signed-Text'),
        timer: 8000,
        didClose: () => {
          history.push(`/investor/Portfolio`);
        },
      });
    } else if (!canSeeNDAData(NDACheckData)) {
      setOpen(true);
    } else if (hasDealRoom) {
      // @remark Log access to deal room
      investorAccessedDealRoom({
        variables: {
          stoId: ID,
          activityType: LogAction.InvestorAccessedDealRoom,
        },
      });
      window.location.href = stoDetailsInvestNowLink;
    } else {
      history.push(`/investor/buy-property/${ID}`);
    }
  };

  const investorInterested =
    (isBuyButtonEnabled && !isClosedOffering && !isFilteredOffering) ||
    (isInvestorBuyEnabled && isBuyStoWhitelistPerInvestorEnabled);

  let PreviewCard: React.ReactNode;

  if (isMs || isActivePropertyBigTombstoneEnabled) {
    PreviewCard = (
      <MarketSpaceCard
        props={props}
        investorAppParameters={appData.investorAppParameters}
        view={view}
        changeBuyButtonText={changeBuyButtonText}
        canSeeNDAData={canSeeNDAData}
        buy={buy}
        NDACheckData={NDACheckData}
        isOpen={isOpen}
        setOpen={setOpen}
      />
    );
  } else {
    PreviewCard = noImg ? (
      <MPXCardNoImg
        props={props}
        investorAppParameters={appData.investorAppParameters}
        view={view}
        changeBuyButtonText={changeBuyButtonText}
        canSeeNDAData={canSeeNDAData}
        buy={buy}
        NDACheckData={NDACheckData}
        isOpen={isOpen}
        setOpen={setOpen}
        isProjectCardTagsDisplayEnabled={isProjectCardTagsDisplayEnabled}
        investorInterested={investorInterested}
        noImg
        isBuyStoEnabled={isBuyStoEnabled}
      />
    ) : (
      <MPXCard
        props={props}
        investorAppParameters={appData.investorAppParameters}
        view={view}
        changeBuyButtonText={changeBuyButtonText}
        canSeeNDAData={canSeeNDAData}
        buy={buy}
        NDACheckData={NDACheckData}
        isOpen={isOpen}
        setOpen={setOpen}
        isProjectCardTagsDisplayEnabled={isProjectCardTagsDisplayEnabled}
        investorInterested={investorInterested}
      />
    );
  }

  return <>{PreviewCard}</>;
};

export default ActiveProperty;
