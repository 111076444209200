import styled, { css } from 'styled-components';

export const SettingsBar = styled.div`
  display: flex;
  flex-direction: column;
  background: white;
  width: 660px;
  padding: 2rem;
  position: relative;
  height: 100%;

  @media (max-width: 700px) {
    width: 420px;
  }

  .settings {
    &-header {
      width: 100%;

      &__icon {
        position: absolute;
        top: 1%;
        left: -2%;
        fill: #ebebef;
        transition: all 150ms ease-in-out;

        &:hover {
          cursor: pointer;
          fill: #dcdcdc;
        }
      }

      &__content {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 700px) {
          align-items: baseline;
        }

        &-title {
          flex: 2;
          font-size: 30px;
          font-weight: 500;
          margin-bottom: 0;
        }
        &-action {
          flex: 1;
          display: flex;
          gap: 10px;
          justify-content: flex-end;

          @media (max-width: 700px) {
            gap: 5px;
          }

          .btn {
            border-width: 1px !important;

            @media (max-width: 700px) {
              margin: 10px 1px 0px;
            }
          }

          .hide {
            opacity: 0;
          }
        }
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      padding: 2rem;
      border: 1px solid #dadada;
      border-radius: 4px;

      &__item--predefined {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        margin-bottom: 20px;

        @media (max-width: 700px) {
          flex-direction: column;
          align-items: start;
          gap: 0;
        }

        &-action {
          display: flex;
          gap: 10px;
          flex-wrap: wrap;
        }
      }

      &__item {
        display: flex;
        flex-direction: column;

        &--header {
          position: relative;
          cursor: pointer;
        }
        &--title {
          font-weight: 500;
          color: ${(p) => p.theme.colors.black};
        }

        &--subtitle {
          font-weight: 600;
          font-size: 14px;
          color: ${(p) => p.theme.colors.black};
          text-transform: none;
        }

        &--row {
          display: flex;
          align-items: center;
          gap: 1rem;
          margin-bottom: 35px;
          justify-content: space-between;

          @media (max-width: 700px) {
            flex-direction: column;
            align-items: start;
            gap: 0;
          }

          &-title {
            display: flex;
            flex: 2;

            width: max-content;
            align-items: baseline;
            position: relative;

            .pop-over-container {
              color: #1e1e1e;
              &:hover {
                cursor: pointer;
              }
            }
          }

          &-action {
            display: flex;
            flex: 3;
            justify-content: space-between;
            transition: all 150ms ease-in-out;

            @media (max-width: 700px) {
              flex: 1;
              width: 100%;
            }

            .custom-control-label::before {
              width: 47px;
              height: 25px;
              border-radius: 100vw;
              background-color: #dadada;
              border: none;

              &:active {
                box-shadow: none !important;
              }
            }

            .custom-control-label::after {
              height: 21px;
              width: 21px;
              border-radius: 50%;
              background-color: white;
              cursor: pointer;
            }

            .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
              transform: translateX(22px);
            }
            .custom-switch .custom-control-input:checked ~ .custom-control-label::before {
              background-color: #2196f3;
            }

            .badge {
              display: inline-block;
              width: 28px;
              height: 28px;
              margin-right: 5px;
              box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.25);
              border-radius: 50%;
              border: none;
              position: relative;
              border: 1px solid white;
              cursor: pointer;
              transition: all 150ms ease-in-out;

              &:hover {
                box-shadow: 1px 4px 7px 1px rgba(0, 0, 0, 0.4);
              }
            }
            .active {
              box-shadow: none;
              border: 2px solid #00bbff;

              &:hover {
                box-shadow: none;
              }
            }

            .color-picker__container {
              position: relative;
            }
          }
        }
      }
    }
  }
`;

export const ScrollContainer = styled.div`
  height: 90vh;
  overflow: scroll;

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #212427;
    border-radius: 6px;
  }

  &::-webkit-scrollbar-thumb:hover {
    cursor: grab;
    background-color: #0e1011;
  }
`;

export const ThemePropertyButton = styled.button<{ background?: string }>`
  border-radius: 4px;
  cursor: pointer;
  position: relative;
  font-size: 16px;
  font-weight: normal;
  padding: 6px 12px;

  &.btn-outline-primary {
    border-width: 1px !important;
  }

  &:hover,
  &:focus,
  &:active {
    border: 1px solid ${(p) => p.background};
    background: ${(p) => p.background};
    color: ${(p) => (!p.background && '#00bbff') || 'white'};
    overflow: hidden;
    box-shadow: none !important;
  }

  &.active {
    border: 2px solid ${(p) => p.background};
    background: ${(p) => p.background};
    color: white;
  }

  ${(p) => {
    if (p.background) {
      return css`
        background: transparent;
        color: ${p.background};
        border: 1px solid ${p.background};
      `;
    }
  }};
`;

export const SettingsBarCarcet = styled.i<{ open?: boolean }>`
  position: absolute;
  right: 0%;
  top: 0%;
  transform: ${({ open }) => (open && 'rotateZ(100grad)') || 'rotateZ(-100grad)'};
  transition: all 250ms ease-in;
`;
