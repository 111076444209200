import * as React from 'react';
import { Card, TH, THEAD, TR } from 'atoms';
import { useTranslation } from 'react-i18next';
import { CardHeader, Table } from 'reactstrap';
import { ActivePropertyItem, InvestorPortfolioShares } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';
import PortfolioPropertyStepItem from './PortfolioPropertyStepItem';

interface PortfolioPropertyStepsProps {
  activeProperties: ActivePropertyItem[];
  isBuyStoWhitelistPerInvestorEnabled: boolean;
  shares: InvestorPortfolioShares[];
  appData: AppData;
}

const PortfolioPropertySteps: React.FC<PortfolioPropertyStepsProps> = ({
  activeProperties,
  isBuyStoWhitelistPerInvestorEnabled,
  shares,
  appData,
}) => {
  const { t } = useTranslation();
  const filteredProperties = activeProperties.filter(
    (p) => p.isBuyButtonEnabled && (p.isInvestorBuyEnabled || !isBuyStoWhitelistPerInvestorEnabled),
  );
  const {
    isEntityManagementMeetingFieldEnabled,
    isEntityReceivedTermSheetFieldEnabled,
    isEntityReceivedSubscriptionAgreementFieldEnabled,
    isEntityClosingFieldEnabled,
  } = appData;
  return (
    <Card>
      <CardHeader>
        <h5>{t('PortfolioPropertySteps-Header')}</h5>
      </CardHeader>
      <Table responsive hover>
        <THEAD>
          <TH>{t('PortfolioPropertySteps-Header-Name')}</TH>
          <TH>{t('PortfolioPropertySteps-Header-LaunchDate')}</TH>
          <TH>{t('PortfolioPropertySteps-Header-Invited')}</TH>
          {
            // TODO: Enable when it is possible to track NDA status as requested
            // <TH>{t('PortfolioPropertySteps-Header-RequestedNDA')}</TH>
          }
          <TH>{t('PortfolioPropertySteps-Header-SignedNDA')}</TH>
          <TH>{t('PortfolioPropertySteps-Header-AccessDealRoom')}</TH>
          {isEntityManagementMeetingFieldEnabled && <TH>{t('PortfolioPropertySteps-Header-ManagementMeeting')}</TH>}
          {isEntityReceivedTermSheetFieldEnabled && <TH>{t('PortfolioPropertySteps-Header-ReceivedTermSheet')}</TH>}
          {isEntityReceivedSubscriptionAgreementFieldEnabled && (
            <TH>{t('PortfolioPropertySteps-Header-ReceivedSubscriptionAgreement')}</TH>
          )}
          {isEntityClosingFieldEnabled && <TH>{t('PortfolioPropertySteps-Header-Closing')}</TH>}
          <TH />
          <TH />
        </THEAD>
        <tbody>
          {filteredProperties.map((property) => {
            return (
              <TR key={property.ID}>
                <PortfolioPropertyStepItem
                  activeProperty={property}
                  shares={shares.filter((s) => s.stoID === property.ID)}
                  appData={appData}
                />
              </TR>
            );
          })}
        </tbody>
      </Table>
    </Card>
  );
};

export default PortfolioPropertySteps;
