import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Collapse } from 'reactstrap';

import { MetaValues, StoMetaValue } from 'services/apollo';
import MarkupIframe from 'pages/MarkupIframe';
import DynamicRightContent from 'pages/active-properties/components/rightLayout/DynamicRightContent';
import ContentTabs from 'pages/active-properties/components/ContentTabs';
import WrapMetaContent from 'pages/active-properties/components/WrapMetaContent';
import { TabContentType } from 'pages/active-properties/components/rightLayout/DynamicTabContent';

export interface MetaContentProps {
  meta: Array<StoMetaValue> | Array<MetaValues>;
  name: string;
  currentMeta?: MetaValues[];
  rightLayout?: boolean;
  isChart?: boolean;
  currentTabContent?: TabContentType[];
  tabName?: string;
}

const MetaContent: React.FC<MetaContentProps> = ({
  meta,
  name,
  rightLayout = false,
  isChart = false,
  currentMeta,
  currentTabContent,
  tabName,
}) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(true);

  const content = currentMeta || meta.filter((x) => x.key === name);

  // get tab content meta and it has value and can be displayed
  const tabContent =
    tabName === name
      ? currentTabContent?.find((curr) => curr.key === name)?.tabs || []
      : meta
          .filter((x) => x.key.startsWith(name) && x.key !== name && x.display && x.value)
          .sort((a, b) => a.order - b.order);

  if (!content[0]?.value && !tabContent.length) {
    return <></>;
  }

  return (
    <>
      {rightLayout ? (
        <div>
          {tabContent.length ? (
            <ContentTabs meta={tabContent} name={name} />
          ) : (
            <DynamicRightContent name={name} content={content} isChart={isChart} />
          )}
        </div>
      ) : (
        <WrapMetaContent>
          <MetaHead className="py-2 px-4" onClick={() => toggle(!collapse)}>
            <h5 className="mb-0 w-90 d-inline">{t(name)}</h5>
            <Chevron className="ti-angle-double-left" open={collapse} />
          </MetaHead>
          <div className="p-2">
            <Collapse isOpen={collapse}>
              {tabContent.length ? (
                <ContentTabs meta={tabContent} name={name} />
              ) : (
                <MarkupIframe content={content[0].value} />
              )}
            </Collapse>
          </div>
        </WrapMetaContent>
      )}
    </>
  );
};

export default MetaContent;

const MetaHead = styled.div`
  font-weight: ${(p) => p.theme.fontWeightSideBar && `${p.theme.fontWeightSideBar} !important`};
  color: ${(p) => (p.theme.fontColorSideBar ? `${p.theme.fontColorSideBar} !important` : 'white')};
  color: ${({ theme }) => (theme ? 'white' : 'black')};
  background-color: ${({ theme }) => (theme ? theme.backgroundSideBar : 'rgb(250, 250, 250)')};
  background: ${({ theme }) => (theme ? theme.backgroundSideBar : 'rgb(250, 250, 250)')};
  border-radius: 2px;
`;

export const Chevron = styled.i<{ open: boolean }>`
  color: ${(props) => (props.theme ? 'white' : 'black')};
  margin: 5px 0 0;
  font-size: 18px;
  float: right;
  transform: ${({ open }) => (open && 'rotateZ(-100grad)') || 'rotateZ(100grad)'};
  transition-duration: 0.6s;
  transition-property: transform;
  transform-origin: center;
`;
