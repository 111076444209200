import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useInvestorAppDataQuery,
  useMeQuery,
  useInvestorStoDetailQuery,
  LogAction,
  useCreateLogMutation,
  MetaKeyTypes,
  StoMetaValue,
  MetaValues,
} from 'services/apollo';
import { StoDetails } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';

import { Loading } from 'atoms';
import { ThemeType } from 'lib/themes/themeTypes';
import { useTheme } from 'styled-components';
import { canSeeNDAData, canSeeNDADataRequired, verifyInvestorKeylessBsSwal } from 'services/core/helpers';
import LeftLayoutDetailProperty from 'pages/active-properties/detailPage/LeftLayoutDetailProperty';
import RightLayoutDetailProperty from 'pages/active-properties/detailPage/RightLayoutDetailProperty';
import { useGetPortfolioStoData } from 'hooks/useGetPortfolioStoData';

const ignoredKeysLeft = ['left_sidebar', 'left_sidebar_chart'];
const ignoredKeysRight = ['left_sidebar'];

interface ThemeStylingType {
  color: string | undefined;
  fontWeight: string | undefined;
}

export interface PropertyLayoutDetailProps {
  themeStyling: ThemeStylingType;
  sto: StoDetails;
  parameters: AppData;
  isLeft: boolean;
  NDACheckData: canSeeNDADataRequired;
  changeBuyButtonText: () => string;
  isBuyStoEnabled: boolean;
  pictureUrl: string;
  buy: () => void;
  setOpen: (isOpen: boolean) => void;
  canSignNDA: () => boolean;
  seeNDAData: boolean;
  metaList: Array<StoMetaValue> | Array<MetaValues>;
  detail: Array<StoMetaValue> | Array<MetaValues>;
  stoId: number;
  goToPortfolio: () => void;
  isOpen: boolean;
  investorId: number;
}

const MarketSpacePropertyDetail: React.FC = () => {
  const stoId = Number(useParams<{ _id: string }>()?._id);
  const history = useHistory();
  const { t } = useTranslation();

  const [isOpen, setOpen] = useState(false);
  const { data: appData, loading } = useInvestorAppDataQuery();

  const { data: stoDetails, loading: loadStoDetails } = useInvestorStoDetailQuery({
    variables: { _id: stoId },
  });
  const { data: meData, loading: meLoading } = useMeQuery({ fetchPolicy: 'network-only' });
  const [investorAccessedDealRoom] = useCreateLogMutation();
  const theme: ThemeType = useTheme() as ThemeType;

  const portfolioSto = useGetPortfolioStoData({ stoID: stoId });
  const { isNDASigned, isBuyStoEnabled } = portfolioSto;

  if (loadStoDetails || !stoDetails || meLoading) {
    return <Loading />;
  }

  const { findSto: sto } = stoDetails;

  if (!sto || loading || !appData || !meData || !meData.investorUser) {
    return <Loading />;
  }

  const { investor, investorSto: meInvestorSto } = meData.investorUser;
  const {
    areSTOHostnamesEnabled: areHostNamesEnabled,
    isBuyStoWhitelistPerInvestorEnabled,
    rightLayoutDesignOfferingsDetailPage,
  } = appData.investorAppParameters;

  const NDACheckData: canSeeNDADataRequired = {
    isStoNDAFlowEnabled: sto.isNDAFlowEnabled,
    appData: appData.investorAppParameters,
    investorCountry: investor.country || investor.taxCountry || '',
    isNDASigned,
    isBuyButtonEnabled: sto.isBuyButtonEnabled,
    isBuyStoEnabled,
  };

  const seeNDAData = canSeeNDAData(NDACheckData);
  const buy = () => {
    if (!seeNDAData) {
      setOpen(true);
    } else if (sto.stoDetailsInvestNowLink && sto.stoDetailsInvestNowLink.length > 0) {
      // @remark Log access to deal room
      investorAccessedDealRoom({
        variables: {
          stoId: sto.ID,
          activityType: LogAction.InvestorAccessedDealRoom,
        },
      });
      window.location.href = sto.stoDetailsInvestNowLink;
    } else {
      if (
        verifyInvestorKeylessBsSwal({
          appData: appData.investorAppParameters,
          investorStatus: meInvestorSto.status,
          popUpTitle: t('VerifyInvestorCom-bsswal-title'),
          popUpConfirm: t('VerifyInvestorCom-bsswal-confirm'),
          popUpCancel: t('VerifyInvestorCom-bsswal-cancel'),
          investorCountry: investor.country || '',
        })
      ) {
        return;
      }
      history.push(`/investor/buy-property/${sto.ID}`);
    }
  };

  const detail = [
    {
      key: 'project_overview',
      value: sto.fullDetails,
      stoID: sto.ID,
      order: 0,
      display: true,
      displayClosedProp: true,
      isManagedStoTableColumn: false,
      type: MetaKeyTypes.Text,
    },
  ];
  const metaSidebar = sto.meta.find((x) => x.key === 'left_sidebar');
  const isLeft = !!metaSidebar?.value;

  const ignoredKeys = rightLayoutDesignOfferingsDetailPage ? ignoredKeysRight : ignoredKeysLeft;
  const metaList = sto.meta.filter((meta) => !ignoredKeys.includes(meta.key));
  const pictureUrl =
    sto.ID > 0 && !areHostNamesEnabled
      ? `https://${sto.stolinkfull.substring(sto.stolinkfull.indexOf('.') + 1)}`
      : sto.stolinkfull;

  const changeBuyButtonText = () => {
    if (!seeNDAData) {
      return t('ActiveProperty-Buy-nda-not-signed');
    }
    return t('MarketSpacePropertyDetails-button-investNow');
  };

  const themeStyling = {
    color: theme.fontColorRightPanel,
    fontWeight: theme.fontWeightRightPanel,
  };
  const goToPortfolio = () => {
    history.push('/investor/Portfolio');
  };
  const canSignNDA = () => {
    if (!isBuyStoWhitelistPerInvestorEnabled || !sto.isBuyButtonEnabled) {
      return true;
    }
    if (isBuyStoEnabled) {
      return true;
    }
    return seeNDAData;
  };
  return (
    <>
      {rightLayoutDesignOfferingsDetailPage ? (
        <RightLayoutDetailProperty
          themeStyling={themeStyling}
          sto={sto}
          parameters={appData.investorAppParameters}
          isLeft={isLeft}
          NDACheckData={NDACheckData}
          changeBuyButtonText={changeBuyButtonText}
          isBuyStoEnabled={isBuyStoEnabled}
          pictureUrl={pictureUrl}
          buy={buy}
          setOpen={setOpen}
          canSignNDA={canSignNDA}
          seeNDAData={seeNDAData}
          metaList={metaList}
          detail={detail}
          stoId={stoId}
          goToPortfolio={goToPortfolio}
          isOpen={isOpen}
          investorId={investor.ID}
        />
      ) : (
        <LeftLayoutDetailProperty
          themeStyling={themeStyling}
          sto={sto}
          parameters={appData.investorAppParameters}
          isLeft={isLeft}
          NDACheckData={NDACheckData}
          changeBuyButtonText={changeBuyButtonText}
          isBuyStoEnabled={isBuyStoEnabled}
          pictureUrl={pictureUrl}
          buy={buy}
          setOpen={setOpen}
          canSignNDA={canSignNDA}
          seeNDAData={seeNDAData}
          metaList={metaList}
          detail={detail}
          stoId={stoId}
          goToPortfolio={goToPortfolio}
          isOpen={isOpen}
          investorId={investor.ID}
        />
      )}
    </>
  );
};

export default MarketSpacePropertyDetail;
