import React, { useMemo } from 'react';
import { Card, CardBody } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { Markup } from 'interweave';

type DocumentProps = {
  rawContents: string;
  fieldValues: { [p: string]: string };
};

const Document: React.FC<DocumentProps> = (props) => {
  const { rawContents, fieldValues } = props;

  // itterate over the prefill values and replace the rawContents if they're found
  const contents: string = useMemo(
    () =>
      Object.entries(fieldValues)?.reduce<string>(
        (acc: string, [key, value]) => acc.replaceAll(key, value),
        rawContents,
      ) ?? '',
    [fieldValues, rawContents],
  );

  return (
    <Card>
      <CardHeader text="Document" imgSrc="/img/contracts.png" />
      <CardBody>
        <Markup content={contents} />
      </CardBody>
    </Card>
  );
};

export default Document;
