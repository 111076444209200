import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import { FixedPluginRow, CustomFont } from 'components/fixed-plugin/components';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const ApplicationCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const appBGColor = [colors.white, colors.backgroundGrey100, colors.silver];
  const sidebarFontsColor = [colors.white, colors.textGrey, colors.black];

  const cornerTypes = [t('FixedPlugin-boxy'), t('FixedPlugin-light-round'), t('FixedPlugin-rounded')];
  const fontWeigths = [t('FixedPlugin-lighter'), '300', t('FixedPlugin-normal'), t('FixedPlugin-bold')];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-Application')}</h5>
        <SettingsBarCarcet className="ti-angle-left " open={collapse} />
      </div>
      <Collapse isOpen={collapse}>
        <FixedPluginRow
          values={appBGColor}
          property="backgroundApp"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ApplicationBackground"
          currentColor={theme.backgroundApp}
        />
        <FixedPluginRow
          values={cornerTypes}
          property="typeAppCorner"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ApplicationBorderRadius"
          currentValue={theme.typeAppCorner}
          notColors
        />

        <FixedPluginRow
          values={sidebarFontsColor}
          property="fontColorRightPanel"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ApplicationSubHeaderFontColor"
          popOver="FixedPluginRow-PopOver-ApplicationSubHeaderFontColor"
          currentColor={theme.fontColorRightPanel}
        />
        <FixedPluginRow
          values={fontWeigths}
          property="fontWeightRightPanel"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ApplicationSubHeaderFontWeight"
          popOver="FixedPluginRow-PopOver-ApplicationSubHeaderFontWeight"
          currentValue={theme.fontWeightRightPanel}
          notColors
        />

        <CustomFont handleChange={handleChange} currentFont={theme.appFont} />
      </Collapse>
    </div>
  );
};

export default ApplicationCustomizer;
