import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { Container} from 'reactstrap';
import Auth from 'services/core/auth';
import { signIn } from 'lib/routing/route-sets/public-routes';
import LanguageSelector from 'components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import { useInvestorAppDataQuery } from 'services/apollo';
import {Loading} from "atoms";
import {StyledNav, StyledNavBrand, StyledNavBar} from 'components/navbar/styled/styled'


const AuthNavbar = (props) => {
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  const [state, setState] = useState({
    collapseOpen: false,
    color: 'navbar-transparent',
  });
  const { t } = useTranslation();
  useEffect(() => {
    if (
      window.outerWidth < 993 &&
      props.history.location.pathname !== props.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  });

  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };


  const logout = (e) => {
    e.preventDefault();
    const { history } = props;
    Auth.signOut().then(() => {
      const { SSORedirectFrontEnd, isSSOModeEnabled } = appData.investorAppParameters;
      if (isSSOModeEnabled && SSORedirectFrontEnd !== '') {
        window.location.href = SSORedirectFrontEnd;
      } else {
        history.push(signIn.path);
      }
    });
  };

  if (!appData || appDataLoading) {
    return <Loading />;
  }

  const { isCountryFlagsInSelectorEnabled } = appData.investorAppParameters;

  return (
    <>
      <StyledNavBar className={classnames('navbar-absolute p-0', state.color)} expand="lg" style={{ height: '80px' }}>
        <Container fluid className="bg-white" >
          <div className="navbar-wrapper">
            <div className={classnames('navbar-toggle', { toggled: state.sidebarOpen })}>
              <button className="navbar-toggler ml-2" type="button" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
          </div>
            <StyledNavBrand>
              <p>{t('AuthNavbar-title')}</p>
            </StyledNavBrand>
            <StyledNav>
                  <LanguageSelector navigationBar enableCountryFlags={isCountryFlagsInSelectorEnabled} />
                  <NavLink to="#" className="nav-link navbar-item" onClick={logout}>
                    <i className="ti-arrow-circle-up navbar-item__icon" />
                        <span className='navbar-item__content'>{t('AuthNavbar-button-logout')}</span>
                  </NavLink>
            </StyledNav>
        </Container>
      </StyledNavBar>
    </>
  );
};

export default withRouter(AuthNavbar);