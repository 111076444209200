import React, { useState } from 'react';
import styled from 'styled-components';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { useInfoQuery, useInvestorAppDataQuery } from 'services/apollo';

import Auth from 'services/core/auth';
import { signUp, forgotPassword } from 'lib/routing/route-sets/public-routes';
import { openForum } from 'lib/routing/route-sets/forum-routes';

import { Container, Form, FormGroup, Input, StoLogo, Loading, LoadingButton, Button } from 'atoms';
import LanguageSelector from 'components/LanguageSelector';
import SecretInput from './components/SecretInput';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { t } = useTranslation();
  const { data, loading } = useInfoQuery({ variables: { _id: 0 } });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (loading || appDataLoading || !data || !appData) {
    return <Loading />;
  }

  const {
    allowInvestorsToRegister,
    backgroundImageUrl,
    isCountryFlagsInSelectorEnabled,
    isPublicLanguageSelectorEnabled,
    isForumModuleEnabled,
    isForumRegistrationEnabled,
  } = appData.investorAppParameters;

  const onSubmit = (e: React.FormEvent) => {
    setIsLoading(true);
    setError('');
    e.preventDefault();

    Auth.signIn(username, password)
      .then(() => {
        setIsLoading(false);
        history.push(Auth.need2FA() ? '/two-factor' : '/');
      })
      .catch((err) => {
        setError(err.message);
        setIsLoading(false);
      });
  };

  const setBackgroundImage = () => {
    const backgroundImageUrlObject = JSON.parse(backgroundImageUrl);
    if (backgroundImageUrlObject.login) {
      const backgroundImage = backgroundImageUrlObject.login;
      const backgroundStyle = `{"backgroundImage": "url('${backgroundImage}')" , "backgroundSize": "cover"}`;
      return JSON.parse(backgroundStyle);
    }
    return {};
  };

  return (
    <div className="login-page" style={setBackgroundImage()}>
      <Favicon url={data?.publicSto?.settings?.favicon ?? ''} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.publicSto?.settings?.tabTitle ?? ''}</title>
      </Helmet>
      <Container>
        <LoginForm>
          <div className="login-div">
            <div className="panel">
              <div className="mb-1 logo-img red-swan-custom">
                <StoLogo />
              </div>
              <h1>{t('Login-Title')}</h1>
              <p className="text-muted">
                <small>{t('Login-SubTitle')}</small>
              </p>
              {isPublicLanguageSelectorEnabled && (
                <div style={{ justifySelf: 'center', paddingLeft: '21px' }}>
                  <LanguageSelector
                    navigationBar={false}
                    color="info"
                    outline
                    openDirection="right"
                    enableCountryFlags={isCountryFlagsInSelectorEnabled}
                  />
                </div>
              )}
            </div>
            <Form id="Login" onSubmit={onSubmit}>
              <FormGroup>
                <Input
                  type="text"
                  maxLength="70"
                  placeholder={t('Login-placeholder-username')}
                  value={username}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUsername(e.currentTarget.value)}
                />
              </FormGroup>

              <FormGroup>
                <SecretInput
                  setInputValue={setPassword}
                  inputValue={password}
                  placeholderText="Login-placeholder-password"
                />
              </FormGroup>

              <LoadingButton type="submit" color="info" block size="lg" isLoading={isLoading}>
                {t('Login-LoginButton')}
              </LoadingButton>
            </Form>
            <RouterLink to={forgotPassword.getPath()}>{t('Login-forgotPassword-link')}</RouterLink>
            <br />
            {error ? <ErrorB>{t(error)}</ErrorB> : ''}
            <br />
            {allowInvestorsToRegister && (
              <>
                <b>{t('Login-Register-Label')}</b>
                <br />
                <RouterLink to={signUp.getPath()}>{t('Login-register-link')}</RouterLink>
              </>
            )}
            <br />
            {isForumModuleEnabled && isForumRegistrationEnabled && (
              <RouterLink to={openForum.getPath()}>
                <ForumButton block size="lg">
                  <i className="ti-thought" /> {t('Login-ForumButton')}
                </ForumButton>
              </RouterLink>
            )}
          </div>
        </LoginForm>
      </Container>
    </div>
  );
};

export default Login;

const ErrorB = styled.b`
  color: ${(p) => p.theme.errorColor};
`;

const LoginForm = styled.div`
  text-align: center;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ForumButton = styled(Button)`
  background: #212529;
`;
