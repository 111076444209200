import { createContext, Context } from 'react';
import { defaultTheme } from './defaultTheme';
import { ThemeType } from './themeTypes';

const theme: ThemeType = {
  ...defaultTheme,
};

export default theme;

export type ThemeContextType = {
  setTheme: (s: ThemeType) => void;
};

export const ThemeContext: Context<ThemeContextType> = createContext<ThemeContextType>({
  setTheme: () => ({}),
});
