import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CorporateActionType } from 'services/apollo';
import { CorporateAction } from 'services/apollo/meeting';

import { Button, Card, CardBody, Col, Row, BsSwal } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';

export interface VotingElementProps {
  corporateActions: CorporateAction[];
  cardTitle: string;
  cardImagePath: string;
}

const VotingElement: React.FC<VotingElementProps> = ({ corporateActions, cardTitle, cardImagePath }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const getTitle = (actionType: CorporateActionType) => {
    switch (actionType) {
      case CorporateActionType.InternalPoll:
        return t('Voting-internalPoll');
      case CorporateActionType.PublicPoll:
        return t('Voting-publicPoll');
      case CorporateActionType.Meeting:
      default:
        return t('Voting-meeting');
    }
  };

  const onVotingClick = (votingID: number, votingOpenDate: Date, votingCloseDate: Date): void => {
    const currentDate = new Date();
    const openDate = new Date(votingOpenDate);
    const closeDate = new Date(votingCloseDate);
    if (currentDate >= openDate && currentDate <= closeDate) {
      history.push(`/investor/voting-view/${votingID}`);
    } else {
      BsSwal.fire({
        title: t('VotingElement-NotStartedPopUp-Description'),
        icon: 'error',
      });
    }
  };

  return (
    <Card>
      <CardHeader text={cardTitle} imgSrc={cardImagePath} />
      <CardBody>
        {corporateActions.length > 0 ? (
          corporateActions.map((elem) => (
            <Row className="d-flex align-items-center" key={elem.ID}>
              <Col md={1}>{getTitle(elem.type)}</Col>
              <Col md={4}>{elem.title}</Col>
              <Col md={2}>{new Date(elem.openDate).toLocaleDateString()}</Col>
              <Col md={2}>{new Date(elem.closeDate).toLocaleDateString()}</Col>
              <Col md={3}>
                {elem.type === CorporateActionType.Meeting ? (
                  <Button size="sm" onClick={() => history.push(`/investor/meeting-view/${elem.ID}`)}>
                    {t('View-Meeting-Agenda')}
                  </Button>
                ) : (
                  <Button size="sm" onClick={() => onVotingClick(elem.ID, elem.openDate, elem.closeDate)}>
                    {t('View-Cast-Vote')}
                  </Button>
                )}
              </Col>
            </Row>
          ))
        ) : (
          <label>{t('No-Records-Found')}</label>
        )}
      </CardBody>
    </Card>
  );
};

export default VotingElement;
