import React from 'react';

const CloseIcon: React.FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      onClick={onClick}
      className="settings-header__icon"
    >
      <circle cx="20" cy="20" r="20" />
      <path
        d="
            M23.8789 19.2503L20.3862
            15.7577L21.4468
            14.697L26.7502
            20.0003L21.4468
            25.3036L20.3862
            24.2429L23.8789
            20.7503L17
            20.7504L16.9999
            19.2504L23.8789
            19.2503ZM14
            25.2503V14.7503H15.5V25.2503H14Z"
        fill="#404F5F"
      />
    </svg>
  );
};

export default CloseIcon;
