import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import { FixedPluginRow } from 'components/fixed-plugin/components';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const ButtonCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const sidebarFontsColor = [colors.white, colors.textGrey, colors.black];
  const buttonBGColor = [colors.blue, colors.marketSpaceBlue, colors.black];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-Button')}</h5>
        <SettingsBarCarcet className="ti-angle-left " open={collapse} />
      </div>
      <Collapse isOpen={collapse}>
        <FixedPluginRow
          values={buttonBGColor}
          property="colorControls"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ButtonBackground"
          currentColor={theme.colorControls}
        />
        <FixedPluginRow
          values={sidebarFontsColor}
          property="colorControlsFont"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ButtonFontColor"
          currentColor={theme.colorControlsFont}
        />
        <FixedPluginRow
          property="controlBorder"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ButtonBorder"
          switchValue={theme.controlBorder}
          popOver="FixedPluginRow-PopOver-ButtonBorder"
        />
        <FixedPluginRow
          values={sidebarFontsColor}
          property="colorControlBorder"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ButtonBorderColor"
          currentColor={theme.colorControlBorder}
        />
      </Collapse>
    </div>
  );
};

export default ButtonCustomizer;
