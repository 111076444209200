import React, { useState } from 'react';

import { signIn } from 'lib/routing/route-sets/public-routes';
import { useInfoQuery, useInvestorAppDataQuery, useInvestorResetMutation } from 'services/apollo';
import Auth from 'services/core/auth';

import { Button, Container, Form, FormGroup, Input, Loading, StoLogo } from 'atoms';
import { BsSwal } from 'atoms/Swal';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';
import LanguageSelector from 'components/LanguageSelector';
import { Link as RouterLink } from 'react-router-dom';

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [reset] = useInvestorResetMutation();
  const { t } = useTranslation();

  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  const { data, loading: load2 } = useInfoQuery({
    variables: { _id: Number(Auth.STO) },
  });

  if (appDataLoading || !appData || !data || !data.publicSto || load2) {
    return <Loading />;
  }

  const { backgroundImageUrl, isCountryFlagsInSelectorEnabled, isPublicLanguageSelectorEnabled } =
    appData.investorAppParameters;

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (!email) {
      setError(true);
      return;
    }

    if (loading) {
      return;
    }

    setLoading(true);

    reset({ variables: { email, STO: Number(Auth.STO) } })
      .then(() =>
        BsSwal.fire({
          title: t('ForgotPassword-success-popUp-title'),
          icon: 'success',
        }).then(() => setEmail('')),
      )
      .catch((err) =>
        BsSwal.fire({
          title: err.message,
          icon: 'error',
        }),
      )
      .finally(() => {
        setLoading(false);
      });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError(false);
    setEmail(e.currentTarget.value);
  };

  const setBackgroundImage = () => {
    const backgroundImageUrlObject = JSON.parse(backgroundImageUrl);
    if (backgroundImageUrlObject.login) {
      const backgroundImage = backgroundImageUrlObject.login;
      const backgroundStyle = `{"backgroundImage": "url('${backgroundImage}')" , "backgroundSize": "cover", "height":"100%"}`;
      return JSON.parse(backgroundStyle);
    }
    return {};
  };

  const { favicon, tabTitle } = data.publicSto.settings;

  return (
    <div className="login-page" style={setBackgroundImage()}>
      <Favicon url={favicon ?? ''} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{tabTitle ?? ''}</title>
      </Helmet>
      <Container>
        <ResetForm>
          <div className="login-form">
            <div className="main-div">
              <div className="panel">
                <div className="mb-1 logo-img red-swan-custom">
                  <StoLogo />
                </div>
                <h1>{t('ForgotPassword-card-title')}</h1>
                <p>
                  {t('ForgotPassword-email-label-1')}
                  <br />
                  {t('ForgotPassword-email-label-2')}
                </p>
                {isPublicLanguageSelectorEnabled && (
                  <div style={{ justifySelf: 'center', paddingLeft: '21px' }}>
                    <LanguageSelector
                      navigationBar={false}
                      color="info"
                      outline
                      openDirection="right"
                      enableCountryFlags={isCountryFlagsInSelectorEnabled}
                    />
                  </div>
                )}
              </div>

              <Form onSubmit={onSubmit}>
                <FormGroup>
                  <Input
                    invalid={error}
                    type="text"
                    maxLength="70"
                    placeholder={t('ForgotPassword-email-placeholder')}
                    value={email}
                    onChange={handleChange}
                  />
                </FormGroup>
                <Button disabled={loading} color="info" block size="lg">
                  {t('ForgotPassword-button-send')}
                </Button>
              </Form>
              <RouterLink to={signIn.getPath()}>{t('ForgotPassword-login-link')}</RouterLink>
            </div>
          </div>
        </ResetForm>
      </Container>
    </div>
  );
};

export default ForgotPassword;

const ResetForm = styled.div`
  height: 80vh;
  text-align: center;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
