import React, { useState } from 'react';
import { CustomInput } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { RadioBlock } from 'components/RadioBlock';
import { Button, Col, Loading, Row } from 'atoms';
import {
  useInvestorRegisterVoteMutation,
  useInvestorVoteOptionsQuery,
  useOptionsVoteStatisticQuery,
} from 'services/apollo';
import { VoteStatistic } from './VoteStatistic';

export interface VotingOptionsProps {
  votingID: number;
  companyType: number;
  isClosed: boolean;
}

const VotingOptions: React.FC<VotingOptionsProps> = (props) => {
  const { votingID, companyType, isClosed } = props;
  /* hook  */
  const { t } = useTranslation();
  const history = useHistory();

  /* state */
  const [votedOption, setVoteOption] = useState(0);
  const [alreadyVoted, setAlreadyVoted] = useState(false);

  /* gql */
  const { data: voteData, loading } = useInvestorVoteOptionsQuery({ variables: { meetingId: votingID } });
  const { data: statistic, loading: loadingStats } = useOptionsVoteStatisticQuery({
    variables: { votingId: votingID },
  });
  const [vote] = useInvestorRegisterVoteMutation();

  if (loading || !voteData || loadingStats || !statistic) {
    return <Loading />;
  }

  /* methods */

  const submitAgendaChoice = async (e: React.FormEvent) => {
    e.preventDefault();
    await vote({
      variables: {
        data: {
          type: companyType,
          optionID: votedOption,
          meetingID: votingID,
        },
      },
    });
    history.go(0);
  };

  const { investorVotingOptions: voteDataOptions } = voteData;
  const { investorVotingOptions = [] } = statistic || {};
  const dbVote = voteData.investorVotingOptions[0].userVotedOption?.votingOption.ID;
  if (dbVote && votedOption === 0) {
    setVoteOption(dbVote);
    setAlreadyVoted(true);
  }

  const onChange = (selectedID: number) => {
    if (votedOption !== selectedID) {
      setVoteOption(selectedID);
    }
  };
  const done = alreadyVoted || isClosed;

  return (
    <>
      <div className="bg-light w-100 p-2 fs-18 ">{t('VotingOptions-Options')}</div>
      <b>{t(done ? 'VotingOptions-Voted-Caption' : 'VotingOptions-SelectVote-Caption')}</b>
      <Row>
        {voteDataOptions.length ? (
          <Col>
            {voteDataOptions.map((voteOpt) => (
              <div key={voteOpt.ID} className="radio-row">
                <CustomInput
                  id={`${voteOpt.ID}_1`}
                  type="radio"
                  onChange={() => onChange(voteOpt.ID)}
                  checked={votedOption === voteOpt.ID}
                  disabled={done}
                />
                {voteOpt.optionTxt}
              </div>
            ))}
            {done ? null : (
              <RadioBlock>
                <Button size="sm" type="button" onClick={submitAgendaChoice}>
                  {t('Submit')}
                </Button>
              </RadioBlock>
            )}
          </Col>
        ) : null}
        {done ? (
          <Col>
            <VoteStatistic statistics={investorVotingOptions} />
          </Col>
        ) : null}
      </Row>
    </>
  );
};

export default VotingOptions;
