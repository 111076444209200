import React from 'react';
import { useInvestorAppDataQuery } from 'services/apollo';
import { Button } from 'atoms';
import { useTranslation } from 'react-i18next';

const NavigationDisabledBackButton: React.FC = () => {
  const { data } = useInvestorAppDataQuery();
  const { t } = useTranslation();

  if (!data?.investorAppParameters) {
    return null;
  }

  const {
    isInvestorNavigationDisabled,
    frontendRedirectConfig: { afterPaymentUrl },
  } = data.investorAppParameters;

  if (!isInvestorNavigationDisabled) {
    return null;
  }

  return <Button href={afterPaymentUrl}>{t('BuyProperty-go-back-title')}</Button>;
};

export default NavigationDisabledBackButton;
