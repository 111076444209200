import React from 'react';

import NdaModal from 'pages/nda/NdaModal';

import PropertyGallery from 'pages/active-properties/components/PropertyGallery';
import DynamicContent from 'pages/active-properties/components/DynamicContent';
import PropertyDetailActionsButtonGroup from 'pages/active-properties/components/PropertyDetailActionsButtonGroup';
import PDFViewer from 'pages/active-properties/components/PDFViewer';
import SellEntitySubTitleLink from 'pages/active-properties/components/SellEntitySubTitleLink';
import InterestedSwitch from 'pages/active-properties/components/interestedSwitch/InterestedSwitch';
import { PropertyLayoutDetailProps } from 'pages/active-properties/detailPage/MarketSpacePropertyDetail';
import MetaTagsCard from 'pages/active-properties/components/MetaTagsCard';
import OfferingOverviewContent from 'pages/active-properties/components/rightLayout/OfferingOverviewContent';
import { StyledDetails } from 'pages/active-properties/detailPage/styled/styled';

const RightLayoutDetailProperty: React.FC<PropertyLayoutDetailProps> = ({
  sto,
  parameters,
  isBuyStoEnabled,
  pictureUrl,
  buy,
  setOpen,
  canSignNDA,
  seeNDAData,
  metaList,
  stoId,
  detail,
  isOpen,
}) => {
  const { isMetakeysFilterTagsDisplayEnabled, isProjectPDFPresentationEnabled } = parameters;

  return (
    <StyledDetails className="content" noMetaKeys={!isMetakeysFilterTagsDisplayEnabled}>
      <div className="detail-header">
        <div className="detail-header__content">
          <div>
            <h1 className="detail-header__content-title">{sto.title}</h1>
            <InterestedSwitch isInterested={isBuyStoEnabled} appData={parameters} stoID={stoId} />
            <p>{sto.details}</p>
            <SellEntitySubTitleLink
              appData={parameters}
              title={sto.sellSideEntity?.title}
              ID={sto.sellSideEntity?.ID}
            />
            {isMetakeysFilterTagsDisplayEnabled && (
              <div className="mt-4">
                <MetaTagsCard metaContent={sto.meta} isDetail />
              </div>
            )}
          </div>
          <PropertyDetailActionsButtonGroup
            buy={buy}
            setOpen={setOpen}
            documents={sto.documents ?? []}
            canSignNDA={canSignNDA()}
            canSeeNDAData={seeNDAData}
            stoID={sto.ID || 0}
            isLeft={false}
            appData={parameters}
          />
        </div>
        <div className="detail-img">
          {isProjectPDFPresentationEnabled && sto.isProjectPDFPresentationEnabled ? (
            <PDFViewer fileUrl={sto.presentation?.url} />
          ) : (
            sto.images.length > 0 && (
              <PropertyGallery logo={`${pictureUrl}/img/stologo/${sto.logo}`} images={sto.images || []} rightLayout />
            )
          )}
        </div>
      </div>
      <OfferingOverviewContent detail={detail} />
      <DynamicContent meta={metaList} isBuyEnabled={sto.isBuyButtonEnabled} rightLayout />
      <NdaModal stoID={sto.ID} isOpen={isOpen} setOpen={setOpen} />
    </StyledDetails>
  );
};

export default RightLayoutDetailProperty;
