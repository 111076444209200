import React from 'react';
import { useTranslation } from 'react-i18next';

import { useInvestorAppDataQuery, useInvestorInvestingEntitiesQuery, useMeQuery } from 'services/apollo';

import { Card, CardBody, CenteredCol, Loading, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import InvestingEntityItem from './components/InvestingEntityItem';
import InvestingEntityNew from './components/InvestingEntityNew';

const InvestorEntities: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useInvestorInvestingEntitiesQuery();
  const { data: meData, loading: meLoading } = useMeQuery({ fetchPolicy: 'network-only' });
  const { data: investorAppData, loading: loadingAppData } = useInvestorAppDataQuery();

  const { investorInvestingEntities: entities } = data || {};
  const { investor } = meData?.investorUser || {};

  if (loading || meLoading || loadingAppData || !data || !meData || !investorAppData) {
    return <Loading />;
  }

  const hasEntities = Number(entities?.length) > 0;

  const { isFilterPreferencesEnabled, isLimitedInvestingEntityInfo } = investorAppData?.investorAppParameters;

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('entityManagementCardHeader')}
          caption={t('entityManagementCardCaption')}
          imgSrc="/img/agendaitem.png"
        />
        <CardBody className="mb-2">
          {hasEntities && (
            <>
              <Row>
                <CenteredCol>
                  <b>{t('entityItemRowHeaderNickname')}</b>
                </CenteredCol>
                <CenteredCol>
                  <b>{t('entityItemRowHeaderNumberMembers')}</b>
                </CenteredCol>
                <CenteredCol>
                  <b>{t('entityItemRowAccountTypeLabel')}</b>
                </CenteredCol>
                <CenteredCol>
                  <b>{t('InvestingEntity-Status-Text')}</b>
                </CenteredCol>
                <CenteredCol md={1} />
              </Row>
              <br />
              {entities?.map((entity) => (
                <InvestingEntityItem
                  key={entity.ID}
                  entity={entity}
                  investor={investor}
                  appData={investorAppData?.investorAppParameters}
                  isOpenDefault={entities.length === 1}
                  isFilterPreferencesEnabled={isFilterPreferencesEnabled}
                />
              ))}
            </>
          )}
          {!hasEntities || !isLimitedInvestingEntityInfo ? (
            <InvestingEntityNew hasEntities={hasEntities} appData={investorAppData?.investorAppParameters} />
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default InvestorEntities;
