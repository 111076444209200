import React from 'react';

import { Button, Label } from 'atoms';
import { useTranslation } from 'react-i18next';
import { isProtocolERC1404 } from 'pages/exchange/constants';
import { AllInvestorShareWallets } from 'services/apollo/shares';
import SortTable from 'pages/exchange/components/SortTable';
import { Address } from 'wagmi';

interface SharesWalletProps {
  whitelistedWallets: AllInvestorShareWallets[];
  setIsTokenAdded?: (v: boolean) => void;
}

const SharesWalletList: React.FC<SharesWalletProps> = (props) => {
  const { t } = useTranslation();
  const { whitelistedWallets, setIsTokenAdded } = props;

  /* const isProtocolERC1404 = (blockchainProtocol: number) => {
    switch (blockchainProtocol) {
      case BLOCKCHAIN_PROTOCOL.ERC1404_ETHEREUM:
      case BLOCKCHAIN_PROTOCOL.ERC1404_POLYGON:
      case BLOCKCHAIN_PROTOCOL.ERC1404_BINANCE:
      case BLOCKCHAIN_PROTOCOL.ERC1404_LIBEX:
      case BLOCKCHAIN_PROTOCOL.ERC1404_Fantom:
        return true;
      default:
        return false;
    }
  }; */

  const importTokenToWallet = async (inputAddress: string, symbol: string, decimals: number) => {
    if (window.ethereum) {
      await window.ethereum.request({
        method: 'wallet_watchAsset',
        params: {
          type: 'ERC20',
          options: {
            address: inputAddress as Address,
            symbol,
            decimals,
          },
        },
      });
      if (setIsTokenAdded !== undefined) {
        setIsTokenAdded(true);
      }
    }
  };
  const columns = React.useMemo(
    () => [
      {
        Header: t('EthereumKeys-WhitelistedWallets-Header-PublicAddress'),
        accessor: (w: AllInvestorShareWallets) => {
          return w.publicKey;
        },
      },
      {
        Header: t('EthereumKeys-WhitelistedWallets-Header-ShareTitle'),
        accessor: (w: AllInvestorShareWallets) => {
          return w.shareType.title;
        },
      },
      {
        Header: t('EthereumKeys-WhitelistedWallets-Header-ShareCount'),
        accessor: (w: AllInvestorShareWallets) => {
          return w.shares;
        },
      },
      {
        Header: t('EthereumKeys-WhitelistedWallets-Header-AddToken'),
        accessor: (w: AllInvestorShareWallets) => {
          return (
            isProtocolERC1404(w.shareType.blockchainProtocolID || 0) && (
              <Button
                onClick={() =>
                  importTokenToWallet(
                    w.shareType.ethereumContractAddress ?? '',
                    w.shareType.assetTag !== '' ? w.shareType.assetTag ?? 'TKN' : 'TKN',
                    w.shareType.blockchaindecimals,
                  )
                }
              >
                {t('SharesWallet-button-importTokens')}
              </Button>
            )
          );
        },
      },
    ],
    [t],
  );

  return (
    <>
      <h3> {t('EthereumKeys-WhitelistedWallets-Header')} </h3>
      {whitelistedWallets.length ? (
        <SortTable columns={columns} data={whitelistedWallets} />
      ) : (
        <Label>{t('No-Public-Keys-Found')}</Label>
      )}
    </>
  );
};

export default SharesWalletList;
