import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { Col, Row } from 'atoms';

import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { InvestorSharesCurrency } from 'services/apollo/shares';
import { DiscountType, PriceTransformerTrigger } from 'services/apollo/graphql';
import { DiscountQueryResult } from 'services/apollo/discount';
import { useTranslation } from 'react-i18next';
import PopoverHover from 'components/PopoverHover';
import { Markup } from 'interweave';
import PotentialDiscountsDetails from './PotentialDiscountsDetails';

interface DiscountDetailsProps {
  shareID: number;
  discounts: DiscountQueryResult[];
  currency: InvestorSharesCurrency;
  subtotal?: number;
}

const DiscountDetails: React.FC<DiscountDetailsProps> = (prop) => {
  const { shareID, discounts, currency, subtotal = 0 } = prop;
  const [collapse, toggle] = useState(false);
  const { t } = useTranslation();
  const potentialDiscounts: DiscountQueryResult[] = [];
  const visibleDiscounts: DiscountQueryResult[] = [];

  const checkCondition = (trigger: PriceTransformerTrigger): boolean => {
    const isUpperBoundEnabled = !!trigger.upperBound;
    const isUpperBoundMet = subtotal <= trigger.upperBound;
    const isLowerBoundMet = subtotal >= trigger.lowerBound;

    if (!isLowerBoundMet) {
      return false;
    }
    return !(isUpperBoundEnabled && !isUpperBoundMet);
  };

  discounts.forEach((d) => {
    if (!d.triggers.length || d.triggers.some((trigger) => checkCondition(trigger))) {
      visibleDiscounts.push(d);
    } else if (d.triggers.some((trigger) => trigger.isAlwaysVisible)) {
      potentialDiscounts.push(d);
    }
  });

  return (
    <>
      <div onClick={() => toggle(!collapse)}>
        <PopoverHover
          popoverID={`BuyPropertyShare-TransactionDiscount-${shareID}`}
          label={t('BuyPropertyShare-TransactionDiscount')}
          target={<i className="ti-help-alt pl-1" />}
          body={<Markup content={t('DiscountDetails-popoverHover-body')} />}
        />
      </div>

      <Collapse isOpen={collapse}>
        {visibleDiscounts.length ? (
          <>
            <h6>{t('DiscountDetails-header')}</h6>
            <ul>
              {visibleDiscounts.map((d) => (
                <Row key={d.ID}>
                  <li>
                    <Col>
                      {t(d.appliesTo)}
                      <b>
                        {d.type === DiscountType.Percentage ? (
                          <label>{d.amount}%</label>
                        ) : (
                          <label>
                            <CurrencySymbolDisplay value={d.amount} currency={currency} />
                          </label>
                        )}
                      </b>
                    </Col>
                  </li>
                </Row>
              ))}
            </ul>
          </>
        ) : (
          <b>{t('DiscountDetails-noDiscountsApplied')}</b>
        )}
        {potentialDiscounts.length ? (
          <>
            <hr />
            <PotentialDiscountsDetails discounts={potentialDiscounts} currency={currency} />
          </>
        ) : null}
      </Collapse>
    </>
  );
};

export default DiscountDetails;
