import React from 'react';
import { useTranslation } from 'react-i18next';
import { Currency, useInvestorAppDataQuery } from 'services/apollo';
import { getCurrencySymbol } from 'services/core/helpers';

export interface CurrencySymbolDisplayProps {
  value: number | null;
  currency?: Partial<Currency>;
}

const CurrencySymbolDisplay: React.FC<CurrencySymbolDisplayProps> = (props) => {
  const { i18n } = useTranslation();
  const { value, currency } = props;
  if (!currency) {
    return (
      <>
        {value?.toLocaleString(i18n.language, {
          minimumFractionDigits: 2,
        })}
      </>
    );
  }

  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  if (appDataLoading || !appData) {
    return null;
  }

  const { currencySymbolDisplayMode } = appData.investorAppParameters;

  return (
    <>
      {getCurrencySymbol({
        locale: i18n.language,
        currencySymbolDisplayMode,
        data: {
          value,
          currency,
        },
      })}
    </>
  );
};

export default CurrencySymbolDisplay;
