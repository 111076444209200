import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';

import { useGetExchangeTransactionsQuery } from 'services/apollo';
import { ExchangeTransactionItem } from 'services/apollo/exchange';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

import { CardHeader } from 'components/card-header/CardHeader';
import { Loading, Card, CardBody, BrandIcon } from 'atoms';
import SortTable from '../SortTable';
import MyTransactionTypeCell from './MyTransactionTypeCell';

const MyTransactions: React.FC = () => {
  const { t } = useTranslation();
  const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';

  const columns = useMemo(
    () => [
      {
        Header: t('MyTransactions-Column-Header-ID'),
        accessor: 'ID',
      },
      {
        Header: t('MyTransactions-Column-Header-stoTitle'),
        accessor: 'stoTitle',
      },
      {
        Header: t('MyTransactions-Column-Header-shareTypeTitle'),
        accessor: 'shareTypeTitle',
      },
      {
        Header: t('MyTransactions-Column-Header-type'),
        accessor: 'type',
        Cell: MyTransactionTypeCell,
      },
      {
        Header: t('MyTransactions-Column-Header-buyerName'),
        accessor: 'buyerName',
      },
      {
        Header: t('MyTransactions-Column-Header-sellerName'),
        accessor: 'sellerName',
      },
      {
        Header: t('MyTransactions-Column-Header-amount'),
        accessor: 'amount',
      },
      {
        Header: t('MyTransactions-Column-Header-pricePerShare'),
        accessor: (item: ExchangeTransactionItem) => {
          return <CurrencySymbolDisplay value={item.pricePerShare} currency={item.currency} />;
        },
      },
      {
        Header: t('MyTransactions-Column-Header-totalPrice'),
        accessor: (item: ExchangeTransactionItem) => {
          return <CurrencySymbolDisplay value={item.totalPrice} currency={item.currency} />;
        },
      },
      {
        Header: t('MyTransactions-Column-Header-dateCompleted'),
        accessor: (item: ExchangeTransactionItem) => {
          return format(item.dateCompleted, dateTimeFormat);
        },
      },
    ],
    [t],
  );

  const { data, loading } = useGetExchangeTransactionsQuery({
    fetchPolicy: 'no-cache',
  });

  if (loading || !data) {
    return <Loading />;
  }
  const { getExchangeTransactions: transactions } = data;

  if (!transactions.length) {
    return null;
  }

  return (
    <Card>
      <CardHeader
        text={t('MyTransactions-CardHeader-Text')}
        caption={t('MyTransactions-CardHeader-Caption')}
        icon={<BrandIcon icon="history" pill color="#314f75" />}
      />
      <CardBody className="mb-2">
        <SortTable columns={columns} data={transactions} />
      </CardBody>
    </Card>
  );
};

export default MyTransactions;
