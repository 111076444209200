import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useGetAllForumThreadsQuery } from 'services/apollo';
import Auth from 'services/core/auth';

import { openThread } from 'lib/routing/route-sets/forum-routes';

import { CardTitle, CardSubtitle, FontAweIcon, Loading } from 'atoms';

import { Wrapper, SectionWrapper } from './components/forum-styles';
import SearchBox from './components/SearchBox';
import ThreadCreate from './ThreadCreate';

const ThreadList: React.FC = () => {
  const { i18n, t } = useTranslation();

  const [searchText, setSearchText] = useState('');

  const applySearch = (searchKey: string): void => {
    setSearchText(searchKey);
  };

  const { data: allThreadsData, loading: allThreadsLoading } = useGetAllForumThreadsQuery();

  if (allThreadsLoading || !allThreadsData) {
    return <Loading />;
  }

  const threads =
    searchText.length >= 3
      ? allThreadsData.getAllForumThreads.filter(
          (thread) =>
            thread.title.toLowerCase().includes(searchText.toLowerCase()) ||
            thread.body.toLowerCase().includes(searchText.toLowerCase()),
        )
      : allThreadsData.getAllForumThreads;

  return (
    <Wrapper className="content">
      {Auth.isLoggedIn ? <ThreadCreate /> : null}
      <SearchBox value={searchText} applySearch={applySearch} />
      {threads.map((thread) => (
        <SectionWrapper key={thread.ID}>
          <Link to={Auth.isLoggedIn ? `/investor/thread/${thread.ID}` : openThread.getPath({ threadID: thread.ID })}>
            <CardTitle tag="h5">{thread.title}</CardTitle>
          </Link>
          <CardSubtitle className="text-muted">
            <p>
              <small>
                <FontAweIcon icon="calendar-day" /> {t('Forum-CardSubtitle-CreatedAt')}{' '}
                <strong>{new Date(thread.createdAt).toLocaleString(i18n.language)}</strong>
              </small>
            </p>
            <ThreadBody>{thread.body}</ThreadBody>
          </CardSubtitle>
        </SectionWrapper>
      ))}
    </Wrapper>
  );
};

const ThreadBody = styled.div`
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  margin-bottom: 10px;
`;

export default ThreadList;
