import { useGetInvestorInvestingEntityPermissionsLazyQuery, useInvestorActiveStoLazyQuery } from 'services/apollo';
import { AppData } from 'services/apollo/core';
import { useEffect, useState } from 'react';

export interface PortfolioStoData {
  stoID: number;
  isBuyStoEnabled: boolean;
  isNDASigned: boolean;
  isManagementMeetingsEnabled: boolean;
  hasReceivedTermSheet: boolean;
  hasReceivedSubscriptionAgreements: boolean;
  isClosing: boolean;
}

export const fillPortfolioState = (): PortfolioStoData => {
  return {
    stoID: 0,
    isBuyStoEnabled: false,
    isNDASigned: false,
    isManagementMeetingsEnabled: false,
    hasReceivedTermSheet: false,
    hasReceivedSubscriptionAgreements: false,
    isClosing: false,
  };
};

export interface GetPortfolioStoDataInput {
  stoID: number;
  appData?: AppData;
}

/**
 * fetches the investorSto/entitySto data based on the entities usage.
 * if entities are turned on, the entitySto data is fetched, otherwise investorSto
 * @param input
 */
export const useGetPortfolioStoData = (input: GetPortfolioStoDataInput): PortfolioStoData => {
  const { stoID } = input;
  const [state, setState] = useState(fillPortfolioState());
  const [getInvestorSto, { data: investorStoData }] = useInvestorActiveStoLazyQuery();
  const [getEntitySto, { data: entityStoData }] = useGetInvestorInvestingEntityPermissionsLazyQuery();

  useEffect(() => {
    getEntitySto({
      variables: { stoId: stoID },
    }).catch((e) => console.error(e));
    getInvestorSto({
      variables: { _id: stoID },
    }).catch((e) => console.error(e));

    const entity = entityStoData?.investorInvestingEntityPermissions;

    const newState: Partial<PortfolioStoData> = {
      stoID,
      isBuyStoEnabled: entity?.isBuyStoEnabled ?? investorStoData?.investorSto.isBuyStoEnabled ?? false,
      isNDASigned: entity?.isNDASigned ?? investorStoData?.investorSto.isNDASigned ?? false,
      isManagementMeetingsEnabled: entity?.managementMeetings ?? false,
      hasReceivedTermSheet: entity?.receivedTermSheet ?? false,
      hasReceivedSubscriptionAgreements: entity?.receivedSubscriptionAgreements ?? false,
      isClosing: entity?.closing ?? false,
    };
    setState((prevState) => ({ ...prevState, ...newState }));
  }, [investorStoData, entityStoData]);

  return state;
};
