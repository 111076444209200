import React, { useEffect } from 'react';

import { Card, CardBody, Col, Row, Button, BsSwal } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import { VerifyInvestorKeyLessParam } from 'services/apollo';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import styles from 'assets/css/verifyInvestorPopUp.css';

export interface VerifyInvestorComProps {
  investorID: number;
  stoID: number;
  accessToken: string;
  url: string;
  verifyInvestorComKeylessIntegration: VerifyInvestorKeyLessParam;
}

const VerifyInvestorCom: React.FC<VerifyInvestorComProps> = (props) => {
  const { investorID, stoID, accessToken, url, verifyInvestorComKeylessIntegration } = props;
  const { isEnabled, redirectUrl } = verifyInvestorComKeylessIntegration;
  const { t } = useTranslation();
  useEffect(() => {
    const scriptTag = document.createElement('script');
    scriptTag.src = url;
    scriptTag.async = true;
    document.body.appendChild(scriptTag);
    return () => {
      document.body.removeChild(scriptTag);
    };
  });

  const handleKeylessIntegration = () => {
    BsSwal.fire({
      title: t('VerifyInvestorCom-bsswal-title'),
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: t('VerifyInvestorCom-bsswal-confirm'),
      cancelButtonText: t('VerifyInvestorCom-bsswal-cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        goToLink();
      }
    });
  };

  const goToLink = () => {
    const newWindow = window.open(redirectUrl || '', '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const initiateVerifiyInvestorComButton = () => {
    if (isEnabled) {
      handleKeylessIntegration();
      return;
    }
    const token = `${accessToken}`;
    const identifier = `${investorID}-${stoID}`;
    window.verifyInvestor(token, identifier);
  };

  return (
    <div className="content">
      <Card className={styles}>
        <CardHeader text={t('verifyInvestorComCardTitle')} caption={t('verifyInvestorComCardCaption')} />
        <CardBody>
          <Row>
            <Col>
              <Button id="invest" size="sm" onClick={() => initiateVerifiyInvestorComButton()}>
                {t('VerifyInvestorCom-button')}
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </div>
  );
};

export default VerifyInvestorCom;
