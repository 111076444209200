import React, { useEffect, useState } from 'react';
import {
  CustomInput,
  Label,
  InputGroup,
  InputGroupButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { formatISO, parseISO } from 'date-fns';

import { MetaValues } from 'services/apollo';
import { FilterOption } from 'services/apollo/investor-meta';
import { Button, Col, DatePicker, FormGroup, Input } from 'atoms';
import FilterOptionsItemSelect from './FilterOptionsItemSelect';
import FilterOptionsItemCountriesSelect from './FilterOptionsItemCountriesSelect';

interface FilterOptionsItemProps {
  item: FilterOption;
  values: MetaValues[];
  disabled: boolean;
  isCountry: boolean;

  update(next: MetaValues[]): void;
}

const optionNames = {
  none: 'Settings-Filter-Options-Item-Value-Is',
  gt: 'Settings-Filter-Options-Item-Value-Greater-Than',
  lt: 'Settings-Filter-Options-Item-Value-Less-Than',
};

const FilterOptionsItem: React.FC<FilterOptionsItemProps> = ({ item, update, disabled, values, isCountry }) => {
  const value = values.find((x) => x.key === item.key);
  const valueSign = values.find((x) => x.key === `${item.key}_sign`);
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState(false);
  const [symbol, setSymbol] = useState<keyof typeof optionNames>('none');
  const [filterNumber, setFilterNumber] = useState(0);
  const [date, setDate] = useState<Date | null>(null);

  useEffect(() => {
    if (value && item.type === 'filter_number') {
      setFilterNumber(parseInt(value.value || '', 10));
    }
    if (value && item.type === 'filter_date') {
      setDate(parseISO(value.value));
    }
  }, [value]);

  useEffect(() => {
    if (valueSign && (item.type === 'filter_number' || item.type === 'filter_date')) {
      setSymbol(valueSign.value as keyof typeof optionNames);
    }
  }, [valueSign]);

  const toggleOpen = () => setOpen((prev) => !prev);

  const handleBox = () => {
    const index = values.findIndex((x) => x.key === item.key);
    if (index !== -1) {
      values.splice(index, 1);
      update([...values]);
      return;
    }

    update([
      ...values,
      {
        type: item.type,
        key: item.key,
        value: 'true',
        order: item.order,
      },
    ]);
  };
  const handleSelect = (selected: string) => {
    const index = values.findIndex((x) => x.key === item.key);
    if (index !== -1) {
      values.splice(index, 1);
    }

    update([
      ...values,
      {
        type: item.type,
        key: item.key,
        value: selected,
        order: item.order,
      },
    ]);
  };

  const handleSign = (sign: keyof typeof optionNames) => {
    setSymbol(sign);
    const signIndex = values.findIndex((x) => x.key === `${item.key}_sign`);

    if (signIndex !== -1) {
      values.splice(signIndex, 1);
    }
    const index = values.findIndex((x) => x.key === item.key);
    if (sign === 'none' && index !== -1) {
      values.splice(index, 1);
      if (item.type === 'filter_number') {
        setFilterNumber(0);
      }
      if (item.type === 'filter_date') {
        setDate(null);
      }
    }

    update([
      ...values,
      {
        type: item.type,
        key: `${item.key}_sign`,
        value: sign,
        order: item.order,
      },
    ]);
  };

  const handleNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const numberValue = parseInt(e.target.value, 10) || 0;
    setFilterNumber(numberValue);
    const index = values.findIndex((x) => x.key === item.key);
    if (index !== -1) {
      values.splice(index, 1);
    }
    update([
      ...values,
      {
        type: item.type,
        key: item.key,
        value: `${numberValue}`,
        order: item.order,
      },
    ]);
  };

  const handleDate = (d: Date) => {
    setDate(d);
    const index = values.findIndex((x) => x.key === item.key);
    if (index !== -1) {
      values.splice(index, 1);
    }
    update([
      ...values,
      {
        type: item.type,
        key: item.key,
        value: formatISO(d, { representation: 'date' }),
        order: item.order,
      },
    ]);
  };

  if (item.type === 'filter_checkbox') {
    return (
      <Col md={3} xl={2}>
        <CustomInput
          key={item.key}
          id={item.key}
          type="checkbox"
          onChange={handleBox}
          label={t(item.key)}
          checked={value?.value === 'true'}
          disabled={disabled}
        />
      </Col>
    );
  }
  if (item.type === 'filter_select') {
    return (
      <Col md={12} lg={6}>
        <FormGroup>
          {isCountry ? (
            <FilterOptionsItemCountriesSelect
              key={item.key}
              value={value?.value || ''}
              update={handleSelect}
              disabled={disabled}
            />
          ) : (
            <FilterOptionsItemSelect
              key={item.key}
              value={value?.value || ''}
              filterKey={item.key}
              update={handleSelect}
              disabled={disabled}
            />
          )}
        </FormGroup>
      </Col>
    );
  }
  if (item.type === 'filter_number' || item.type === 'filter_date') {
    return (
      <Col md={6} lg={4}>
        <FormGroup>
          <Label for={item.key}>{t(item.key)}</Label>
          <InputGroup size="sm">
            {disabled ? (
              <InputGroupAddon addonType="prepend">
                <InputGroupText>{t(optionNames[symbol])}</InputGroupText>
              </InputGroupAddon>
            ) : (
              <InputGroupButtonDropdown addonType="prepend" isOpen={isOpen} toggle={toggleOpen}>
                <DropdownToggle caret className="m-0" color="primary" tag={Button}>
                  {t(optionNames[symbol])}
                </DropdownToggle>
                <DropdownMenu className="shadow">
                  <DropdownItem active={symbol === 'gt'} onClick={() => handleSign('gt')}>
                    {t(optionNames.gt)}
                  </DropdownItem>
                  <DropdownItem active={symbol === 'lt'} onClick={() => handleSign('lt')}>
                    {t(optionNames.lt)}
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem onClick={() => handleSign('none')}>
                    {t('Settings-Filter-Options-Item-Value-Clear')}
                  </DropdownItem>
                </DropdownMenu>
              </InputGroupButtonDropdown>
            )}
            {item.type === 'filter_number' ? (
              <Input
                type="number"
                inputMode="numeric"
                name={item.key}
                id={item.key}
                onChange={handleNumber}
                value={`${filterNumber}`}
                disabled={disabled}
              />
            ) : null}
            {item.type === 'filter_date' ? (
              <DatePicker
                name={item.key}
                id={item.key}
                dateFormat="dd.MM.yyyy"
                showPopperArrow={false}
                selected={date}
                onChange={handleDate}
                disabled={disabled}
              />
            ) : null}
          </InputGroup>
        </FormGroup>
      </Col>
    );
  }

  return <></>;
};

export default FilterOptionsItem;
