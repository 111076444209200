import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useFetchFeesQuery, FeeBeneficiary, FeeType } from 'services/apollo';

import { CardHeader } from 'components/card-header/CardHeader';
import { Button, Card, CardBody, BrandIcon, Col, Row, BsSwal, Label, Loading } from 'atoms';

const RegistrationFeePayment: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const { data: feeData, loading: feeLoading } = useFetchFeesQuery({
    variables: { stoID: 0, beneficiary: FeeBeneficiary.Platform, type: FeeType.Registration },
    fetchPolicy: 'no-cache',
  });

  if (feeLoading || !feeData) {
    return <Loading />;
  }

  const [feeInfo] = feeData.fetchFees;

  const goBack = (): void => history.goBack();

  const handlePay = () => {
    /*
      // TODO: DIG-1470
      Here we should put the Payment logic and call the callback function when the
      Payment is successful in order to apply the Registration Fee
    */
    BsSwal.fire({
      title: t('RegistrationFeePayment-BsSwal-Title'),
      text: t('RegistrationFeePayment-BsSwal-Text'),
      icon: 'success',
    });
    goBack();
  };

  return (
    <Col className="content">
      <Card>
        <CardHeader
          text={t('RegistrationFeePayment-CardHeader-Text')}
          icon={<BrandIcon icon="info" color="cyan" pill />}
        />
        <CardBody className="mb-2">
          <Row className="d-flex align-items-center justify-content-center">
            <Col xs="auto" md={3}>
              <Label>{t('RegistrationFeePayment-Label-Title')}</Label>
            </Col>
            <Col xs="auto" md={2}>
              <b>{feeInfo.amount || 0}</b>
            </Col>
            <Col xs="auto" md={3}>
              <Button onClick={handlePay}>{t('RegistrationFeePayment-Button-Pay')}</Button>
              <Button onClick={goBack}>{t('RegistrationFeePayment-Button-Cancel')}</Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Col>
  );
};

export default RegistrationFeePayment;
