import React from 'react';

interface PopoverProps {
  toggle: boolean;
  onClick?: () => void;
  pullRight?: boolean;
}

const PopoverHover: React.FC<PopoverProps> = ({ toggle, onClick, pullRight }) => {
  let direction = toggle ? 'ti-arrow-circle-up pl-1' : 'ti-arrow-circle-down pl-1';
  direction += pullRight ? ' pull-right' : '';

  return <i className={direction} onClick={onClick} />;
};

export default PopoverHover;
