import React from 'react';

import { OFFERINGHTMLIDS } from 'pages/active-properties/ActiveProperties';
import { useTranslation } from 'react-i18next';
import { StyledCard } from './styled/styled';

interface OfferingSummaryItemProps {
  title: string;
  count: number | null;
  navid: OFFERINGHTMLIDS;
  setActivePropertyGrid: (value: OFFERINGHTMLIDS) => void;
  active: boolean;
  canBeActive: boolean;
}

const OfferingSummaryItem: React.FC<OfferingSummaryItemProps> = ({
  title,
  count,
  navid,
  setActivePropertyGrid,
  active,
  canBeActive,
}) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (canBeActive) {
      setActivePropertyGrid(navid);
    }
  };

  return (
    <StyledCard navid={navid} canBeActive={canBeActive} onClick={handleClick} className={active ? 'active' : ''}>
      <div className="item-header">
        <h5 className="header-medium">{title}</h5>
        {!active && canBeActive && <span>{t('OfferingSummary-Item-NavLabel')}</span>}
      </div>
      <span className="h2">{count}</span>
    </StyledCard>
  );
};

export default OfferingSummaryItem;
