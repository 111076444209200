import React from 'react';
import { Cell } from 'react-table';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { InvestorShares } from 'services/apollo/shares';

import { BsSwal, Button } from 'atoms';

const InternalExchangeActionCell: React.FC<Cell<InvestorShares>> = ({ row: { original } }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const { ID, shareType, shares } = original;

  const onSellClick = (): void => {
    if (shareType.isInvestorTradable) {
      history.push(`/investor/sell-order/${ID}`);
    } else {
      BsSwal.fire({
        title: t('InternalExchangeActionCell-NotTradablePopUp-Title'),
        text: t('InternalExchangeActionCell-NotTradablePopUp-Description'),
        icon: 'error',
      });
    }
  };

  return (
    <div>
      <Button size="sm" disabled={shares === 0} onClick={onSellClick}>
        {t('InternalExchangeActionCell-button-sell')}
      </Button>
    </div>
  );
};

export default InternalExchangeActionCell;
