import React from 'react';
import { useTranslation } from 'react-i18next';

import { useContractsPageQuery, useInvestorAppDataQuery } from 'services/apollo';

import { CardHeader } from 'components/card-header/CardHeader';
import { Card, CardBody, Loading } from 'atoms';
import { OfferedContracts } from './offered-contracts/OfferedContract';
import InvestorDocuments from './investor-documents/InvestorDocuments';
import SignedDocumentList from './components/SignedDocumentList';

const ContractsDocuments: React.FC = () => {
  const { t } = useTranslation();
  const { data, loading } = useContractsPageQuery({ fetchPolicy: 'no-cache' });
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery();
  if (loading || !data || !appData || appDataLoad) {
    return <Loading />;
  }

  const { isOfferedContractsEnabled } = appData.investorAppParameters;

  return (
    <div className="content">
      {isOfferedContractsEnabled && <OfferedContracts />}
      <InvestorDocuments />
      <Card>
        <CardHeader text={t('Signed-Contracts')} imgSrc="/img/contracts.png" />
        <CardBody className="mb-2">
          {data.submittedDocuments.length === 0 ? (
            <>
              <br />
              <label>{t('No-Records-Found')}</label>
            </>
          ) : (
            <SignedDocumentList submittedDocuments={data.submittedDocuments} />
          )}
        </CardBody>
      </Card>
    </div>
  );
};

export default ContractsDocuments;
