import React from 'react';
import { Cell } from 'react-table';
import { Badge } from 'reactstrap';
import styled from 'styled-components';

import { ExchangeTransactionItem } from 'services/apollo/exchange';
import { TransactionType } from 'services/apollo';
import { useTranslation } from 'react-i18next';

const MyTransactionTypeCell: React.FC<Cell<ExchangeTransactionItem>> = ({ row: { original } }) => {
  const { type } = original;
  const { t } = useTranslation();
  const isSellType = TransactionType.Sell === type;

  return <TransactionTypeBadge>{isSellType ? t('SELL') : t('BUY')}</TransactionTypeBadge>;
};

const TransactionTypeBadge = styled(Badge)`
  font-size: 12px;
  background: ${(p) => p.theme.colors.grayDark};
  border: none;
`;

export default MyTransactionTypeCell;
