import { FontAweIcon } from 'atoms';
import styled, { css } from 'styled-components';

export const StyledDiv = styled.div`
  position: relative;

  &:hover {
    .star-icon {
      cursor: pointer;
      scale: 1.1;
      transition: all 150ms ease;
    }

    .star-popup {
      display: block;
    }
  }
`;

export const StyledIconContainer = styled.div<{ isDetail: boolean }>`
  .star-popup {
    position: absolute;
    display: none;
    z-index: 100;
    background-color: ${(p) => p.theme.interestedPopoverBGColor};
    color: ${(p) => p.theme.interestedPopoverFontColor};
    overflow: visible;
    right: 0%;
    width: max-content;
    padding: 5px 10px;
    border-radius: 3px;
  }
  ${(p) => {
    if (p.isDetail) {
      return css`
        background-color: ${p.theme.interestedDetailsBGColor};
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        .star-popup {
          top: 100%;
        }
      `;
    }
  }}
`;

export const StyledIcon = styled(FontAweIcon)<{ switchState: boolean }>`
  font-size: 30px;
  padding-bottom: 5px;
  color: ${(p) => p.theme.interestedBtnColor};

  ${(p) => {
    if (p.switchState) {
      return css`
        color: ${p.theme.interestedBtnActiveColor};
      `;
    }
  }}
`;
