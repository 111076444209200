import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { GetThreadContentDocument, useCreateForumPostMutation } from 'services/apollo';

import { Button, Input, Row, Col } from 'atoms';

import { SectionWrapper } from './components/forum-styles';

interface PostCreateProps {
  threadID: number;
}

const PostCreate: React.FC<PostCreateProps> = ({ threadID }) => {
  const { t } = useTranslation();

  const [postText, setPostText] = useState('');

  const [createForumPost] = useCreateForumPostMutation({
    refetchQueries: [{ query: GetThreadContentDocument, variables: { threadID } }],
  });

  const createPost = () => {
    const newPost = {
      threadID,
      body: postText,
    };
    if (postText.length > 0) {
      createForumPost({ variables: { postData: newPost } }).then(() => setPostText(''));
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      createPost();
    }
  };

  return (
    <SectionWrapper>
      <Row className="d-flex align-items-center">
        <Col xs="auto" md={10}>
          <Input
            autoComplete="off"
            required
            maxLength="5000"
            name="post"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPostText(e.currentTarget.value || '')}
            onKeyDown={handleKeyDown}
            value={postText}
            placeholder={t('PostCreate-PostInput-Placeholder')}
          />
        </Col>
        <Col xs="auto" md={2}>
          <CreatePostButton block onClick={createPost}>
            {t('PostCreate-Button-CreatePost')}
          </CreatePostButton>
        </Col>
      </Row>
    </SectionWrapper>
  );
};

const CreatePostButton = styled(Button)`
  background: ${(p) => p.theme.colors.orange};
`;

export default PostCreate;
