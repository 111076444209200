import React from 'react';
import { StoMetaValue } from 'services/apollo';
import MarkupIframe from 'pages/MarkupIframe';

export interface CategoryValuesDisplayProps {
  values: StoMetaValue[];
}

const CategoryValuesDisplay: React.FC<CategoryValuesDisplayProps> = (props) => {
  const { values } = props;

  return (
    <div className="content">
      <ul>
        {values.map((v) => (
          <li key={v.key}>
            <MarkupIframe content={v.key || ''} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default CategoryValuesDisplay;
