import styled, { css } from 'styled-components';

export const StyledDynamicContainer = styled.div`
  .dynamic-content__title {
    font-size: 20px;
    font-weight: 500;
  }

  .dynamic-content__content {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 3rem;
    min-height: 400px;
    padding: 1rem;

    @media (max-width: 1000px) {
      grid-template-columns: 1fr;
      grid-template-rows: auto 1fr;
    }

    &--tabs {
      display: flex;
      flex-direction: column;

      @media (max-width: 1000px) {
        max-width: max-content;
        flex-direction: row;
        overflow: auto;
      }
    }
  }
`;
export const StyledTab = styled.span<{ active: boolean }>`
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  background-color: ${(p) => p.theme.offeringDetailDetailsTabBGColor};
  padding: 1rem 2rem;
  width: 300px;
  color: ${(p) => p.theme.offeringDetailDetailsTabFontColor};
  border-left: 3px solid transparent;

  @media (max-width: 1400px) {
    width: 270px;
  }

  &:hover {
    background-color: ${(p) => p.theme.offeringDetailDetailsTabHoverBGColor};
  }

  @media (max-width: 1000px) {
    border-left: none;
    border-top: 3px solid transparent;
  }

  ${(p) => {
    if (p.active) {
      return css`
        background-color: ${p.theme.offeringDetailDetailsTabActiveBGColor};
        border-left: 3px solid ${p.theme.offeringDetailDetailsTabActiveBorderColor};
        font-weight: 600;
        color: ${p.theme.offeringDetailDetailsTabActiveFontColor};

        @media (max-width: 1000px) {
          border-left: none;
          border-top: 3px solid ${p.theme.offeringDetailDetailsTabActiveBorderColor};
        }
      `;
    }
  }}
`;
