import React, { useState } from 'react';
import { BsSwal, FormGroup } from 'atoms';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { InvestorAssociationStoSwitchEnum, useInvestorBuyPropertyUpdateMutation } from 'services/apollo';
import { AppData } from 'services/apollo/core';
import { getTranslationKeyOfApiError, GqlError } from 'services/core/helpers';
import { CustomInput } from 'reactstrap';
import InterestedStarIcon from 'pages/active-properties/components/interestedSwitch/InterestedStarIcon';

interface InterestedSwitchProps {
  appData: AppData;
  stoID: number;
  isInterested: boolean;
  isDetail?: boolean;
}

const InterestedSwitch: React.FC<InterestedSwitchProps> = ({ appData, stoID, isInterested, isDetail = false }) => {
  const { t } = useTranslation();
  const { isBuyStoWhitelistPerInvestorEnabled, isInvestorDisassociationSwitchEnabled } = appData;
  const [updateInvestorBuyProperty] = useInvestorBuyPropertyUpdateMutation();
  const [switchState, setSwitchState] = useState(isInterested); // used to have the fancy toggle animation on the switch

  const onChangeBuyStoEnabled = async () => {
    try {
      await updateInvestorBuyProperty({
        variables: {
          stoID,
          isEnabled: !switchState,
        },
      });
      setSwitchState(!switchState);
    } catch (err) {
      const gqlError = getTranslationKeyOfApiError(err as GqlError);
      await BsSwal.fire({
        title: gqlError.message,
        icon: 'error',
        cancelButtonText: t('Cancel'),
      });
    }
  };

  const displayInterestedSwitch = (): boolean => {
    if (!isBuyStoWhitelistPerInvestorEnabled) {
      return false;
    }
    switch (isInvestorDisassociationSwitchEnabled) {
      case InvestorAssociationStoSwitchEnum.NeverDisplay:
        return false;
      case InvestorAssociationStoSwitchEnum.OnlyWhenInterested:
        return isInterested;
      case InvestorAssociationStoSwitchEnum.AlwaysDisplay:
        return true;
      default:
        return false;
    }
  };
  return (
    <>
      {displayInterestedSwitch() && (
        <StyledCustomFormGroup switch>
          <StyledCustomInput
            id={`is-interested-${stoID}`}
            type="switch"
            onChange={onChangeBuyStoEnabled}
            label={<InterestedStarIcon switchState={switchState} isDetail={isDetail} />}
            checked={switchState}
          />
        </StyledCustomFormGroup>
      )}
    </>
  );
};

export default InterestedSwitch;

const StyledCustomFormGroup = styled(FormGroup)`
  align-self: center;
`;

const StyledCustomInput = styled(CustomInput)`
  .custom-control-label::before,
  .custom-control-label::after {
    display: none;
  }
`;
