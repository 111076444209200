import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Markup } from 'interweave';

import { BuyAlertStatus, useInvestorPortfolioQuery } from 'services/apollo';
import { MeData } from 'services/apollo/core';
import { StoDetails } from 'services/apollo/multisto';

import { Loading } from 'atoms';
import ShareCertificateBorder from 'assets/img/share-certificate.png';
import ShareQuantity from './ShareQuantity';

interface ShareCertificateFrameProps {
  stoDetails: StoDetails;
  investor: MeData;
}

const ShareCertificateFrame: React.FC<ShareCertificateFrameProps> = ({ stoDetails, investor }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectUrl = `/investor/Portfolio`;
  const dateFormat = 'd MMM yyyy';
  const { investor: investorData } = investor;

  const { data: portfolioData, loading: portfolioLoading } = useInvestorPortfolioQuery({
    variables: {
      _id: Number(investorData.ID),
      status: [BuyAlertStatus.Accepted],
    },
  });

  if (portfolioLoading || !portfolioData) {
    return <Loading />;
  }

  const shares = portfolioData.investorShares.filter((balance) => balance.stoID === stoDetails.ID);
  if (shares.length === 0) {
    history.push(redirectUrl);
  }
  const investorName =
    investorData.investorType === 1
      ? investorData.companyName
      : `${[investorData.firstName, investorData.middleName, investorData.lastName].filter((s) => !!s).join(' ')}`;
  const investorAddress = `${[
    investorData.address,
    investorData.zip,
    investorData.town,
    investorData.state,
    investorData.country,
  ]
    .filter((s) => !!s)
    .join(', ')}`;

  return (
    <div className="content">
      {shares.length ? (
        <>
          <ShareCertificateContainer>
            <h3
              style={{ fontFamily: 'CaramelRegular', fontSize: '6rem', color: '#04767a' }}
              className="text-center font-weight-bold mb-0"
            >
              {t('BeautifulShareCertificate-CardHeader-text')}
            </h3>
            <hr />
            <h3 className="text-center font-weight-bold mb-1">{investorName}</h3>
            <p style={{ fontSize: '17px' }} className="text-center font-weight-normal mb-0">
              {investorAddress}
            </p>
            <p style={{ fontSize: '17px' }} className="text-center font-weight-normal mb-0">
              <Markup content={t('ShareCertificateFrame-Content-Line-1')} />
            </p>
            <p style={{ fontSize: '17px' }} className="text-center font-weight-normal mb-0">
              <mark>{stoDetails.title}</mark> {t('ShareCertificateFrame-Content-located')}{' '}
              <mark>{stoDetails.projectAddress}</mark>
            </p>
            <p style={{ fontSize: '17px' }} className="text-center font-weight-normal mb-3">
              {t('ShareCertificateFrame-Content-Line-2')}
            </p>
            <div className="text-center m-auto w-50">
              {shares
                .filter((balance) => balance.shares > 0)
                .map((balance) => (
                  <ShareQuantity key={balance.ID} balance={balance} />
                ))}
            </div>
            <p className="text-center mt-4">
              {t('ShareCertificateFrame-Date-Label')}
              <strong>{format(new Date(), dateFormat)}</strong>
            </p>
          </ShareCertificateContainer>
          <br />
          <br />
          <h3 className="text-left p-4">{t('ShareCertificateFrame-Terms-and-Conditions')}</h3>
          <Markup content={stoDetails.legalDetails ?? ''} />
        </>
      ) : null}
    </div>
  );
};

const ShareCertificateContainer = styled.div`
  background-color: #f9f8f3;
  border: 30px solid transparent;
  padding: 15px;
  border-image: url(${ShareCertificateBorder}) 30 round;
`;

export default ShareCertificateFrame;
