import React from 'react';
import theme from 'lib/themes/themeController';

import { ThemeType } from 'lib/themes/themeTypes';
import { ColorPicker } from 'components/fixed-plugin/components';
import { ThemePropertyButton } from '../styled/styled';

interface FixedPluginChoicesProps {
  values: string[];
  property: keyof ThemeType;
  handleChange: (property: keyof ThemeType, cl: string | boolean) => void;
  notColors?: boolean;
  currentValue?: string;
  handleActive: (e: React.MouseEvent<HTMLSpanElement>) => void;
  badgeActive: boolean;
}

const FixedPluginChoices: React.FC<FixedPluginChoicesProps> = ({
  notColors,
  values,
  currentValue,
  handleChange,
  property,
  handleActive,
  badgeActive,
}) => {
  return (
    <>
      {notColors ? (
        values.map((vl) => (
          <ThemePropertyButton
            key={vl}
            className={currentValue && currentValue === vl ? 'btn-outline-primary active' : 'btn-outline-primary'}
            onClick={() => {
              handleChange(property, vl);
            }}
          >
            {vl}
          </ThemePropertyButton>
        ))
      ) : (
        <>
          <div>
            {values.map((cl) => (
              <span
                key={cl}
                className={
                  currentValue && currentValue === cl ? `badge ${property}-badge active` : `badge ${property}-badge`
                }
                data-color="black"
                style={{ background: cl }}
                onClick={(e) => {
                  handleActive(e);
                  handleChange(property, cl);
                }}
              />
            ))}
          </div>
          <div className="color-picker__container">
            <ColorPicker
              notActive={badgeActive}
              currentColor={currentValue || theme.backgroundSideBar}
              property={property}
              handleChangeColor={handleChange}
            />
          </div>
        </>
      )}
    </>
  );
};

export default FixedPluginChoices;
