import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CommissionType } from 'services/apollo';
import { FetchFees } from 'services/apollo/fee';
import { InvestorShareShareTypeCurrency, InvestorShareWallet } from 'services/apollo/shares';

import { Input, Label, Col, FormGroup } from 'atoms';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { UseSellOrderState } from './useSellOrderState';

interface SellOrderRateProps extends Pick<UseSellOrderState, 'order' | 'onChange'> {
  currency: InvestorShareShareTypeCurrency;
  shares: number;
  wallets: InvestorShareWallet[];
  fee: FetchFees[];
  premiumValue: number;
}

const SellOrderRate: React.FC<SellOrderRateProps> = ({
  premiumValue,
  currency,
  shares,
  wallets,
  order,
  fee,
  onChange,
}) => {
  const { t } = useTranslation();

  const [invalid, setInvalid] = useState(false);
  const [price, setPrice] = useState(premiumValue);
  const [count, setCount] = useState(!order.atomicSwapSharesWalletID || wallets.length === 1 ? shares : 0);
  const [totalAfterFee, setTotalAfterFee] = useState(0);
  const [isDefaultCount, setIsDefaultCount] = useState(true);

  const [feeInfo] = fee;

  const handleChange = (setState: (value: number) => void): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e) => {
      // expect NaN
      const value = parseFloat(e.currentTarget.value) || 0;
      setState(value);
    };
  };

  useEffect(() => {
    if (order.atomicSwapSharesWalletID) {
      const wallet = wallets.find((w) => w.ID === order.atomicSwapSharesWalletID);
      if (wallet && isDefaultCount) {
        setCount(wallet.shares);
        setIsDefaultCount(false);
      }

      if (wallet && wallet.shares < count) {
        onChange({ shares: 0, rateFrom: 0 });
        setInvalid(true);
        return;
      }
    } else if (count > shares) {
      onChange({ shares: 0, rateFrom: 0 });
      setInvalid(true);
      return;
    }

    setInvalid(false);
    if (price && count) {
      const total: number = price * count;
      setTotalAfterFee(total);
      if (feeInfo) {
        if (feeInfo.status === CommissionType.Flat) {
          setTotalAfterFee(total - feeInfo.amount);
        } else {
          const feePrice = (feeInfo.amount * total) / 100;
          setTotalAfterFee(total - feePrice);
        }
      }
      onChange({ shares: count, rateFrom: total });
    } else {
      setTotalAfterFee(0);
      onChange({ shares: 0, rateFrom: 0 });
    }
  }, [count, price, order.atomicSwapSharesWalletID]);

  return (
    <>
      <Col xs="auto" md={3}>
        <FormGroup>
          <Label className="mb-1">
            <b>{t('SellOrderRate-Label-ShareAmount')}</b>
          </Label>
          <Input invalid={invalid} onChange={handleChange(setCount)} value={count} />
        </FormGroup>
      </Col>
      <Col xs="auto" md={3}>
        <FormGroup>
          <Label className="mb-1">
            <b>{t('SellOrderRate-label-price')}</b>
          </Label>
          <Input onChange={handleChange(setPrice)} value={price} />
        </FormGroup>
      </Col>
      <Col xs="auto" md={2}>
        <FormGroup>
          <Label className="mb-1">
            <b>{t('SellOrderRate-label-rateFrom')}</b>
          </Label>
          <h4 className="m-0">
            <CurrencySymbolDisplay value={order.rateFrom} currency={currency} />
          </h4>
        </FormGroup>
      </Col>
      <Col xs="auto" md={2}>
        <FormGroup>
          <Label className="mb-1">
            <b>{t('SellOrderRate-label-marketplaceFee')}</b>
          </Label>
          <h4 className="m-0">
            <CurrencySymbolDisplay
              value={feeInfo?.amount ?? 0}
              currency={feeInfo?.status === CommissionType.Percentage ? { symbol: '%', abbreviation: '%' } : currency}
            />
          </h4>
        </FormGroup>
      </Col>
      <Col xs="auto" md={2}>
        <FormGroup>
          <Label className="mb-1">
            <b>{t('SellOrderRate-label-totalAfterFee')}</b>
          </Label>
          <h4 className="m-0">
            <CurrencySymbolDisplay value={totalAfterFee} currency={currency} />
          </h4>
        </FormGroup>
      </Col>
    </>
  );
};

export default SellOrderRate;
