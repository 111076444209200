import React from 'react';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';

const SkeletonMetaKey: React.FC = () => {
  return (
    <StyledSkeleton>
      <Skeleton height={15} count={3} />
    </StyledSkeleton>
  );
};

export default SkeletonMetaKey;

const StyledSkeleton = styled.div`
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 4px;
`;
