import React from 'react';

import { Col, FormGroup, Input, Label } from 'atoms';
import { CustomInput } from 'reactstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface MetaValueField {
  type: string;
  value: string;
  label: string;
  id: string;

  update(value: string): void;
}

const MetaValueField: React.FC<MetaValueField> = ({ type, value, label, id, update }) => {
  if (type === 'text') {
    return (
      <Col md={6}>
        <FormGroup>
          <Label>{label}</Label>
          <Input
            type="text"
            placeholder={label}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => update(e.currentTarget.value)}
          />
        </FormGroup>
      </Col>
    );
  }

  if (type === 'richtext') {
    return (
      <Col md={10}>
        <FormGroup>
          <Label>{label}</Label>
          <ReactQuill theme="snow" value={value} onChange={update} />
        </FormGroup>
      </Col>
    );
  }

  if (type === 'boolean') {
    return (
      <Col md={6}>
        <CustomInput
          id={id}
          type="checkbox"
          onChange={() => update(value !== 'true' ? 'true' : 'false')}
          label={label}
          checked={value === 'true'}
        />
      </Col>
    );
  }

  if (type === 'number') {
    return (
      <Col md={6}>
        <FormGroup>
          <Label>{label}</Label>
          <Input
            type="number"
            placeholder={label}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => update(`${parseInt(e.target.value, 10) || 0}`)}
          />
        </FormGroup>
      </Col>
    );
  }

  return null;
};

export default MetaValueField;
