import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import {
  useMeQuery,
  ExchangeType,
  GetExchangeOfferDetailDocument,
  useCreateNewOfferMutation,
  useGetSharesWalletsQuery,
  useGetInvestorSharesQuery,
  useGetInvestorBalanceQuery,
} from 'services/apollo';
import { ExchangeOfferDetailOrder } from 'services/apollo/exchange';

import { FormGroup, Button, Col, Input, Row, Label, Select, Loading, BsSwal, FontAweIcon } from 'atoms';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import useNewOfferState from './useNewOfferState';

interface NewOfferProps {
  order: ExchangeOfferDetailOrder;
}

const NewOffer: React.FC<NewOfferProps> = ({ order }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const { state, empty, onChange, checkEmpty } = useNewOfferState(order.ID, order.atomicSwapAcceptable);
  const [price, setPrice] = useState(order.rateFrom / order.shares);

  const { data: meData } = useMeQuery({ fetchPolicy: 'network-only' });
  const { investor } = meData?.investorUser || {};

  const { data: walletsData, loading: walletsLoading } = useGetSharesWalletsQuery({
    variables: { shareTypeID: order.shareType.ID },
  });

  const { data: sharesData, loading: sharesLoading } = useGetInvestorSharesQuery({
    variables: { shareTypeID: order.shareType.ID },
  });

  const { data: balanceData, loading: balanceLoading } = useGetInvestorBalanceQuery({
    variables: {
      currencyID: order.shareType.currency.ID,
      stoID: order.stoID,
    },
  });

  const [createNewOffer] = useCreateNewOfferMutation({
    refetchQueries: [{ query: GetExchangeOfferDetailDocument, variables: { orderID: order.ID } }],
  });

  useEffect(() => {
    onChange({ sharesPartial: order.shares });
  }, []);

  useEffect(() => {
    onChange({ rateFrom: price * state.sharesPartial });
  }, [onChange, price, state.sharesPartial]);

  if (walletsLoading || !walletsData || sharesLoading || !sharesData || balanceLoading || !balanceData) {
    return <Loading />;
  }

  const { getSharesWallets: wallets } = walletsData;
  const internalWallet = wallets.find((wallet) => wallet.publicKey === 'platform');
  const internalShares = internalWallet?.shares || 0;
  const externalWallets = wallets.filter((wallet) => wallet.publicKey !== 'platform');
  const externalShares = externalWallets.reduce((shares, wallet) => shares + wallet.shares, 0);
  const { investorShare } = sharesData;
  let availableShares = 0;
  if (order.shareType.isBlockchain) {
    availableShares = order.atomicSwapAcceptable ? externalShares : internalShares;
  } else {
    availableShares = investorShare?.shares || 0;
  }

  const goBack = (): void => history.push(`/investor/trading`);

  const startNegotiation = (): void => {
    history.push(`/investor/price-negotiation/${order.ID}/${investor?.ID}`);
  };

  const handleSubmit = (): void => {
    if (checkEmpty()) {
      return;
    }
    BsSwal.fire({
      title: t('NewOffer-BsSwal-OfferConfirmation'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('NewOffer-BsSwal-ConfirmButton'),
      cancelButtonText: t('NewOffer-BsSwal-Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        createNewOffer({ variables: { offer: state } })
          .then(() => {
            BsSwal.fire({
              title: t('NewOffer-BsSwal-OfferCreated'),
              icon: 'success',
            });
            goBack();
          })
          .catch((err) => {
            BsSwal.fire({
              icon: 'error',
              text: err.message,
            });
          });
      }
    });
  };

  const handleChange = (key: string): ((e: React.ChangeEvent<HTMLInputElement>) => void) => {
    return (e) => {
      // expect NaN
      const value = parseFloat(e.currentTarget.value) || 0;
      onChange({ [key]: value });
    };
  };

  // TODO: not needed in the new invoice payment flow as no balances and balances flows should work in parallel,
  //  TODO: but do double check in DIG-1675 just to make sure
  // const { investorBalance } = balanceData;
  // const investorBalanceAmount = investorBalance?.amount || 0;
  // if (order.type === ExchangeType.Sell && !order.atomicSwapAcceptable && !investorBalanceAmount) {
  //   return (
  //     <Alert color="warning">
  //       <label>
  //         <FontAweIcon icon="info-circle" size="lg" /> {t('NewOffer-label-InsufficientFunds-Part1')}{' '}
  //         <CurrencySymbolDisplay value={order.rateFrom} currency={order.shareType.currency} />{' '}
  //         {t('NewOffer-label-InsufficientFunds-Part2')}{' '}
  //         <CurrencySymbolDisplay value={investorBalanceAmount} currency={order.shareType.currency} />
  //       </label>
  //     </Alert>
  //   );
  // }
  if (order.type === ExchangeType.Buy && !availableShares) {
    return (
      <Alert color="warning">
        <label>
          <FontAweIcon icon="info-circle" size="lg" />{' '}
          {t('NewOffer-label-InsufficientAvailableShares', {
            shares: order.shares,
            availableShares,
          })}
        </label>
      </Alert>
    );
  }
  if (order.atomicSwapAcceptable && !externalWallets.length) {
    return (
      <Alert color="warning">
        <label>
          <FontAweIcon icon="info-circle" size="lg" /> {t('NewOffer-label-NoExternalWallet')}
        </label>
      </Alert>
    );
  }

  // TODO: not needed in the new invoice payment flow, but do double check in DIG-1675 just to make sure
  // const renderBalance = () => {
  //   if (order.atomicSwapAcceptable) {
  //     return null;
  //   }
  //   return (
  //     <Alert color="info">
  //       <label>
  //         <FontAweIcon icon="info-circle" size="lg" />{' '}
  //         {order.type === ExchangeType.Sell ? (
  //           <>
  //             {t('NewOffer-label-BalanceAmount-Part1')}{' '}
  //             <CurrencySymbolDisplay value={investorBalanceAmount} currency={order.shareType.currency} />{' '}
  //             {t('NewOffer-label-BalanceAmount-Part2')}
  //           </>
  //         ) : (
  //           t('NewOffer-label-offerDetails2', { amount: availableShares })
  //         )}
  //       </label>
  //     </Alert>
  //   );
  // };

  // For Further Refactoring : DIG-1675
  const renderWallets = () => {
    if (!order.atomicSwapAcceptable) {
      return null;
    }
    const options = externalWallets.map((item) => ({ value: item.publicKey, label: item.publicKey }));
    return (
      <Row>
        <Col>
          <Row>
            <Col md={3}>
              <Label>{t('NewOffer-label-wallet')}</Label>
            </Col>
            <Col md={7}>
              <Select
                class="form-control border-input"
                invalid={empty.includes('atomicBuyerPublicKey')}
                options={options}
                placeholder={t('NewOffer-placeholder-wallet')}
                value={{ value: state.atomicBuyerPublicKey, label: state.atomicBuyerPublicKey }}
                onChange={(option: { value: string; label: string }) =>
                  onChange({ atomicBuyerPublicKey: option.value })
                }
              />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  return (
    <div className="content">
      <>
        <Row>
          <Col xs="auto">
            <FormGroup>
              <Label>{t('NewOffer-label-shareCount')}</Label>
              <Input
                invalid={empty.includes('sharesPartial')}
                onChange={handleChange('sharesPartial')}
                value={state.sharesPartial}
              />
            </FormGroup>
          </Col>
          <Col xs="auto">
            <FormGroup>
              <Label>{t('NewOffer-label-price')}</Label>
              <Input
                invalid={empty.includes('rateFrom')}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrice(parseFloat(e.currentTarget.value) || 0)}
                value={price}
              />
            </FormGroup>
          </Col>
          <Col xs="auto" className="justify-content-center d-flex flex-column">
            <h4 className="m-0">
              <CurrencySymbolDisplay value={state.rateFrom} currency={order.shareType.currency} />
            </h4>
          </Col>
        </Row>
        {renderWallets()}
        <br />
        <Row>
          <Col md={3}>
            <Button onClick={handleSubmit}>{t('NewOffer-Button-SubmitOffer')}</Button>
          </Col>
          <Col md={3}>
            <Button onClick={startNegotiation}>{t('NewOffer-button-negotiate')}</Button>
          </Col>
          <Col md={3}>
            <Button onClick={goBack}>{t('NewOffer-button-cancel')}</Button>
          </Col>
        </Row>
      </>
    </div>
  );
};

export default NewOffer;
