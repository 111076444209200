import React from 'react';
import { Alert, Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

export interface WhitelistProgressionDisplayProps {
  condition: boolean;
  translationKey: string;
}

const WhitelistProgressionDisplay: React.FC<WhitelistProgressionDisplayProps> = (props) => {
  const { t } = useTranslation();
  const { condition, translationKey } = props;

  return (
    <>
      <Row>
        <Col>{condition ? <Alert color="success">{t(translationKey)}</Alert> : <b>{t(translationKey)}</b>}</Col>
      </Row>
    </>
  );
};

export default WhitelistProgressionDisplay;
