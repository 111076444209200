import React from 'react';
import { ButtonProps, Spinner } from 'reactstrap';
import { Button } from '.';

interface LoadingButtonProps extends ButtonProps {
  isLoading: boolean;
}

const LoadingButton: React.FC<LoadingButtonProps> = ({ children, isLoading, ...buttonProps }) => (
  <Button disabled={isLoading} {...buttonProps}>
    {isLoading ? <Spinner style={{ color: 'white' }} /> : children}
  </Button>
);

export default LoadingButton;
