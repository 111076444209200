import React, { useState } from 'react';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw } from 'draft-js';
import { FormFeedback } from 'reactstrap';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardBody, Form, FormGroup, Input, Label, Col, Row, BsSwal } from 'atoms';
import { useGetInvestorCommissionsSumQuery, useSendLinkToFriendsMutation } from 'services/apollo';
import { CardHeader } from 'components/card-header/CardHeader';

const TellFriends: React.FC = () => {
  const { t } = useTranslation();

  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [emailsState, setEmailsState] = useState('');
  const [areEmailsValid, setAreEmailsValid] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sendLink] = useSendLinkToFriendsMutation();

  const { data: commissionData } = useGetInvestorCommissionsSumQuery({
    fetchPolicy: 'no-cache',
  });

  const onEditorStateChange = (state: EditorState) => setEditorState(state);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();

    const validate = validateEmails();

    if (validate) {
      // split into emails and remove white spaces
      let emails = emailsState.split(',').map((email) => email.trim());
      // remove duplicate emails
      emails = Array.from(new Set(emails).values());
      const data = {
        emails,
        content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
      };
      setLoading(true);
      sendLink({ variables: { data } })
        .then(() => {
          return BsSwal.fire({
            title: t('TellAFriend-sendEmail-success'),
            icon: 'success',
          });
        })
        .then(() => {
          setEditorState(EditorState.createEmpty());
          setEmailsState('');
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          return BsSwal.fire({
            title: t('TellAFriend-sendEmail-error'),
            icon: 'error',
          });
        });
    }
    setAreEmailsValid(validate);
  };

  const validateEmails = () => {
    const emails = emailsState.split(',');
    return !emails.some((email) => {
      email = email.trim();
      return !email.match(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i);
    });
  };

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('Invite-a-friend')}
          caption={t('Do-you-know-investors-in-or-managers-of-private-companies')}
          imgSrc="/img/user.png"
        />
        <CardBody className="mb-2">
          <p>
            <span className="font-weight-bold">{t('Collected-Commissions')}</span>
            <strong>
              <mark className="text-info">
                {commissionData?.getInvestorCommissionsSum ? commissionData.getInvestorCommissionsSum : 0}
              </mark>
            </strong>
            <small>{t('Pending')}</small>
          </p>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>{t('Email-Address')}</Label>
                  <Input
                    id="email-address"
                    name="emailAddress"
                    type="textarea"
                    maxLength="700"
                    placeholder={t('Email-Address')}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setEmailsState(e.target.value);
                    }}
                    value={emailsState}
                    required
                    rows={3}
                    invalid={!areEmailsValid}
                    onFocus={() => setAreEmailsValid(true)}
                  />
                  <FormFeedback className="h6 text-lowercase"> {t('Email address is not valid')} </FormFeedback>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>{t('Enter-invitation-text')}</Label>
                  <Editor
                    editorState={editorState}
                    wrapperClassName="demo-wrapper"
                    editorClassName="demo-editor form-control border-input h-editor"
                    onEditorStateChange={onEditorStateChange}
                    placeholder={t('TellAFriend-emailContent-placeholder')}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Button type="submit" color="primary" disabled={loading}>
              {t('Send-Proposal')}
            </Button>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default TellFriends;
