import React from 'react';
import {
  BuyAlertStatus,
  useFindInvestorDividendPayoutsQuery,
  useInvestorActivePropertiesQuery,
  useInvestorAppDataQuery,
  useInvestorBuyAlertsQuery,
  useInvestorPortfolioQuery,
  useMeQuery,
  usePintSynchronizeTransactionsInvestorQuery,
} from 'services/apollo';
import { BsSwal, Card, CardBody, Col, Loading, Row, TH, THEAD, TR } from 'atoms';
import PortfolioSto from 'pages/my-portfolio/components/PortfolioSto';
import { CardHeader } from 'components/card-header/CardHeader';
import AlertItem from 'pages/my-portfolio/components/AlertItem';
import PortfolioOverview from 'pages/my-portfolio/components/PortfolioOverview';
import SUMMARY from 'assets/img/summary.png';
import { useTranslation } from 'react-i18next';
import MoonpayFirstAlert from 'pages/moonpay/components/MoonpayFirstAlert';
import { useHistory } from 'react-router-dom';
import Invoice from 'pages/invoice/Invoice';
import PortfolioOverviewMarketspace from 'pages/my-portfolio/components/PortfolioOverviewMarketspace';
import { Table } from 'reactstrap';
import { arrayRemoveDuplicates } from 'services/core/helpers';
import PortfolioPropertySteps from './components/PortfolioPropertySteps';

const Portfolio: React.FC = () => {
  const { t } = useTranslation();
  const { data } = useMeQuery();
  const { investor } = data?.investorUser || {};
  const { data: portfolio, loading: load1 } = useInvestorPortfolioQuery({
    variables: { _id: Number(investor?.ID), status: [BuyAlertStatus.Pending] },
    fetchPolicy: 'no-cache',
  });

  // TODO: refactor pollInterval to websocket
  const { data: investorAlerts, previousData: previousBuyAlerts } = useInvestorBuyAlertsQuery({
    variables: {
      status: [
        BuyAlertStatus.Pending,
        BuyAlertStatus.KycRequired,
        BuyAlertStatus.AccreditationRequired,
        BuyAlertStatus.AccreditationPending,
        BuyAlertStatus.PendingDocuments,
        BuyAlertStatus.BlockchainProcessing,
        BuyAlertStatus.PaymentOngoing,
      ],
    },
    fetchPolicy: 'no-cache',
    pollInterval: 5000,
  });

  // TODO: refactor pollInterval to websocket
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { data: transactionSync, previousData: previousTransactionSync } = usePintSynchronizeTransactionsInvestorQuery({
    fetchPolicy: 'no-cache',
    pollInterval: 600000, // 10min
  });
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  const history = useHistory();

  const { data: activeProperty, loading: load2 } = useInvestorActivePropertiesQuery({
    variables: {
      buyEnabledFilterInput: {
        isBuyStoEnabled: true,
      },
    },
    fetchPolicy: 'no-cache',
  });
  const { data: dividendPayouts, loading: load4 } = useFindInvestorDividendPayoutsQuery({
    fetchPolicy: 'no-cache',
  });

  if (
    load1 ||
    load2 ||
    load4 ||
    appDataLoading ||
    !portfolio ||
    !activeProperty ||
    !dividendPayouts ||
    !appData ||
    !investorAlerts
  ) {
    return <Loading />;
  }

  const { investorActiveProperties } = activeProperty;
  const shares = portfolio?.investorShares;
  const investorDividendPayouts = dividendPayouts?.findInvestorDividendPayouts;
  const alerts = investorAlerts?.investorBuyAlerts;
  const previousAlerts = previousBuyAlerts?.investorBuyAlerts;
  const currencies = arrayRemoveDuplicates(
    shares.map((e) => {
      return {
        symbol: e.shareType?.currency.symbol,
        abbreviation: e.shareType?.currency.abbreviation,
        isBlockchainBased: e.shareType.currency.isBlockchainBased,
      };
    }),
    'abbreviation',
  );
  const {
    isOfferingProgressTrackingEnabled,
    isBuyStoWhitelistPerInvestorEnabled,
    IsMoonpayEnabled: isMoonpayEnabled,
    IsMarketSpace: isMarketspaceEnabled,
    isPortfolioOverviewEnabled,
  } = appData.investorAppParameters;

  if ((previousAlerts?.length ?? 0) > (alerts?.length ?? 0)) {
    BsSwal.fire({
      title: t('Portfolio-PopUp-Title'),
      html: t('Portfolio-PopUp-Message'),
      icon: 'info',
      didClose: () => {
        history.go(0);
      },
    });
  }
  if (!shares || !alerts || !investorDividendPayouts || !currencies) {
    return <Loading />;
  }
  const totalBalances = currencies.map((c) => {
    return {
      abbreviation: c.abbreviation ?? '',
      symbol: c.symbol ?? '',
      amount: 0,
      isBlockchainBased: c.isBlockchainBased,
    };
  });
  const sharesIds = new Set(shares.map((e) => e.stoID));

  let properties = investorActiveProperties;
  if (isBuyStoWhitelistPerInvestorEnabled) {
    properties = investorActiveProperties.filter((p) => p.isInvestorBuyEnabled);
  } else {
    properties = investorActiveProperties?.filter((e) => sharesIds.has(e.ID));
  }

  const alertPropertiesMap = new Map(investorActiveProperties.map((property) => [property.ID, property]));

  let paidDividends = 0;
  investorDividendPayouts.forEach((payout) => {
    const payoutInterval = isMarketspaceEnabled
      ? new Date(payout.lastUpdatedAt).getFullYear() === new Date().getFullYear()
      : new Date(payout.lastUpdatedAt).getMonth() === new Date().getMonth();
    if (payout.status === 'completed' && payoutInterval) {
      paidDividends += payout.amount;
    }
  });
  let totalShares = 0;
  shares.forEach((share) => {
    totalShares += share.shares;
    totalBalances.forEach((balance) => {
      if (balance.symbol === share.shareType?.currency.symbol && share.shareType?.premiumValue != null)
        balance.amount += share.shares * share.shareType?.premiumValue;
    });
  });

  const moveToActiveProperty = (e: React.MouseEvent<Element, MouseEvent>) => {
    e.preventDefault();
    history.push('active-properties');
  };
  const isInvoicingEnabled = appData?.investorAppParameters?.isInvoicingEnabled;
  const isInvestmentReturnCalculationEnabled = appData?.investorAppParameters?.isInvestmentReturnCalculationEnabled;
  const isSellBackEnabled = appData?.investorAppParameters?.isSellBackEnabled;
  const isShareCertificateButtonEnabled = appData?.investorAppParameters?.isShareCertificateButtonEnabled;
  const isMergingPaymentsSharesRequestsEnabled = appData?.investorAppParameters?.isMergingPaymentsSharesRequestsEnabled;
  const handleDisplayingInvoices = () => {
    if (isMergingPaymentsSharesRequestsEnabled) {
      return false;
    }
    return !!isInvoicingEnabled;
  };
  return (
    <div className="content">
      {isPortfolioOverviewEnabled &&
        (isInvestmentReturnCalculationEnabled ? (
          <PortfolioOverviewMarketspace
            totalBalances={totalBalances}
            yearlyDividends={paidDividends}
            shares={shares}
            appData={appData.investorAppParameters}
          />
        ) : (
          <PortfolioOverview
            totalShares={totalShares}
            totalBalances={totalBalances}
            monthlyDividend={paidDividends}
            shares={shares}
            appData={appData.investorAppParameters}
          />
        ))}
      {alerts.length ? (
        <Row>
          <Col md={12}>
            <Card>
              <CardHeader text={t('Portfolio-AlertItem-Title')} caption={t('Portfolio-AlertItem')} imgSrc={SUMMARY} />
              <CardBody className="mb-2">
                <Table responsive borderless>
                  <THEAD>
                    <TR>
                      <TH>
                        <label>{t('Portfolio-Transaction-Headers-Type')}</label>
                      </TH>
                      <TH>
                        <label>{t('Portfolio-Transaction-Headers-Date')}</label>
                      </TH>
                      <TH>
                        <label>{t('Portfolio-Transaction-Headers-Company-Project')}</label>
                      </TH>
                      <TH>
                        <label>{t('Portfolio-Transaction-Headers-ShareType')}</label>
                      </TH>
                      {isMergingPaymentsSharesRequestsEnabled && (
                        <TH>
                          <label>{t('Portfolio-Transaction-Headers-Price-To-pay')}</label>
                        </TH>
                      )}
                      <TH>
                        <label>{t('Portfolio-Transaction-Headers-Shares')}</label>
                      </TH>
                      <TH>
                        <label>{t('Portfolio-Transaction-Headers-Payment-Status')}</label>
                      </TH>
                      <TH />
                    </TR>
                  </THEAD>
                  {alerts.map(
                    (alert) =>
                      !alert.isHiddenForInvestor && (
                        <AlertItem
                          key={alert.ID}
                          alert={alert}
                          property={alertPropertiesMap.get(alert.stoID)}
                          appData={appData.investorAppParameters}
                          isMergingEnabled={isMergingPaymentsSharesRequestsEnabled}
                          isInvoicingEnabled={isInvoicingEnabled}
                        />
                      ),
                  )}
                </Table>
                {isMoonpayEnabled && <MoonpayFirstAlert alerts={alerts} />}
              </CardBody>
            </Card>
          </Col>
        </Row>
      ) : null}
      {handleDisplayingInvoices() && <Invoice properties={alertPropertiesMap} />}
      {isOfferingProgressTrackingEnabled && (
        <PortfolioPropertySteps
          activeProperties={properties}
          isBuyStoWhitelistPerInvestorEnabled={isBuyStoWhitelistPerInvestorEnabled}
          shares={shares}
          appData={appData.investorAppParameters}
        />
      )}
      {properties.length ? (
        <Card>
          <CardHeader text={t('Portfolio-Accessible-Offers')} />
          <Row>
            {properties.map((property) => (
              <Col md={12} key={property.ID}>
                <PortfolioSto
                  property={property}
                  isMarketSpace={isMarketspaceEnabled}
                  shares={shares?.filter((share) => share.stoID === property.ID)}
                  isSellBackEnabled={isSellBackEnabled}
                  isShareCertificateButtonEnabled={isShareCertificateButtonEnabled}
                  appData={appData?.investorAppParameters}
                />
              </Col>
            ))}
          </Row>
        </Card>
      ) : (
        <Card>
          <CardBody>
            <Row style={{ textAlign: 'center' }}>
              <Col>{t('portfolioUnaffiliatedLabel')}</Col>
            </Row>
            <br />
            <Row style={{ textAlign: 'center' }}>
              <Col>
                {t('portfolioPleaseNavigateTo')}
                <a
                  href="/"
                  onClick={(e) => {
                    moveToActiveProperty(e);
                  }}
                >
                  <span> {t('ActiveProperties')} </span>
                </a>
                {t('portfolioBuyShares')}
              </Col>
            </Row>
            <br />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default Portfolio;
