import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { AtomicSwapStatus, ExchangeType, useMeQuery, useAffirmPolymeshExchangeSwapMutation } from 'services/apollo';
import Auth from 'services/core/auth';
import { ExchangeOfferDetailOffer, ExchangeOfferDetailOrder } from 'services/apollo/exchange';
import { BsSwal, Button, FontAweIcon, Loading, Col, Input, Row } from 'atoms';

const PolymeshSwapComponent: React.FC<{ order: ExchangeOfferDetailOrder; offer: ExchangeOfferDetailOffer }> = ({
  order,
  /* offer , */
}) => {
  const { t } = useTranslation();

  const [atomicSwapCurrentStatus, setAtomicSwapCurrentStatus] = useState(order.atomicSwapCurrentStatus);
  const [isSwapComponentEnabled, setIsSwapComponentEnabled] = useState(false);
  const [isSwapLoading, setIsSwapLoading] = useState(false);
  const [privateKey, setPrivateKey] = useState('');

  const { loading, data: investorData } = useMeQuery();
  const [affirmPolymeshSwap] = useAffirmPolymeshExchangeSwapMutation();

  useEffect(() => {
    if (order.type === ExchangeType.Sell) {
      if (Auth.payload.ID === order.investorID) {
        setIsSwapComponentEnabled([AtomicSwapStatus.BuyerCompleted].includes(atomicSwapCurrentStatus));
      } else {
        setIsSwapComponentEnabled([AtomicSwapStatus.SellerSent].includes(atomicSwapCurrentStatus));
      }
    }
    if (order.type === ExchangeType.Buy) {
      if (Auth.payload.ID === order.investorID) {
        setIsSwapComponentEnabled([AtomicSwapStatus.SellerSent].includes(atomicSwapCurrentStatus));
      } else {
        setIsSwapComponentEnabled([AtomicSwapStatus.BuyerCompleted].includes(atomicSwapCurrentStatus));
      }
    }
  }, [atomicSwapCurrentStatus, order.investorID, order.type]);

  if (loading || !investorData) {
    return <Loading />;
  }

  const isOrderCreator = investorData.investorUser?.investor.ID === order.investorID;

  const startSwap = async (): Promise<void> => {
    if (privateKey.length > 0) {
      setIsSwapLoading(true);
      return affirmPolymeshSwap({ variables: { orderID: order.ID, secretKey: privateKey } })
        .then(() => {
          setIsSwapLoading(false);
          setPrivateKey('');
          if (order.type === ExchangeType.Sell) {
            if (Auth.payload.ID === order.investorID) {
              if (order.atomicSwapCurrentStatus === AtomicSwapStatus.BuyerCompleted) {
                setAtomicSwapCurrentStatus(AtomicSwapStatus.Processed);
              }
            } else if (order.atomicSwapCurrentStatus === AtomicSwapStatus.SellerSent) {
              setAtomicSwapCurrentStatus(AtomicSwapStatus.BuyerCompleted);
            }
          }
          BsSwal.fire({
            title: t('PolymeshSwapComponent-popUp-startswap-success'),
            icon: 'success',
          });
        })
        .catch((err) => {
          setIsSwapLoading(false);
          BsSwal.fire({
            icon: 'error',
            text: err.message,
          });
        });
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      startSwap();
    }
  };

  if (order.atomicSwapCurrentStatus === AtomicSwapStatus.NotInitialized) {
    return <></>;
  }

  return (
    <>
      {isOrderCreator ? null : (
        <>
          <h4>{t('PolymeshSwapComponent-header')}</h4>
          <hr />
        </>
      )}
      {isSwapComponentEnabled || order.atomicSwapCurrentStatus === AtomicSwapStatus.Processed ? null : (
        <div className="alert alert-warning" role="alert">
          {t('PolymeshSwapComponent-statusLabel-inProgressOnTheOtherParty')}
        </div>
      )}
      {order.atomicSwapCurrentStatus === AtomicSwapStatus.Processed ? (
        <div className="alert alert-success" role="alert">
          {t('PolymeshSwapComponent-statusLabel-completed')}
        </div>
      ) : null}
      <StepPanel disabled={!isSwapComponentEnabled}>
        <Row className="d-flex align-items-center">
          <Col xs="auto" md={9}>
            <Input
              autoComplete="off"
              required
              maxLength="1000"
              name="privateKey"
              type="password"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrivateKey(e.currentTarget.value || '')}
              onKeyDown={handleKeyDown}
              value={privateKey}
              placeholder={t('PolymeshSwapComponent-inputPlaceholder-privateKey')}
            />
          </Col>
          <Col xs="auto" md={3}>
            <Button size="lg" disabled={isSwapLoading} block onClick={startSwap}>
              {t('PolymeshSwapComponent-button-startSwap')}
            </Button>
          </Col>
        </Row>
        {isSwapLoading ? (
          <p className="text-warning">
            <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
            {t('PolymeshSwapComponent-label-loading')}
          </p>
        ) : null}
      </StepPanel>
    </>
  );
};

const StepPanel = styled.div<{ disabled: boolean }>`
  pointer-events: ${(p) => (p.disabled ? 'none' : 'auto')};
  opacity: ${(p) => (p.disabled ? 0.5 : 1)};
  padding: 15px;
  margin: 10px;
  border-radius: 10px;
  background: #ebf0f7;
`;

export default PolymeshSwapComponent;
