import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Row, Col, FormGroup, Input, Label, Select, Loading } from 'atoms';
import { InvestingEntityInput, useCountriesQuery } from 'services/apollo';
import { FormFeedback } from 'reactstrap';

interface LimitedInvestingEntityFormBaseProps {
  state: InvestingEntityInput;
  onChange: (data: Partial<InvestingEntityInput>) => void;
  validationError: InvestingEntityInput;
  canModifyEntity?: boolean;
}

const LimitedInvestingEntityFormBase: React.FC<LimitedInvestingEntityFormBaseProps> = ({
  state,
  onChange,
  validationError,
  canModifyEntity,
}) => {
  const { t } = useTranslation();
  const { data } = useCountriesQuery();
  const [countryValid, setCountryValid] = useState('United States');

  if (!data) return <Loading />;

  const countryOptions = data.countries.map((value) => ({
    value,
    label: value,
  }));

  return (
    <>
      <h3 className="pt-4">{t('entityItemRowEntityInformation')}</h3>
      <FormGroup>
        <Label>{t('entityItemRowLegalEntityNameLabel')}*</Label>
        <Input
          invalid={!!validationError.name}
          name="legalEntityName"
          max="30"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            onChange({ name: e.target.value });
            validationError.name = '';
          }}
          placeholder={t('entityItemRowLegalEntityNameLabel')}
          value={state.name}
          disabled={!canModifyEntity}
        />
        {validationError.name ? <FormFeedback>{validationError.name}</FormFeedback> : ''}
      </FormGroup>
      <Row>
        <Col>
          <FormGroup>
            <Label>{t('entityItemRowCountryLabel')}*</Label>
            <Select
              class="form-control border-input"
              name="countryValid"
              options={countryOptions}
              value={{ value: countryValid, label: countryValid }}
              onChange={({ value }: { value: string }) => setCountryValid(value)}
              isDisabled={!canModifyEntity}
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>{t('entityItemRowStateLabel')}*</Label>
            <Input
              name="state"
              invalid={validationError.state}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange({ state: e.target.value });
                validationError.state = '';
              }}
              placeholder={t('entityItemRowStateLabel')}
              value={state.state}
              disabled={!canModifyEntity}
            />
            {validationError.state ? <FormFeedback>{validationError.state}</FormFeedback> : ''}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <Label>{t('entityItemRowCityLabel')}*</Label>
            <Input
              invalid={validationError.city}
              name="city"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange({ city: e.target.value });
                validationError.city = '';
              }}
              placeholder={t('entityItemRowCityLabel')}
              value={state.city}
              disabled={!canModifyEntity}
            />
            {validationError.city ? <FormFeedback>{validationError.city}</FormFeedback> : ''}
          </FormGroup>
        </Col>
      </Row>
      <hr />
    </>
  );
};

export default LimitedInvestingEntityFormBase;
