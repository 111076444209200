import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { Input } from 'atoms';

import { SectionWrapper } from './forum-styles';

interface SearchBoxProps {
  value: string;
  applySearch: (searchText: string) => void;
}

const SearchBox: React.FC<SearchBoxProps> = ({ value, applySearch }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper>
      <div className="position-relative">
        <SearchInput
          type="text"
          autoComplete="off"
          maxLength="500"
          value={value}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => applySearch(e.currentTarget.value || '')}
          placeholder={t('SearchBox-SearchInput-Placeholder')}
        />
        <SearchIcon className="fa fa-search" />
      </div>
    </SectionWrapper>
  );
};

const SearchInput = styled(Input)`
  height: 50px;
`;

const SearchIcon = styled.i`
  position: absolute;
  right: 13px;
  top: 15px;
`;

export default SearchBox;
