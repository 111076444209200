import { QueryInfo } from '@apollo/client/core/QueryInfo';
import { useState } from 'react';

/**
 * Usage:
 const [error, setGqlError] = useGqlErrorExtractor(YOUR OBJECT's DEFAULT VALUES);
 */
function useGqlErrorExtractor<S>(emptyErrorObject: S): [S, (err: QueryInfo | null) => void] {
  const [errorDictionary, setErrorDictionary] = useState(emptyErrorObject);
  function setGqlErrors(err: QueryInfo | null) {
    if (err?.graphQLErrors?.length) {
      const errors = err.graphQLErrors[0].extensions?.exception.validationErrors ?? [];
      errors.forEach((validationError: { property: string; constraints: string }) => {
        const key = validationError.property as keyof S;
        const newState = { [key]: Object.values(validationError.constraints) };
        setErrorDictionary((prevState) => ({ ...prevState, ...newState }));
      });
    } else {
      setErrorDictionary(emptyErrorObject);
    }
  }
  return [errorDictionary, setGqlErrors];
}

/**
 * extracts the GQL validation errors defined as @Min() or @Max() without using a state variable
 * use this in combination with the getTranslationKeyOfApiError hook to get both the Validation errors and the translated errors
 * @param err
 * @returns an object containing all invalid properties as Attributes and the failed constraint as the Vallues {Attrubite: Value}
 */
export function extractValidationError<S>(err: QueryInfo | null): S | null {
  // TODO: DIG-1742 add support for parameters in the frontend gql errors
  if (!err?.graphQLErrors?.length) {
    return null;
  }
  // find all validation errors
  const errors = err.graphQLErrors[0].extensions?.exception.validationErrors ?? [];
  // reduce them to a single object containing all properties as {fieldName: value}
  return errors.reduce(
    (accumulator: Record<keyof S, string[]>, validationError: { property: string; constraints: string }) => {
      const key = validationError.property as keyof S; // the property name which was not valid
      return {
        ...accumulator,
        [key]: Object.values(validationError.constraints)[0], // the error
      };
    },
    {},
  );
}

export default useGqlErrorExtractor;
