import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import { useInvestorAppDataQuery, useInvestorStoDetailQuery, useMeQuery } from 'services/apollo';

import { BsSwal, Button, Card, CardBody, BrandIcon, Loading } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import ShareCertificateFrame from './components/ShareCertificateFrame';

const BeautifulShareCertificate: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const redirectUrl = `/investor/Portfolio`;
  const { _id } = useParams<{ _id: string }>();
  const stoID: number = parseInt(_id, 10);

  if (!stoID) {
    BsSwal.fire({
      title: t('BeautifulShareCertificate-BsSwal-No-STO'),
      icon: 'error',
    }).then(() => {
      history.push(redirectUrl);
    });
  }

  const { data: investorAppData, loading: investorAppLoading } = useInvestorAppDataQuery();
  const { data: stoData, loading: stoLoading } = useInvestorStoDetailQuery({
    variables: {
      _id: stoID,
    },
  });
  const { data: meData, loading: meLoading } = useMeQuery();

  if (
    investorAppLoading ||
    !investorAppData ||
    stoLoading ||
    !stoData ||
    meLoading ||
    !meData ||
    !meData.investorUser ||
    !stoData.findSto
  ) {
    return <Loading />;
  }

  const { isShareCertificatePageEnabled } = investorAppData.investorAppParameters;
  if (!isShareCertificatePageEnabled) {
    history.push(redirectUrl);
  }

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('BeautifulShareCertificate-CardHeader-text')}
          icon={<BrandIcon icon="stamp" color="cyan" pill />}
        />
        <CardBody>
          <ShareCertificateFrame stoDetails={stoData.findSto} investor={meData.investorUser} />
          <Button onClick={() => history.goBack()}>{t('Back')}</Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default BeautifulShareCertificate;
