import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Card, CardBody, Col, DocumentLink, Loading, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useInvestorMeetingQuery } from 'services/apollo';
import { useActiveSto } from 'hooks';
import MarkupIframe from 'pages/MarkupIframe';
import ContactPerson from './MeetingDetails/ContactPerson';

const MeetingDetails: React.FC = () => {
  const { _id } = useParams<{ _id: string }>();
  const votingId = Number(_id);
  const { t } = useTranslation();
  const { sto } = useActiveSto();

  const { data, loading } = useInvestorMeetingQuery({
    variables: {
      meetingId: votingId,
      _id: sto,
    },
  });

  if (loading || !data) {
    return <Loading />;
  }

  const { investorMeeting } = data;

  if (!investorMeeting) {
    return null;
  }

  const responsiblePerson = {
    name: investorMeeting.nameResponsiblePerson || '',
    email: investorMeeting.emailResponsiblePerson || '',
    phone: investorMeeting.phoneResponsiblePerson || '',
  };

  const proxyPerson = {
    name: investorMeeting.nameProxyPerson || '',
    email: investorMeeting.emailProxyPerson || '',
    phone: investorMeeting.phoneProxyPerson || '',
  };

  return (
    <div className="content">
      <Card>
        <CardHeader text={`${t('Meeting')} - ${investorMeeting.title}`} imgSrc="/img/meeting.png" />
        <CardBody>
          <div className="bg-light w-100 p-2 fs-18 ">{t('MeetingDetails-Information')}</div>
          <Row className="mb-2">
            <Col>
              <Col md={3} className="fs-16 minw-200">
                <label>{t('Time')}</label>
              </Col>
              <Col md={6}>
                <div className="ml-2 minw-200 d-inline-block mb-1">
                  <img src="/img/date.png" width="20px;" className="mr-2" />
                  {new Date(investorMeeting.openDate).toDateString()}
                </div>
                <div className="minw-200 d-inline-block mb-1 ml-2">
                  <img src="/img/time.png" width="20px;" className="mr-2" />
                  <span className="mr-1">{new Date(investorMeeting.openDate).toLocaleTimeString()}</span>
                  to
                  <span className="ml-1">{new Date(investorMeeting.closeDate).toLocaleTimeString()}</span>
                  <span className="fa-w-12 ml-2 px-1 badges badge-info rounded">
                    {investorMeeting.timezone} - {investorMeeting.timePadding}
                  </span>
                </div>
              </Col>
            </Col>
          </Row>
          <ContactPerson title={t('MeetingDetails-Responsible-Person')} person={responsiblePerson} />
          <ContactPerson title={t('MeetingDetails-Proxy-Person')} person={proxyPerson} />

          <div className="bg-light w-100 p-2 fs-18 ">{t('MeetingDetails-Description')}</div>
          <Row className="mb-2 ml-2">
            <MarkupIframe content={investorMeeting.content || ''} />
          </Row>

          <div className="bg-light w-100 p-2 fs-18 ">{t('MeetingDetails-Documents')}</div>
          {investorMeeting.documents?.length
            ? investorMeeting.documents?.map((doc) => (
                <Row key={doc.ID}>
                  <Col md={9}>
                    <img src="/img/document2.png" className="mr-2" width="30px" />
                    {doc.title} - {doc.description}
                  </Col>
                  <Col md={3}>
                    <DocumentLink link={doc.documentLink} title={t('Download')} />
                  </Col>
                </Row>
              ))
            : null}
        </CardBody>
      </Card>
    </div>
  );
};

export default MeetingDetails;
