import { Badge, BadgeProps } from 'reactstrap';
import styled, { css, StyledProps } from 'styled-components';

interface StatusBadgeProps extends BadgeProps {
  color: 'pending' | 'succeeded' | 'rejected' | 'inProgress';
}

const backgroundColor = {
  pending: '#c9a402',
  succeeded: '#386e47',
  rejected: '#8c1434',
  inProgress: '#20437a',
};

const badgeVariant = ({ color }: StyledProps<StatusBadgeProps>) => {
  return css`
    font-size: 12px;
    background-color: ${backgroundColor[color]};
    border: none;
    padding: 10px;
    margin-right: 5px;
  `;
};

const StatusBadge = styled(Badge)<StatusBadgeProps>`
  ${badgeVariant};
`;

export default StatusBadge;
