import React from 'react';
import { MetaValues } from 'services/apollo';
import MarkupIframe from 'pages/MarkupIframe';
import MetaChart from 'pages/active-properties/components/MetaChart';
import { StoDetailMeta } from 'services/apollo/multisto';

interface DynamicRightContentProp {
  name: string;
  content: MetaValues[];
  isChart?: boolean;
}

const checkType = (content: StoDetailMeta[] | MetaValues[]) => {
  if ((content[0] as StoDetailMeta).type) {
    return content as StoDetailMeta[];
  }
  return [];
};

const DynamicRightContent: React.FC<DynamicRightContentProp> = ({ name, content, isChart = false }) => {
  const meta = checkType(content);
  return <>{isChart ? <MetaChart meta={meta} name={name} /> : <MarkupIframe content={content[0].value} />}</>;
};

export default DynamicRightContent;
