import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, GrayDot } from 'atoms';

export interface RegistrationInstructionsProps {
  title: string;
}

const RegistrationInstructions: React.FC<RegistrationInstructionsProps> = (props) => {
  const { t } = useTranslation();
  const { title } = props;

  return (
    <>
      <h3>{t('RegistrationForm-card-header')}</h3>
      <p> {t('RegistrationForm-instructions')} </p>
      <p> {t('RegistrationForm-steps-start')} </p>
      <Row>
        <Col xs="auto">
          <GrayDot /> <b>{t('RegistrationForm-steps-label-1')}</b>
        </Col>
        <Col tag="p">
          {t('RegistrationForm-steps-1')}
          <br />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <GrayDot /> <b>{t('RegistrationForm-steps-label-2')}</b>
        </Col>
        <Col tag="p">
          {t('RegistrationForm-steps-2-preTitle')}
          {` ${title} `}
          {t('RegistrationForm-steps-2-postTitle')}
          <br />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <GrayDot /> <b>{t('RegistrationForm-steps-label-3')}</b>
        </Col>
        <Col tag="p">
          {t('RegistrationForm-steps-3')}
          <br />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <GrayDot /> <b>{t('RegistrationForm-steps-label-4')}</b>
        </Col>
        <Col tag="p">
          {t('RegistrationForm-steps-4')}
          {` ${title} `}
          <br />
        </Col>
      </Row>
      <Row>
        <Col xs="auto">
          <GrayDot /> <b>{t('RegistrationForm-steps-label-5')}</b>
        </Col>
        <Col tag="p">
          {t('RegistrationForm-steps-5', { title })}
          <br />
        </Col>
      </Row>
    </>
  );
};

export default RegistrationInstructions;
