import styled from 'styled-components';

const Badge = styled.div<{ big?: boolean; color: string }>`
  height: ${(p) => (p.big ? 20 : 15)}px;
  width: ${(p) => (p.big ? 20 : 15)}px;
  background-color: ${(p) => p.color};
  border-radius: 50%;
  display: inline-block;
  margin-right: 0.5rem;
`;

export default Badge;
