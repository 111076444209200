import React, { useMemo } from 'react';
import { SubmittedDocuments } from 'services/apollo/documents';
import useLocalDate from 'hooks/useLocalDate';
import SortTable from 'pages/exchange/components/SortTable';
import { SharePurchaseModeEnum } from 'services/apollo';
import { useTranslation } from 'react-i18next';
import ViewDocumentButton from './ViewDocumentButton';

interface SignedDocumentListProps {
  submittedDocuments: SubmittedDocuments[];
}

const SignedDocumentList: React.FC<SignedDocumentListProps> = (props) => {
  const toLocalDate = useLocalDate();
  const { t } = useTranslation();
  const { submittedDocuments } = props;

  const columns = useMemo(
    () => [
      {
        Header: t('contract-documents-Index-thead-title'),
        accessor: (doc: SubmittedDocuments) => {
          return doc.document.title;
        },
      },
      {
        Header: t('contract-documents-Index-thead-date'),
        accessor: (doc: SubmittedDocuments) => {
          return toLocalDate(new Date(doc.signature?.modified ?? ''));
        },
      },
      {
        Header: t('contract-documents-Index-thead-provider'),
        accessor: (doc: SubmittedDocuments) => {
          if (doc.document.docusignDocumentID) {
            return t('Document-Provider-DocuSign');
          }
          if (doc.document.helloSignDocumentID) {
            return t('Document-Provider-HelloSign');
          }
          return t('Document-Provider-Internal');
        },
      },
      {
        Header: t('contract-documents-Index-thead-buttons') || ' ',
        accessor: (doc: SubmittedDocuments) => {
          let newPath = '';
          let provider = SharePurchaseModeEnum.Internal;
          const { signatureFilePath: filePath, document } = doc;
          const { docusignDocumentID, helloSignDocumentID } = document;
          if (docusignDocumentID) {
            provider = SharePurchaseModeEnum.Docusign;
            newPath = filePath?.includes('docusignViewSignedDocumentsUrl/')
              ? filePath?.replace('docusignViewSignedDocumentsUrl/', '')
              : '';
          }
          if (helloSignDocumentID) {
            provider = SharePurchaseModeEnum.Hellosign;
            if (filePath?.includes('helloSignViewSignedDocumentsUrl/')) {
              newPath = filePath?.split('/').pop() || '';
            }
          }
          return <ViewDocumentButton doc={doc} newPath={newPath} provider={provider} />;
        },
      },
    ],
    [t],
  );

  return <SortTable columns={columns} data={submittedDocuments} />;
};

export default SignedDocumentList;
