import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'atoms';

export interface Person {
  name: string;
  phone: string;
  email: string;
}

export interface ContactPersonProps {
  title: string;
  person: Person;
}

const ContactPerson: React.FC<ContactPersonProps> = (props) => {
  const { t } = useTranslation();
  const { title, person } = props;

  const style = { color: '#239fdb' };
  return (
    <>
      <div className="bg-light w-100 p-2 fs-18 ">{title}</div>
      {(person.name || person.phone || person.email) && (
        <Row className="mb-2">
          {person.name && (
            <Col>
              <img src="/img/user.png" width="20px" className="mr-2" alt={t('User')} />
              <span className="mr-1">{person.name}</span>
            </Col>
          )}
          {person.phone && (
            <Col>
              <i className="ti-mobile fs-20 align-self-center mr-2" style={style} />
              <span className="mr-1">{person.phone}</span>
            </Col>
          )}
          {person.email && (
            <Col>
              <i className="ti-mobile fs-20 align-self-center mr-2" style={style} />
              <span className="mr-1">{person.email}</span>
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default ContactPerson;
