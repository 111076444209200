import { Button } from 'atoms';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

interface SellBuyButtonProps {
  isVisible: boolean;
  isEnabled: boolean;
  text: string;
  onClick: () => void;
}

const SellBuyButton: React.FC<SellBuyButtonProps> = ({ isVisible, isEnabled, text, onClick }) => {
  const { t } = useTranslation();
  return (
    <>
      {isVisible ? (
        <Button disabled={!isEnabled} size="sm" wmin="130px" className="m-2" onClick={onClick}>
          {t(text)}
        </Button>
      ) : null}
    </>
  );
};

export default SellBuyButton;
