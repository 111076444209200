import React from 'react';
import styled from 'styled-components';

import { useInfoQuery } from 'services/apollo';
import LOGO from '../assets/img/logo/digishares.png';

interface StoLogoProps {
  style?: React.CSSProperties;
  _id?: number;
  title?: string;
  displayInvestorLogo?: boolean;
  height?: number;
  width?: number;
}

const Logo = styled.img`
  max-width: 100%;
  height: ${(props) => (props.height ? `${props.height}px` : null)};
  width: ${(props) => (props.width ? `${props.width}px` : null)};
`;

const StoLogo: React.FC<StoLogoProps> = ({
  style,
  _id = 0,
  title = 'logo',
  displayInvestorLogo = true,
  height,
  width,
}) => {
  const { data, loading } = useInfoQuery({ variables: { _id } });
  if (loading || !data?.publicSto) {
    return <></>;
  }

  const { logo } = data.publicSto;

  return displayInvestorLogo ? (
    <Logo style={style} width={width} height={height} src={logo || LOGO} title={title} />
  ) : null;
};

export default StoLogo;
