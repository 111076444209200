import React, { FC, useContext, useState } from 'react';
import { ThemeContext, ThemeContextType } from 'lib/themes/themeController';

import { Button } from 'reactstrap';
import { useTheme } from 'styled-components';
import { useModal } from 'components/modals/Modal';
import { useTranslation } from 'react-i18next';
import { Divider } from 'atoms';
import { InvestorParam, useSetInvestorParamMutation, useSetThemeConfigMutation } from 'services/apollo';
import { defaultTheme, MarketSpaceTheme } from 'lib/themes/defaultTheme';
import { ThemeType } from 'lib/themes/themeTypes';
import { SettingsBar, ScrollContainer } from './styled/styled';

import {
  ActiveOfferingsCustomizer,
  ApplicationCustomizer,
  ButtonCustomizer,
  FooterCustomizer,
  MarketPlaceCustomizer,
  MessagesCustomizer,
  NavbarCustomizer,
  NewEntityCustomizer,
  PredefinedThemesCustomizer,
  SidebarCustomizer,
} from './components/customizer';
import { CloseIcon } from './components';

const defaultThemes = [
  { title: 'Default', value: defaultTheme },
  { title: 'MarketSpace', value: MarketSpaceTheme },
];

export interface ChoicesCustomizerType {
  handleChange: (property: keyof ThemeType, cl: string | boolean) => void;
  theme: ThemeType;
}

const FixedPluginContent: FC = () => {
  const { setTheme } = useContext<ThemeContextType>(ThemeContext);
  const [uploadThemeConfig] = useSetThemeConfigMutation();
  const [setInvestorParam] = useSetInvestorParamMutation();
  const { t } = useTranslation();
  const modal = useModal();
  const theme: ThemeType = useTheme() as ThemeType;
  const [isChanged, setIsChanged] = useState(false);

  const [oldConfig] = useState({ ...theme });

  const handleChange = (property: keyof ThemeType, color: string | boolean) => {
    setIsChanged(true);
    if (setTheme !== null) {
      setTheme({
        ...theme,
        [property]: color,
      });
    }
  };

  const handleSave = () => {
    uploadThemeConfig({ variables: { theme: JSON.stringify(theme) } });

    if (theme.appFont !== oldConfig.appFont) {
      setInvestorParam({
        variables: {
          param: InvestorParam.LeftSideMenuFont,
          stringValue: theme.appFont,
        },
      });
    }

    modal.hideModal();
  };

  return (
    <SettingsBar>
      <div className="settings-header">
        <CloseIcon
          onClick={() => {
            setTheme(oldConfig);
            setIsChanged(false);
            modal.hideModal();
          }}
        />
        <div className="settings-header__content mb-3 mt-3">
          <h2 className="settings-header__content-title">{t('FixedPlugin-Title-InterfaceCustomizer')}</h2>
          <div className="settings-header__content-action">
            <Button
              outline
              className={isChanged ? '' : 'hide'}
              color="danger"
              onClick={() => {
                setTheme(oldConfig);
                setIsChanged(false);
              }}
            >
              {t('FixedPlugin-Action-ResetButton')}
            </Button>

            <Button
              color="primary"
              disabled={!isChanged}
              onClick={() => {
                handleSave();
              }}
            >
              {t('Save')}
            </Button>
          </div>
        </div>
      </div>
      <ScrollContainer id="ScrollContainer">
        <div className="settings-content">
          <PredefinedThemesCustomizer defaultThemes={defaultThemes} theme={theme} setIsChanged={setIsChanged} />
          <Divider />
          <NavbarCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <SidebarCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <ApplicationCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <ButtonCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <FooterCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <ActiveOfferingsCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <MarketPlaceCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <NewEntityCustomizer handleChange={handleChange} theme={theme} />
          <Divider />
          <MessagesCustomizer handleChange={handleChange} theme={theme} />
        </div>
      </ScrollContainer>
    </SettingsBar>
  );
};

export default FixedPluginContent;
