import React from 'react';

import { useGetFilterSelectOptionsQuery } from 'services/apollo';
import { Select } from 'atoms';

interface FilterOptionsItemSelectProps {
  filterKey: string;
  value: string;
  disabled: boolean;

  update(value: string): void;
}

const FilterOptionsItemSelect: React.FC<FilterOptionsItemSelectProps> = ({ filterKey, update, disabled, value }) => {
  const { data, loading } = useGetFilterSelectOptionsQuery({ variables: { keys: filterKey } });

  if (loading || !data?.findAllSto) {
    return null;
  }

  const valueArray = value.split(',');

  const values = new Set<string>([]);
  data.findAllSto
    .filter((sto) => sto.isActive)
    .forEach((sto) => {
      const [meta] = sto.meta;
      if (meta?.value) {
        values.add(meta.value);
      }
    });
  const options = Array.from(values).map((v) => ({
    value: v,
    label: v,
  }));

  const selected = options.filter((option) => valueArray.includes(option.value));

  const handleSelect = (opts: { value: string }[]) => {
    update(opts.map((o) => o.value).join(','));
  };

  return (
    <Select
      onChange={handleSelect}
      value={selected}
      options={options}
      isDisabled={disabled}
      isMulti
      closeMenuOnSelect={false}
    />
  );
};

export default FilterOptionsItemSelect;
