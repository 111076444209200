import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import { Container, Loading } from 'atoms';
import { Alert } from 'reactstrap';

import Auth from 'services/core/auth';
import { signIn } from 'lib/routing/route-sets/public-routes';
import { useTranslation } from 'react-i18next';
import LanguageSelector from 'components/LanguageSelector';
import { useInvestorAppDataQuery } from 'services/apollo';

const VerifySecret: React.FC = () => {
  const { t } = useTranslation();
  const { secret } = useParams<{ secret: string }>();
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();

  useEffect(() => {
    setError('');
    Auth.verify(secret)
      .then(() => setSuccess(true))
      .catch((err) => {
        setError(err.message);
      });
  }, [secret]);

  if (appDataLoading || !appData) {
    return <Loading />;
  }

  const { isPublicLanguageSelectorEnabled, isCountryFlagsInSelectorEnabled } = appData.investorAppParameters;

  return (
    <Container>
      {error ? <Alert color="danger">{t(error)}</Alert> : ''}
      {success ? (
        <h1>
          <Alert color="success">
            {t('VerifySecret-message')}
            <RouterLink to={signIn.getPath()}>{t('VerifySecret-loginLink')}</RouterLink>
          </Alert>
        </h1>
      ) : (
        ''
      )}

      {isPublicLanguageSelectorEnabled && (
        <div style={{ justifySelf: 'center', paddingLeft: '21px' }}>
          <LanguageSelector
            navigationBar={false}
            color="info"
            outline
            openDirection="right"
            enableCountryFlags={isCountryFlagsInSelectorEnabled}
          />
        </div>
      )}
    </Container>
  );
};

export default VerifySecret;
