import React from 'react';
import { Cell } from 'react-table';

import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { InvestorShares } from 'services/apollo/shares';

import { BadgeIcon } from 'atoms';

const InternalExchangeValueCell: React.FC<Cell<InvestorShares>> = ({ row: { original } }) => {
  if (original.shareType.nominalValue > 0) {
    return (
      <>
        <BadgeIcon color="cyan" pill>
          N
        </BadgeIcon>
        <CurrencySymbolDisplay value={original.shareType.nominalValue} currency={original.shareType.currency} />
      </>
    );
  }
  return (
    <>
      <BadgeIcon color="pink" pill>
        P
      </BadgeIcon>
      <CurrencySymbolDisplay value={original.shareType.premiumValue} currency={original.shareType.currency} />
    </>
  );
};

export default InternalExchangeValueCell;
