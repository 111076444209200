import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  useGetExchangeOfferDetailQuery,
  useGetAcceptedExchangeOfferQuery,
  useInvestorAppDataQuery,
  useFindAllBlockchainProtocolsQuery,
} from 'services/apollo';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

import { Card, CardBody, Row, Loading, Col } from 'atoms';
import AtomicSwapComponent from './components/offer-details/AtomicSwapComponent';
import PolymeshSwapComponent from './components/polymesh/PolymeshSwapComponent';
import { BLOCKCHAIN_PROTOCOL, isProtocolERC1404 } from './constants';

const AtomicSwap: React.FC = () => {
  const params = useParams<{ orderID: string }>();
  const orderID = parseInt(params.orderID, 10);
  const { t } = useTranslation();

  const { loading: load1, data } = useGetExchangeOfferDetailQuery({
    variables: { orderID },
    fetchPolicy: 'network-only',
  });
  const { loading: load2, data: offerData } = useGetAcceptedExchangeOfferQuery({
    variables: { orderID },
    fetchPolicy: 'network-only',
  });

  const { data: blockchainProtocolData, loading: loadingBlockchainProtocolData } = useFindAllBlockchainProtocolsQuery();

  const { data: appData, loading: loadingAppData } = useInvestorAppDataQuery();

  if (
    load1 ||
    load2 ||
    loadingAppData ||
    loadingBlockchainProtocolData ||
    !data ||
    !offerData ||
    !appData ||
    !blockchainProtocolData ||
    !blockchainProtocolData.findAllBlockchainProtocols
  ) {
    return <Loading />;
  }

  const { getExchangeOrder: order } = data;
  const { getAcceptedExchangeOffer: offer } = offerData;
  const { isBlockchainTestnetEnabled } = appData.investorAppParameters;
  const blockchainProtocol = blockchainProtocolData.findAllBlockchainProtocols.find(
    (protocol) => protocol.ID === order.shareType.blockchainProtocolID,
  );

  if (!blockchainProtocol) {
    return <Loading />;
  }

  const renderSwapComponent = (): React.ReactElement => {
    if (isProtocolERC1404(order.shareType.blockchainProtocolID || 0)) {
      return (
        <AtomicSwapComponent
          order={order}
          offer={offer}
          isTestnetEnabled={isBlockchainTestnetEnabled}
          blockchainProtocol={blockchainProtocol}
        />
      );
    }
    if (order.shareType.blockchainProtocolID === BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
      return <PolymeshSwapComponent order={order} offer={offer} />;
    }
    return <></>;
  };

  return (
    <div className="content">
      <Card>
        <CardBody>
          <h5>{t('AtomicSwap-title')}</h5>
          <Row>
            <Col md={3}>{t('AtomicSwap-label-shareCount')}</Col>
            <Col md={2}>{order.shares}</Col>
          </Row>
          <Row>
            <Col md={3}>{t('AtomicSwap-label-price')}</Col>
            <Col md={2}>
              <CurrencySymbolDisplay value={offer ? offer.rateFrom : null} currency={order.shareType.currency} />
            </Col>
          </Row>
          <hr />
          {renderSwapComponent()}
        </CardBody>
      </Card>
    </div>
  );
};

export default AtomicSwap;
