import React from 'react';

import { MetaKeyTypes, MetaValues, StoMetaValue } from 'services/apollo';

import DynamicTabContent, { TabContentType } from 'pages/active-properties/components/rightLayout/DynamicTabContent';
import MetaContent from './MetaContent';

interface DynamicContentProps {
  meta: Array<StoMetaValue> | Array<MetaValues>;
  isBuyEnabled?: boolean;
  rightLayout?: boolean;
}

const DynamicContent: React.FC<DynamicContentProps> = ({ meta, isBuyEnabled = false, rightLayout = false }) => {
  let metaValues = meta;
  if (!isBuyEnabled) {
    metaValues = meta.filter((metaVal) => metaVal.displayClosedProp);
  }

  const tabs = meta.filter((m) => m.type === MetaKeyTypes.Tabs);

  const metaKeys = metaValues
    .filter((value) => {
      // if we found tab content, then do not show it directly
      const tab = tabs.find((item) => item.key !== value.key && value.key.startsWith(item.key));

      return (value.display && !tab && value.value) || value.type === MetaKeyTypes.Tabs;
    })
    .sort((a, b) => a.order - b.order)
    .map((value) => value.key);

  const { rightMetaKeys, rightTabValues } = tabs.reduce(
    (accumulator, tab) => {
      const tabContent = meta
        .filter((x) => x.key.startsWith(tab.key || '') && x.key !== tab.key && x.display && x.value)
        .sort((a, b) => a.order - b.order);

      if (tabContent.length === 0) {
        accumulator.rightMetaKeys = accumulator.rightMetaKeys.filter((item) => item !== tab.key);
      } else {
        accumulator.rightTabValues.push({
          key: tab.key,
          tabs: tabContent,
        });
      }

      return accumulator;
    },
    { rightMetaKeys: metaKeys, rightTabValues: [] as TabContentType[] },
  );

  return (
    <>
      {rightLayout
        ? metaKeys.length > 0 && <DynamicTabContent meta={metaValues} keys={rightMetaKeys} tabs={rightTabValues} />
        : metaKeys.map((key) => <MetaContent key={key} meta={metaValues} name={key} />)}
    </>
  );
};

export default DynamicContent;
