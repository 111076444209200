import React from 'react';
import { useTranslation } from 'react-i18next';

import { Card, CardBody } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import styled from 'styled-components';
import { ActivePropertyItem } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';
import ActiveProperty from 'pages/active-properties/ActiveProperty';

import { OFFERINGHTMLIDS } from 'pages/active-properties/ActiveProperties';

interface PropertiesGridProps {
  properties: ActivePropertyItem[];
  isClosedOffering?: boolean;
  isFilter?: boolean;
  appData: AppData;
  htmlID: OFFERINGHTMLIDS;
  shown: boolean;
}

const PropertiesGrid: React.FC<PropertiesGridProps> = ({
  properties,
  isClosedOffering,
  isFilter,
  appData,
  htmlID,
  shown,
}) => {
  const { t } = useTranslation();

  const {
    IsMarketSpace: isMs,
    isBuyStoWhitelistPerInvestorEnabled,
    isActivePropertyBigTombstoneEnabled,
    isClosedOfferingsCardEnabled,
    isInvestorOfferingsDashboard,
    isFilterPreferencesEnabled,
  } = appData;

  const activePropTranslationKey = isClosedOffering
    ? 'PropertiesGrid-No-Whitelisted-Closed-PropertiesFound'
    : 'PropertiesGrid-No-Whitelisted-Active-PropertiesFound';
  const noPropertiesFound = isFilter ? 'PropertiesGrid-NoFilteredPropertiesFound' : activePropTranslationKey;
  const propertiesNotFoundTranslationKey = isBuyStoWhitelistPerInvestorEnabled
    ? noPropertiesFound
    : 'PropertiesGrid-NoActivePropertiesFound';
  const propertiesTitle = isClosedOffering
    ? 'ActiveProperties-NonBuyProperty-CardHeader'
    : 'ActiveProperties-BuyProperty-CardHeader';
  const cardTitle = isFilter ? 'ActiveProperties-FilteredProperty-CardHeader' : propertiesTitle;

  return isClosedOffering && !isClosedOfferingsCardEnabled ? null : (
    <StyledCard id={htmlID} shown={shown} className={properties.length === 0 ? 'adjusted-layout' : ''} noBoxShadow>
      {!isInvestorOfferingsDashboard || !isFilterPreferencesEnabled ? (
        <CardHeader text={t(cardTitle)} fontWeight="500" />
      ) : null}
      <CardBody>
        <Container>
          {isMs || isActivePropertyBigTombstoneEnabled ? (
            <ContentMs>
              {properties.map((card) => (
                <ItemMs key={card.ID}>
                  <ActiveProperty props={card} isClosedOffering={isClosedOffering} />
                </ItemMs>
              ))}
            </ContentMs>
          ) : (
            <Content>
              {properties.length ? (
                <>
                  {properties.map((card) => (
                    <Item key={card.ID}>
                      <ActiveProperty props={card} isClosedOffering={isClosedOffering} isFilteredOffering={isFilter} />
                    </Item>
                  ))}
                </>
              ) : (
                <div>
                  <p>{t(propertiesNotFoundTranslationKey)}</p>
                </div>
              )}
            </Content>
          )}
        </Container>
      </CardBody>
    </StyledCard>
  );
};

export default PropertiesGrid;

const StyledCard = styled(Card)<{ shown: boolean }>`
  background-color: ${(p) => p.theme.offeringsCardBGColor};
  display: ${(p) => (p.shown ? 'block' : 'none')};

  &.adjusted-layout {
    display: ${(p) => (p.shown ? 'flex' : 'none')};
    flex-direction: row;
    align-items: baseline;

    @media (max-width: 750px) {
      flex-direction: column;
    }
  }
`;
const Container = styled.div`
  width: 100%;
`;

const Content = styled.div`
  display: grid;
  grid-column-gap: 50px;
  grid-row-gap: 50px;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
`;

const ContentMs = styled.div`
  display: grid;
  grid-gap: 10px;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fit, minmax(510px, 1fr));
`;

const Item = styled.div`
  height: 560px;
  align-content: center;
  vertical-align: top;
  justify-self: center;
  width: 400px;
`;

const ItemMs = styled.div`
  height: 560px;
  max-width: 750px;
  align-content: center;
  vertical-align: top;

  @media (max-width: 600px) {
    max-width: 450px;
  }
  @media (max-width: 530px) {
    max-width: 400px;
  }
  @media (max-width: 480px) {
    max-width: 350px;
  }
`;
