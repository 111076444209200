import React from 'react';

import { BsSwal, Button, Card, CardBody, CenteredCol, Col, Loading, Row } from 'atoms';
import { CardH4, CardHeader } from 'components/card-header/CardHeader';
import SUMMARY from 'assets/img/summary.png';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  BuyAlertStatus,
  InvestorAppDataQuery,
  KycProviders,
  PaymentProcessor,
  SharePurchaseDocumentsQuery,
  useInvestorBuyAlertsQuery,
  useInvestorInvoiceAlertsQuery,
  usePayInvoiceMutation,
} from 'services/apollo';
import { getRedirectUrl } from 'layouts/Root';
import { accreditationProcess } from 'lib/routing/route-sets/verify-routes';
import { InvestorInvoiceAlert } from 'services/apollo/multisto';

interface ContractsListProps {
  accreddRedirectLink: string;
  skipDocumentSignScreen: boolean;
  isMarketspace: boolean;
  kycProvider: KycProviders;
  sharePurchaseID: number;
  sharePurchaseDocuments: SharePurchaseDocumentsQuery['sharePurchaseDocuments'][0][];
  appData: InvestorAppDataQuery;
}

enum POST_SIGN_DESTINATIONS {
  KYC_REQUIRED,
  ACCREDITATION_REQUIRED,
  ACCREDITATION_PENDING,
  PORTFOLIO,
  NO_DOCUMENTS,
  INVOICE_REVIEW,
}

const ContractsList: React.FC<ContractsListProps> = (props) => {
  const {
    accreddRedirectLink,
    skipDocumentSignScreen,
    isMarketspace,
    kycProvider,
    sharePurchaseID,
    sharePurchaseDocuments,
    appData,
  } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const {
    isAllDocumentsSignedPopUpEnabled,
    frontendRedirectConfig: { afterPaymentUrl },
    activePaymentProcessor,
  } = appData.investorAppParameters;
  const { data: invoices, loading: loadingInvoices } = useInvestorInvoiceAlertsQuery({
    fetchPolicy: 'no-cache',
  });
  const [payInvoice, { called: isCreateCheckoutCalled }] = usePayInvoiceMutation();

  const { isInvoicingEnabled } = appData.investorAppParameters;
  const { data: buyAlerts, loading: loadingBuyAlerts } = useInvestorBuyAlertsQuery({
    variables: {
      status: [
        BuyAlertStatus.Accepted,
        BuyAlertStatus.Pending,
        BuyAlertStatus.KycRequired,
        BuyAlertStatus.AccreditationRequired,
        BuyAlertStatus.AccreditationPending,
      ],
    },
    fetchPolicy: 'network-only',
  });

  if (!invoices || !buyAlerts || loadingBuyAlerts || loadingInvoices) {
    return <Loading />;
  }

  const currentInvoice: InvestorInvoiceAlert | undefined = invoices.investorInvoiceAlerts.find(
    (req) => req.buyAlert?.ID === sharePurchaseID,
  );

  const isBlockchain = currentInvoice?.paymentChannelID !== -1;

  const handlePayInvoice = async () => {
    if (!currentInvoice) {
      history.push('/investor/Portfolio');
      return;
    }
    try {
      // if the mutation is called once, don't call it again, as it breaks payout.
      if (isCreateCheckoutCalled) {
        return;
      }
      const result = await payInvoice({ variables: { invoiceID: currentInvoice.ID } });
      const checkoutUrl = result.data?.payInvoice;
      if (!checkoutUrl) {
        history.push('/investor/Portfolio');
        return;
      }
      window.location.replace(checkoutUrl);
    } catch (e) {
      const { isConfirmed } = await BsSwal.fire({
        title: (e as Error).message,
        icon: 'error',
        showConfirmButton: true,
      });
      if (isConfirmed) {
        history.push('/investor/Portfolio');
      }
    }
  };

  const sendTo = (destination: POST_SIGN_DESTINATIONS) => {
    switch (destination) {
      case POST_SIGN_DESTINATIONS.KYC_REQUIRED:
        history.push(getRedirectUrl(kycProvider));
        break;
      case POST_SIGN_DESTINATIONS.ACCREDITATION_REQUIRED:
        history.push(accreditationProcess.path);
        break;
      case POST_SIGN_DESTINATIONS.INVOICE_REVIEW:
        history.push(`/investor/invoice/${currentInvoice?.ID}`);
        break;
      case POST_SIGN_DESTINATIONS.PORTFOLIO:
      case POST_SIGN_DESTINATIONS.NO_DOCUMENTS:
      case POST_SIGN_DESTINATIONS.ACCREDITATION_PENDING:
      default:
        if (afterPaymentUrl) {
          window.location.href = `${afterPaymentUrl}?purchase-id=${sharePurchaseID}`;
        } else {
          history.push(`/investor/Portfolio`);
        }
    }
  };

  const handleClick = () => {
    BsSwal.fire({
      title: t('AlertItem-marketspace-PopUp-title'),
      icon: 'success',
      showCancelButton: true,
      confirmButtonText: t('AlertItem-marketspace-PopUp-confirm'),
      cancelButtonText: t('AlertItem-marketspace-PopUp-cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        sendTo(
          isInvoicingEnabled && isBlockchain && currentInvoice
            ? POST_SIGN_DESTINATIONS.INVOICE_REVIEW
            : POST_SIGN_DESTINATIONS.PORTFOLIO,
        );
        goToAccredd();
      }
    });
  };

  const buyAlert = buyAlerts.investorBuyAlerts?.find((req) => req.ID === sharePurchaseID && req.isBuySharesSigned);
  if (buyAlert) {
    if (buyAlert.status === BuyAlertStatus.KycRequired) {
      BsSwal.fire({
        icon: 'info',
        title: `${t('AlertItem-PopUp-AllSigned')} | ${t('AlertItem-status-KycRequired')}`,
      }).then(() => {
        sendTo(POST_SIGN_DESTINATIONS.KYC_REQUIRED);
      });
    } else if (buyAlert.status === BuyAlertStatus.AccreditationRequired) {
      if (isMarketspace) {
        handleClick();
      } else {
        BsSwal.fire({
          icon: 'info',
          title: `${t('AlertItem-PopUp-AllSigned')} | ${t('AlertItem-status-AccreditationRequired')}`,
        }).then(() => sendTo(POST_SIGN_DESTINATIONS.ACCREDITATION_REQUIRED));
      }
    } else if (buyAlert.status === BuyAlertStatus.AccreditationPending) {
      BsSwal.fire({
        icon: 'info',
        title: `${t('AlertItem-PopUp-AllSigned')} | ${t('AlertItem-status-popUp-title-AccreditationPending')}`,
      }).then(() => sendTo(POST_SIGN_DESTINATIONS.ACCREDITATION_PENDING));
    } else if (isAllDocumentsSignedPopUpEnabled) {
      BsSwal.fire({
        icon: 'success',
        title: t('AletItem-PopUp-Alternate-AllSigned'),
        confirmButtonText: t('AletItem-PopUp-Alternate-confirm'),
      }).then(() => {
        // TODO: DIG-1572 ensure that the payment providers work in the same way
        if (activePaymentProcessor !== PaymentProcessor.PayoutOne) {
          sendTo(isInvoicingEnabled ? POST_SIGN_DESTINATIONS.INVOICE_REVIEW : POST_SIGN_DESTINATIONS.PORTFOLIO);
        } else {
          handlePayInvoice();
        }
      });
    } else if (activePaymentProcessor === PaymentProcessor.Off) {
      sendTo(isInvoicingEnabled ? POST_SIGN_DESTINATIONS.INVOICE_REVIEW : POST_SIGN_DESTINATIONS.PORTFOLIO);
    } else {
      handlePayInvoice();
    }
  }

  const goToAccredd = () => {
    const newWindow = window.open(accreddRedirectLink, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
  };

  const navigateToContract = (documentID: string | number) => {
    history.push(`/investor/share-purchase-contract-mode/${sharePurchaseID}/${documentID}`);
  };

  if (skipDocumentSignScreen) {
    const nextToSign = sharePurchaseDocuments.find((d) => d.status !== 3);
    if (nextToSign?.document.ID) {
      navigateToContract(nextToSign?.document.ID);
    }
  }

  if (sharePurchaseDocuments.length === 0) {
    sendTo(
      isInvoicingEnabled && isBlockchain ? POST_SIGN_DESTINATIONS.INVOICE_REVIEW : POST_SIGN_DESTINATIONS.NO_DOCUMENTS,
    );
  }

  return (
    <Row>
      <Col md={12}>
        <Card>
          <CardHeader
            text={t('sharePurchaseSigning-CardHeader')}
            caption={t('sharePurchaseSigning-CardBody')}
            imgSrc={SUMMARY}
          />
          <CardBody className="mb-2">
            {sharePurchaseDocuments.map((sharePurchase) => (
              <Row key={sharePurchase.document.ID}>
                <Col>
                  <Row className="mt-5">
                    <Col md={1}>
                      <img alt="contracts" width="35px;" src="/img/contracts.png" />
                    </Col>
                    <CenteredCol md={10}>
                      <CardH4>{sharePurchase.document.title}</CardH4>
                    </CenteredCol>
                  </Row>
                  <Row>
                    <CenteredCol md={{ size: 12, offset: 1 }}>
                      {sharePurchase.requireOnce ? (
                        <span>{t('sharePurchaseSigning-ContractRequiredOnce')}</span>
                      ) : (
                        <span>{t('sharePurchaseSigning-ContractRequiredAlways')}</span>
                      )}
                    </CenteredCol>
                  </Row>
                  <Row>
                    <CenteredCol md={{ size: 2, offset: 1 }}>
                      {(sharePurchase.status ?? 0) > 1 ? (
                        <span style={{ color: 'green' }}>
                          {sharePurchase.requireOnce ? (
                            <>{t('sharePurchaseSigning-ContractRequiredOnce-NotSigned')}</>
                          ) : (
                            <>{t('sharePurchaseSigning-ContractRequiredAlways-NotSigned')}</>
                          )}
                        </span>
                      ) : (
                        <Button size="sm" onClick={() => navigateToContract(sharePurchase.document.ID)}>
                          {t('Sign Now')}
                        </Button>
                      )}
                    </CenteredCol>
                  </Row>
                </Col>
              </Row>
            ))}
          </CardBody>
        </Card>
      </Col>
    </Row>
  );
};

export default ContractsList;
