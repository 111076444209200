import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { OfferStatus } from 'services/apollo';
import { ExchangeOrderDetailCurrency, ExchangeOrderDetailOffer } from 'services/apollo/exchange';

import { Col, Row } from 'atoms';
import StatusBadge from '../StatusBadge';

interface NotPendingOfferProps {
  offer: ExchangeOrderDetailOffer;
  currency: ExchangeOrderDetailCurrency;
}

const NotPendingOffer: React.FC<NotPendingOfferProps> = ({ offer, currency }) => {
  const { t } = useTranslation();

  const statusLabel = (status: OfferStatus): React.ReactElement => {
    switch (status) {
      case OfferStatus.Accepted: {
        return <StatusBadge color="inProgress">{status}</StatusBadge>;
      }
      case OfferStatus.Rejected: {
        return <StatusBadge color="rejected">{status}</StatusBadge>;
      }
      case OfferStatus.Succeeded: {
        return <StatusBadge color="succeeded">{status}</StatusBadge>;
      }
      default: {
        return <StatusBadge color="pending">{status}</StatusBadge>;
      }
    }
  };

  return (
    <Wrap>
      <Row>
        <Col md={2}>
          <small>{t('OrderDetailPage-NotPendingOffers-Label-SharesAmount')}</small>
        </Col>
        <Col md={2}>
          <mark>
            <b>{offer.sharesPartial}</b>
          </mark>
        </Col>
        <Col md={2}>
          <small>{t('OrderDetailPage-NotPendingOffers-Label-PricePerShare')}</small>
        </Col>
        <Col md={2}>
          <mark>
            <b>
              <CurrencySymbolDisplay value={offer.pricePerShare} currency={currency} />
            </b>
          </mark>
        </Col>
        <Col md={2}>
          <small>{t('OrderDetailPage-NotPendingOffers-Label-TotalPriceOffered')}</small>
        </Col>
        <Col md={2}>
          <mark>
            <b>
              <CurrencySymbolDisplay value={offer.rateFrom} currency={currency} />
            </b>
          </mark>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col md={2}>
          <small>{t('OrderDetailPage-NotPendingOffers-Label-DateOffered')}</small>
        </Col>
        <Col md={2}>
          <mark>
            <b>{offer.description}</b>
          </mark>
        </Col>
        <Col md={2}>
          <small>{t('OrderDetailPage-NotPendingOffers-Label-Status')}</small>
        </Col>
        <Col md={2}>{statusLabel(offer.status)}</Col>
      </Row>
    </Wrap>
  );
};

const Wrap = styled.div`
  border: 1px solid #b8bebf;
  background: #faf8f2;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 2px;
`;

export default NotPendingOffer;
