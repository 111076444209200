import React, { useState } from 'react';

import { useInvestorAppDataQuery, useInvestorWalletQuery } from 'services/apollo';
import { useActiveSto } from 'hooks';

import { Loading } from 'atoms';
import WalletManagement from 'pages/wallet/components/WalletManagement';
import DepositHistory from 'pages/wallet/components/DepositHistory';

const Wallet: React.FC = () => {
  const { sto } = useActiveSto();
  const [selectedSto, setSto] = useState(sto);
  const { data, loading } = useInvestorWalletQuery({
    variables: { _id: Number(selectedSto) },
    fetchPolicy: 'no-cache',
  });

  const { data: appData, loading: loadingData } = useInvestorAppDataQuery({ fetchPolicy: 'no-cache' });
  if (loading || !data || loadingData || !appData) {
    return <Loading />;
  }

  const { investorBalances: accounts, investorPaymentChannels: channels, investorDepositHistory: history } = data;

  const { investorAppParameters } = appData;
  const { isInvestorDashboardSwitchEnabled } = investorAppParameters;

  const displayPropertyName = !isInvestorDashboardSwitchEnabled || Number(selectedSto) < 0;

  return (
    <div className="content">
      <WalletManagement
        accounts={accounts}
        channels={channels}
        sto={Number(selectedSto)}
        setSto={(value) => setSto(Number(value))}
        appData={investorAppParameters}
      />
      <DepositHistory history={history} displayPropertyName={displayPropertyName} />
    </div>
  );
};

export default Wallet;
