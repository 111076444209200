import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  useInvestorWalletQuery,
  usePortfolioValueCalculationsQuery,
  usePortfolioValueQuery,
} from 'services/apollo/graphql';

import { Card, CardBody, Col, Row, CenteredCol, Loading, AnimatedArrowToggle } from 'atoms';
import { useActiveSto } from 'hooks';
import { InvestorPortfolioShares } from 'services/apollo/multisto';
import PopoverHover from 'components/PopoverHover';
import { Collapse } from 'reactstrap';
import { AppData } from 'services/apollo/core';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import * as math from 'mathjs';
import PortfolioValueCalculationsView from './PortfolioValueCalculationsView';

interface PortfolioOverviewMarketspaceProps {
  totalBalances: Array<{ symbol: string; amount: number }>;
  yearlyDividends: number;
  shares: InvestorPortfolioShares[];
  appData: AppData;
}

const PortfolioOverviewMarketspace: React.FC<PortfolioOverviewMarketspaceProps> = (props) => {
  const { totalBalances, yearlyDividends, shares, appData } = props;
  const { isInvestmentDetailsCardUnfolded, isInvestmentDetailsCardEnabled } = appData;
  const { i18n, t } = useTranslation();
  const { sto } = useActiveSto();
  const [isOpen, setIsOpen] = useState(isInvestmentDetailsCardUnfolded);
  const { data, loading } = useInvestorWalletQuery({
    variables: { _id: sto },
    fetchPolicy: 'no-cache',
  });
  const { data: valueData, loading: valueLoading } = usePortfolioValueQuery();
  const { data: calculationsData, loading: calculationsLoading } = usePortfolioValueCalculationsQuery();

  if (
    !totalBalances ||
    loading ||
    !data?.investorBalances ||
    !valueData ||
    valueLoading ||
    !calculationsData ||
    calculationsLoading
  ) {
    return <Loading />;
  }

  const totalAmountInvested = totalBalances.reduce<number>((accumulator, obj) => {
    return accumulator + obj.amount;
  }, 0);

  const toggleCalculationsView = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Card>
        <CardBody className="mb-2">
          <Row className="text-muted">
            <Col md={3}>
              <PopoverHover
                label={t('portfolioOverviewMarketspace-totalInvested')}
                target={<i className="ti-help-alt pl-1" />}
                body={t('portfolioOverviewMarketspace-totalInvested-desc')}
              />
            </Col>
            <Col md={3}>
              <PopoverHover
                label={t('portfolioOverviewMarketspace-portfolioValue')}
                target={<i className="ti-help-alt pl-1" onClick={() => toggleCalculationsView()} />}
                body={t('portfolioOverviewMarketspace-portfolioValue-desc')}
              />
            </Col>
            <Col md={4}>
              <PopoverHover
                label={t('portfolioOverviewMarketspace-distributionsReceived')}
                target={<i className="ti-help-alt pl-1" />}
                body={t('portfolioOverviewMarketspace-distributionsReceived-desc')}
              />
            </Col>
            <Col>
              <PopoverHover
                label={t('portfolioOverviewMarketspace-totalBeforeProfit')}
                target={<i className="ti-help-alt pl-1" />}
                body={t('portfolioOverviewMarketspace-totalBeforeProfit-desc')}
              />
            </Col>
          </Row>
          <Row>
            <CenteredCol md={3}>
              <h5>
                {totalAmountInvested.toLocaleString(i18n.language, {
                  minimumFractionDigits: 2,
                })}
              </h5>
            </CenteredCol>
            <CenteredCol md={3}>
              <h5>
                <CurrencySymbolDisplay value={valueData.portfolioValue} currency={shares[0]?.shareType?.currency} />
                {isInvestmentDetailsCardEnabled && shares.length ? (
                  <AnimatedArrowToggle toggle={isOpen} onClick={toggleCalculationsView} />
                ) : null}
              </h5>
            </CenteredCol>
            <CenteredCol md={4}>
              <h5>
                <CurrencySymbolDisplay value={yearlyDividends} currency={shares[0]?.shareType?.currency} />
              </h5>
            </CenteredCol>
            <CenteredCol md={1}>
              <h5>
                <CurrencySymbolDisplay
                  value={math.subtract(+valueData.portfolioValue, +yearlyDividends)}
                  currency={shares[0]?.shareType?.currency}
                />
              </h5>
            </CenteredCol>
          </Row>
        </CardBody>
      </Card>
      {isInvestmentDetailsCardEnabled ? (
        <Collapse className="justify-content-end" isOpen={isOpen}>
          <PortfolioValueCalculationsView
            investmentsCalculations={calculationsData.portfolioCalculations}
            currency={shares[0]?.shareType?.currency}
          />
        </Collapse>
      ) : null}
    </>
  );
};

export default PortfolioOverviewMarketspace;
