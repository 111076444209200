import React, { useEffect, useState } from 'react';
import { Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import {
  useGetFilterOptionsQuery,
  useInvestorMetaValuesUpdateMutation,
  MetaValues,
  ReservedMetaKeys,
  GetCurrentInvestorFilterPreferenceDocument,
} from 'services/apollo';
import { Button, Loading, Row, BsSwal } from 'atoms';
import { useGetSortedMetaKeys } from 'hooks/useGetSortedMetaKeys';
import FilterOptionsItem from './FilterOptionsItem';
import useFilterPreference from './useFilterPreference';

interface FilterOptionsProps {
  canModifyPreferences: boolean;
}

const FilterOptions: React.FC<FilterOptionsProps> = ({ canModifyPreferences }) => {
  const { t } = useTranslation();
  const [investorMeta, setMeta] = useState<MetaValues[]>([]);

  const [updateMeta] = useInvestorMetaValuesUpdateMutation();

  const { data, loading } = useGetFilterOptionsQuery({
    fetchPolicy: 'no-cache',
  });

  const { loading: metaLoading, preference } = useFilterPreference();

  const onSubmit = async (): Promise<void> => {
    const values = [{ value: JSON.stringify(investorMeta), key: ReservedMetaKeys.InvestorFilterPreference }];
    await updateMeta({ variables: { values }, refetchQueries: [{ query: GetCurrentInvestorFilterPreferenceDocument }] })
      .then(() => {
        BsSwal.fire({
          title: t('Settings-Filter-Options-success-save'),
          icon: 'success',
        });
      })
      .catch((err) =>
        BsSwal.fire({
          title: err,
          icon: 'success',
        }),
      );
  };

  useEffect(() => {
    if (preference.length) {
      setMeta(preference);
    }
  }, [preference.length]);

  if (loading || metaLoading) {
    return <Loading />;
  }

  if (!data || !data.getFilterMetaKeys.length) {
    return <></>;
  }

  const { groupedMetaFilters } = useGetSortedMetaKeys(data.getFilterMetaKeys);

  return (
    <>
      <h3 className="pt-4">{t('Settings-Filter-Options-title')}</h3>
      <p className="text-muted">{t('Settings-Filter-Options-description')}</p>
      {Object.keys(groupedMetaFilters).map((category) => (
        <React.Fragment key={category}>
          {category ? (
            <Label>
              <b>{t(`${category}`)}</b>
            </Label>
          ) : null}
          <Row>
            {groupedMetaFilters[category].map((item) => (
              <FilterOptionsItem
                key={item.key}
                item={item}
                values={investorMeta}
                update={(next) => setMeta(next)}
                disabled={!canModifyPreferences}
                isCountry={category === 'Country'}
              />
            ))}
          </Row>
        </React.Fragment>
      ))}
      {canModifyPreferences ? <Button onClick={onSubmit}>{t('Settings-Filter-Options-save')}</Button> : null}
      <hr />
    </>
  );
};

export default FilterOptions;
