import { useEffect, useState } from 'react';
import axios from 'axios';

export interface IpApiData {
  asn: string;
  city: string;
  // eslint-disable-next-line camelcase
  continent_code: string;
  country: string;
  // eslint-disable-next-line camelcase
  country_area: number;
  // eslint-disable-next-line camelcase
  country_calling_code: string; // phone prefix
  // eslint-disable-next-line camelcase
  country_capital: string;
  // eslint-disable-next-line camelcase
  country_code: string;
  // eslint-disable-next-line camelcase
  country_code_iso3: string;
  // eslint-disable-next-line camelcase
  country_name: string;
  // eslint-disable-next-line camelcase
  country_population: number;
  // eslint-disable-next-line camelcase
  country_tld: string;
  currency: string;
  // eslint-disable-next-line camelcase
  currency_name: string;
  // eslint-disable-next-line camelcase
  in_eu: boolean;
  ip: string;
  languages: string;
  latitude: number;
  longitude: number;
  network: string;
  org: string; // internet service provider
  postal: string;
  region: string;
  // eslint-disable-next-line camelcase
  region_code: string;
  timezone: string;
  // eslint-disable-next-line camelcase
  utc_offset: string;
  version: string;
}

export interface IpData {
  countryCode: string;
}

export const fillData = (): IpData => {
  return {
    countryCode: '',
  };
};

const getDataFromIpApi = (state: IpData, setState: (state: IpData) => void) => {
  axios.get('https://ipapi.co/json/').then((response) => {
    const ipData: IpApiData = response.data;
    if (state.countryCode !== ipData.country_code)
      setState({
        countryCode: ipData.country_code,
      });
  });
};

/**
 * fetches the current user's IP data using an external provider
 */
export const useGetCurrentIpData = (): IpData => {
  const [state, setState] = useState(fillData());

  useEffect(() => {
    getDataFromIpApi(state, setState);
  }, [state]);

  return state;
};
