import { Button, CenteredCol, Col, FontAweIcon, Label, Row } from 'atoms';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { InvestorDocument } from 'services/apollo';

interface DocumentGroupProps {
  stoID: number;
  stoTitle: string;
  activeDocumentGroup: InvestorDocument[] | undefined;
}

export const DocumentGroup: FC<DocumentGroupProps> = (props) => {
  const { stoID, stoTitle, activeDocumentGroup } = props;
  const [isOpen, setIsOpen] = useState(!!activeDocumentGroup?.length);

  const { t } = useTranslation();

  const toggle = () => setIsOpen(!isOpen);

  const download = (documentLink: string) => {
    const element = document.createElement('a');
    element.href = `${documentLink}`;
    element.target = `_blank`;
    element.rel = 'noreferrer noopener';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  return (
    <>
      <Row className="bg-light" key={`stoRowCollapse_${stoID}`}>
        <Col>
          <Row key={`stoRowBG_${stoID}`} className="h5 bg-light" onClick={toggle}>
            <CenteredCol>
              {t('DocumentGroup-title', { stoTitle })}
              {!activeDocumentGroup?.length ? <b>{t('DocumentGroup-label-no-documents')}</b> : null}
            </CenteredCol>
            <CenteredCol md={1}>
              <Label className={isOpen ? 'ti-angle-up' : 'ti-angle-down'} />
            </CenteredCol>
          </Row>
          <Collapse key={`stoCollapse_${stoID}`} className="justify-content-end" isOpen={isOpen}>
            <Row key={`stoRowHeadings_${stoID}`} className="h6 text-capitalize">
              <CenteredCol md={6}>{t('InvestorDocuments-Index-thead-title')}</CenteredCol>
              <CenteredCol md={3}>{t('InvestorDocuments-Index-thead-date')}</CenteredCol>
            </Row>
            {activeDocumentGroup?.map((doc: InvestorDocument) => {
              if (doc.stoID === stoID) {
                return (
                  <Row key={`document_${doc.ID}`}>
                    <CenteredCol md={6}>
                      <span>
                        <FontAweIcon icon="copy" style={{ marginRight: '0.2rem' }} />
                        <label>{doc.documentTitle}</label>
                      </span>
                    </CenteredCol>
                    <CenteredCol md={4}>
                      <label>{doc.uploadDate}</label>
                    </CenteredCol>
                    <CenteredCol>
                      <Button size="sm" onClick={() => download(doc.link)}>
                        {t('DocumentGroup-button-download')}
                      </Button>
                    </CenteredCol>
                  </Row>
                );
              }
              return <></>;
            })}
          </Collapse>
        </Col>
      </Row>
      <br />
    </>
  );
};
