import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'atoms';
import { Alert } from 'reactstrap';
import VerificationBanner from './VerificationBanner';

export interface RRegistrationSuccessProps {
  email: string;
}

const RegistrationSuccess: React.FC<RRegistrationSuccessProps> = (props) => {
  const { t } = useTranslation();
  const { email } = props;

  return (
    <Row>
      <Col md={{ size: 8, offset: 2 }}>
        <VerificationBanner />
        <Alert color="success">
          <h3>{t('RegistrationSuccess-card-header')}</h3>
          <p>{t('RegistrationSuccess-message-email', { email })}</p>
          <p>{t('RegistrationSuccess-message-verification')}</p>
          <p className="text-muted">
            {t('RegistrationSuccess-message-emailNotReceived')}
            <br />
            <br />
            <ul>
              <li>{t('RegistrationSuccess-message-checkSpam')}</li>
              <li>{t('RegistrationSuccess-message-checkEmailCorrectness')}</li>
              <li>{t('RegistrationSuccess-message-contactSupport')}</li>
            </ul>
          </p>
        </Alert>
      </Col>
    </Row>
  );
};

export default RegistrationSuccess;
