import React, { useState } from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { Modal, ModalBody, ModalFooter, Input } from 'reactstrap';

import {
  usePolymeshSwapMutation,
  GetPendingPolymeshTransactionsCountDocument,
  PolymeshSwapBetween,
} from 'services/apollo';
import { PolymeshTransactionItem } from 'services/apollo/polymesh';
import { Button, BsSwal } from 'atoms';

const MyTransactionActionCell: React.FC<Cell<PolymeshTransactionItem>> = ({ row: { original } }) => {
  const { t } = useTranslation();

  const [modal, setModal] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [privateKey, setPrivateKey] = useState('');

  const [doPolymeshSwap] = usePolymeshSwapMutation({
    refetchQueries: [
      { query: GetPendingPolymeshTransactionsCountDocument, variables: { between: PolymeshSwapBetween.InvestorAdmin } },
    ],
  });

  const { ID } = original;

  const toggle = (): void => {
    setModal(!modal);
  };

  const handleSwap = async (): Promise<void> => {
    setIsButtonDisabled(true);
    return doPolymeshSwap({ variables: { recordID: ID, secretKey: privateKey } })
      .then(() => {
        setIsButtonDisabled(false);
        setModal(!modal);
        BsSwal.fire({
          title: t('MyTransactionActionCell-doPolymesh-popUp-success'),
          icon: 'success',
        });
      })
      .catch((err) => {
        setIsButtonDisabled(false);
        BsSwal.fire({
          icon: 'error',
          text: err.message,
        });
      });
  };

  return (
    <>
      <Button size="sm" onClick={toggle}>
        {t('MyTransactionActionCell-button-swap')}
      </Button>
      <Modal
        isOpen={modal}
        toggle={toggle}
        centered
        role="form"
        returnFocusAfterClose
        backdrop="static"
        keyboard={false}
      >
        <Wrap>
          <ModalBody>
            <Input
              autoComplete="off"
              required
              type="password"
              maxLength={200}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPrivateKey(e.currentTarget.value || '')}
              value={privateKey}
              placeholder={t('MyTransactionActionCell-placeholder-privateKey')}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={handleSwap} disabled={isButtonDisabled}>
              {t('MyTransactionActionCell-button-start')}
            </Button>{' '}
            <Button onClick={toggle} disabled={isButtonDisabled}>
              {t('MyTransactionActionCell-button-cancel')}
            </Button>
          </ModalFooter>
        </Wrap>
      </Modal>
    </>
  );
};

export default MyTransactionActionCell;

const Wrap = styled.div`
  padding: 0.5rem;
  text-align: center;
  background: #ebf0f7;
  border-radius: 10px;
`;
