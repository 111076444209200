import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useActiveSto } from 'hooks';
import { useInvestorSharesQuery } from 'services/apollo';
import { InvestorShares } from 'services/apollo/shares';

import { Button, Loading, Card, CardBody, Row, Col, BadgeIcon, BrandIcon } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import InternalExchangeValueCell from './InternalExchangeValueCell';
import InternalExchangeActionCell from './InternalExchangeActionCell';
import HistoricalPerformanceActionCell from './HistoricalPerformanceActionCell';
import SortTable from '../SortTable';

interface InternalExchangeProps {
  isTradingBuyOrdersEnabled: boolean;
}

const InternalExchange: React.FC<InternalExchangeProps> = ({ isTradingBuyOrdersEnabled }) => {
  const { sto } = useActiveSto();
  const history = useHistory();
  const { t } = useTranslation();

  const columns = React.useMemo(
    () => [
      {
        Header: t('InternalExchange-column-header-title'),
        accessor: 'shareType.title',
      },
      {
        Header: t('InternalExchange-column-header-value'),
        accessor: (item: InvestorShares) => {
          return item.shareType.nominalValue > 0 ? item.shareType.nominalValue : item.shareType.premiumValue;
        },
        Cell: InternalExchangeValueCell,
        id: 'value',
        className: 'text-center',
      },
      {
        Header: t('InternalExchange-column-header-share'),
        accessor: 'shares',
        className: 'text-center',
      },
      {
        accessor: 'ID',
        Cell: InternalExchangeActionCell,
      },
      {
        Header: t('InternalExchange-TableHeader-HistoricalPerformance'),
        accessor: 'shareTypeID',
        Cell: HistoricalPerformanceActionCell,
      },
    ],
    [t],
  );

  const { data, loading } = useInvestorSharesQuery({
    variables: { ...(sto > 0 && { stoID: sto }), getTrueBalances: true },
    fetchPolicy: 'no-cache',
  });

  if (loading || !data) {
    return <Loading />;
  }

  const { investorShares } = data;

  return (
    <Card>
      <CardHeader
        text={t('InternalExchange-CardHeader-Text')}
        caption={t('InternalExchange-CardHeader-Caption')}
        icon={<BrandIcon icon="wallet" pill color="#314f75" />}
        rightButton={
          isTradingBuyOrdersEnabled ? (
            <Button onClick={() => history.push('/investor/new-buy-order/')}>{t('InternalExchange-button-new')}</Button>
          ) : undefined
        }
      />
      <CardBody className="mb-2">
        <SortTable columns={columns} data={investorShares} />
        <Row>
          <Col xs="auto">
            <BadgeIcon color="cyan" pill>
              N
            </BadgeIcon>
            {t('InternalExchange-label-nominal')}
          </Col>
          <Col xs="auto">
            <BadgeIcon color="pink" pill>
              P
            </BadgeIcon>
            {t('InternalExchange-label-premium')}
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default InternalExchange;
