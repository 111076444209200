import React from 'react';
import styled from 'styled-components';
import { Modal } from 'reactstrap';

const SideModal = ({ 
  isOpen,
  hideModal,
  className,
  noWrapper,
  bodyContent
}) => {

  return (
    <SideBarModal
      id="ModalSideBar"
      isOpen={isOpen}
      className={isOpen ? className : `${className} closed`}
      backdrop
      toggle={hideModal}
    >
      {noWrapper ? (
        bodyContent({ hideModal })
      ) : (
        <>{typeof bodyContent === 'string' ? bodyContent : bodyContent()}</>
      )}
     </SideBarModal>
  )
}

export default SideModal;


const SideBarModal = styled(Modal)`
  position: fixed;
  top: 0;
  right: 0;
  max-width: max-content;
  margin-top: -60px;
  transition: all 50s ease-in-out !important;
  transform: translate(0px, 0px) !important;

  .closed {
    transform: translateX(-100vw);
  }
`;