import React from 'react';

import { AtomicSwapStatus, useAcceptBlockchainSwapMutation } from 'services/apollo/graphql';

import { ethers } from 'ethers';
import { BsSwal, Button, FontAweIcon } from 'atoms';
import { useTranslation } from 'react-i18next';
import { Address, useContractWrite, useNetwork, usePrepareContractWrite, useWaitForTransaction } from 'wagmi';
import { toTokenPowerOf } from 'lib/helpers';
import atomicSwapContractAbi from 'abis/atomicSwapContractAbi.json';
import Web3 from 'web3';
import BlockchainNetworkSwitchButton from 'components/BlockchainNetworkSwitchButton';

interface AtomicSwapOpenButtonProps {
  chainID: number;
  blockchainExplorer: string;
  swapContractAddress: string;
  setAtomicSwapCurrentStatus: (atomicSwapStatus: AtomicSwapStatus) => void;
  offerID: number;
  isSell: boolean;
  shareTypeContractAddress: string;
  currencyContractAddress: string;
  swapSecret: string;
  swapExpiryDate: string;
  executorWalletAddress: string;
  offeredSharesAmount: number;
  offeredCurrencyAmount: number;
  atomicSwapStatus: AtomicSwapStatus;
}

const AtomicSwapOpenButton: React.FC<AtomicSwapOpenButtonProps> = ({
  chainID,
  blockchainExplorer,
  swapContractAddress,
  setAtomicSwapCurrentStatus,
  offerID,
  isSell,
  shareTypeContractAddress,
  currencyContractAddress,
  swapSecret,
  swapExpiryDate,
  executorWalletAddress,
  offeredSharesAmount,
  offeredCurrencyAmount,
  atomicSwapStatus,
}) => {
  const { t } = useTranslation();
  const [acceptBlockchainSwap] = useAcceptBlockchainSwapMutation();
  const { chain } = useNetwork();
  const isBuyerSellerCommitted = [AtomicSwapStatus.BuyerCommitted, AtomicSwapStatus.SellerCommitted].includes(
    atomicSwapStatus,
  );
  const sharesTokenAmount = toTokenPowerOf(shareTypeContractAddress, offeredSharesAmount, chainID);
  const currencyTokenAmount = toTokenPowerOf(currencyContractAddress, offeredCurrencyAmount, chainID);

  const _swapNumber = Web3.utils.toHex(swapSecret);
  const _executor = executorWalletAddress;
  const _openingToken = isSell ? shareTypeContractAddress : currencyContractAddress;
  const tokensToOpen = isSell ? sharesTokenAmount : currencyTokenAmount;
  const _tokensToOpen = Web3.utils.toHex(tokensToOpen);
  const _closingToken = isSell ? currencyContractAddress : shareTypeContractAddress;
  const tokenToClose = isSell ? currencyTokenAmount : sharesTokenAmount;
  const _tokensToClose = Web3.utils.toHex(tokenToClose);
  const _expiry = ethers.utils.hexValue(Math.floor(new Date(swapExpiryDate).getTime() / 1000));

  const { config } = usePrepareContractWrite({
    address: swapContractAddress as Address,
    abi: atomicSwapContractAbi,
    functionName: 'open',
    args: [_swapNumber, _executor, _openingToken, _tokensToOpen, _closingToken, _tokensToClose, _expiry],
    enabled: isBuyerSellerCommitted,
  });

  const {
    write,
    isLoading,
    data: writeContractData,
  } = useContractWrite({
    ...config,
    chainId: chainID,
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  const { isLoading: transactionLoading } = useWaitForTransaction({
    hash: writeContractData?.hash,
    enabled: isBuyerSellerCommitted,
    onSuccess(data) {
      acceptBlockchainSwap({
        variables: { offerID, walletAddress: data.from.toString() },
      })
        .then(() => {
          if (isSell) {
            setAtomicSwapCurrentStatus(AtomicSwapStatus.SellerSent);
          }
          if (!isSell) {
            setAtomicSwapCurrentStatus(AtomicSwapStatus.BuyerCompleted);
          }
          BsSwal.fire({
            title: t('useAtomicSwap-openSwap-PopUp-success-title'),
            text: t('useAtomicSwap-openSwap-PopUp-success-text'),
            icon: 'success',
            footer: `<a target="_blank" href="${blockchainExplorer}tx/${data.transactionHash}">
            ${t('useAtomicSwap-openSwap-PopUp-success-footer')}</a>`,
          });
        })
        .catch((error) => {
          BsSwal.fire({
            title: t('Error'),
            icon: 'error',
            text: error.message,
          });
        });
    },
    onError(error) {
      BsSwal.fire({
        title: t('Error'),
        icon: 'error',
        text: error.message,
      });
    },
  });

  return (
    <>
      {chain?.id !== chainID && isBuyerSellerCommitted ? (
        <BlockchainNetworkSwitchButton chainID={chainID} />
      ) : (
        <>
          <Button size="lg" disabled={transactionLoading || isLoading} onClick={() => write?.()}>
            {t('AtomicSwapComponent-button-step3-openSwap')}
          </Button>
          {(transactionLoading || isLoading) && (
            <p>
              <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
              {t('AtomicSwapComponent-label-step3Loading')}
            </p>
          )}
        </>
      )}
    </>
  );
};

export default AtomicSwapOpenButton;
