import { MetaValues } from 'services/apollo';
import { useState } from 'react';

export interface MetaState {
  [key: string]: string;
}

export interface MetaStateUpdate {
  (value: string, key: string): void;
}

const defaultMetaState = (meta: MetaValues[]) => {
  const result: MetaState = {};
  meta.forEach((m) => {
    result[m.key] = m.value;
  });
  return result;
};

type UseMetaState = [MetaState, MetaStateUpdate];

const useMetaState = (meta: MetaValues[]): UseMetaState => {
  const [state, setState] = useState<MetaState>(defaultMetaState(meta));
  const onChange = (value: string, key: string) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  return [state, onChange];
};

export default useMetaState;
