import { Chain } from 'wagmi';

/*
Since wagmi which is part of the WalletConnect SDK does not support the Libex blockchain by default,
 it needed to be implemented as a custom chain utilizing the Chain object from the wagmi library
*/

export const libexTestnet = {
  id: 2702,
  name: 'Libex Testnet',
  network: 'libexTestnet',
  nativeCurrency: {
    decimals: 18,
    name: 'LIBEX',
    symbol: 'LBX',
  },
  rpcUrls: {
    public: { http: ['https://test-rpc.libex.ai'] },
    default: { http: ['https://test-rpc.libex.ai'] },
  },
  blockExplorers: {
    etherscan: { name: 'LibexExplorer', url: 'https://test-explorer.libex.ai' },
    default: { name: 'LibexExplorer', url: 'https://test-explorer.libex.ai' },
  },
} as Chain;

export const libex = {
  id: 2701,
  name: 'Libex',
  network: 'libex',
  nativeCurrency: {
    decimals: 18,
    name: 'LIBEX',
    symbol: 'LBX',
  },
  rpcUrls: {
    public: { http: ['https://rpc.libex.ai'] },
    default: { http: ['https://rpc.libex.ai'] },
  },
  blockExplorers: {
    etherscan: { name: 'LibexExplorer', url: 'https://explorer.libex.ai' },
    default: { name: 'LibexExplorer', url: 'https://explorer.libex.ai' },
  },
} as Chain;
