import styled from 'styled-components';

import { CardHeader, Button } from 'atoms';

export const Wrapper = styled.div`
  text-align: center;
  padding: 5px;
  background: #d9d9d9;
  min-height: 100vh;
`;

export const BackButton = styled(Button)`
  background: ${(p) => p.theme.colors.grayDark};
`;

export const SectionWrapper = styled(CardHeader)`
  margin: 10px;
  background: ${(p) => p.theme.colors.white};
  border-radius: 10px !important;
  text-align: left;
`;
