import { getContrast } from 'polished';
import { ChangeEvent } from 'react';
import { CommissionType, DiscountType, PriceTransformerTrigger } from 'services/apollo';
import { FetchFees } from 'services/apollo/fee';
import { DiscountQueryResult } from 'services/apollo/discount';
import * as math from 'mathjs';

/**
 * returns a random color
 */
export function getRandomColor(): string {
  return `#${Math.floor(Math.random() * 16777215).toString(16)}`;
}

export function getSuitableColor(color = '#ffffff'): string {
  const contrastWhite = getContrast('#ffffff', color);
  const contrastBlack = getContrast('#000000', color);
  return contrastWhite > contrastBlack ? '#ffffff' : '#000000';
}

export function setBackgroundColor(color = '#ffffff'): string {
  const contrast = getContrast('#ffffff', color);
  if (contrast < 1.5) return '#d3d3d3';
  return color;
}

export interface Transformer {
  type: DiscountType;
  amount: number;
  triggers: PriceTransformerTrigger[];
}

export interface CalculatedPrices {
  fees: number;
  total: number;
  discounts: number;
}

export interface CalculateParameters {
  fee: FetchFees[];
  discounts: DiscountQueryResult[];
  shareTotalPrice: number;
  amount: number;
  isSellBack: boolean;
  isFeesEnabled: boolean;
  isDiscountsEnabled: boolean;
}

const calculatePrice = (transformer: Transformer, subTotal: number): number => {
  if (transformer.type === DiscountType.Flat) {
    return transformer.amount;
  }
  const fractionalPrice = math.divide(
    math.multiply(math.number(transformer.amount.toString()), math.number(subTotal.toString())),
    100,
  );
  return fractionalPrice;
};

const checkTriggers = (transformer: Transformer, subTotal: number): number => {
  return transformer.triggers.reduce((previousValue, currentValue) => {
    const isUpperBoundEnabled = currentValue.upperBound > 0;
    const isUpperBoundMet = subTotal <= currentValue.upperBound;
    const isLowerBoundMet = subTotal >= currentValue.lowerBound;

    if (!isLowerBoundMet) {
      return 0;
    }
    if (isUpperBoundEnabled && !isUpperBoundMet) {
      return 0;
    }
    return calculatePrice(transformer, subTotal);
  }, 0);
};

const getPriceTransformer = (transformer: Transformer[], subTotal: number) => {
  const transformerPrice = transformer.reduce((previousValue, currentValue) => {
    if (!currentValue.triggers?.length) {
      return math.add(calculatePrice(currentValue, subTotal), previousValue);
    }
    return math.add(checkTriggers(currentValue, subTotal), previousValue);
  }, 0);
  return transformerPrice;
};

export const CalculateShareFeesAndTotal = (params: CalculateParameters): CalculatedPrices => {
  const { fee, discounts, shareTotalPrice, amount, isSellBack, isFeesEnabled, isDiscountsEnabled } = params;
  const feeTransformer: Transformer[] = isFeesEnabled
    ? fee.map((f) => {
        return {
          amount: f.amount,
          type: f.status === CommissionType.Flat ? DiscountType.Flat : DiscountType.Percentage,
          triggers: [],
        };
      })
    : [];
  const subTotal = math.multiply(amount, shareTotalPrice);
  const feeCost = isSellBack
    ? getPriceTransformer(feeTransformer, subTotal) * -1
    : getPriceTransformer(feeTransformer, subTotal);
  const discountTransformer: Transformer[] = isDiscountsEnabled ? discounts : [];
  const discountCost = isSellBack
    ? getPriceTransformer(discountTransformer, subTotal)
    : getPriceTransformer(discountTransformer, subTotal) * -1;
  const total = math.add(math.add(math.number(subTotal), math.number(feeCost)), math.number(discountCost));
  return { fees: feeCost, total: total < 0 ? 0 : total, discounts: discountCost };
};

export const stripToNumber = (
  shareDecimals: number,
  e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>,
): number => {
  const isShareDivisible = shareDecimals > 0;
  const strippedNumber = isShareDivisible
    ? e.currentTarget.value.replace(/[^0-9.]+/, '')
    : e.currentTarget.value.replace(/[^0-9]+/, '');
  return Number(strippedNumber);
};
