import PublicRoute from 'lib/routing/PublicRoute';
import VerifySecret from 'pages/auth/VerifySecret';
import Login from 'pages/auth/Login';
import Registration from 'pages/auth/Registration';
import ForgotPassword from 'pages/auth/ForgotPassword';
import SetNewPassword from 'pages/auth/SetNewPassword';
import TwoFactorConfirm from 'pages/auth/TwoFactorConfirm';
import SsoSignIn from 'pages/auth/SsoSignIn';

export const verifySecret = new PublicRoute({
  path: '/verify/:secret',
  Component: VerifySecret,
});

export const twoFactor = new PublicRoute({
  path: '/two-factor',
  Component: TwoFactorConfirm,
});

export const ssoSign = new PublicRoute({
  path: '/sso-sign-in/:token',
  Component: SsoSignIn,
});

export const signIn = new PublicRoute({
  path: '/sign-in',
  Component: Login,
});

export const signUp = new PublicRoute({
  path: '/sign-up',
  Component: Registration,
  featureFlagName: 'allowInvestorsToRegister',
});

export const forgotPassword = new PublicRoute({
  path: '/forgot-password',
  Component: ForgotPassword,
});

export const setNewPassword = new PublicRoute({
  path: '/new-password/:token',
  Component: SetNewPassword,
});

export default [verifySecret, signIn, signUp, forgotPassword, setNewPassword, twoFactor, ssoSign];
