import React from 'react';
import { useTranslation } from 'react-i18next';

import { InvestorWalletHistory } from 'services/apollo/multisto';
import { useLocalDate } from 'hooks';

import { Button, Loading, TD, TR } from 'atoms';
import { Sto, useFindBlockchainProtocolQuery, useInvestorAppDataQuery } from 'services/apollo';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

interface HistoryItemProps {
  history: InvestorWalletHistory;
  histSto: Partial<Sto> | undefined;
  displayPropertyName: boolean;
}

const HistoryItem: React.FC<HistoryItemProps> = ({ histSto, history, displayPropertyName }) => {
  const { t } = useTranslation();
  const toLocalDate = useLocalDate();

  const { data: blockchainData, loading } = useFindBlockchainProtocolQuery({
    variables: { id: history.buyAlert?.shareType.blockchainProtocolID ?? 0 },
  });
  const { data: appData, loading: loadingAppData } = useInvestorAppDataQuery();

  if (loading || !appData || loadingAppData) {
    return <Loading />;
  }

  const getBlockchainExplorer = (): string => {
    if (!blockchainData?.findBlockchainProtocol) {
      return '';
    }
    const { isBlockchainTestnetEnabled } = appData.investorAppParameters;
    const { explorerURL, explorerURLTestnet } = blockchainData.findBlockchainProtocol.blockchain;
    const blockchainExplorer = isBlockchainTestnetEnabled ? explorerURLTestnet : explorerURL;
    return `${blockchainExplorer}tx/${history.transactionHash}`;
  };

  const blockchainExplorerURL = getBlockchainExplorer();

  return (
    <TR>
      {displayPropertyName && <TD min="10vw">{histSto?.title}</TD>}
      <TD min="10vw">{toLocalDate(history.dateReceived)}</TD>
      <TD min="5vw">
        <CurrencySymbolDisplay value={history.amount ?? 0} currency={history.currency} />
      </TD>
      <TD min="10vw">
        {history.isApproved ? (
          <>
            {toLocalDate(history.dateApproved)} <br />
            {history.isApproved === 1 && (
              <span style={{ color: 'green' }}>
                <b>{t('HistoryItem-Status-Approved')}</b> {t('HistoryItem-Status-Approved-Added')}
              </span>
            )}
            {history.isApproved === 2 && (
              <span style={{ color: 'red' }}>
                <b>{t('HistoryItem-Status-Declined')}</b>
              </span>
            )}
            {history.isApproved === 3 && (
              <span style={{ color: 'green' }}>
                <b>{t('HistoryItem-Status-Increased')}</b>
              </span>
            )}
            {history.isApproved === 4 && (
              <span style={{ color: 'red' }}>
                <b>{t('HistoryItem-Status-Reduced')}</b>
              </span>
            )}
            {history.isApproved === 5 && (
              <span style={{ color: 'green' }}>
                <b>{t('HistoryItem-Status-Transfer')}</b>
              </span>
            )}
          </>
        ) : (
          <span style={{ color: 'black' }}>
            <b>{t('HistoryItem-Status-Pending')}</b>
          </span>
        )}
      </TD>
      <TD min="10vw">{history.details}</TD>
      {history.transactionHash && blockchainExplorerURL && (
        <TD>
          <Button onClick={() => window.open(blockchainExplorerURL)}>{t('HistoryItem-Button-View')}</Button>
        </TD>
      )}
    </TR>
  );
};

export default HistoryItem;
