import React, { useEffect, useState } from 'react';
import { InvestorWalletChannel } from 'services/apollo/multisto';
import { DpayEntity } from 'services/apollo/pint';
import { BsSwal, Loading } from 'atoms';
import { useTranslation } from 'react-i18next';
import { PintPreconfiguredProcessors, usePintActionBeginTransactionInvestorMutation } from 'services/apollo';
import { useHistory } from 'react-router-dom';
import { DwollaAccountManager } from '../accountManagers/DwollaAccountManager';

interface DepositFormDwollaPlaidProps {
  hideModal: () => void;
  investorID: number;
  stoID: number;
  //   mercuryAccount: MercuryInfo;
  invoiceID?: number;
  channel?: InvestorWalletChannel;
}

interface DwollaFundingSourcePartial {
  id?: string;
  status?: 'verified' | 'unverified' | string;
}

export interface CreateFundingSourceOptions {
  customerId: string; // Dwolla Customer ID
  fundingSourceName: string; // Dwolla Funding Source Name
  plaidToken: string; // Plaid Account Processor Token
  _links: Record<string, unknown>; // Dwolla On Demand Authorization Link
}

export type DwollaAccountState = {
  account: DpayEntity | null;
  isReady: boolean;
};

/**
 * Form for invoking Dwolla and opening the Plaid widget when a bank account needs to be registered first
 *
 * Ensures that the Dwolla account is made and a bank account is set up.
 *
 * After that, initiates a transfer.
 */
export const DepositFormDwollaPlaid: React.FC<DepositFormDwollaPlaidProps> = ({
  hideModal,
  investorID,
  stoID,
  invoiceID,
  channel,
}) => {
  const [accountState, setAccountState] = useState<DwollaAccountState>({
    account: null,
    isReady: false,
  });
  const { t } = useTranslation();
  const history = useHistory();
  // const [account, setAccount] = useState<DpayEntity | null>(null);
  // const [accountIsReady, setAccountIsReady] = useState<boolean>(false);

  // const changeAccount = (entity: DpayEntity) => {
  //   setAccount(entity);
  // }
  // const changeAccountIsReady = (ready: boolean) => {
  //   setAccountIsReady(ready);
  // }

  const changeAccountState = (newAccountState: DwollaAccountState): void => {
    setAccountState(newAccountState);
  };

  // const transfer = usePint
  const [transfer] = usePintActionBeginTransactionInvestorMutation();

  useEffect(() => {
    if (accountState.isReady) {
      const dwFundingSource = accountState.account?.storage?.fundingSource as DwollaFundingSourcePartial | undefined;
      if (dwFundingSource) {
        if (dwFundingSource.status === 'unverified') {
          BsSwal.fire({
            icon: 'error',
            title: t('Error'),
            text: 'dwolla-unverified-funding-source',
          });
          hideModal();
        }
      }

      console.log(
        `PINT Transfer:\nAccount ID:${accountState.account?.paymentAccountID}\nSource ID:${accountState.account?.topUpSourceId}`,
      );

      transfer({
        variables: {
          processor: PintPreconfiguredProcessors.Dwolla,
          invoiceId: invoiceID,
          channelId: channel?.ID,
        },
      })
        .then((mutation) => {
          const { data, errors } = mutation;
          const result = data?.pint_action_beginTransaction_Investor;
          if (result) {
            BsSwal.fire({
              icon: 'success',
              text: t('dwolla-transfer-in-progress'),
            });
            history.push('/investor/Portfolio');
          } else if (!errors) {
            BsSwal.fire({
              icon: 'info',
              text: t('api-pint-transaction-already-in-progress'),
            });
          } else {
            console.log(errors);
            BsSwal.fire({
              icon: t('Error'),
              text: t('api-dwolla-failed-transfer-initiate'),
            });
            history.push('/investor/Portfolio');
          }
          hideModal();
        })
        .catch((e) => {
          console.error(e);

          BsSwal.fire({
            icon: 'error',
            title: t('Error'),
            text: t('api-dwolla-failed-transfer-initiate'),
          });
          hideModal();
        });
    }
  }, [accountState]);

  return (
    <>
      {accountState.isReady ? (
        <>
          {t('pint-processing-transaction')}
          <Loading />
        </>
      ) : (
        <>
          <DwollaAccountManager
            hideModal={hideModal}
            channel={channel}
            investorID={investorID}
            stoID={stoID}
            invoiceID={invoiceID}
            accountState={accountState}
            changeAccountState={changeAccountState}
          />
        </>
      )}
    </>
  );
};
