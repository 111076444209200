import React, { useState } from 'react';
import { BsSwal, Button, Card, CardBody, Col, Loading, Row, Select } from 'atoms';

import { ActivePropertyItem, InvestorInvoice } from 'services/apollo/multisto';
import { CardHeader } from 'components/card-header/CardHeader';
import SUMMARY from 'assets/img/summary.png';
import { useTranslation } from 'react-i18next';
import { useLocalDate } from 'hooks';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import {
  InvoiceStatusType,
  PaymentChannelType,
  useGetSupportedPaymentChannelsQuery,
  usePayOfferWithInternalWalletBalanceMutation,
  useUpdateInvoiceInvestorMutation,
} from 'services/apollo';
import { useHistory } from 'react-router-dom';
import DepositFormXendit from 'pages/wallet/components/Forms/depositForms/DepositFormXendit';
import { useModal } from 'components/modals/Modal';

interface PayInvoiceContentProps {
  invoice: InvestorInvoice;
  hideModal: () => void;
  property?: ActivePropertyItem;
  isHistorical?: boolean;
}

interface SelectOption {
  value: number;
  label: string;
}

const PayInvoiceContent: React.FC<PayInvoiceContentProps> = (props) => {
  const internalModal = useModal();
  const { t } = useTranslation();
  const history = useHistory<{ selectedChannelID?: number }>();
  const { invoice, property, isHistorical, hideModal } = props;
  const toLocalDate = useLocalDate();
  const { data, loading } = useGetSupportedPaymentChannelsQuery({
    variables: {
      invoiceResolver: invoice.resolver,
      invoiceID: invoice.ID,
    },
  });
  const [payChanID, setPayChanID] = useState(0);
  const [payWithInternalWallet] = usePayOfferWithInternalWalletBalanceMutation();
  const [payWithManualDeposit] = useUpdateInvoiceInvestorMutation();

  if (!data || loading) {
    return <Loading />;
  }

  const { getSupportedPaymentChannels: paymentChannels } = data;

  const payChanSelectOptions = paymentChannels.map((chan) => {
    return { value: chan.ID, label: chan.title };
  });

  const handleInternalWalletPayment = async (resolveID: number) => {
    const { data: paymentResult } = await payWithInternalWallet({
      variables: {
        offerID: resolveID,
      },
    });
    if (paymentResult?.payOfferWithInternalWalletBalance) {
      await BsSwal.fire({
        title: t('PayInvoiceContent-internalWallet-popUp-success-title'),
        icon: 'success',
        text: t('PayInvoiceContent-internalWallet-popUp-success-text'),
        didClose: () => {
          history.push('/investor/wallet');
          hideModal();
        },
      });
    }
  };

  const handleManualDepositPayment = async () => {
    const { data: paymentResult } = await payWithManualDeposit({
      variables: {
        invoiceID: invoice.ID,
        paymentChannelID: payChanID,
      },
    });
    if (paymentResult?.updateInvoice_Investor) {
      await BsSwal.fire({
        title: t('PayInvoiceContent-manualDeposit-popUp-success-title'),
        icon: 'success',
        text: t('PayInvoiceContent-manualDeposit-popUp-success-text'),
        didClose: () => {
          history.push({
            pathname: `/investor/invoice/${invoice.ID}`,
            state: { selectedChannelID: payChanID },
          });
          hideModal();
        },
      });
    }
  };

  const onClick = async () => {
    // TODO: DIG-1687 forward to the appropriate payment channel page
    const channel = paymentChannels.find((paymentChannel) => paymentChannel.ID === payChanID);
    if (!channel) {
      throw new Error('no channel found - error occurred in PayInvoiceContent - onClick');
    }
    if (!invoice.resolveID) {
      throw new Error(
        'no resolveID tied to the invoice - error occurred in PayInvoiceContent handleInternalWalletPayment',
      );
    }
    switch (channel.channelType) {
      case PaymentChannelType.Internal:
        await handleInternalWalletPayment(invoice.resolveID);
        break;
      case PaymentChannelType.ManualDeposits:
        await handleManualDepositPayment();
        break;
      case PaymentChannelType.Xendit:
        internalModal.showModal({
          className: 'w-50 mw-100',
          submitText: 'Pay',
          showFooter: false,
          cancelText: 'Cancel',
          bodyContent: () => <DepositFormXendit hideModal={hideModal} invoiceID={invoice?.ID || 0} />,
        });
        break;
      default:
        console.error('unsupported channel type - error occurred in PayInvoiceContent - onClick');
        break;
    }
  };

  const interactableStatuses: InvoiceStatusType[] = [InvoiceStatusType.Unpaid];

  return (
    <>
      <Row>
        <Col md={12}>
          <Card>
            <CardHeader
              text={
                isHistorical
                  ? t('PayInvoiceContent-CardHeader-text-resolvedInvoice')
                  : t('PayInvoiceContent-CardHeader-text-pendingInvoice')
              }
              caption={t('PayInvoiceContent-CardHeader-caption')}
              imgSrc={SUMMARY}
            />
            <CardBody className="mb-2">
              <Row className="text-muted">
                <Col md={6}>
                  <label>{t('PayInvoiceContent-invoiceDetails-label-date')}</label>
                </Col>
                <Col md={6}>{toLocalDate(invoice.dateCreated)}</Col>
              </Row>

              <Row className="text-muted">
                <Col md={6}>
                  <label>{t('PayInvoiceContent-invoiceDetails-label-offeringTitle')}</label>
                </Col>
                <Col md={6}>{property?.title}</Col>
              </Row>

              <Row className="text-muted">
                <Col md={6}>
                  <label>{t('PayInvoiceContent-invoiceDetails-label-shareTitle')}</label>
                </Col>
                <Col md={6}>{invoice.shareType.title}</Col>
              </Row>

              <Row className="text-muted">
                <Col md={6}>
                  <label>{t('PayInvoiceContent-invoiceDetails-label-quantity')}</label>
                </Col>
                <Col md={6}>{invoice.shares}</Col>
              </Row>

              <Row className="text-muted">
                <Col md={6}>
                  <label>{t('PayInvoiceContent-invoiceDetails-label-price')}</label>
                </Col>
                <Col md={6}>
                  <CurrencySymbolDisplay
                    value={invoice.amountToPay}
                    currency={invoice.paymentChannel?.currency ?? invoice.shareType.currency}
                  />
                </Col>
              </Row>

              <Row className="text-muted">
                <Col md={6}>
                  <label>{t('PayInvoiceContent-invoiceDetails-label-status')}</label>
                </Col>
                <Col md={6}>{t(`PayInvoiceContent-invoiceDetails-statusEnum-${invoice.status}`)}</Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
      {interactableStatuses.includes(invoice.status) ? (
        <Row>
          <Col md={9}>
            <Select
              class="form-control border-input"
              name="paymentChannelDropdown"
              placeholder={t('PayInvoiceContent-selector-placeholder')}
              options={payChanSelectOptions}
              onChange={(channel: SelectOption) => setPayChanID(channel.value)}
              invalid={isHistorical}
            />
          </Col>
          <Col md={3}>
            <Button onClick={onClick}> {t('PayInvoiceContent-invoiceDetails-button-pay')} </Button>
          </Col>
        </Row>
      ) : null}
    </>
  );
};

export default PayInvoiceContent;
