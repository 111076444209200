import React, { useState } from 'react';

import { Input } from 'atoms';
import { useTranslation } from 'react-i18next';
import { InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';

export interface SecretInputProps {
  inputValue: string;
  placeholderText: string;
  inputName?: string;
  invalidCheck?: boolean;
  setInputValue?: (value: string) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}
const SecretInput: React.FC<SecretInputProps> = (props) => {
  const [passwordShown, setPasswordShown] = useState(false);
  const { t } = useTranslation();
  const { setInputValue, inputValue, placeholderText, inputName, invalidCheck, onChange } = props;

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <InputGroup>
      <Input
        name={inputName}
        type={passwordShown ? 'text' : 'password'}
        maxLength="100"
        placeholder={t(placeholderText)}
        value={inputValue}
        invalid={invalidCheck}
        onChange={
          setInputValue ? (e: React.ChangeEvent<HTMLInputElement>) => setInputValue(e.currentTarget.value) : onChange
        }
      />
      <InputGroupAddon addonType="append" onClick={togglePassword}>
        <InputGroupText>
          {passwordShown ? (
            <i className="fa fa-eye-slash" onClick={togglePassword} />
          ) : (
            <i className="fa fa-eye" onClick={togglePassword} />
          )}
        </InputGroupText>
      </InputGroupAddon>
    </InputGroup>
  );
};

export default SecretInput;
