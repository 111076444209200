import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useInvestorInvoiceAlertDeleteMutation } from 'services/apollo/graphql';
import { InvestorInvoiceAlert, ActivePropertyItem } from 'services/apollo/multisto';
import { useLocalDate } from 'hooks';
import { Button, Row, CenteredCol, BsSwal } from 'atoms';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import { useModal } from 'components/modals/Modal';
import PayInvoiceContent from './PayInvoiceContent';

interface InvoiceItemWithResolverProps {
  invoice: InvestorInvoiceAlert;
  properties: Map<number, ActivePropertyItem>;
  isHistorical?: boolean;
}

const InvoiceItemWithResolver: React.FC<InvoiceItemWithResolverProps> = ({ invoice, properties, isHistorical }) => {
  const history = useHistory();
  const { i18n, t } = useTranslation();
  const toLocalDate = useLocalDate();
  const [deleteInvoice] = useInvestorInvoiceAlertDeleteMutation();
  const modal = useModal();
  const property = properties.get(invoice?.stoID);

  const handleShowModal = () => {
    modal.showModal({
      className: 'w-50 mw-100',
      submitText: 'Pay',
      showFooter: false,
      cancelText: 'Cancel',
      bodyContent: () => (
        <PayInvoiceContent
          invoice={invoice}
          property={property}
          isHistorical={isHistorical}
          hideModal={modal.hideModal}
        />
      ),
    });
  };

  const removeInvoice = async (id: number) => {
    await BsSwal.fire({
      title: t('InvoiceItemWithResolver-PopUp-deleteQuestion-Title'),
      icon: 'info',
      confirmButtonText: t('InvoiceItemWithResolver-PopUp-deleteQuestion-confirm'),
      cancelButtonText: t('InvoiceItemWithResolver-PopUp-deleteQuestion-cancel'),
      showCancelButton: true,
    });
    const { errors } = await deleteInvoice({
      variables: {
        ID: id,
      },
    });
    if (!errors) {
      await BsSwal.fire({
        title: t('InvoiceItemWithResolver-PopUp-deleteConfirmation-Title'),
        text: t('InvoiceItemWithResolver-PopUp-deleteConfirmation-text'),
        icon: 'success',
        confirmButtonText: t('InvoiceItemWithResolver-PopUp-deleteConfirmation-confirm'),
        timer: 5000,
      });
      history.go(0);
    }
  };

  return (
    <>
      <Row className="mb-2">
        <CenteredCol md={2}>{toLocalDate(invoice.dateCreated)}</CenteredCol>
        <CenteredCol md={2}>{property?.title}</CenteredCol>
        <CenteredCol md={3}>{invoice?.shareType?.title}</CenteredCol>
        <CenteredCol md={1}>{(invoice?.shares ?? 0).toLocaleString(i18n.language)}</CenteredCol>
        <CenteredCol md={1}>
          <CurrencySymbolDisplay
            value={invoice.amountToPay}
            currency={invoice.paymentChannel?.currency ?? invoice.shareType.currency}
          />
        </CenteredCol>
        <CenteredCol md={1}>{t(`INVOICE_STATUS_TYPE-enum-${invoice.status}`)}</CenteredCol>
        <CenteredCol md={1}>
          <Button size="sm" onClick={handleShowModal}>
            {t('InvoiceItem-Review')}
          </Button>
          <Button size="sm" onClick={() => removeInvoice(invoice.ID)}>
            {t('InvoiceItem-Delete')}
          </Button>
        </CenteredCol>
      </Row>
    </>
  );
};

export default InvoiceItemWithResolver;
