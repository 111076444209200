import React, { useRef } from 'react';

import { Button, CardBody, Col, Loading, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import BANK from 'assets/img/bank.png';
import {
  MercuryConfigMode,
  useGetMercuryAccountInfoQuery,
  useGetMercuryConfigModeQuery,
} from 'services/apollo/graphql';
import { useReactToPrint } from 'react-to-print';
import { InvestorWalletChannel } from 'services/apollo/multisto';
import { Markup } from 'interweave';
import WebFont from 'webfontloader';

interface DepositFormMercuryProps {
  hideModal: () => void;
  investorID: number;
  stoID: number;
  invoiceID?: number;
  channel?: InvestorWalletChannel;
}

const DepositFormMercury: React.FC<DepositFormMercuryProps> = ({
  hideModal,
  investorID,
  stoID,
  invoiceID,
  channel,
}) => {
  const componentRef = useRef(null);
  const { t } = useTranslation();
  const { data: mercConfigData, loading: mercConfigLoading } = useGetMercuryConfigModeQuery();
  const { data: mercuryAccount, loading: mercuryAccountLoading } = useGetMercuryAccountInfoQuery({
    fetchPolicy: 'no-cache',
  });

  const mercuryAccountInfo = mercuryAccount?.getMercuryAccountInfo;

  // custom note font that makes clear the difference between: O and 0; 1 and I.
  const noteFont = 'Martian Mono';
  WebFont.load({
    google: {
      families: [noteFont],
    },
  });

  const download = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: `${t('ChannelItem-download-document-title')} - ${channel?.title}`,
  });

  if (!mercConfigData || mercConfigLoading || mercuryAccountLoading || !mercuryAccount || !mercuryAccountInfo) {
    return <Loading />;
  }

  const note =
    invoiceID && mercConfigData.getMercuryConfigMode === MercuryConfigMode.AutoShareTransfer
      ? `I${investorID}S${stoID}IN${invoiceID}`
      : `I${investorID}S${stoID}`;

  return (
    <>
      <CardHeader imgSrc={BANK} text="Mercury Transaction Details" />
      <CardBody>
        <h6>
          Initiate a payment to the following account, if your balance does not update within 3 business days, please
          contact the site administrator
        </h6>
        <hr />
        <Row>
          <Col md={3}>
            <label>Routing Number: </label>
          </Col>
          <Col md={5}>
            <label htmlFor="">{mercuryAccountInfo.routingNumber}</label>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <label htmlFor="">Account Number: </label>
          </Col>
          <Col md={5}>
            <label htmlFor="">{mercuryAccountInfo.accountNumber}</label>
          </Col>
        </Row>
        <hr />
        <div ref={componentRef}>
          <p className="text-center">
            <b>
              <label>{t('DepositFormMercury-TransactionIncludeNote-Label')} </label>
            </b>
          </p>
          <p className="text-center">
            <h3 style={{ fontFamily: noteFont }}>{note}</h3>
          </p>
          <Markup content={channel?.details || ''} className="text-center" />
        </div>
        <p className="text-center">
          <b>
            <label>{t('DepositFormMercury-InternationalWire-Instructions')}</label>
          </b>
        </p>
        <div className="d-flex justify-content-end">
          <Button onClick={download}>{t('DepositFormMercury-download-button')}</Button>
          <Button onClick={hideModal}>{t('Close')}</Button>
        </div>
      </CardBody>
    </>
  );
};

export default DepositFormMercury;
