import React from 'react';
import { Cell } from 'react-table';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { InvestorShares } from 'services/apollo/shares';

import { Button } from 'atoms';

const HistoricalPerformanceActionCell: React.FC<Cell<InvestorShares>> = ({ row: { original } }) => {
  const history = useHistory();

  const openChart = (): void => {
    history.push(`/investor/historical-performance/${original.shareTypeID}`);
  };

  return (
    <OpenChartButton size="sm" onClick={openChart}>
      <i className="ti-stats-up" />
    </OpenChartButton>
  );
};

const OpenChartButton = styled(Button)`
  color: ${(p) => p.theme.marketPlaceIconFontColor};
  font-size: 18px;
  background: ${(p) => p.theme.marketPlaceIconBGColor};
`;

export default HistoricalPerformanceActionCell;
