import React, { useState } from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router';

import {
  DiscountAppliesTo,
  FeeBeneficiary,
  FeeType,
  useFetchFeesQuery,
  useGetDiscountsQuery,
  useInvestorAppDataQuery,
  useInvestorBuyPropertyQuery,
} from 'services/apollo';

import { BsSwal, Card, CardBody, Col, Label, Loading, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import BuyPropertyShare from 'pages/active-properties/BuyPropertyShare';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import BuyPropertyShareMarketspace from './BuyPropertyShare-Marketspace';
import InternalWalletBalances from './components/InternalWalletBalances';
import NavigationDisabledBackButton from './components/NavigationDisabledBackButton';

const DetailProperty: React.FC = () => {
  const [loadingRequest, setLoadingRequest] = useState<boolean>(false);
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery();
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ _id: string }>();
  const _id = parseInt(params._id, 10);
  const { data, loading, error } = useInvestorBuyPropertyQuery({
    variables: { _id },
    fetchPolicy: 'network-only',
  });
  const { data: feeData, loading: feeLoading } = useFetchFeesQuery({
    variables: { stoID: _id, beneficiary: FeeBeneficiary.Platform, type: FeeType.BuyShares },
    fetchPolicy: 'network-only',
  });
  const { data: discountData, loading: discountLoading } = useGetDiscountsQuery({
    variables: { stoID: _id, discountAppliesTo: [DiscountAppliesTo.AllSharePurchases] },
  });

  if (loading || feeLoading || loadingRequest || appDataLoad || discountLoading) {
    return <Loading />;
  }
  if (error) {
    BsSwal.fire({
      title: t('BuyPropertyShare-notWhitelisted-popUp-title'),
      icon: 'warning',
    }).then(() => {
      history.goBack();
    });
  }
  if (
    !feeData?.fetchFees ||
    !data?.investorDetailProperty ||
    !data?.investorBalances ||
    !data?.findShareTypes ||
    !data?.investorPaymentChannels ||
    !appData?.investorAppParameters ||
    !discountData?.getDiscounts
  ) {
    return <Loading />;
  }

  const {
    investorDetailProperty: { title, details, picture },
    investorBalances: accounts,
    findShareTypes: types,
    investorPaymentChannels: channels,
  } = data;
  const { fetchFees: fees } = feeData;
  const { getDiscounts: discounts } = discountData;
  const { IsMarketSpace: isMarketSpace } = appData.investorAppParameters;
  const enabledTypes = types.filter((item) => item.isEnabled);

  return (
    <div className="content">
      <NavigationDisabledBackButton />
      {isMarketSpace ? (
        <Card>
          <Row>
            <Col md={1} />
            <Col md={11}>
              <CardHeader text={title} caption={details} imgSrc="/img/buy.png" captionFontSize="1.2em" />
            </Col>
          </Row>
          <CardBody>
            <Row>
              <Col md={1} />
              <Col md={10}>
                <Img src={picture} />
              </Col>
              <Col md={1} />
            </Row>
            <Row>
              <Col md={1} />
              <Col>
                {enabledTypes.map((item) => (
                  <React.Fragment key={item.ID}>
                    <h4>
                      <Label>
                        {t('BuyProperty-shareClassTitle')} {item.title}
                      </Label>
                    </h4>
                    <BuyPropertyShareMarketspace
                      sto={_id}
                      share={item}
                      setLoadingRequest={setLoadingRequest}
                      appData={appData.investorAppParameters}
                    />
                    <hr />
                  </React.Fragment>
                ))}
              </Col>
              <Col md={1} />
            </Row>
          </CardBody>
        </Card>
      ) : (
        <Card>
          <CardHeader
            text={t('BuyProperty-card-header-text')}
            caption={t('BuyProperty-card-caption')}
            imgSrc="/img/buy.png"
          />
          <CardBody>
            <Row>
              <Col>
                <h1>{title}</h1>
              </Col>
            </Row>
            <Row>
              <Col md={4} style={{ maxHeight: '300px' }}>
                <Img src={picture} />
                <p className="p-3">{details}</p>
              </Col>
              <Col md={8}>
                {enabledTypes.map((item) => (
                  <React.Fragment key={item.ID}>
                    <h4>
                      <Label>
                        {t('BuyProperty-shareClassTitle')} {item.title}
                      </Label>
                    </h4>
                    <BuyPropertyShare
                      sto={_id}
                      share={item}
                      hasFunds={accounts.length > 0}
                      fee={fees}
                      discounts={discounts}
                      setLoadingRequest={setLoadingRequest}
                      appData={appData.investorAppParameters}
                      channels={channels.filter(
                        (c) => c.conversionEnabled && c.currencyToConvert?.ID === item.currencyID,
                      )}
                    />
                    <hr />
                  </React.Fragment>
                ))}
              </Col>
            </Row>
            <InternalWalletBalances appData={appData.investorAppParameters} accounts={accounts} />
          </CardBody>
        </Card>
      )}
    </div>
  );
};

export default DetailProperty;

const Img = styled.img`
  width: 3840px;
  height: auto;
  object-fit: contain;
`;
