import React from 'react';

import { InvestorWalletHistory } from 'services/apollo/multisto';

import WALLET from 'assets/img/wallet.png';
import { Card, CardBody, TABLE, TH, TR, THEAD, Loading } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import HistoryItem from 'pages/wallet/components/HistoryItem';
import { useTranslation } from 'react-i18next';
import { useFindAllStoQuery } from 'services/apollo';

interface DepositHistoryProps {
  history: InvestorWalletHistory[];
  displayPropertyName: boolean;
}

const DepositHistory: React.FC<DepositHistoryProps> = ({ history, displayPropertyName }) => {
  const { t } = useTranslation();
  const { data, loading } = useFindAllStoQuery();
  if (loading || !data) {
    return <Loading />;
  }
  return (
    <Card>
      <CardHeader text={t('DepositHistory-card-header-text')} imgSrc={WALLET} />
      <CardBody className="mb-2">
        {history ? (
          <TABLE responsive>
            <THEAD>
              <TR>
                {displayPropertyName && <TH max="200px">{t('DepositHistory-Header-PropertyName')}</TH>}
                <TH max="200px">{t('DepositHistory-Header-DateReceived')}</TH>
                <TH max="200px">{t('DepositHistory-Header-Amount')}</TH>
                <TH width="50%" min="280px">
                  {t('DepositHistory-Header-Status')}
                </TH>
                <TH width="50%" min="280px">
                  {t('DepositHistory-Header-Message')}
                </TH>
                <TH />
              </TR>
            </THEAD>
            <tbody>
              {history.map((hst) => (
                <HistoryItem
                  key={hst.ID}
                  history={hst}
                  histSto={data.findAllSto?.find((item) => item.ID === hst.stoID)}
                  displayPropertyName={displayPropertyName}
                />
              ))}
            </tbody>
          </TABLE>
        ) : (
          <label>{t('DepositHistory-NoRecords')}</label>
        )}
      </CardBody>
    </Card>
  );
};

export default DepositHistory;
