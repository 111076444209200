import React from 'react';
import DP, { getDefaultLocale, ReactDatePickerProps } from 'react-datepicker';
import { createGlobalStyle } from 'styled-components';

import Input from './Input';

interface CustomInputProps {
  value?: string;
  onClick?: () => void;
  invalid?: boolean;
  disabled?: boolean;
}

const CustomInput = React.forwardRef<HTMLInputElement, CustomInputProps>(
  ({ value, onClick, invalid, disabled }, ref) => {
    return (
      <Input
        invalid={invalid}
        onClick={onClick}
        style={{ backgroundColor: '#f7f7f7' }}
        ref={ref}
        value={value}
        readOnly
        disabled={disabled}
      />
    );
  },
);

interface DatePickerProps extends ReactDatePickerProps {
  invalid?: boolean;
}

const DatePicker: React.FC<DatePickerProps> = ({ invalid, ...props }) => (
  <>
    <DP
      locale={getDefaultLocale()}
      {...props}
      showYearDropdown
      customInput={<CustomInput disabled={props.disabled} invalid={invalid} />}
    />
    <DPStyles />
  </>
);

export default DatePicker;

const DPStyles = createGlobalStyle`
  .react-datepicker-wrapper {
    width: 100%;
  }

  .input-group > .react-datepicker-wrapper {
    height: calc(1.5em + 0.5rem + 2px);
    flex: 1 1 auto;
    width: 1%;
  }

  .input-group-sm > .react-datepicker-wrapper input {
    padding: 0.25rem 0.5rem;
    font-size: 0.875rem;
    line-height: 1.5;
    border-radius: 0.2rem;
    height: auto;
  }
`;
