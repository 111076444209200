import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { toolbarPlugin, ToolbarSlot, TransformToolbarSlot } from '@react-pdf-viewer/toolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';

import { Label } from 'atoms';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

interface PDFViewerProps {
  fileUrl: string | undefined;
}

const PDFViewer: React.FC<PDFViewerProps> = ({ fileUrl }) => {
  const { t } = useTranslation();
  const toolbarPluginInstance = toolbarPlugin();
  const { renderDefaultToolbar, Toolbar } = toolbarPluginInstance;

  const transform: TransformToolbarSlot = (slot: ToolbarSlot) => ({
    ...slot,
    SwitchTheme: () => <></>,
    Download: () => <></>,
    Open: () => <></>,
  });
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
      {fileUrl ? (
        <CoreViewer className="rpv-core__viewer">
          <PDFButtons>
            <Toolbar>{renderDefaultToolbar(transform)}</Toolbar>
          </PDFButtons>
          <PDFContainer>{fileUrl && <Viewer fileUrl={fileUrl} plugins={[toolbarPluginInstance]} />}</PDFContainer>
        </CoreViewer>
      ) : (
        <div className="w-100 h-50 text-center">
          <Label>{t('PDFViewer-Content-Text-No-Presentation')}</Label>
        </div>
      )}
    </Worker>
  );
};

export default PDFViewer;

// This is necessary as Boostrap classes don't affect height for the PDF viewer
const PDFContainer = styled.div`
  width: 100%;
  height: 65vh;
`;

const PDFButtons = styled.div`
  align-items: center;
  background-color: #eeeeee;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  padding: 4px;
`;

const CoreViewer = styled.div`
  border: 1px solid rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
`;
