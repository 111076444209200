import WebFont from 'webfontloader';

const useCustomFont = (prevFont: string): string => {
  if (prevFont && prevFont !== '') {
    const font = prevFont[prevFont.length - 1] === ';' ? prevFont.slice(0, -1) : prevFont;
    WebFont.load({
      google: {
        families: [font],
      },
    });

    return font;
  }
  return 'Montserrat, sans-serif';
};

export default useCustomFont;
