import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import { AppData } from 'services/apollo/core';
import styled from 'styled-components';

export interface SellEntitySubTitleLinkProps {
  appData: AppData;
  displayRouteLinkOnly?: boolean;
  ID?: number;
  title?: string;
}

const SellEntitySubTitleLink: React.FC<SellEntitySubTitleLinkProps> = (props) => {
  const { t } = useTranslation();
  const { appData, displayRouteLinkOnly, ID, title } = props;
  const { isSellSideEntityEnabled } = appData;

  if (!isSellSideEntityEnabled || !ID || !title) {
    return null;
  }
  return displayRouteLinkOnly ? (
    <RouterLink to={`/investor/sell-side-entity/${ID}`}>{title}</RouterLink>
  ) : (
    <CardSubHeading className="card-subheading header-small">
      {t('SellEntitySubTitleLink-Seller')}:{' '}
      <span>
        <RouterLink to={`/investor/sell-side-entity/${ID}`}>{title}</RouterLink>
      </span>
    </CardSubHeading>
  );
};

export default SellEntitySubTitleLink;

export const CardSubHeading = styled.h5`
  color: rgba(102, 97, 91, 50%);
  display: flex;
  gap: 0.5rem;
`;
