import React from 'react';
import { Cell } from 'react-table';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Badge } from 'reactstrap';
import styled, { css } from 'styled-components';

import { AtomicSwapStatus, useDeleteOrderMutation, useGetExchangeOrderOffersCountQuery } from 'services/apollo';
import { InvestorExchangeOrder } from 'services/apollo/exchange';

import { BsSwal, Button } from 'atoms';
import StatusBadge from '../StatusBadge';

const MyOrderActionCell: React.FC<Cell<InvestorExchangeOrder>> = ({ row: { original } }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [deleteOrder] = useDeleteOrderMutation();

  const { ID, atomicSwapAcceptable: swap, atomicSwapCurrentStatus: status } = original;

  const { data } = useGetExchangeOrderOffersCountQuery({
    variables: {
      orderID: ID,
    },
    fetchPolicy: 'network-only',
  });
  const { getExchangeOrderOffersCount = 0 } = data ?? {};

  const handleDetails = (): void => {
    history.push(`/investor/order-detail/${ID}`);
  };

  const handleInProgressSwap = (): void => {
    history.push(`/investor/atomic-swap/${ID}`);
  };

  const handleDelete = (): void => {
    BsSwal.fire({
      title: t('MyOrderActionCell-BsSwal-DeleteConfirmation-Title'),
      text: t('MyOrderActionCell-BsSwal-DeleteConfirmation-Text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('MyOrderActionCell-BsSwal-DeleteConfirmation-ConfirmButton'),
      cancelButtonText: t('MyOrderActionCell-BsSwal-DeleteConfirmation-Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        return deleteOrder({ variables: { orderID: ID } })
          .then(() => {
            history.go(0);
          })
          .catch((err) => {
            BsSwal.fire({
              icon: 'error',
              title: err.message,
            });
          });
      }
    });
  };

  const renderSwap = (): React.ReactElement => {
    if (!swap) {
      return <></>;
    }
    switch (status) {
      case AtomicSwapStatus.NotInitialized: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="pending">{t('MySellOrderActionCell-StatusLabel-NewlyInitialized')}</StatusBadge>
            {getExchangeOrderOffersCount ? (
              <OffersCountBadge pill>
                {getExchangeOrderOffersCount} {t('MySellOrderActionCell-StatusLabel-OfferCount')}
              </OffersCountBadge>
            ) : null}
            <ActionButton delete size="sm" onClick={handleDelete}>
              <i className="ti-trash" />
            </ActionButton>
            <ActionButton size="sm" onClick={handleDetails}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
      case AtomicSwapStatus.Processed: {
        return <StatusBadge color="succeeded">{t('MySellOrderActionCell-StatusLabel-Successful')}</StatusBadge>;
      }
      case AtomicSwapStatus.Unsuccessful: {
        return <StatusBadge color="rejected">{t('MySellOrderActionCell-StatusLabel-Unsuccessful')}</StatusBadge>;
      }
      default: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="inProgress">{t('MySellOrderActionCell-StatusLabel-InProgress')}</StatusBadge>
            {getExchangeOrderOffersCount ? (
              <OffersCountBadge pill>
                {getExchangeOrderOffersCount} {t('MySellOrderActionCell-StatusLabel-OfferCount')}
              </OffersCountBadge>
            ) : null}
            <ActionButton size="sm" onClick={handleInProgressSwap}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
    }
  };

  const renderNotSwap = (): React.ReactElement => {
    if (swap) {
      return <></>;
    }
    switch (status) {
      case AtomicSwapStatus.NotInitialized: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="pending">{t('MySellOrderActionCell-StatusLabel-NewlyInitialized')}</StatusBadge>
            {getExchangeOrderOffersCount ? (
              <OffersCountBadge pill>
                {getExchangeOrderOffersCount} {t('MySellOrderActionCell-StatusLabel-OfferCount')}
              </OffersCountBadge>
            ) : null}
            <ActionButton delete size="sm" onClick={handleDelete}>
              <i className="ti-trash" />
            </ActionButton>
            <ActionButton size="sm" onClick={handleDetails}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
      case AtomicSwapStatus.Processed: {
        return <StatusBadge color="succeeded">{t('MySellOrderActionCell-StatusLabel-Successful')}</StatusBadge>;
      }
      case AtomicSwapStatus.Unsuccessful: {
        return <StatusBadge color="rejected">{t('MySellOrderActionCell-StatusLabel-Unsuccessful')}</StatusBadge>;
      }
      case AtomicSwapStatus.AwaitingBuyerPayment: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="inProgress">{t('MySellOrderActionCell-StatusLabel-AwaitingBuyerPayment')}</StatusBadge>
            {getExchangeOrderOffersCount ? (
              <OffersCountBadge pill>
                {getExchangeOrderOffersCount} {t('MySellOrderActionCell-StatusLabel-OfferCount')}
              </OffersCountBadge>
            ) : null}
            <ActionButton size="sm" onClick={handleDetails}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
      default: {
        return (
          <div className="d-flex align-items-center justify-content-between">
            <StatusBadge color="inProgress">{t('MySellOrderActionCell-StatusLabel-InProgress')}</StatusBadge>
            {getExchangeOrderOffersCount ? (
              <OffersCountBadge pill>
                {getExchangeOrderOffersCount} {t('MySellOrderActionCell-StatusLabel-OfferCount')}
              </OffersCountBadge>
            ) : null}
            <ActionButton size="sm" onClick={handleDetails}>
              <i className="ti-eye" />
            </ActionButton>
          </div>
        );
      }
    }
  };

  return (
    <>
      {renderSwap()}
      {renderNotSwap()}
    </>
  );
};

const ActionButton = styled(Button)<{ color?: string; delete?: boolean }>`
  color: ${(p) => p.theme.marketPlaceIconFontColor};
  font-size: 18px;
  background-color: ${(p) => p.theme.marketPlaceIconBGColor};

  ${(p) => {
    if (p.delete) {
      return css`
        background-color: ${p.theme.marketPlaceDeleteIconBGColor};
        color: ${p.theme.marketPlaceDeleteIconFontColor};
      `;
    }
  }}
`;

const OffersCountBadge = styled(Badge)`
  font-size: 11px;
  font-weight: 500;
  background: #0b6aa2;
  border: none;
  padding: 8px;
  margin-right: 5px;
`;

export default MyOrderActionCell;
