import React, { useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import { useGetThreadContentQuery, useGetThreadByIdQuery } from 'services/apollo';
import Auth from 'services/core/auth';

import { CardTitle, CardSubtitle, FontAweIcon, Loading } from 'atoms';

import { Wrapper, SectionWrapper, BackButton } from './components/forum-styles';
import SearchBox from './components/SearchBox';
import PostCreate from './PostCreate';

const ThreadContent: React.FC = () => {
  const history = useHistory();
  const { i18n, t } = useTranslation();

  const [searchText, setSearchText] = useState('');

  const params = useParams<{ threadID: string }>();
  const threadID = parseInt(params.threadID, 10);

  const applySearch = (searchKey: string): void => {
    setSearchText(searchKey);
  };

  const goBack = (): void => history.goBack();

  const { data: threadHeaderData, loading: threadHeaderLoading } = useGetThreadByIdQuery({ variables: { threadID } });
  const { data: threadData, loading: threadLoading } = useGetThreadContentQuery({ variables: { threadID } });

  if (threadLoading || !threadData || threadHeaderLoading || !threadHeaderData) {
    return <Loading />;
  }

  const { title: threadTitle, body: threadBody, createdAt: threadCreatedAt } = threadHeaderData.getThreadByID;

  const posts =
    searchText.length >= 3
      ? threadData.getThreadContent.filter((post) => post.body.toLowerCase().includes(searchText.toLowerCase()))
      : threadData.getThreadContent;

  return (
    <Wrapper className="content">
      <SectionWrapper>
        <CardTitle tag="h5">{threadTitle}</CardTitle>
        <CardSubtitle className="text-muted">
          <p>
            <small>
              <FontAweIcon icon="calendar-day" /> {t('Forum-CardSubtitle-CreatedAt')}{' '}
              <strong>{new Date(threadCreatedAt).toLocaleString(i18n.language)}</strong>
            </small>
          </p>
          <p>{threadBody}</p>
        </CardSubtitle>
      </SectionWrapper>
      {Auth.isLoggedIn ? <PostCreate threadID={threadID} /> : null}
      <SearchBox value={searchText} applySearch={applySearch} />
      {posts.map((post) => (
        <PostWrapper key={post.ID}>
          <CardSubtitle>
            <p>
              <small>
                <FontAweIcon icon="calendar-day" /> {t('Forum-CardSubtitle-CreatedAt')}{' '}
                <strong>{new Date(post.createdAt).toLocaleString(i18n.language)}</strong>{' '}
                {t('Forum-CardSubtitle-CreatedBy')} <strong>{post.investor.firstName}</strong>
              </small>
            </p>
            <p>{post.body}</p>
          </CardSubtitle>
        </PostWrapper>
      ))}
      <BackButton onClick={goBack}>{t('ThreadContent-Button-Back')}</BackButton>
    </Wrapper>
  );
};

const PostWrapper = styled(SectionWrapper)`
  background: #f5faf7;
`;

export default ThreadContent;
