import React from 'react';
import { useGetGCashLoginUrlQuery, useMeQuery } from 'services/apollo';

import { Button, Card, CardBody, CardHeader, Col, GrayDot, Loading, Row } from 'atoms';
import { useTranslation } from 'react-i18next';
import GCashError from './GCashError';

const GCashKyc: React.FC = () => {
  const { t } = useTranslation();
  const { data: dataMe, loading: loadingMe } = useMeQuery({ fetchPolicy: 'network-only' });
  const { investor, sto } = dataMe?.investorUser || {};
  const { data: gCashData, loading: gCashLoading, error: gCashError } = useGetGCashLoginUrlQuery();

  if (gCashError) {
    return <GCashError error={gCashError} />;
  }

  if (gCashLoading || loadingMe || !investor || !sto || !gCashData) {
    return <Loading />;
  }
  const url = gCashData.getGCashLoginUrl;

  const navigateToOutsideLink = () => {
    window.location.href = url;
  };

  return (
    <Card>
      <CardHeader className="mt-3">
        <b>{t('Welcome to your Verification Process')}</b>
      </CardHeader>
      <CardBody className="mb-3">
        <Row>
          <Col xs="auto">
            <GrayDot />
          </Col>
          <Col tag="p">{t('You can logout anytime and re-login with your email and password to continue')}</Col>
        </Row>
        <Button onClick={navigateToOutsideLink}>{t('GCashKyc-button-verify')}</Button>
      </CardBody>
    </Card>
  );
};

export default GCashKyc;
