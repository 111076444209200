import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';

import { StoDetailMeta } from 'services/apollo/multisto';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

interface MetaChartProps {
  meta: StoDetailMeta[];
  name: string;
}

interface ChartDataItem {
  title: string;
  value: number;
  color: string;
}

const MetaChart: React.FC<MetaChartProps> = ({ meta, name }) => {
  const content = meta.filter((x) => x.key === name);

  if (!content[0]?.value) {
    return <></>;
  }

  const chartData = JSON.parse(content[0].value);
  const data = {
    labels: [''],
    datasets: chartData.data.map((d: ChartDataItem) => ({
      label: d.title,
      data: [d.value],
      backgroundColor: d.color,
      barPercentage: 0.2,
      stack: 'a',
    })),
  };
  const values = chartData.data.map((v: { value: number }) => v.value);
  const sumValue = values.reduce((sum: number, currentValue: number) => sum + currentValue, 0);

  return (
    <div className="mt-md-2">
      <div className="mt-md-2">
        <b>{chartData.title}</b>
      </div>
      <Bar
        height={chartData.height}
        data={data}
        options={{
          maintainAspectRatio: true,
          scales: {
            y: {
              ticks: {
                stepSize: sumValue / 5,
                callback: (value) => {
                  value = value.toString();
                  if (parseInt(value, 10) >= 1000) {
                    // TODO: use locale number formatting
                    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                  }
                  return value;
                },
              },
            },
          },
        }}
      />
    </div>
  );
};

export default MetaChart;
