import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Alert, Badge } from 'reactstrap';
import styled from 'styled-components';

import {
  DpayResolver,
  ExchangeType,
  GetExchangeOrderDetailDocument,
  OfferStatus,
  useAcceptInternalSwapMutation,
  useCreateInvoiceInvestorMutation,
  useGetExchangeOrderDetailQuery,
  useInitiatePolymeshExchangeSwapMutation,
  useStartSwapMutation,
} from 'services/apollo';
import { ExchangeOrderDetailOffer } from 'services/apollo/exchange';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

import { CardHeader } from 'components/card-header/CardHeader';
import { BrandIcon, BsSwal, Button, Card, CardBody, Col, FontAweIcon, Label, Loading, Row } from 'atoms';
import NotPendingOffer from 'pages/exchange/components/offer-details/NotPendingOffer';
import { BLOCKCHAIN_PROTOCOL, isProtocolERC1404 } from 'pages/exchange/constants';
import { getTranslationKeyOfApiError, GqlError } from 'services/core/helpers';

const OrderDetailPage: React.FC = () => {
  const params = useParams<{ orderID: string }>();
  const orderID = parseInt(params.orderID, 10);
  const history = useHistory();
  const { t } = useTranslation();
  const [isSwapLoading, setIsSwapLoading] = useState(false);

  const refetchQueries = [{ query: GetExchangeOrderDetailDocument, variables: { orderID } }];

  const [startSwap] = useStartSwapMutation({ refetchQueries });
  const [acceptSwap] = useAcceptInternalSwapMutation({ refetchQueries });
  const [initiatePolymeshSwap] = useInitiatePolymeshExchangeSwapMutation({ refetchQueries });
  const [createInvoice] = useCreateInvoiceInvestorMutation();

  const { data, loading } = useGetExchangeOrderDetailQuery({ variables: { orderID } });

  if (loading || !data) {
    return <Loading />;
  }
  const { getExchangeOrder: order, getExchangeOrderOffers: offers } = data;

  const pendingOffers = offers.filter((offer) => offer.status === OfferStatus.Pending);
  const notPendingOffers = offers.filter((offer) => offer.status !== OfferStatus.Pending);

  const onStartSwap = (offerID: number): Promise<void> => {
    return startSwap({ variables: { offerID } })
      .then(() => {
        if (isProtocolERC1404(order.shareType.blockchainProtocolID || 0)) {
          history.push(`/investor/atomic-swap/${order.ID}`);
        }
        if (order.shareType.blockchainProtocolID === BLOCKCHAIN_PROTOCOL.POLYMESH_TOKEN) {
          setIsSwapLoading(true);
          return initiatePolymeshSwap({ variables: { orderID: order.ID } })
            .then(() => {
              setIsSwapLoading(false);
              BsSwal.fire({
                title: t('OrderDetailPage-popUp-startSwap-success'),
                icon: 'success',
              });
              history.go(-1);
            })
            .catch((err) => {
              setIsSwapLoading(false);
              BsSwal.fire({
                icon: 'error',
                title: err.message,
              });
            });
        }
      })
      .catch((err) => {
        BsSwal.fire({
          icon: 'error',
          text: err.message,
        });
      });
  };

  const onAcceptSwap = async (offer: ExchangeOrderDetailOffer): Promise<void> => {
    const { isConfirmed: acceptSwapPopUpConfirmed } = await BsSwal.fire({
      title: t('OrderDetailPage-BsSwal-AcceptSwap'),
      icon: 'question',
      showCancelButton: true,
      confirmButtonText: t('OrderDetailPage-BsSwal-Confirm'),
      cancelButtonText: t('OrderDetailPage-BsSwal-Cancel'),
    });
    if (!acceptSwapPopUpConfirmed) {
      return;
    }
    await createInvoice({
      variables: {
        data: {
          stoID: order.stoID,
          resolver: DpayResolver.ExchangeOffer,
          resolveID: offer.ID,
          isBlockchain: false,
          shareTypeID: order.shareType.ID,
          investorWallet: '',
          invoiceDescription: '',
        },
      },
    });

    try {
      await acceptSwap({ variables: { offerID: offer.ID } });

      await BsSwal.fire({
        title: t('OrderDetailPage-BsSwal-InvoiceCreated-title'),
        text: t('OrderDetailPage-BsSwal-InvoiceCreated-text'),
        icon: 'success',
        confirmButtonText: t('OrderDetailPage-BsSwal-InvoiceCreated-Confirm'),
      });
    } catch (err) {
      const gqlError = getTranslationKeyOfApiError(err as GqlError);
      await BsSwal.fire({
        icon: 'error',
        text: t(gqlError.message),
      });
    }
  };

  return (
    <div className="content">
      <Card>
        <CardHeader text={t('OrderDetailPage-card-header')} icon={<BrandIcon icon="info" color="cyan" pill />} />
        <CardBody>
          <Row>
            <Col>
              <Label>{t('OrderDetailPage-label-orderType')}</Label>
              <OrderTypeBadge>
                {order.type === ExchangeType.Sell
                  ? t('OrderDetailPage-OrderType-SELL')
                  : t('OrderDetailPage-OrderType-BUY')}
              </OrderTypeBadge>
            </Col>
            <Col>
              <Label>{t('OrderDetailPage-label-shareType')}</Label>
              {order.shareType.title}
            </Col>
            <Col md={4}>
              <Label className="mr-1">{t('OrderDetailPage-label-date')}</Label>
              {order.dateFrom} - {order.dateTo}
            </Col>
            {order.atomicSwapAcceptable ? (
              <>
                <Col>
                  <Label>{t('OrderDetailPage-label-acceptableOffer-shareOffered')}</Label>
                  {order.shares}
                </Col>
                <Col>
                  <Label>{t('OrderDetailPage-label-price')}</Label>
                  <CurrencySymbolDisplay value={order.rateFrom} currency={order.shareType.currency} />
                </Col>
              </>
            ) : (
              <>
                <Col>
                  <Label>{t('OrderDetailPage-label-nonAcceptableOffer-shareOffered')}</Label>
                  {order.shares}
                </Col>
                <Col>
                  <Label>{t('OrderDetailPage-label-nonAcceptableOffer-askingAmount')}</Label>
                  <CurrencySymbolDisplay value={order.rateFrom} currency={order.shareType.currency} />
                </Col>
              </>
            )}
          </Row>
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          text={t('OrderDetailPage-PendingOffers-CardHeader-Text')}
          caption={t('OrderDetailPage-PendingOffers-CardHeader-Caption', { offersCount: pendingOffers.length })}
          icon={<BrandIcon icon="info" color="cyan" pill />}
        />
        <CardBody>
          {pendingOffers.map((offer) => (
            <Wrap key={offer.ID}>
              <Row>
                <Col md={2}>
                  <small>{t('OrderDetailPage-PendingOffers-Label-SharesAmount')}</small>
                </Col>
                <Col md={2}>
                  <mark>
                    <b>{offer.sharesPartial}</b>
                  </mark>
                </Col>
                <Col md={2}>
                  <small>{t('OrderDetailPage-PendingOffers-Label-PricePerShare')}</small>
                </Col>
                <Col md={2}>
                  <mark>
                    <b>
                      <CurrencySymbolDisplay value={offer.pricePerShare} currency={order.shareType.currency} />
                    </b>
                  </mark>
                </Col>
                <Col md={2}>
                  <small>{t('OrderDetailPage-PendingOffers-Label-TotalPriceOffered')}</small>
                </Col>
                <Col md={2}>
                  <mark>
                    <b>
                      <CurrencySymbolDisplay value={offer.rateFrom} currency={order.shareType.currency} />
                    </b>
                  </mark>
                </Col>
              </Row>
              <Row>
                <Col>
                  {order.atomicSwapAcceptable ? (
                    <>
                      <Button disabled={isSwapLoading} onClick={() => onStartSwap(offer.ID)}>
                        {t('OrderDetailPage-offer-button-acceptOffer')}
                      </Button>
                      {isSwapLoading ? (
                        <p className="text-warning">
                          <FontAweIcon icon="circle-notch" spin className="text-primary mr-2" />
                          {t('OrderDetailPage-label-atomicSwapLoading')}
                        </p>
                      ) : null}
                    </>
                  ) : (
                    <Button onClick={() => onAcceptSwap(offer)}>{t('OrderDetailPage-offer-button-acceptSwap')}</Button>
                  )}
                </Col>
              </Row>
            </Wrap>
          ))}
        </CardBody>
      </Card>
      <Card>
        <CardHeader
          text={t('OrderDetailPage-NotPendingOffers-CardHeader-Text')}
          caption={t('OrderDetailPage-NotPendingOffers-CardHeader-Caption')}
          icon={<BrandIcon icon="info" color="cyan" pill />}
        />
        <CardBody>
          {notPendingOffers.length ? (
            <div className="my-2">
              {notPendingOffers.map((offer) => (
                <NotPendingOffer key={offer.ID} offer={offer} currency={order.shareType.currency} />
              ))}
            </div>
          ) : (
            <Alert color="secondary">{t('OrderDetailPage-NotPendingOffers-Alert-NoDataFound')}</Alert>
          )}
        </CardBody>
      </Card>
    </div>
  );
};

const OrderTypeBadge = styled(Badge)`
  font-size: 12px;
  background: #454545;
  border: none;
`;

const Wrap = styled.div`
  border: 1px solid #b8bebf;
  background: #faf8f2;
  padding: 8px;
  border-radius: 10px;
  margin-bottom: 2px;
`;

export default OrderDetailPage;
