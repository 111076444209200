import React from 'react';
import { useOnboardDpayAccountQuery } from 'services/apollo';
import { Loading } from 'atoms';

const BeginDpayOnboarding: React.FC = () => {
  const { data, loading } = useOnboardDpayAccountQuery();
  if (!data || loading) {
    return <Loading />;
  }
  const { onboardAccount: onboardUrl } = data;
  window.location.replace(onboardUrl);

  return <Loading />;
};

export default BeginDpayOnboarding;
