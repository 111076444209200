import React from 'react';
import { Route as RouterRoute } from 'react-router-dom';

import VerifyLayout from 'layouts/VerifyLayout';
import Route, { IRoute } from './Route';

interface IVerifyRoute extends IRoute {
  name: string;
  icon: string;
  Component: React.ComponentType<{ nextStep?(): void }>;
  singleSidebarRoute: boolean;
}

class PublicRoute extends Route {
  name: string;

  icon: string;

  singleSidebarRoute: boolean;

  constructor({ name, icon, path, Component, singleSidebarRoute }: IVerifyRoute) {
    super({ path, Component });
    this.name = name;
    this.icon = icon;
    this.singleSidebarRoute = singleSidebarRoute;
  }

  getRoute(): React.ReactElement {
    const { path, Component } = this;
    return (
      <RouterRoute
        key={path}
        path={path}
        render={() => (
          <VerifyLayout singleSidebarRoute={this.singleSidebarRoute} name={this.name}>
            <Component />
          </VerifyLayout>
        )}
      />
    );
  }
}

export default PublicRoute;
