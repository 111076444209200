export enum BLOCKCHAIN_PROTOCOL {
  R_TOKEN = 1,
  POLYMATH = 2,
  RAVENCOIN = 3,
  ERC1404_ETHEREUM = 4,
  ERC1404_POLYGON = 5,
  ERC1404_BINANCE = 6,
  POLYMESH_TOKEN = 7,
  ERC1404_LIBEX = 8,
  ERC1404_FANTOM = 9,
}

export const isProtocolERC1404 = (blockchainProtocol: number): boolean => {
  switch (blockchainProtocol) {
    case BLOCKCHAIN_PROTOCOL.POLYMATH:
    case BLOCKCHAIN_PROTOCOL.ERC1404_ETHEREUM:
    case BLOCKCHAIN_PROTOCOL.ERC1404_POLYGON:
    case BLOCKCHAIN_PROTOCOL.ERC1404_BINANCE:
    case BLOCKCHAIN_PROTOCOL.ERC1404_LIBEX:
    case BLOCKCHAIN_PROTOCOL.ERC1404_FANTOM:
      return true;
    default:
      return false;
  }
};
