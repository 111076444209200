import React from 'react';
import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { SenderType, MessageType } from 'services/apollo';
import { ChatItem } from 'services/apollo/chat';
import { AppData } from 'services/apollo/core';
import { Chip } from 'atoms';
import SellEntitySubTitleLink from 'pages/active-properties/components/SellEntitySubTitleLink';

interface MessagesProps {
  data: ChatItem[];
  investorName: string;
  appData: AppData;
}

const Messages: React.FC<MessagesProps> = ({ data, investorName, appData }) => {
  const { t } = useTranslation();

  if (!data) {
    return null;
  }

  const dateTimeFormat = 'yyyy-MM-dd HH:mm:ss';
  const { areSellSideEntityChatLinksEnabled } = appData;

  return (
    <ChatPanel className="mb-3 overflow-auto d-flex flex-column-reverse">
      <div>
        {data.map((d) => {
          const { ID, dateSent, message, sender, type, admin } = d;
          const adminName = `${admin?.firstName} ${admin?.lastName}`;
          return (
            <div key={ID} className={sender === SenderType.Investor ? 'text-right m-3' : 'text-left m-3'}>
              <p className="mb-2">
                <strong>{sender === SenderType.Investor ? investorName : adminName}</strong>
                {areSellSideEntityChatLinksEnabled ? (
                  <SellEntityUrl>
                    <SellEntitySubTitleLink
                      appData={appData}
                      title={admin?.sellSideEntity?.title}
                      ID={admin?.sellSideEntity?.ID}
                      displayRouteLinkOnly
                    />
                  </SellEntityUrl>
                ) : null}
                <DateTime>
                  {t('At')} {format(dateSent, dateTimeFormat)}
                </DateTime>
              </p>
              {type === MessageType.Message ? (
                <Chip color={sender === SenderType.Investor ? '#e4f5ef' : '#dedbb4'}>{message}</Chip>
              ) : (
                <Chip color={sender === SenderType.Investor ? '#e4f5ef' : '#dedbb4'}>
                  <i className="ti-import" />
                  <FileLink target="_blank" href={JSON.parse(message).link} download={JSON.parse(message).originalName}>
                    {JSON.parse(message).originalName}
                  </FileLink>
                </Chip>
              )}
            </div>
          );
        })}
      </div>
    </ChatPanel>
  );
};

const SellEntityUrl = styled.small`
  padding-left: 5px;
`;

const DateTime = styled.small`
  color: ${(p) => p.theme.messagesDateTimeColor};
  padding-left: 5px;
`;

const ChatPanel = styled.div`
  height: 380px;
  max-height: 380px;
`;

const FileLink = styled.a`
  text-decoration: none;
  color: #017698;
  margin-left: 15px;
`;

export default Messages;
