import React from 'react';
import styled from 'styled-components';

const Divider: React.FC = () => <StyledDivider />;

export default Divider;

const StyledDivider = styled.div`
  width: 100%;
  height: 2px;
  background-color: #f9f9f9;
  margin-bottom: 35px;
`;
