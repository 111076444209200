import React from 'react';
import styled from 'styled-components';

import { Button, Card, CardBody, CenteredCol, Row } from 'atoms';
import NdaModal from 'pages/nda/NdaModal';
import { useTranslation } from 'react-i18next';
import { AppData } from 'services/apollo/core';
import { CardH4 } from 'components/card-header/CardHeader';
import { ActivePropertyItem } from 'services/apollo/multisto';
import { canSeeNDADataRequired } from 'services/core/helpers';
import SellEntitySubTitleLink from '../SellEntitySubTitleLink';

interface MarketSpaceCardProps {
  props: ActivePropertyItem;
  investorAppParameters: AppData;
  view: () => void;
  changeBuyButtonText: () => string;
  canSeeNDAData: (data: canSeeNDADataRequired) => boolean;
  buy: () => void;
  NDACheckData: canSeeNDADataRequired;
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
}

const MarketSpaceCard: React.FC<MarketSpaceCardProps> = ({
  props,
  investorAppParameters,
  view,
  changeBuyButtonText,
  canSeeNDAData,
  NDACheckData,
  buy,
  isOpen,
  setOpen,
}) => {
  const { t } = useTranslation();
  const { ID, title, details, picture, isBuyButtonEnabled, sellSideEntity } = props;

  const { isProjectCardImageDisplayEnabled } = investorAppParameters;

  return (
    <Card className="m-auto" width="100%" height="100%">
      {isProjectCardImageDisplayEnabled ? <ImgCardMs className="w-100 pb-0 mt-0" alt={title} src={picture} /> : null}
      <CardBody className="px-4 d-flex flex-column justify-content-between">
        <div>
          <CardH4 className="mt-0">{title}</CardH4>
          <SellEntitySubTitleLink
            appData={investorAppParameters}
            title={sellSideEntity?.title}
            ID={sellSideEntity?.ID}
          />
          <div
            className="overflow-auto mb-1 mt-1 pt-1"
            style={{
              minHeight: '30px',
              textOverflow: 'ellipsis',
            }}
          >
            {details}
          </div>
        </div>
        <Row className="justify-content-center">
          <CenteredCol md={6} className="justify-content-center">
            <Button outline size="sm" wmin="80px" style={{ width: '100%', wordWrap: 'normal' }} onClick={view}>
              {!canSeeNDAData(NDACheckData)
                ? t('ActiveProperty-ViewDetails-nda-not-signed')
                : t('ActiveProperty-ViewDetails')}
            </Button>
          </CenteredCol>
          {isBuyButtonEnabled ? (
            <CenteredCol md={6} className="justify-content-center">
              <Button size="sm" wmin="80px" style={{ width: '100%', wordWrap: 'normal' }} onClick={buy}>
                {changeBuyButtonText()}
              </Button>
            </CenteredCol>
          ) : null}
        </Row>
        <NdaModal stoID={ID} isOpen={isOpen} setOpen={setOpen} />
      </CardBody>
    </Card>
  );
};

export default MarketSpaceCard;

const ImgCardMs = styled.img`
  width: 480px;
  height: 350px !important;
  object-fit: cover;
  text-align: justify;
  margin: 0;
  border-radius: 0;
  border-radius: none;

  @media (max-width: 720px) {
    height: 300px !important;
  }
  @media (max-width: 530px) {
    height: 250px !important;
  }
  @media (max-width: 480px) {
    height: 220px !important;
  }
`;
