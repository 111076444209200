import React from 'react';
import { Loading, Select } from 'atoms';
import { useTranslation } from 'react-i18next';
import { SelectOption } from 'components/navbar/StoSwitchList';
import { useFindAllStoQuery, useInvestorAppDataQuery } from 'services/apollo';

interface CorporateStoProps {
  sto: number;
  setSto: ({ sto }: { sto: number }) => void;
}

const CorporateStoSelect: React.FC<CorporateStoProps> = ({ sto, setSto }) => {
  const { t } = useTranslation();
  const { data, loading } = useInvestorAppDataQuery();
  const { data: allStosData, loading: allStosLoading } = useFindAllStoQuery();

  if (loading || !data || !allStosData || allStosLoading) return <Loading />;
  const { investorAppParameters } = data;
  const { findAllSto } = allStosData;

  const hideSto0 = investorAppParameters.isPlatformOperatorHidden;

  const stos: SelectOption[] = [
    { value: String(-1), label: t('CompanyUpdate-select-showAll') },
    ...(findAllSto || [])
      .filter((_sto) => !(_sto.ID === 0 && hideSto0) && _sto.isActive)
      .map((_sto) => ({
        value: String(_sto.ID),
        label: _sto.title,
      })),
  ];

  const activeSto = stos.find((s) => s.value === sto.toString()) || stos[0];

  const onChangeSto = (ent: { value: string; label: string }) => {
    setSto({ sto: parseInt(ent.value, 10) });
  };

  return (
    <div className="d-flex align-items-baseline py-4">
      <h6>{t('Voting-projectDropdown-caption')}:</h6>
      <Select
        name="DocumentTitle"
        style={{ zIndex: '100' }}
        options={stos}
        value={{
          value: activeSto.value,
          label: activeSto.label,
        }}
        onChange={onChangeSto}
        className="w-25 ml-2"
      />
    </div>
  );
};

export default CorporateStoSelect;
