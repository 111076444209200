import React from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { getRandomColor } from 'lib/utils';
import { PollStatistics } from 'services/apollo/meeting';

export interface VoteStatisticProps {
  statistics?: PollStatistics;
}

export const VoteStatistic: React.FC<VoteStatisticProps> = (props) => {
  const { statistics } = props;
  const { t } = useTranslation();

  if (!statistics?.length) {
    return null;
  }

  const labels: string[] = [];
  const percentages: number[] = [];
  const colors: string[] = [];
  statistics.forEach((s) => {
    labels.push(s.optionTxt);
    percentages.push(s.votingUserStatistic.thisPercent);
    colors.push(getRandomColor());
  });

  const data = {
    labels,
    datasets: [
      {
        data: percentages,
        barPercentage: 0.5,
        backgroundColor: colors,
      },
    ],
  };

  return (
    <Bar
      height="100px"
      width="300px"
      data={data}
      options={{
        scales: {
          y: {
            max: 100,
          },
          x: {
            labels,
          },
        },
        elements: {
          bar: {
            borderWidth: 2,
          },
        },
        responsive: true,
        plugins: {
          legend: {
            display: false,
          },
          title: {
            display: true,
            text: t('VoteStatistic-Graph-Title'),
          },
        },
      }}
    />
  );
};
