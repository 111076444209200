export const colors = {
  gray: '#F9F9F9',
  red: '#DC3545',
  paleRed: '#dc3545',
  cyan: '#51bcda',
  blue: '#007bff',
  bootStrapBlue: '#2196F3',
  indigo: '#6610f2',
  purple: '#6f42c1',
  pink: '#e83e8c',
  orange: '#fd7e14',
  yellow: '#ffc107',
  green: '#28a745',
  paleGreen: '#f5faf7',
  teal: '#20c997',
  grayDark: '#343a40',
  white: '#fff',
  black: '#1E1E1E',
  silver: '#d9d9d9',
  marketSpaceBlue: '#002d56',
  textGrey: '#66615B',
  backgroundGrey100: '#F9F9F9',
  systemBlue: '#3F92D4',
};
