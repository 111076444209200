import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  StyledDiv,
  StyledIcon,
  StyledIconContainer,
} from 'pages/active-properties/components/styled/styledInterestedIcon';

interface InterestedStarIconProps {
  switchState: boolean;
  isDetail?: boolean;
}
const InterestedStarIcon: React.FC<InterestedStarIconProps> = ({ switchState, isDetail = false }) => {
  const { t } = useTranslation();

  return (
    <StyledDiv>
      <StyledIconContainer isDetail={isDetail}>
        <StyledIcon switchState={switchState} icon={switchState ? 'star' : ['far', 'star']} />
        <p className="star-popup">{t('InterestedSwitch-popover-text')}</p>
      </StyledIconContainer>
    </StyledDiv>
  );
};

export default InterestedStarIcon;
