import React from 'react';
import { parseISO, isAfter, isBefore } from 'date-fns';

import { AppData } from 'services/apollo/core';
import { ActivePropertyItem } from 'services/apollo/multisto';
import { Loading } from 'atoms';
import useFilterPreference from 'pages/investor-ownership/components/useFilterPreference';
import ActivePropertiesLayout from './components/ActivePropertiesLayout';

interface FilterActivePropertiesProps {
  properties: ActivePropertyItem[];
  parameters: AppData;
}

const FilterActiveProperties: React.FC<FilterActivePropertiesProps> = ({ properties, parameters }) => {
  const { preference, loading } = useFilterPreference();

  if (loading) {
    return <Loading />;
  }

  const filteredKey = preference.filter((x) => x.key.slice(-5) !== '_sign');

  const filteredProperties: ActivePropertyItem[] = properties.filter((property) =>
    filteredKey.some(({ key, value, type }) => {
      const meta = property.meta.find((m) => m.key === key);
      if (!meta || !meta.value) {
        return false;
      }

      // select
      if (type === 'filter_select') {
        const options = value.split(',');
        return options.includes(meta.value);
      }

      // checkbox
      if (value === 'true' && meta.value === 'true') {
        return true;
      }

      // sign is require
      const sign = preference.find((x) => x.key === `${key}_sign`);
      if (!sign) {
        return false;
      }

      // date
      const investorDate = parseISO(value);
      const propertyDate = parseISO(meta.value);
      // check that dates are not invalid
      if (!Number.isNaN(investorDate.getDate()) && !Number.isNaN(propertyDate.getDate())) {
        if (sign.value === 'gt') {
          return isAfter(propertyDate, investorDate);
        }
        if (sign.value === 'lt') {
          return isBefore(propertyDate, investorDate);
        }
        return false;
      }

      // number
      const investorValue = parseInt(value, 10);
      const propertyValue = parseInt(meta.value, 10);
      if (!Number.isNaN(propertyValue) && !Number.isNaN(investorValue)) {
        if (sign.value === 'gt') {
          return propertyValue >= investorValue;
        }
        if (sign.value === 'lt') {
          return propertyValue <= investorValue;
        }
        return false;
      }

      return false;
    }),
  );

  return (
    <ActivePropertiesLayout
      propertiesArray={properties}
      filteredPropertiesArray={filteredProperties}
      parameters={parameters}
      isProfileMatching
    />
  );
};

export default FilterActiveProperties;
