import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AppData, MeData } from 'services/apollo/core';
import { InvestorInvestingEntity } from 'services/apollo/investor-entity';
import { Row, Label, CenteredCol, Col } from 'atoms';
import { InvestingEntityMemberRoles } from 'services/apollo';
import { useTheme } from 'styled-components';
import InvestingEntityForm from './InvestingEntityForm';
import { ThemeType } from '../../../lib/themes/themeTypes';

interface InvestingEntityProps {
  entity: InvestorInvestingEntity;
  investor: MeData['investor'] | undefined;
  appData: AppData;
  isOpenDefault?: boolean;
  isFilterPreferencesEnabled?: boolean;
}

const InvestingEntityItem: React.FC<InvestingEntityProps> = (props) => {
  const { entity, investor, appData, isOpenDefault = false, isFilterPreferencesEnabled = false } = props;
  const [isOpen, setIsOpen] = useState(isOpenDefault);
  const { t } = useTranslation();
  const theme: ThemeType = useTheme() as ThemeType;
  const investorRole = entity.members.find((m) => m.investorID === investor?.ID)?.role;

  const canModifyEntity = entity.investorID === investor?.ID || investorRole === InvestingEntityMemberRoles.Owner;

  const toggle = () => setIsOpen(!isOpen);
  const rowStyles = {
    background: theme.investingEntityRowBackground,
  };
  const style = {
    background: theme.investingEntityBackground,
  };
  return (
    <>
      <Row key={entity.ID} className="pointer" style={rowStyles} onClick={toggle}>
        <CenteredCol>{entity.nickname || entity.name}</CenteredCol>
        <CenteredCol>{entity.members.length}</CenteredCol>
        <CenteredCol>{entity.type.title}</CenteredCol>
        <CenteredCol> {t(`Investing-Entity-Status-${entity.isApprovedByAdmin}`)}</CenteredCol>
        <CenteredCol md={1}>
          <Label className="ti-angle-down" />
        </CenteredCol>
      </Row>
      <br />
      <Collapse className="justify-content-end" isOpen={isOpen}>
        <Row className="pointer" style={style}>
          <Col>
            <InvestingEntityForm
              entity={entity}
              canModifyEntity={canModifyEntity}
              appData={appData}
              isFilterPreferencesEnabled={isFilterPreferencesEnabled}
            />
          </Col>
        </Row>
      </Collapse>
    </>
  );
};

export default InvestingEntityItem;
