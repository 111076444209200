import { PaymentProcessor } from '../apollo';

export enum HTMLElementTypes {
  SCRIPT = 'script',
  LINK = 'link',
}

const createExternalSDKElementActions: { [key: string]: (url: string) => HTMLElement } = {
  [HTMLElementTypes.LINK]: (url: string) => {
    const element = document.createElement(HTMLElementTypes.LINK);
    element.href = url;
    element.rel = 'stylesheet';
    return element;
  },
  [HTMLElementTypes.SCRIPT]: (url: string) => {
    const element = document.createElement(HTMLElementTypes.SCRIPT);
    element.defer = true;
    element.src = url;
    return element;
  },
};
export const createExternalSDKElement = (elementType: HTMLElementTypes, url: string): HTMLElement => {
  // create element with specific properties
  const element = createExternalSDKElementActions[elementType](url);
  element.id = url;
  return element;
};

export const loadExternalSDK = (
  url: string,
  elementType: HTMLElementTypes,
  callback: () => unknown = () => undefined,
): void => {
  const existingElement = document.getElementById(url);
  if (existingElement) {
    callback();
    return;
  }
  const element = createExternalSDKElement(elementType, url);
  document.body.appendChild(element);
  element.onload = () => {
    callback();
  };
};

export const topUpSDKUrlScripts: {
  [key: string]: (sandboxMode?: boolean) => string;
} = {
  [PaymentProcessor.Kladot]: () => `https://www.kladot.com/api/static/js/main.e868bb08.js`,
  [PaymentProcessor.Fortress]: (sandboxMode?: boolean) =>
    sandboxMode
      ? `https://elements.sandbox.fortressapi.com/libs/fortress-elements-js.min.js`
      : `https://elements.fortressapi.com/libs/fortress-elements-js.min.js`,
};

export const topUpSDKUrlStyles: {
  [key: string]: string;
} = {
  [PaymentProcessor.Kladot]: `https://www.kladot.com/api/static/css/main.073c9b0a.css`,
};
