import React, { useState } from 'react';
import styled from 'styled-components';

import { ActivePropertyItem, InvestorPortfolioShares } from 'services/apollo/multisto';

import { Card, CardBody, Col, Row } from 'atoms';
import { CardH4 } from 'components/card-header/CardHeader';
import PortfolioItem from 'pages/my-portfolio/components/PortfolioItem';
import { LogAction, useCreateLogMutation } from 'services/apollo';
import NdaModal from 'pages/nda/NdaModal';
import { useHistory } from 'react-router-dom';
import SellEntitySubTitleLink from 'pages/active-properties/components/SellEntitySubTitleLink';
import { AppData } from 'services/apollo/core';
import { useGetPortfolioStoData } from 'hooks/useGetPortfolioStoData';
import SellBuyButton from './SellBuyButton';

interface PortfolioStoProps {
  property: ActivePropertyItem;
  shares: InvestorPortfolioShares[];
  isMarketSpace: boolean;
  isSellBackEnabled: boolean;
  isShareCertificateButtonEnabled: boolean;
  appData: AppData;
}

const PortfolioSto: React.FC<PortfolioStoProps> = ({
  property,
  shares,
  isMarketSpace,
  isSellBackEnabled,
  isShareCertificateButtonEnabled,
  appData,
}) => {
  const [isOpen, setOpen] = useState(false);
  const history = useHistory();
  const isSellBackButtonEnabled =
    shares.filter((share) => share.shareType.sellToCompany).length > 0 && isSellBackEnabled;
  const { sellSideEntity, stoDetailsInvestNowLink, isNDAFlowEnabled, ID, isBuyButtonEnabled } = property;

  const portfolioSto = useGetPortfolioStoData({ stoID: ID, appData });

  const [investorAccessedDealRoom] = useCreateLogMutation();

  if (!shares) {
    return null;
  }
  const signNDA = isNDAFlowEnabled && !portfolioSto.isNDASigned;

  const hasShares = shares.filter((s) => s.shares > 0).length > 0;
  const hasDealRoom = stoDetailsInvestNowLink && stoDetailsInvestNowLink.length > 0;

  const changeBuyButtonText = () => {
    let buyButtonText;
    if (signNDA) {
      buyButtonText = 'ActiveProperty-Buy-No-NDA';
    } else if (hasDealRoom) {
      buyButtonText = 'ActiveProperty-AccessDealRoom';
    } else if (!hasShares) {
      buyButtonText = `ActiveProperty-Buy-no-shares`;
    } else {
      buyButtonText = `ActiveProperty-Buy`;
    }
    return buyButtonText;
  };
  const buy = () => {
    if (signNDA) {
      setOpen(true);
    } else if (hasDealRoom) {
      // @remark Log access to deal room
      investorAccessedDealRoom({
        variables: {
          stoId: ID,
          activityType: LogAction.InvestorAccessedDealRoom,
        },
      });
      window.location.href = stoDetailsInvestNowLink?.toString() ?? '';
    } else {
      history.push(`/investor/buy-property/${ID}`);
    }
  };

  return (
    <Card>
      <CardBody>
        <Row>
          <ColImage className="d-flex justify-content-center pb-0" xl={4} lg={5} md={5} sm={12} xs={12}>
            <ImgCard alt={property.title} src={property.picture} />
          </ColImage>
          <ColContent className="pt-0" xl={8} lg={7} md={7} sm={12} xs={12}>
            <Row id="row-btn" className="justify-content-md-start">
              <SellBuyButton
                isEnabled
                isVisible
                text="View-Details"
                onClick={() => {
                  history.push(`/investor/detail-property/${ID}`);
                }}
              />
              <SellBuyButton
                isEnabled
                isVisible={isBuyButtonEnabled}
                text={changeBuyButtonText()}
                onClick={() => buy()}
              />
              <SellBuyButton
                isEnabled={!isBuyButtonEnabled}
                isVisible={isSellBackButtonEnabled && !isBuyButtonEnabled && !isMarketSpace}
                text="shareItem-sellToCompany"
                onClick={() => history.push(`/investor/sell-property/${ID}`)}
              />
              {!hasDealRoom && (
                <SellBuyButton
                  isEnabled={isShareCertificateButtonEnabled && hasShares}
                  isVisible={isShareCertificateButtonEnabled && hasShares}
                  text="shareItem-shareCertificate"
                  onClick={() => history.push(`/investor/share-certificate/${property.ID}`)}
                />
              )}
            </Row>
            <div className="mb-3 text-justify">
              <CardH4 className="mt-2">{property.title}</CardH4>
              <SellEntitySubTitleLink appData={appData} title={sellSideEntity?.title} ID={sellSideEntity?.ID} />
              {property.details}
              <hr />
              {shares.map((share) => (
                <>
                  <PortfolioItem key={share?.ID} share={share} />
                  <hr />
                </>
              ))}
            </div>
          </ColContent>
        </Row>
        <NdaModal stoID={property.ID} isOpen={isOpen} setOpen={setOpen} />
      </CardBody>
    </Card>
  );
};

export default PortfolioSto;

const ColImage = styled(Col)`
  padding: 15px;
`;

const ColContent = styled(Col)`
  min-width: 250px;
  padding: 15px;

  @media (max-width: 760px) {
    display: flex;
    flex-direction: column-reverse;
    #row-btn {
      justify-content: space-around;
      button {
        padding: 10px;
      }
    }
  }
`;

const ImgCard = styled.img`
  width: 100%;
  margin: auto;
  max-width: 340px;
  border-radius: 4px;
  height: 220px;
  max-height: 250px;
  object-fit: contain;
`;
