import React from 'react';

import { Label, Col, Row, RadioInput } from 'atoms';
import styled from 'styled-components';

interface SellOrderOptionProps {
  isChecked: boolean;
  id: string;
  onChange: () => void;
  shareLabel: string;
  shareAmountText: string;
  label2?: string;
  text2?: string;
}

const SellOrderOption: React.FC<SellOrderOptionProps> = ({
  isChecked,
  id,
  onChange,
  shareLabel,
  shareAmountText,
  label2,
  text2,
}) => {
  return (
    <StyledInput
      defaultChecked={isChecked}
      big
      id={id}
      type="radio"
      name="sell-option"
      onChange={onChange}
      label={
        <Row className="font-weight-bold">
          <Col md={7}>
            <Label>{shareLabel}:</Label>
            {shareAmountText}
          </Col>
          {label2 ? (
            <Col xs="auto">
              <Label>{label2}:</Label>
              {text2}
            </Col>
          ) : null}
        </Row>
      }
    />
  );
};

export default SellOrderOption;

const StyledInput = styled(RadioInput)`
  display: flex;
  .custom-control-label::before {
    top: -15%;
  }
  .custom-control-label::after {
    top: -15%;
  }

  label {
    min-width: 0;
  }
`;
