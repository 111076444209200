import React from 'react';
import { useTranslation } from 'react-i18next';

import { MetaValues } from 'services/apollo';
import { MetaStateUpdate, MetaState } from 'components/meta-values/useMetaState';
import MetaValueField from 'components/meta-values/MetaValueField';
import { Row } from 'atoms';

interface InvestingMetaKycProps {
  entityID: number;
  meta: MetaValues[];
  state: MetaState;
  onChange: MetaStateUpdate;
}

const InvestingMetaKyc: React.FC<InvestingMetaKycProps> = ({ meta, state, onChange, entityID }) => {
  const { t } = useTranslation();
  if (!meta.length) {
    return null;
  }

  return (
    <>
      <hr />
      <h4>{t('PersonalInformationForm-additional-information')}</h4>
      <Row>
        {meta.map((m) => (
          <MetaValueField
            key={m.key}
            id={`${entityID}-${m.key}`}
            label={t(m.key)}
            type={m.type}
            value={state[m.key]}
            update={(v: string) => onChange(v, m.key)}
          />
        ))}
      </Row>
    </>
  );
};

export default InvestingMetaKyc;
