import React from 'react';

import { InvestorBuyPropertyBalance } from 'services/apollo/multisto';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

interface BalanceItemProps {
  account: InvestorBuyPropertyBalance;
}

const AccountItem: React.FC<BalanceItemProps> = ({ account }) => {
  return (
    <p>
      <b className="mr-4" style={{ fontSize: '16px' }}>
        {account.currency.abbreviation}
      </b>
      <CurrencySymbolDisplay value={account.amount} currency={account.currency} />
    </p>
  );
};

export default AccountItem;
