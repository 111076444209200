import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  InvestingEntityInput,
  InvestingEntityPaymentMethods,
  useInvestorInvestingEntityCreateMutation,
  useInvestorInvestingEntityUpdateMutation,
  InvestorInvestingEntitiesDocument,
  useInvestorInvestingEntityMetaUpdateMutation,
} from 'services/apollo';
import { BsSwal, Form } from 'atoms';
import { InvestorInvestingEntity } from 'services/apollo/investor-entity';
import useMetaState from 'components/meta-values/useMetaState';
import { AppData } from 'services/apollo/core';
import { QueryInfo } from '@apollo/client/core/QueryInfo';
import { getTranslationKeyOfApiError, GqlError } from 'services/core/helpers';
import { extractValidationError } from 'hooks/useGqlErrorExtractor';
import InvestingEntityFormControl from './InvestingEntityFormControl';
import InvestingEntityFormBase from './InvestingEntityFormBase';
import InvestingEntityFormAddress from './InvestingEntityFormAddress';
import InvestingEntityMembers from './InvestingEntityMembers';
import InvestingEntityFormPayment from './InvestingEntityFormPayment';
import InvestingEntityFormMeta from './InvestingEntityFormMeta';
import FilterOptions from './FilterOptions';
import LimitedInvestingEntityFormBase from './LimitedInvestingEntityFormBase';

interface InvestingEntityFormProps {
  entity?: InvestorInvestingEntity;
  canModifyEntity?: boolean;
  appData: AppData;
  close?: () => void;
  isEntityAsInvestorEnabled?: boolean;
  isFilterPreferencesEnabled?: boolean;
}

interface ErrorExtension {
  attributeName: string;
}

const fillState = (entity?: Partial<InvestorInvestingEntity>): InvestingEntityInput => {
  return {
    name: entity?.name || '',
    nickname: entity?.nickname || '',
    typeID: entity?.type?.ID || 1,
    accredited: entity?.accredited || false,
    taxId: entity?.taxId || '',
    address: entity?.address || '',
    city: entity?.city || '',
    registrationState: entity?.registrationState || '',
    state: entity?.state || '',
    postalCode: entity?.postalCode || '',
    country: entity?.country || '',
    paymentMethod: entity?.paymentMethod || InvestingEntityPaymentMethods.Ach,
  };
};

const InvestingEntityForm: React.FC<InvestingEntityFormProps> = ({
  entity,
  close,
  canModifyEntity = true,
  appData,
  isEntityAsInvestorEnabled = false,
  isFilterPreferencesEnabled = false,
}) => {
  const prefUS = entity || { country: 'United States' };
  const [state, setState] = useState(fillState(prefUS));
  const [metaState, setMetaState] = useMetaState(entity?.meta || []);
  const [error, setGqlError] = useState(fillState());
  const history = useHistory<{ id: string }>();
  const { t } = useTranslation();
  const { isLimitedInvestingEntityInfo } = appData;

  const [createEntity] = useInvestorInvestingEntityCreateMutation({
    refetchQueries: [{ query: InvestorInvestingEntitiesDocument }],
  });
  const [updateEntity] = useInvestorInvestingEntityUpdateMutation({
    refetchQueries: [{ query: InvestorInvestingEntitiesDocument }],
  });
  const [updateEntityMeta] = useInvestorInvestingEntityMetaUpdateMutation({
    refetchQueries: [{ query: InvestorInvestingEntitiesDocument }],
  });

  const handleChange = (data: Partial<InvestingEntityInput>) => {
    setState((prev) => ({ ...prev, ...data }));
    if (data.accredited) {
      return BsSwal.fire({
        title: t('Accredition-Acceptence-popup-title'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: t('Accredition-Acceptence-popup-confirm-button'),
        cancelButtonText: t('Accredition-Acceptence-popup-cancel-button'),
      }).then((result) => {
        if (result.isConfirmed) {
          return false;
        }
        setState((prev) => ({ ...prev, accredited: false }));
      });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    const data = { ...state, taxId: state.taxId.replace(/[^A-Z0-9]/gi, '') };
    if (entity) {
      const values = Object.keys(metaState).map((m) => ({ key: m, value: metaState[m] }));
      updateEntity({ variables: { data, entityID: entity.ID } })
        .then(() => {
          updateEntityMeta({ variables: { values, entityID: entity.ID } });
        })
        .then(() => {
          BsSwal.fire({
            title: t('InvestingEntityForm-saveEntity-success-popUp-title'),
            icon: 'success',
            confirmButtonText: t('ok'),
          });
        })
        .catch((err) => {
          // TODO: DIG-1742 add support for parameters in the frontend gql errors
          const gqlError = getTranslationKeyOfApiError<ErrorExtension>(err as GqlError<ErrorExtension>);
          const validationError = extractValidationError<Partial<InvestorInvestingEntity>>(err);
          setGqlError(
            fillState({
              ...validationError,
              [gqlError.data.attributeName]: gqlError.message,
            }),
          );
        });
    } else if (close) {
      createEntity({ variables: { data } })
        .then(() => {
          close();
          setState(fillState(prefUS));

          if (history?.location?.state) {
            BsSwal.fire({
              title: t('MarketSpace-createnewEntity-redirect-popup-title-text'),
              icon: 'info',
              showCancelButton: true,
              confirmButtonText: t('MarketSpace-createnewEntity-redirect-confirm-text'),
              cancelButtonText: t('MarketSpace-createnewEntity-redirect-cancel-text'),
            }).then((result) => {
              if (result.isConfirmed) {
                history.push({
                  pathname: `/investor/buy-property/${history.location.state.id}`,
                  state: { ...history.location.state },
                });
              }
            });
          }

          BsSwal.fire({
            title: t('InvestingEntityForm-saveEntity-success-popUp-title'),
            icon: 'success',
            confirmButtonText: t('ok'),
          }).then((result) => {
            if (result.isConfirmed) {
              if (history?.location?.state) {
                history.push({
                  pathname: `/investor/buy-property/${history.location.state.id}`,
                  state: { ...history.location.state },
                });
              }
            }
          });
        })
        .catch((err) => {
          const gqlError = getTranslationKeyOfApiError<ErrorExtension>(err as GqlError<ErrorExtension>);
          const validationError = extractValidationError<Partial<InvestorInvestingEntity>>(err as QueryInfo);
          setGqlError(
            fillState({
              ...validationError,
              [gqlError.data.attributeName]: gqlError.message,
            }),
          );
        });
    }
  };

  return (
    <>
      <Form onSubmit={handleSubmit} className="pt-4">
        {isLimitedInvestingEntityInfo ? (
          <>
            <LimitedInvestingEntityFormBase
              state={state}
              onChange={handleChange}
              validationError={error}
              canModifyEntity={canModifyEntity}
            />
          </>
        ) : (
          <>
            <InvestingEntityFormBase
              state={state}
              onChange={handleChange}
              validationError={error}
              canModifyEntity={canModifyEntity}
            />
            <InvestingEntityFormAddress
              state={state}
              onChange={handleChange}
              validationError={error}
              canModifyEntity={canModifyEntity}
            />
          </>
        )}
        {!isEntityAsInvestorEnabled && !isLimitedInvestingEntityInfo ? (
          <InvestingEntityFormPayment state={state} onChange={handleChange} canModifyEntity={canModifyEntity} />
        ) : null}
        <InvestingEntityFormMeta
          entityID={entity?.ID}
          meta={entity?.meta || []}
          state={metaState}
          onChange={setMetaState}
        />
        <InvestingEntityFormControl
          entityID={entity?.ID || 0}
          canModifyEntity={canModifyEntity}
          isEntityAsInvestorEnabled={isEntityAsInvestorEnabled}
        />
      </Form>
      {isFilterPreferencesEnabled ? <FilterOptions canModifyPreferences={canModifyEntity} /> : null}
      {entity ? (
        <InvestingEntityMembers
          entityID={entity.ID}
          members={entity.members}
          canModifyEntity={canModifyEntity}
          appData={appData}
        />
      ) : null}
    </>
  );
};

export default InvestingEntityForm;
