import { MetaValues, useGetCurrentInvestorFilterPreferenceQuery } from 'services/apollo';

interface UseFilterPreference {
  loading: boolean;
  preference: MetaValues[];
}

const useFilterPreference = (): UseFilterPreference => {
  const { data, loading } = useGetCurrentInvestorFilterPreferenceQuery();

  if (loading || !data) {
    return {
      loading,
      preference: [],
    };
  }

  try {
    const [filterMeta] = data.investorCurrentUser.meta;
    const preference: MetaValues[] = JSON.parse(filterMeta.value);

    return {
      loading: false,
      preference,
    };
  } catch (e) {
    return {
      loading,
      preference: [],
    };
  }
};

export default useFilterPreference;
