import VerifyRoute from 'lib/routing/VerifyRoute';
import PersonalInformation from 'pages/kyc-process/PersonalInformation';
import WizardComplete from 'pages/kyc-process/WizardComplete';
import CustomKYC from 'pages/kyc-process/CustomKYC';
import SumSubKyc from 'pages/external-kyc-process/sum-sub/SumSubKyc';
import BlockPassKyc from 'pages/external-kyc-process/block-pass/BlockPassKyc';
import NetkiKyc from 'pages/external-kyc-process/netki/NetkiKyc';
import AccreditationMainView from 'pages/external-accreditation-process/AccreditationMainView';
import GCashKyc from 'pages/external-kyc-process/g-cash/GCashKyc';
import FinishedKycRedirect from 'pages/external-kyc-process/g-cash/finishedKyc-redirect';

export const personalInfo = new VerifyRoute({
  path: '/personal-info',
  Component: PersonalInformation,
  name: '1 - Personal Info',
  icon: 'ti-layout-grid2',
  singleSidebarRoute: false,
});

export const kycProcess = new VerifyRoute({
  path: `/kyc-process/:id`,
  Component: CustomKYC,
  name: 'kyc-process',
  icon: 'ti-money',
  singleSidebarRoute: false,
});

export const wizardComplete = new VerifyRoute({
  path: '/wizard-complete',
  Component: WizardComplete,
  name: 'Wizard complete',
  icon: 'ti-upload',
  singleSidebarRoute: false,
});

export const sumSubProcess = new VerifyRoute({
  path: `/sumsub`,
  Component: SumSubKyc,
  name: 'sum-sub',
  icon: 'ti-upload',
  singleSidebarRoute: true,
});

export const gCashProcess = new VerifyRoute({
  path: `/gCash`,
  Component: GCashKyc,
  name: 'g-cash',
  icon: 'ti-upload',
  singleSidebarRoute: true,
});

export const gCashRedirect = new VerifyRoute({
  path: `/gCashDone`,
  Component: FinishedKycRedirect,
  name: 'g-cash',
  icon: 'ti-upload',
  singleSidebarRoute: true,
});

export const blockPassProcess = new VerifyRoute({
  path: `/blockpass`,
  Component: BlockPassKyc,
  name: 'block-pass',
  icon: 'ti-upload',
  singleSidebarRoute: true,
});

export const netkiProcess = new VerifyRoute({
  path: `/netki`,
  Component: NetkiKyc,
  name: 'netki',
  icon: 'ti-upload',
  singleSidebarRoute: true,
});

export const accreditationProcess = new VerifyRoute({
  path: '/accreditation',
  Component: AccreditationMainView,
  name: 'accreditation',
  icon: 'ti-upload',
  singleSidebarRoute: true,
});

export default [
  personalInfo,
  wizardComplete,
  kycProcess,
  sumSubProcess,
  gCashProcess,
  gCashRedirect,
  blockPassProcess,
  netkiProcess,
  accreditationProcess,
];
