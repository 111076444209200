import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import {
  BuyAlertStatus,
  InvestorBuyAlertsDocument,
  InvoiceStatusType,
  PaymentProcessor,
  useInvestorBuyAlertHideMutation,
  useInvestorBuyAlertRemoveMutation,
  useInvestorInvoiceAlertsQuery,
  useFindAllBlockchainProtocolsQuery,
  BlockchainProtocol,
} from 'services/apollo/graphql';
import { ActivePropertyItem, InvestorPortfolioBuyAlert } from 'services/apollo/multisto';
import { useLocalDate } from 'hooks';
import MoonpayButton from 'pages/moonpay/components/MoonpayButton';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';
import PayInvoiceExternal from 'pages/my-portfolio/components/PayInvoiceExternal';

import { BsSwal, Button, Loading, TD, TR } from 'atoms';
import { getRedirectUrl } from 'layouts/Root';
import { accreditationProcess } from 'lib/routing/route-sets/verify-routes';
import { AppData } from 'services/apollo/core';
import AlertItemStatus from './AlertItemStatus';

interface AlertItemProps {
  alert: InvestorPortfolioBuyAlert;
  property?: ActivePropertyItem;
  appData: AppData;
  isMergingEnabled: boolean;
  isInvoicingEnabled: boolean;
}

const isDeletable = (status?: BuyAlertStatus): boolean => {
  if (!status) return true; // Old behaviour
  return [
    BuyAlertStatus.Unused,
    BuyAlertStatus.Pending,
    BuyAlertStatus.PaymentAwaiting,
    BuyAlertStatus.KycRequired,
    BuyAlertStatus.AccreditationRequired,
    BuyAlertStatus.AccreditationPending,
    BuyAlertStatus.PendingDocuments,
  ].includes(status);
};
const isHideable = (status?: BuyAlertStatus): boolean => {
  if (!status) return true; // Will be deletable in this case
  return [BuyAlertStatus.Accepted, BuyAlertStatus.Declined, BuyAlertStatus.PaymentFailure].includes(status);
};
const getStatusColor = (status?: BuyAlertStatus): string => {
  switch (status) {
    default:
    case BuyAlertStatus.PendingDocuments:
      return 'orange';
    case BuyAlertStatus.Pending:
      return 'orange';
    case BuyAlertStatus.PaymentOngoing:
    case BuyAlertStatus.Accepted:
      return 'green';
    case BuyAlertStatus.Declined:
    case BuyAlertStatus.PaymentFailure:
      return 'red';
  }
};

const AlertItem: React.FC<AlertItemProps> = ({ alert, property, appData, isMergingEnabled, isInvoicingEnabled }) => {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const toLocalDate = useLocalDate();
  const [loading, setLoading] = useState(false);
  const [removeBuyAlert] = useInvestorBuyAlertRemoveMutation({
    refetchQueries: [{ query: InvestorBuyAlertsDocument }],
  });
  const [hideBuyAlert] = useInvestorBuyAlertHideMutation();
  const { data: Invoices, loading: InvoiceLoading } = useInvestorInvoiceAlertsQuery({ fetchPolicy: 'no-cache' });
  const { blockchainProtocolID } = alert.shareType;
  const { data: blockchainProtocolsData, loading: isBlockchainProtocolsDataLoading } =
    useFindAllBlockchainProtocolsQuery();
  const { IsMoonpayEnabled, activePaymentProcessor, KycProvider } = appData;

  const navigateToSharePurchaseSigning = () => history.push(`/investor/share-purchase-signing/${alert.ID}`);
  const { isSellRequest } = alert;

  const handleDeleteSend = async () => {
    setLoading(true);
    const alertID: number = alert.ID;
    BsSwal.fire({
      title: t('AlertItem-DeletePurchaseAlert'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: t('Delete'),
      cancelButtonText: t('Cancel'),
    }).then((result) => {
      if (result.isConfirmed) {
        removeBuyAlert({ variables: { alertID } })
          .then(() => {
            history.go(0);
          })
          .catch(() => {
            history.go(0);
          });
      }
    });
    setLoading(false);
  };

  const handleHideSend = async () => {
    setLoading(true);
    hideBuyAlert({ variables: { alertID: alert.ID } })
      .then(() => {
        history.go(0);
      })
      .catch(() => {
        history.go(0);
      });
    setLoading(false);
  };

  const handleKycRequired = () => {
    history.push(getRedirectUrl(KycProvider));
  };

  const handleAccreditationRequired = () => {
    history.push(accreditationProcess.path);
  };

  if (
    loading ||
    InvoiceLoading ||
    isBlockchainProtocolsDataLoading ||
    !blockchainProtocolsData?.findAllBlockchainProtocols
  ) {
    return <Loading />;
  }

  const blockchainProtocol = blockchainProtocolsData.findAllBlockchainProtocols.find(
    (protocol: BlockchainProtocol) => protocol.ID === blockchainProtocolID,
  );

  const invoice = Invoices?.investorInvoiceAlerts.find((invoiceData) => invoiceData.buyAlert?.ID === alert.ID);
  const navigateToInvoice = () => {
    history.push(`/investor/invoice/${invoice?.ID}`);
  };

  const checkStatusofPayments = () => {
    if (isMergingEnabled) {
      if (alert.isBuySharesSigned) {
        return null;
      }
      return (
        <Button size="sm" onClick={navigateToSharePurchaseSigning}>
          {t('AlertItem-sign')}
        </Button>
      );
    }
    if (alert.isBuySharesSigned) {
      return (
        <>
          <label className="mr-2" style={{ color: getStatusColor(alert.status) }}>
            <AlertItemStatus
              alert={alert}
              appData={appData}
              statusColor={getStatusColor(alert.status)}
              blockchainProtocol={blockchainProtocol}
            />
          </label>
        </>
      );
    }
    return (
      <Button size="sm" onClick={navigateToSharePurchaseSigning}>
        {t('AlertItem-sign')}
      </Button>
    );
  };

  return (
    <TR>
      <TD>
        <Label title={`# ${alert.ID}`} isSellRequest={isSellRequest}>
          {isSellRequest ? t('SELL') : t('BUY')}
        </Label>
      </TD>
      <TD>{toLocalDate(alert.date)}</TD>
      <TD>{property?.title}</TD>
      <TD>{alert.shareType?.title}</TD>
      {isMergingEnabled ? (
        <>
          {invoice ? (
            <>
              <TD>
                <CurrencySymbolDisplay value={invoice.amountToPay} currency={alert.shareType.currency} />
              </TD>
              <TD>{(alert.shares ?? 0).toLocaleString(i18n.language)}</TD>
              {invoice?.status === InvoiceStatusType.Unpaid ? (
                <TD>
                  <label style={{ color: '#bfb51f' }}>{t('Portfolio-Transaction-Status-Type-Pending')}</label>
                </TD>
              ) : (
                <TD>
                  <label className="mr-2" style={{ color: getStatusColor(alert.status) }}>
                    {t(`AlertItem-status-${alert.status}`)}
                  </label>
                </TD>
              )}
            </>
          ) : (
            <>
              <TD>
                <CurrencySymbolDisplay value={alert.purchasePriceOffered ?? 0} currency={alert.shareType.currency} />
              </TD>
              <TD>{(alert.shares ?? 0).toLocaleString(i18n.language)}</TD>
              <TD>
                <AlertItemStatus
                  alert={alert}
                  appData={appData}
                  statusColor={getStatusColor(alert.status)}
                  blockchainProtocol={blockchainProtocol}
                />
              </TD>
            </>
          )}
        </>
      ) : (
        <TD>{(alert.shares ?? 0).toLocaleString(i18n.language)}</TD>
      )}

      <TD>
        <ActionContainer>
          {checkStatusofPayments()}

          {alert.status === BuyAlertStatus.KycRequired && (
            <Button size="sm" onClick={handleKycRequired}>
              {t('AlertItem-Button-KycRequired')}
            </Button>
          )}
          {alert.status === BuyAlertStatus.AccreditationRequired && (
            <Button size="sm" onClick={handleAccreditationRequired}>
              {t('AlertItem-Button-AccreditationRequired')}
            </Button>
          )}
          {isMergingEnabled && isInvoicingEnabled && invoice && (
            <>
              <Button size="sm" onClick={navigateToInvoice}>
                {t('AlertItem-Specified-Review')}
              </Button>
              {
                // TODO get rid of this entirely by adding payoutOne to a payment channel - DIG-1752
                activePaymentProcessor === PaymentProcessor.PayoutOne ? <PayInvoiceExternal invoice={invoice} /> : null
              }
            </>
          )}
          {isDeletable(alert.status) ? (
            <Button size="sm" onClick={handleDeleteSend}>
              {t('Delete')}
            </Button>
          ) : (
            isHideable(alert.status) && (
              <Button size="sm" onClick={handleHideSend}>
                {t('AlertItem-hide')}
              </Button>
            )
          )}
          {IsMoonpayEnabled && <MoonpayButton alert={alert} />}
        </ActionContainer>
      </TD>
    </TR>
  );
};

export default AlertItem;

const Label = styled.label<{ isSellRequest?: boolean }>`
  color: ${(props) => (props.isSellRequest ? 'green' : 'blue')};
`;

const ActionContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  width: 100%;

  button {
    min-width: 145px;
    max-width: fit-content;
    margin-left: 0 !important;
  }
`;
