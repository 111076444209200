import React, { ChangeEvent } from 'react';
import { FormFeedback } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { InvestorDepositWithdrawAlertInput } from 'services/apollo';

import { Button, CardBody, Col, Input, Row } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import BANK from 'assets/img/bank.png';
import { Markup } from 'interweave';
import { InvestorWalletChannel } from 'services/apollo/multisto';
import CurrencySymbolDisplay from 'components/CurrencySymbolDisplay';

interface DepositFormBankProps {
  hideModal: () => void;
  channel: InvestorWalletChannel;
  investorID: number;
  state: InvestorDepositWithdrawAlertInput;
  error: InvestorDepositWithdrawAlertInput;
  onChange: (e: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => void;
  onSubmit: (isWithdrawRequest: boolean, title: string) => void;
  defaultAmount?: number;
}

const DepositFormBank: React.FC<DepositFormBankProps> = ({
  hideModal,
  channel,
  investorID,
  state,
  error,
  onChange,
  onSubmit,
  defaultAmount,
}) => {
  const { t } = useTranslation();
  const { details, depositAlertDisplayParams } = channel;
  const { canDisplayBankName, canDisplayBankAccount, canDisplayDetails, canDisplaySwift, canDisplayCardBody } =
    depositAlertDisplayParams ?? {
      canDisplayBankAccount: false,
      canDisplayCardBody: false,
      canDisplayBankName: false,
      canDisplayDetails: false,
      canDisplaySwift: false,
    };

  return (
    <>
      <CardHeader imgSrc={BANK} text={t('depositFormCardHeader')} />
      <CardBody>
        <Markup content={details} />
        {canDisplayCardBody ? (
          <>
            <br />
            <hr />
            <b>{t('depositFormCardBody')} </b>
            <br />
            <hr />
          </>
        ) : null}
        <Row>
          <Col md={5}>
            <b>{t('depositFormAmount')}</b> * <br />
            <CurrencySymbolDisplay value={state.amount || defaultAmount || 0} currency={channel.currency} />
            <FormFeedback>{error.amount}</FormFeedback>
          </Col>
          {canDisplayBankName ? (
            <Col md={7}>
              <b>{t('depositFormBankName')}</b> * <br />
              <Input
                invalid={!!error.bankName}
                type="text"
                max="120"
                value={state.bankName}
                onChange={onChange}
                name="bankName"
                placeholder=""
              />
              <FormFeedback>{error.bankName}</FormFeedback>
            </Col>
          ) : null}
        </Row>
        <Row>
          {canDisplaySwift ? (
            <Col md={5}>
              <b>{t('depositFormSwift')}</b>
              <br />
              <Input
                invalid={!!error.swiftCode}
                type="text"
                max="30"
                value={state.swiftCode}
                onChange={onChange}
                name="swiftCode"
                placeholder=""
              />
              <FormFeedback>{error.swiftCode}</FormFeedback>
            </Col>
          ) : null}

          {canDisplayBankAccount ? (
            <Col md={5}>
              <b>{t('depositFormBankNumber')}</b> * <br />
              <Input
                invalid={!!error.bankAccount}
                type="text"
                max="80"
                name="bankAccount"
                value={state.bankAccount}
                onChange={onChange}
                placeholder=""
              />
              <FormFeedback>{error.bankAccount}</FormFeedback>
            </Col>
          ) : null}
        </Row>
        <br />
        <hr />
        {canDisplayDetails ? (
          <>
            <Row>
              <Col md={12}>
                <b>{t('depositFormDetails')}</b> * <br />
                <label>{t('depositFormInvestorIdLabel', { investorID })}</label>
                <textarea
                  rows={5}
                  className="w-100"
                  maxLength={1000}
                  name="details"
                  placeholder=""
                  onChange={onChange}
                  defaultValue={`InvestorID: ${investorID}`}
                />
                <FormFeedback>{error.details}</FormFeedback>
              </Col>
            </Row>
            <hr />
          </>
        ) : null}
        <div className="d-flex justify-content-end">
          <Button className="ml-auto" onClick={onSubmit}>
            {t('depositFormSendButton')}
          </Button>
          <Button onClick={() => hideModal()}>{t('Cancel')}</Button>
        </div>
      </CardBody>
    </>
  );
};

export default DepositFormBank;
