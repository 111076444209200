import { ethers } from 'ethers';
import { Blockchains } from 'services/apollo';
import { Address, useToken } from 'wagmi';

export const keys = Object.keys as <T>(o: T) => Extract<keyof T, string>[];

export const toTokenPowerOf = (contractAddress: string, amount: number, chainId: number): string => {
  const { data } = useToken({
    address: contractAddress as Address,
    chainId,
  });
  return ethers.utils.parseUnits(amount.toString(), data?.decimals).toString();
};

export const forceBlockchain = async (chain: string): Promise<boolean> => {
  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: `0x${parseInt(chain.substring(2), 10).toString(16)}` }],
      });
      return true;
    } catch (error) {
      return false;
    }
  }
  return false;
};

export const prepareBlockchainData = (
  isTestnetEnabled: boolean,
  blockchainData?: Blockchains,
): { chainID: number; nodeProvider: string; blockchainExplorer: string; swapContractAddress: string } => {
  const {
    chainNumberTestnet,
    nodeProviderURLTestnet,
    explorerURLTestnet,
    swapContractAddressTestnet,
    chainNumber,
    nodeProviderURL,
    explorerURL,
    swapContractAddress,
  } = blockchainData ?? {};

  if (isTestnetEnabled) {
    return {
      chainID: parseInt((chainNumberTestnet ?? '').substring(2), 10),
      nodeProvider: nodeProviderURLTestnet ?? '',
      blockchainExplorer: explorerURLTestnet ?? '',
      swapContractAddress: swapContractAddressTestnet ?? '',
    };
  }
  return {
    chainID: parseInt((chainNumber ?? '').substring(2), 10),
    nodeProvider: nodeProviderURL ?? '',
    blockchainExplorer: explorerURL ?? '',
    swapContractAddress: swapContractAddress ?? '',
  };
};
