import React from 'react';
import { useTranslation } from 'react-i18next';

import { useGetPolymeshTransactionsQuery, PolymeshSwapBetween, PolymeshSwapStatus } from 'services/apollo';
import { Card, CardBody, BrandIcon, Col, Loading } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import MyTransactions from './components/polymesh/MyTransactions';

const PolymeshSwap: React.FC = () => {
  const { t } = useTranslation();

  const { data, loading } = useGetPolymeshTransactionsQuery({
    variables: { between: PolymeshSwapBetween.InvestorAdmin, status: PolymeshSwapStatus.Pending },
    fetchPolicy: 'network-only',
  });

  if (loading || !data) {
    return <Loading />;
  }

  const { getPolymeshTransactions: transactions } = data;

  return (
    <Col className="content">
      <Card>
        <CardHeader
          text={t('Polymesh-Swap-Transactions-Header')}
          icon={<BrandIcon icon="arrow-down" color="cyan" pill />}
        />
        <CardBody>
          <MyTransactions data={transactions} />
        </CardBody>
      </Card>
    </Col>
  );
};

export default PolymeshSwap;
