import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ButtonGroup } from 'reactstrap';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TooltipItem,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { format, subDays, subWeeks, subMonths, subYears } from 'date-fns';

import { useGetPerformanceGraphDataLazyQuery } from 'services/apollo';
import { PerformanceGraphData } from 'services/apollo/exchange';

import { Card, CardBody, BrandIcon, Button } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const HistoricalPerformance: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const params = useParams<{ shareTypeID: string }>();
  const shareTypeID = parseInt(params.shareTypeID, 10);

  const [performanceGraphData, setPerformanceGraphData] = useState<PerformanceGraphData>();

  const dateFormat = 'yyyy-MM-dd';

  const [getPerformanceGraphData, { data: graphData }] = useGetPerformanceGraphDataLazyQuery();

  const goBack = (): void => history.goBack();

  useEffect(() => {
    getPerformanceGraphData({ variables: { shareTypeID }, fetchPolicy: 'network-only' });
    setPerformanceGraphData(graphData?.getPerformanceGraphData);
  }, []);

  useEffect(() => {
    setPerformanceGraphData(graphData?.getPerformanceGraphData);
  }, [graphData]);

  const getOneDayData = (): void => {
    const currentDate = format(new Date(), dateFormat);
    getPerformanceGraphData({
      variables: { shareTypeID, startDate: currentDate, endDate: currentDate },
      fetchPolicy: 'network-only',
    });
  };

  const getOneWeekData = (): void => {
    const startDate = format(subWeeks(new Date(), 1), dateFormat);
    const endDate = format(new Date(), dateFormat);
    getPerformanceGraphData({ variables: { shareTypeID, startDate, endDate }, fetchPolicy: 'network-only' });
  };

  const getOneMonthData = (): void => {
    const startDate = format(subMonths(new Date(), 1), dateFormat);
    const endDate = format(new Date(), dateFormat);
    getPerformanceGraphData({ variables: { shareTypeID, startDate, endDate }, fetchPolicy: 'network-only' });
  };

  const getThreeMonthData = (): void => {
    const startDate = format(subMonths(new Date(), 3), dateFormat);
    const endDate = format(new Date(), dateFormat);
    getPerformanceGraphData({ variables: { shareTypeID, startDate, endDate }, fetchPolicy: 'network-only' });
  };

  const getOneYearData = (): void => {
    const startDate = format(subYears(new Date(), 1), dateFormat);
    const endDate = format(new Date(), dateFormat);
    getPerformanceGraphData({ variables: { shareTypeID, startDate, endDate }, fetchPolicy: 'network-only' });
  };

  const getYesterdayData = (): void => {
    const yesterdayDate = format(subDays(new Date(), 1), dateFormat);
    getPerformanceGraphData({
      variables: { shareTypeID, startDate: yesterdayDate, endDate: yesterdayDate },
      fetchPolicy: 'network-only',
    });
  };

  const getAllData = (): void => {
    getPerformanceGraphData({ variables: { shareTypeID }, fetchPolicy: 'network-only' });
  };

  const data = {
    labels: performanceGraphData?.dates,
    datasets: [
      {
        label: performanceGraphData?.shareType.title,
        data: performanceGraphData?.data,
        borderColor: 'rgb(4,162,55)',
        backgroundColor: 'rgba(203,240,199)',
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top' as const,
      },
      title: {
        display: true,
        text: '',
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          beforeLabel: (context: TooltipItem<'line'>) => {
            return `${context.dataset.label}`;
          },
          label: (context: TooltipItem<'line'>) => {
            return `${t('HistoricalPerformance-Tooltip-Label')} ${performanceGraphData?.sharesSum[context.dataIndex]}`;
          },
          afterLabel: (context: TooltipItem<'line'>) => {
            return `${t('HistoricalPerformance-Tooltip-AfterLabel')} ${context.dataset.data[context.dataIndex]} ${
              performanceGraphData?.shareType.currency.symbol
            }`;
          },
        },
      },
    },
  };

  return (
    <div className="content">
      <Card>
        <CardHeader
          text={t('HistoricalPerformance-CardHeader-Text')}
          icon={<BrandIcon icon="chart-line" color="cyan" pill />}
        />
        <CardBody className="mb-2">
          <div className="text-center">
            <ButtonGroup>
              <Button onClick={getOneDayData}>{t('HistoricalPerformance-Button-1D')}</Button>
              <Button onClick={getOneWeekData}>{t('HistoricalPerformance-Button-7D')}</Button>
              <Button onClick={getOneMonthData}>{t('HistoricalPerformance-Button-1M')}</Button>
              <Button onClick={getThreeMonthData}>{t('HistoricalPerformance-Button-3M')}</Button>
              <Button onClick={getOneYearData}>{t('HistoricalPerformance-Button-1Y')}</Button>
              <Button onClick={getYesterdayData}>{t('HistoricalPerformance-Button-YTD')}</Button>
              <Button onClick={getAllData}>{t('HistoricalPerformance-Button-ALL')}</Button>
            </ButtonGroup>
            <Line options={options} data={data} />
          </div>
          <br />
          <Button size="lg" onClick={goBack}>
            {t('HistoricalPerformance-Button-Back')}
          </Button>
        </CardBody>
      </Card>
    </div>
  );
};

export default HistoricalPerformance;
