import * as React from 'react';

import { Loading } from 'atoms';
import { useInvestorAppDataQuery } from 'services/apollo';
import { useHistory } from 'react-router-dom';

const ExternalModule: React.FC = () => {
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery();
  const history = useHistory();
  if (appDataLoad || !appData) {
    return <Loading />;
  }

  const { isExternalModuleEnabled } = appData.investorAppParameters;
  const { redirectUrl } = isExternalModuleEnabled;

  if (!redirectUrl.includes('http')) {
    window.open(`http://${redirectUrl}`);
    history.goBack();
  } else {
    window.open(redirectUrl);
    history.goBack();
  }

  return <Loading />;
};

export default ExternalModule;
