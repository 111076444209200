import React from 'react';
import { Route as RouterRoute } from 'react-router-dom';

import ForumLayout from 'layouts/ForumLayout';
import Route from './Route';

class ForumRoute extends Route {
  getRoute(): React.ReactElement {
    const { path, Component } = this;
    return (
      <RouterRoute
        key={path}
        path={path}
        render={() => (
          <ForumLayout>
            <Component />
          </ForumLayout>
        )}
      />
    );
  }
}

export default ForumRoute;
