import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import { NavLink, withRouter } from 'react-router-dom';
import { Navbar } from 'reactstrap';
import Auth from 'services/core/auth';

import { signIn } from 'lib/routing/route-sets/public-routes';
import {
  kycProcess,
  personalInfo,
  sumSubProcess,
  blockPassProcess,
  netkiProcess,
  gCashProcess,
} from 'lib/routing/route-sets/verify-routes';
import LanguageSelector from 'components/LanguageSelector';
import { useTranslation } from 'react-i18next';
import {Loading } from 'atoms';
import CustomizationComponent from 'components/CustomizationComponent';
import { KycProviders, useInvestorAppDataQuery, useMeQuery } from 'services/apollo';
import Web3ConnectButton from 'components/navbar/components/WalletConnectButton';
import {StyledNav, StyledContainer} from 'components/navbar/styled/styled'

const InvestorNavbar = (props) => {
  const { data: appData, loading: appDataLoad } = useInvestorAppDataQuery();
  const { data: userData, loading: userLoading } = useMeQuery();
  const [state, setState] = useState({
    collapseOpen: false,
    color: 'navbar-transparent',
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (
      window.outerWidth < 993 &&
      props.history.location.pathname !== props.location.pathname &&
      document.documentElement.className.indexOf('nav-open') !== -1
    ) {
      document.documentElement.classList.toggle('nav-open');
    }
  }, [props.history.location.pathname, props.location.pathname]);


  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    document.documentElement.classList.toggle('nav-open');
  };



  if (appDataLoad || !appData || !userData || userLoading) {
    return <Loading />;
  }

  const logout = (e) => {
    e.preventDefault();
    const { history } = props;
    Auth.signOut().then(() => {
      const { SSORedirectFrontEnd, isSSOModeEnabled } = appData.investorAppParameters;
      if (isSSOModeEnabled && SSORedirectFrontEnd !== '') {
        window.location.href = SSORedirectFrontEnd;
      } else {
        history.push(signIn.path);
      }
    });
  };

  const {
    isInvestorLanguageSelectorEnabled,
    isCountryFlagsInSelectorEnabled,
    IsMarketSpace,
    isWalletConnectEnabled,
    isInvestorDashboardConnectWalletEnabled,
  } = appData?.investorAppParameters;

  const investorUser = userData.investorUser?.investor;
  const { KycProvider } = appData.investorAppParameters;
  const MarkeetspaceProfileLink = IsMarketSpace ? kycProcess.path : personalInfo.path;
  const link = (kycProvider) => {
    switch (kycProvider) {
      case KycProviders.Internal:
        return MarkeetspaceProfileLink;
      case KycProviders.SumSub:
        return sumSubProcess.path;
      case KycProviders.BlockPass:
        return blockPassProcess.path;
      case KycProviders.Netki:
        return netkiProcess.path;
      case KycProviders.GCash:
        return gCashProcess.path;
      default:
        return personalInfo.path;
    }
  };
  const myProfileLink = link(KycProvider);

  return (
    <>
      <CustomizationComponent name="Navbar" />
      <Navbar className={classnames('navbar-absolute p-0', state.color)} expand="lg" style={{ height: '80px' }}>
        <StyledContainer fluid className="bg-white">
            <span
                className="logo empty-logo"
                style={{
                  verticalAlign: 'middle',
                  height: '79px',
                }}
            />
            <div className={classnames('navbar-toggle', { toggled: state.sidebarOpen })}>
              <button className="navbar-toggler ml-2" type="button" onClick={toggleSidebar}>
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <StyledNav>
              {isInvestorDashboardConnectWalletEnabled && (
                  <Web3ConnectButton isWalletConnectEnabled={isWalletConnectEnabled} />
              )}
                {isInvestorLanguageSelectorEnabled  && (
                  <LanguageSelector navigationBar enableCountryFlags={isCountryFlagsInSelectorEnabled} />
                ) }
                  <NavLink className="nav-link navbar-item" to={myProfileLink}>
                      <i className="ti-user navbar-item__icon" />
                      <span className='initial-circle'>{investorUser.firstName[0]}</span>
                      <span className='navbar-item__content'>
                        {investorUser.firstName} {investorUser.lastName}
                      </span>
                  </NavLink>
                  <NavLink to="#" className="nav-link navbar-item" onClick={logout}>
                    <i className="ti-arrow-circle-up navbar-item__icon" />
                    <span className='navbar-item__content'>{t('Logout')}</span>
                  </NavLink>
            </StyledNav>
        </StyledContainer>
      </Navbar>
    </>
  );
};

export default withRouter(InvestorNavbar);
