import React from 'react';

import { Loading, Select } from 'atoms';
import { useCountriesQuery } from 'services/apollo';

interface FilterOptionsItemCountriesSelectProps {
  value: string;
  disabled: boolean;

  update(value: string): void;
}

const FilterOptionsItemCountriesSelect: React.FC<FilterOptionsItemCountriesSelectProps> = ({
  update,
  disabled,
  value,
}) => {
  const { loading: countriesLoading, data: countriesData } = useCountriesQuery();

  if (countriesLoading || !countriesData) {
    return <Loading />;
  }
  const { countries } = countriesData;
  // the countries already selected
  const valueArray = value.split(',');

  const options = countries.map((country) => ({
    value: country,
    label: country,
  }));

  const selected = options.filter((option) => valueArray.includes(option.value));

  const handleSelect = (opts: { value: string }[]) => {
    update(opts.map((o) => o.value).join(','));
  };

  return (
    <Select
      onChange={handleSelect}
      value={selected}
      options={options}
      isDisabled={disabled}
      isMulti
      closeMenuOnSelect={false}
    />
  );
};

export default FilterOptionsItemCountriesSelect;
