import React from 'react';
import { useTranslation } from 'react-i18next';
import StepProgressBar, { StepProps } from 'pages/StepProgressBar';
import { BLOCKCHAIN_ID } from './EthereumKeys';

export interface WhitelistProgressionBarProps {
  radioEthereumStatus: BLOCKCHAIN_ID;
  publickey: string;
  isTokenAdded: boolean;
  walletAdded: boolean;
  isConnected: boolean;
}

const WhitelistProgressionBar: React.FC<WhitelistProgressionBarProps> = (props) => {
  const { t } = useTranslation();
  const { radioEthereumStatus, publickey, isTokenAdded, walletAdded, isConnected } = props;

  const orderedSteps: StepProps[] = [
    {
      condition:
        [BLOCKCHAIN_ID.ETHEREUM, BLOCKCHAIN_ID.POLYGON, BLOCKCHAIN_ID.LIBEX, BLOCKCHAIN_ID.FANTOM].includes(
          radioEthereumStatus,
        ) &&
        isConnected &&
        !!publickey.length,
      tiIcon: {
        tiIcon: 'ti-link',
        size: '30px',
        color: 'green',
      },
      text: t('WhitelistProgressBar-Connect'),
    },
    {
      condition: isConnected && !!publickey.length && walletAdded,
      tiIcon: {
        tiIcon: 'ti-list',
        size: '30px',
        color: 'green',
      },
      text: t('WhitelistProgressBar-Whitelist'),
    },
    {
      condition: isTokenAdded,
      tiIcon: {
        tiIcon: 'ti-plus',
        size: '30px',
        color: 'green',
      },
      text: t('WhitelistProgressBar-Import'),
    },
  ];

  return <StepProgressBar orderedSteps={orderedSteps} />;
};

export default WhitelistProgressionBar;
