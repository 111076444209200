import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';

import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import FixedPluginRow from 'components/fixed-plugin/components/FixedPluginRow';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const MarketPlaceCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const marketPlaceIconBG = [colors.green, colors.orange, colors.blue];
  const marketPlaceIconColor = [colors.white, colors.backgroundGrey100, colors.black];

  const marketPlaceDeleteIconBG = [colors.orange, colors.red, colors.paleRed];
  const marketPlaceLabelsFontColor = [colors.silver, colors.textGrey, colors.black];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-Marketplace')}</h5>
        <SettingsBarCarcet className="ti-angle-left" open={collapse} />
      </div>

      <Collapse isOpen={collapse}>
        <FixedPluginRow
          values={marketPlaceIconBG}
          property="marketPlaceIconBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-MarketPlaceIconBackground"
          currentColor={theme.marketPlaceIconBGColor}
        />
        <FixedPluginRow
          values={marketPlaceIconColor}
          property="marketPlaceIconFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-MarketPlaceIconFontColor"
          currentColor={theme.marketPlaceIconFontColor}
        />
        <FixedPluginRow
          values={marketPlaceDeleteIconBG}
          property="marketPlaceDeleteIconBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-MarketPlaceDeleteIconBackground"
          currentColor={theme.marketPlaceDeleteIconBGColor}
        />
        <FixedPluginRow
          values={marketPlaceIconColor}
          property="marketPlaceDeleteIconFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-MarketPlaceDeleteIconFontColor"
          currentColor={theme.marketPlaceDeleteIconFontColor}
        />
        <FixedPluginRow
          values={marketPlaceLabelsFontColor}
          property="marketPlaceLabelFontColor"
          handleChange={handleChange}
          title="Labels font color"
          currentColor={theme.marketPlaceLabelFontColor}
        />
      </Collapse>
    </div>
  );
};

export default MarketPlaceCustomizer;
