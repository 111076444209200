import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';
import { Collapse } from 'reactstrap';
import { colors } from 'lib/themes/colors';
import { ChoicesCustomizerType } from 'components/fixed-plugin/FixedPluginContent';
import { FixedPluginRow } from 'components/fixed-plugin/components';
import { SettingsBarCarcet } from 'components/fixed-plugin/styled/styled';

const ActiveOfferingsCustomizer: React.FC<ChoicesCustomizerType> = ({ handleChange, theme }) => {
  const { t } = useTranslation();
  const [collapse, toggle] = useState(false);

  const mainCardBGColor = [colors.white, colors.backgroundGrey100, colors.silver];
  const dashboardCardBGColor = [colors.white, colors.backgroundGrey100, colors.silver];
  const dashboardActiveCardBGColor = [colors.white, colors.backgroundGrey100, colors.silver];
  const leftBorderColor = [colors.green, colors.blue, colors.red];

  const cardsFontColor = [colors.grayDark, colors.textGrey, colors.black];
  const cardsViewFontColor = [colors.white, colors.blue, colors.marketSpaceBlue];

  const iconColor = [colors.white, colors.yellow, colors.black];
  const popOverColor = [colors.gray, colors.textGrey, colors.silver];

  return (
    <div className="settings-content__item">
      <div className="settings-content__item--header" onClick={() => toggle(!collapse)}>
        <h5 className="header-small settings-content__item--title mb-3">{t('FixedPlugin-SubTitle-ActiveOfferings')}</h5>
        <SettingsBarCarcet className="ti-angle-left" open={collapse} />
      </div>
      <Collapse isOpen={collapse}>
        <FixedPluginRow
          values={mainCardBGColor}
          property="offeringsCardBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-CardBGColor"
          currentColor={theme.offeringsCardBGColor}
        />

        <h6 className="settings-content__item--subtitle">{t('FixedPluginRow-SubTitle-ActiveOfferings-DashBoard')}</h6>
        <FixedPluginRow
          values={dashboardCardBGColor}
          property="offeringsDBCardBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-DashBoardColor"
          currentColor={theme.offeringsDBCardBGColor}
        />
        <FixedPluginRow
          values={dashboardActiveCardBGColor}
          property="offeringsDBActiveCardBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-DashBoardActiveCardColor"
          currentColor={theme.offeringsDBActiveCardBGColor}
        />

        <h6 className="settings-content__item--subtitle">{t('FixedPluginRow-SubTitle-ActiveOfferings-Border')}</h6>
        <FixedPluginRow
          values={leftBorderColor}
          property="offeringsDBActiveCardBorderColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-LeftBorderActive"
          currentColor={theme.offeringsDBActiveCardBorderColor}
        />
        <FixedPluginRow
          values={leftBorderColor}
          property="offeringsDBClosedCardBorderColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-LeftBorderClosed"
          currentColor={theme.offeringsDBClosedCardBorderColor}
        />
        <FixedPluginRow
          values={leftBorderColor}
          property="offeringsDBProfileCardBorderColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-LeftBorderProfile"
          currentColor={theme.offeringsDBProfileCardBorderColor}
        />
        <h6 className="settings-content__item--subtitle">{t('FixedPluginRow-SubTitle-ActiveOfferings-CardsFont')}</h6>
        <FixedPluginRow
          values={cardsFontColor}
          property="offeringsDBCardFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-CardsFontColor"
          currentColor={theme.offeringsDBCardFontColor}
        />
        <FixedPluginRow
          values={cardsFontColor}
          property="offeringsDBActiveCardFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-CardsFontColorActive"
          currentColor={theme.offeringsDBActiveCardFontColor}
        />
        <FixedPluginRow
          values={cardsViewFontColor}
          property="offeringsDBCardViewFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-CardsFontColorView"
          currentColor={theme.offeringsDBCardViewFontColor}
        />

        <h6 className="settings-content__item--subtitle">{t('FixedPluginRow-SubTitle-ActiveOfferings-InterestBtn')}</h6>
        <FixedPluginRow
          values={iconColor}
          property="interestedBtnColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-InterestBtnIconColor"
          currentColor={theme.interestedBtnColor}
          popOver="FixedPluginRow-PopOver-ActiveOfferings-InterestBtn"
        />
        <FixedPluginRow
          values={iconColor}
          property="interestedBtnActiveColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-InterestBtnActiveIconColor"
          currentColor={theme.interestedBtnActiveColor}
        />
        <FixedPluginRow
          values={popOverColor}
          property="interestedPopoverBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-InterestPopoverBGColor"
          currentColor={theme.interestedPopoverBGColor}
        />
        <FixedPluginRow
          values={popOverColor}
          property="interestedPopoverFontColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-InterestPopoverFontColor"
          currentColor={theme.interestedPopoverFontColor}
        />
        <FixedPluginRow
          values={mainCardBGColor}
          property="interestedDetailsBGColor"
          handleChange={handleChange}
          title="FixedPluginRow-Title-ActiveOfferings-InterestDetailsBGColor"
          currentColor={theme.interestedDetailsBGColor}
        />
      </Collapse>
    </div>
  );
};

export default ActiveOfferingsCustomizer;
