import React from 'react';

import { CardH4 } from 'components/card-header/CardHeader';
import { useTranslation } from 'react-i18next';
import { ActivePropertyItem } from 'services/apollo/multisto';
import { AppData } from 'services/apollo/core';
import { canSeeNDADataRequired } from 'services/core/helpers';
import styled from 'styled-components';
import NdaModal from 'pages/nda/NdaModal';
import { Button, Card, CardBody, Row, CenteredCol } from 'atoms';
import SellEntitySubTitleLink from '../SellEntitySubTitleLink';
import MetaTagsCard from '../MetaTagsCard';

interface MPXCardProps {
  props: ActivePropertyItem;
  investorAppParameters: AppData;
  view: () => void;
  changeBuyButtonText: () => string;
  canSeeNDAData: (data: canSeeNDADataRequired) => boolean;
  buy: () => void;
  NDACheckData: canSeeNDADataRequired;
  isOpen: boolean;
  setOpen: (value: React.SetStateAction<boolean>) => void;
  isProjectCardTagsDisplayEnabled: boolean;
  investorInterested: boolean;
}

const MPXCard: React.FC<MPXCardProps> = ({
  props,
  investorAppParameters,
  view,
  changeBuyButtonText,
  canSeeNDAData,
  NDACheckData,
  buy,
  isOpen,
  setOpen,
  isProjectCardTagsDisplayEnabled,
  investorInterested,
}) => {
  const { t } = useTranslation();
  const { ID, title, details, picture, meta, sellSideEntity } = props;

  return (
    <StyledCard width="100%" wmax="400px" height="100%">
      <ImgCard className="w-100" alt={title} src={picture} />

      <StyledCardBody className="px-2 pb-2 d-flex flex-column justify-content-between">
        <div>
          <CardH4>{title}</CardH4>
          <SellEntitySubTitleLink
            appData={investorAppParameters}
            title={sellSideEntity?.title}
            ID={sellSideEntity?.ID}
          />
          {isProjectCardTagsDisplayEnabled ? <MetaTagsCard metaContent={meta} /> : null}
          <p>{details}</p>
        </div>
        <Row className="justify-content-center">
          <CenteredCol md={6} className="justify-content-center">
            <Button outline size="sm" wmin="80px" style={{ width: '100%', wordWrap: 'normal' }} onClick={view}>
              {!canSeeNDAData(NDACheckData)
                ? t('ActiveProperty-ViewDetails-nda-not-signed')
                : t('ActiveProperty-ViewDetails')}
            </Button>
          </CenteredCol>
          {investorInterested ? (
            <CenteredCol md={6} className="justify-content-center">
              <Button size="sm" wmin="80px" style={{ width: '100%', wordWrap: 'normal' }} onClick={buy}>
                {changeBuyButtonText()}
              </Button>
            </CenteredCol>
          ) : null}
        </Row>
        <NdaModal stoID={ID} isOpen={isOpen} setOpen={setOpen} />
      </StyledCardBody>
    </StyledCard>
  );
};

export default MPXCard;

const StyledCard = styled(Card)`
  display: flex;
`;

export const ImgCard = styled.img`
  min-height: 190px !important;
  max-height: 260px !important;
  object-fit: cover;
  text-align: justify;
  margin: 0;
  flex: 1;
  border-radius: none;

  @media (max-width: 800px) {
    min-height: 150px !important;
    max-height: 150px !important;
  }
`;

const StyledCardBody = styled(CardBody)`
  flex: 2;

  &.action-container {
    margin-bottom: 0.5rem;
  }
`;
