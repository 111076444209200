import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useParams } from 'react-router';

import Auth from 'services/core/auth';

const SsoSignIn: React.FC = () => {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const redirectUrl = query.get('redirectUrl');

  useEffect(() => {
    Auth.signInSSO(token)
      .then((isTwoFactorEnabled) => {
        if (isTwoFactorEnabled) {
          history.push(redirectUrl ? `/two-factor?redirectUrl=${redirectUrl}` : '/two-factor');
        } else {
          history.push(redirectUrl || '/');
        }
      })
      .catch(() => {
        history.push('/sign-in');
      });
  }, [history, token]);

  return <></>;
};

export default SsoSignIn;
