import React from 'react';
import { Modal, ModalBody } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from 'atoms';

interface NdaModalProps {
  stoID: number;
  isOpen: boolean;
  setOpen: (isOpen: boolean) => void;
}

const NdaModal: React.FC<NdaModalProps> = (props) => {
  const { t } = useTranslation();
  const { stoID, isOpen, setOpen } = props;
  const history = useHistory();

  const goToChat = () => history.push(`/investor/chat/${stoID}`);
  return (
    <Modal isOpen={isOpen} toggle={() => setOpen(false)}>
      <ModalBody>
        <h3 className="m-0">{t('NdaModal-label-nda')}</h3>
        <Button color="primary" onClick={goToChat}>
          {t('NdaModal-button-upload')}
        </Button>
      </ModalBody>
    </Modal>
  );
};

export default NdaModal;
