import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
import Favicon from 'react-favicon';
import { Helmet } from 'react-helmet';

import Auth from 'services/core/auth';
import { useInfoQuery, useInvestorAppDataQuery } from 'services/apollo';

import { Card, CardBody, CardHeader, Row, Container, Loading, CenteredCol } from 'atoms';
import { useTranslation } from 'react-i18next';
import LanguageSelector from 'components/LanguageSelector';
import RegistrationForm from './components/RegistrationForm';
import MarketSpaceForm from './components/MarketSpaceForm';

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Registration = memo(() => {
  const { t } = useTranslation();
  const query = useQuery();
  const { data: appData, loading: appDataLoading } = useInvestorAppDataQuery();
  const { data: stoData, loading: stoLoading } = useInfoQuery({ variables: { _id: 0 } });

  const { data, loading: load2 } = useInfoQuery({
    variables: { _id: Number(Auth.STO) },
  });

  if (appDataLoading || load2 || !data || !appData || !stoData || stoLoading) {
    return <Loading />;
  }

  const { registrationText, title } = data.publicSto;
  const {
    IsMarketSpace,
    backgroundImageUrl,
    isCountryFlagsInSelectorEnabled,
    isPublicLanguageSelectorEnabled,
    isMarketSpaceRegistrationFormEnabled,
  } = appData.investorAppParameters;

  const setBackgroundImage = () => {
    const backgroundImageUrlObject = JSON.parse(backgroundImageUrl);
    if (backgroundImageUrlObject.registration) {
      const backgroundImage = backgroundImageUrlObject.registration;
      const backgroundStyle = `{"backgroundImage": "url('${backgroundImage}')", "backgroundSize": "cover"}`;
      return JSON.parse(backgroundStyle);
    }
    return {};
  };

  return (
    <div className="login-page" style={setBackgroundImage()}>
      <Favicon url={data?.publicSto?.settings?.favicon ?? ''} />
      <Helmet>
        <meta charSet="utf-8" />
        <title>{data?.publicSto?.settings?.tabTitle ?? ''}</title>
      </Helmet>
      <Container>
        <Card>
          <CardHeader>
            <Row>
              <CenteredCol>
                <h3>{!IsMarketSpace ? <>{t('Registration-card-header')}</> : null}</h3>
              </CenteredCol>
              <CenteredCol md={3}>
                {isPublicLanguageSelectorEnabled && (
                  <LanguageSelector
                    navigationBar={false}
                    color="info"
                    outline
                    openDirection="left"
                    enableCountryFlags={isCountryFlagsInSelectorEnabled}
                  />
                )}
              </CenteredCol>
            </Row>
          </CardHeader>
          <CardBody>
            {isMarketSpaceRegistrationFormEnabled ? (
              <MarketSpaceForm />
            ) : (
              <RegistrationForm brokerID={query.get('brokerID')} registrationText={registrationText} title={title} />
            )}
          </CardBody>
        </Card>
      </Container>
    </div>
  );
});

export default Registration;
