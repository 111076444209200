import React from 'react';

import { Card, CardBody } from 'atoms';
import { CardHeader } from 'components/card-header/CardHeader';
import { OFFERINGHTMLIDS } from 'pages/active-properties/ActiveProperties';
import { AppData } from 'services/apollo/core';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import OfferingSummaryItem from './OfferingSummaryItem';

interface OfferingSummaryProps {
  appData: AppData;
  activeOffering: number;
  closedOffering: number;
  profileMatchesOffering: number | null;
  setActivePropertyGrid: (value: OFFERINGHTMLIDS) => void;
  activePropertyGrid: OFFERINGHTMLIDS;
}

const OfferingsHeader: React.FC<OfferingSummaryProps> = ({
  appData,
  activeOffering,
  closedOffering,
  profileMatchesOffering,
  setActivePropertyGrid,
  activePropertyGrid,
}) => {
  const { t } = useTranslation();

  const {
    isClosedOfferingsCardEnabled,
    isFilterPreferencesEnabled,
    isActiveOfferingsFilterCardEnabled,
    isClosedOfferingsFilterCardEnabled,
    isProfileMatchesFilterCardEnabled,
  } = appData;

  return (
    <StyledCard twoItem={isClosedOfferingsCardEnabled && isFilterPreferencesEnabled} noBoxShadow>
      <CardHeader text={t('OfferingSummary-Header-Title')} fontWeight="500" />
      <StyledBody twoItem={isClosedOfferingsCardEnabled && isFilterPreferencesEnabled}>
        <OfferingSummaryItem
          title={t('OfferingSummary-Title-Active')}
          count={activeOffering}
          navid={OFFERINGHTMLIDS.ACTIVE_OFFERING}
          setActivePropertyGrid={setActivePropertyGrid}
          active={activePropertyGrid === OFFERINGHTMLIDS.ACTIVE_OFFERING}
          canBeActive={isActiveOfferingsFilterCardEnabled}
        />
        {isFilterPreferencesEnabled && (
          <OfferingSummaryItem
            title={t('OfferingSummary-Title-Profile-Mathes')}
            count={profileMatchesOffering}
            navid={OFFERINGHTMLIDS.PROFILE_MATCHES}
            setActivePropertyGrid={setActivePropertyGrid}
            active={activePropertyGrid === OFFERINGHTMLIDS.PROFILE_MATCHES}
            canBeActive={isProfileMatchesFilterCardEnabled}
          />
        )}
        {isClosedOfferingsCardEnabled && (
          <OfferingSummaryItem
            title={t('OfferingSummary-Title-Closed')}
            count={closedOffering}
            navid={OFFERINGHTMLIDS.CLOSED_OFFERING}
            setActivePropertyGrid={setActivePropertyGrid}
            active={activePropertyGrid === OFFERINGHTMLIDS.CLOSED_OFFERING}
            canBeActive={isClosedOfferingsFilterCardEnabled}
          />
        )}
      </StyledBody>
    </StyledCard>
  );
};

export default OfferingsHeader;

const StyledCard = styled(Card)`
  width: 100%;
  background-color: ${(p) => p.theme.offeringsCardBGColor};
`;

const StyledBody = styled(CardBody)<{ twoItem: boolean }>`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  justify-content: space-between;

  ${(p) =>
    p.twoItem
      ? ''
      : `
    grid-template-columns: repeat(2, 1fr);
  `};

  @media (max-width: 600px) {
    grid-template-columns: repeat(2, 1fr);

    ${(p) =>
      p.twoItem
        ? ''
        : `
        grid-template-columns: repeat(1, 1fr);
        `}
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  } ;
`;
